/*!
    Version: 1.0.0
    Author: Arunachalamoorthy Sethuraja
    Website: http://workativ.com
*/

/* Workativ | Cognitive Automation Solutions */

/************ TABLE OF CONTENTS ***************
1.  Fonts
2.  Reset
3.  Global Settings
4.  Main Header
5.  Main Slider
6.  Dialog Editor
7.  Benefits
8.  Blogs Future Communication
9.  Latest Event
10. How it Works
11. Try Now
10. Signup Free Demo
11. Main Footer
12. Design Transform
13. Explore our best features
14. Capabilities
15. Intergations
16. Know How Cognidesk
17. Future Communication
18. AI Adoption Space
19. Internet of Everything
20. Killer AI Project
21. Space Explorations
22. Statistics Boggle
23. Whooping Percent
24. We believe in making
25. Timeline Year
26. Skitter was born out
27. Our Mission
28. Make an impact
29. Timeline
30. Automation Catalog
31. Designed Transform Support
32. Digial Channels
33. Digial Channels Integrate
34. Active Directory Tabs
35. Contact Us
36. Signup form
37. Select Box
38. Dropdown list as ul li method
39. Home Page Responsive for Landscape and Portrait
40. Automation Page Responsive for Landscape and Portrait
41. Integrations Page Responsive for Landscape and Portrait
42. Contact Us Page Responsive for Landscape and Portrait
43. Sign-up Page Responsive for Landscape and Portrait
44. About Us Page Responsive for Landscape and Portrait
45. 404 Error Page
46. Timeline Year Wise Workplace
47. Timeline Year Responsive for Landscape and Portrait
48. Top Header


**********************************************/

@font-face {
  font-family: Montserrat-Bold;
  src: url("../fonts/Montserrat-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../fonts/Montserrat-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Gordita-Bold;
  src: url("../fonts/Gordita-Bold.woff2") format("woff2"),
    url("../fonts/Gordita-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Gordita-Medium;
  src: url("../fonts/Gordita-Medium.woff2") format("woff2"),
    url("../fonts/Gordita-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Gordita-Regular;
  src: url("../fonts/Gordita-Regular.woff2") format("woff2"),
    url("../fonts/Gordita-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Gordita-Thin;
  src: url("../fonts/Gordita-Thin.woff2") format("woff2"),
    url("../fonts/Gordita-Thin.woff") format("woff");
  font-display: swap;
}

/*** 

====================================================================
	Reset
====================================================================

 ***/

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-size: 14px;
  color: #505050;
  line-height: 1.6em;
  font-weight: 400;
  background: #fff;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto-Medium;
  font-weight: normal;
  margin: 0px;
  background: none;
}

input,
button,
select,
textarea {
  font-family: Gordita-Regular;
}

/*** 

====================================================================
	Main Header
====================================================================

    ***/

.header-logo ul li a {
  font-size: 15px;
  color: #1f3750 !important;
  font-family: "Roboto-Medium";
  /* text-transform: lowercase; */
  padding: 0;
  line-height: 1.25;
}

/* .header-logo ul li {
  padding: 10px;
  cursor: pointer;
  padding-left:0px;
} */

.hamburger {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.hamburger svg {
  font-size: 25px;
  color: #494949;
}

.nav-offcanvas {
  width: 300px;
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
  height: 100vh;
  position: fixed;
  overflow-x: auto;
  z-index: 2000;
  /* padding: 50px 0; */
  top: 0;
  right: -1200px;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.15);
}

.nav-offcanvas .close {
  position: absolute;
  right: -15px;
  top: 20px;
  outline: 0;
  opacity: 1;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.nav-offcanvas .close i {
  font-size: 15px;
  color: #333333;
  text-shadow: none;
  position: relative;
  top: -8px;
  right: 5px;
}

.nav-offcanvas .close:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav-offcanvas-menu ul {
  margin-bottom: 0;
  padding: 0px 40px;
  list-style: none;
}

.nav-offcanvas-menu ul li a {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 40px;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
}

.nav-offcanvas-menu ul li a:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.nav-offcanvas.open {
  right: 0;
  width: 100%;
}

.btn-large.mt-3 a {
  color: #fff;
  cursor: pointer;
}

.offcanvas-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1999;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
  opacity: 0;
}

.offcanvas-overlay.on {
  opacity: 1;
  visibility: visible;
}

.free_trial {
  background: #1757dc;
  color: #fff;
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
}

.navbar-nav .free_trial a {
  color: #fff !important;
  font-size: 14px;
  padding: 0px !important;
}

.slider_buton ul {
  padding-left: 0;
}

.slider_buton ul li a {
  text-transform: uppercase;
}

.download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.nav-link.nav_link_active {
  color: rgb(23, 99, 255) !important;
}

.nav-offcanvas-menu ul li .nav_link_active {
  color: rgb(23, 99, 255) !important;
}

.hamburger svg {
  font-size: 25px;
  color: #494949;
  display: none;
}

.header-logo .free_trial {
  background: #2e6dfa;
  color: #fff;
  border-radius: 0px;
  width: 112px;
  text-align: center;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  border: none;
  outline: 0;
  cursor: pointer;
}

.header-logo .free_trial:hover {
  transform: scale(1.012);
}

.header-logo img {
  width: 140px;
}

.header-logo {
  padding: 0px !important;
  /* margin: 15px 0px; */
  width: 100%;
}

.header.sticky {
  background: #fff !important;
}

.sticky {
  position: fixed;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  top: 0;
  box-shadow: 0 3px 5px -5px #ccc;
  height: 60px;
  padding-top: 0px;
  transition: transform 0.5s ease;
  display: flex;
  align-items: center;
  animation: smoothScroll 0.5s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.sticky .header-logo img {
  width: 140px;
}

.header.sticky .sticky_pad .sticky_pad_0 {
  padding-bottom: 15px;
}

.svg_roate_icon svg {
  transform: rotate(180deg) !important;
}

.submenu_ul .svg_roate_icon_inside svg {
  transform: rotate(180deg) !important;
}

/* .sticky .header-logo {
  margin: 15px 0px;
} */

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(23, 99, 255) !important;
}

.navbar-light .navbar-nav .nav-link.trial-white:focus,
.navbar-light .navbar-nav .nav-link.trial-white:hover {
  color: rgb(255, 255, 255) !important;
}

/*** 

====================================================================
	Main Slider
====================================================================

	***/

.main-slider-left h1 {
  font-family: "Gordita-Bold";
  font-size: 40px;
  color: #000000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: left;
}

.main-slider-left {
  padding: 4em 0;
}

.main-slider-right img {
  width: 100%;
}

.main-slider-left p {
  font-size: 18px;
  color: #485a63;
  font-family: "Gordita-Regular";
  line-height: 30px;
  margin-bottom: 10px;
}

.main-slider {
  padding: 0px 0px 0px 0px;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  float: left;
}

.main-slider.partner_slider .main-slider-right img {
  padding: 40px 0;
}

.main-slider.partner_slider {
  margin-bottom: 0px;
  width: 100%;
  float: left;
}

.main-slider.partner_slider .main-slider-left {
  padding: 40px 0;
}

.free-trial {
  font-family: "Roboto-Medium";
  background: #2e6dfa;
  color: #fff;
  border-radius: 0px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  transition: transform 0.5s ease;
}

.free-trial a {
  color: #fff !important;
  font-size: 16px;
}

.main-slider-button ul {
  padding-left: 0;
}

/* .main-slider-button ul li a {
  text-transform: uppercase;
} */

.main-download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.main-slider-button ul li {
  display: inline-block;
}

.main-slider-button ul li a {
  color: #2468f6;
}

.main-slider-button ul li:nth-child(2) a:hover {
  color: #1757dc;
  text-decoration: underline;
}

.main-slider-left p a {
  color: #1757dc;
}

.main-slider-right img {
  padding: 6em 0;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
  background-color: rgba(255, 255, 255, 0.6784313725490196);
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}

/*** 

====================================================================
	Dialog Editor
====================================================================

	***/

.dialog-editor-content h5 {
  color: #333333;
  font-size: 24px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.dialog-editor-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 10em;
}

.dialog-editor-list ul {
  padding-left: 0px;
}

.dialog-editor-list ul li {
  display: inline-block;
  margin: 0 28px;
}

.dialog-editor-list ul li img {
  width: 160px;
}

.dialog-editor-list {
  text-align: center;
  margin-bottom: 100px;
}

.dialog-editor .carousel-inner img {
  padding-bottom: 20px;
}

img.d-block.w-100 {
  width: 30% !important;
  margin: 0 auto;
}

.dialog-editor .carousel-indicators {
  position: relative;
}

.dialog-editor .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2889e8;
  margin-bottom: 0px;
}

.our-technology-partners-content h5 {
  color: #adbec6;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  letter-spacing: 3px;
}

/*** 

====================================================================
	Benefits
====================================================================

	***/

.benefits-workplace {
  /* background-image: url(../images/benefits.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 600px 600px; */
  margin-top: 150px;
}

.benefits-works-content h3 {
  color: #333333;
  font-size: 32px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 40px;
}

.benefits-works-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Gordita-Regular";
  line-height: 30px;
  text-align: center;
  margin-bottom: 75px;
  padding: 0 13em;
}

.benefits-workplace-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 25px;
  font-family: "Gordita-Bold";
  line-height: 1.3;
}

.benefits-workplace-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Gordita-Regular";
  line-height: 30px;
  margin-bottom: 60px;
}

.benefits-workplace-content img {
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
}

.analysts-perspective-content h5 {
  color: #adbec6;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  letter-spacing: 3px;
}

/*** 

====================================================================
	Blogs Future Communication
====================================================================

    ***/

.blogs-future-communication {
  padding: 100px 0;
}

.blogs-future-communication-content h6 {
  font-family: "Roboto-Regular";
  font-size: 16px;
  color: #adbec6;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
  letter-spacing: 3px;
}

.blogs-future-communication-content h1 {
  font-family: "Roboto-Bold";
  font-size: 50px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 40px;
  text-align: center;
}

.blogs-future-communication-yellow {
  background-color: #ffffdb;
  border-radius: 15px;
  padding: 40px 30px !important;
  margin-bottom: 30px;
}

.blogs-future-communication-yellow h3 {
  font-family: Montserrat-SemiBold;
  font-size: 30px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.blogs-future-communication-yellow p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.blogs-future-communication-blue {
  background-color: #daf8ff;
  border-radius: 15px;
  padding: 40px 30px !important;
  margin-bottom: 30px;
}

.blogs-future-communication-blue h3 {
  font-family: Montserrat-SemiBold;
  font-size: 30px;
  line-height: 1.57;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.blogs-future-communication-blue p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 38px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.blogs-future-communication-blue img {
  width: 100%;
  margin-bottom: 40px;
}

.blogs-future-communication-blue h3 a {
  color: #333333;
  text-decoration: none;
  padding: 0;
}

.blogs-future-communication-yellow img {
  width: 100%;
  margin-bottom: 40px;
}

.blogs-future-communication-cyan {
  background-color: #daf8ff;
  border-radius: 15px;
  padding: 40px 30px !important;
  margin-bottom: 30px;
}

.blogs-future-cyan-right img {
  width: 100%;
}

.blogs-future-cyan-left h3 {
  font-family: Montserrat-Semibold;
  font-size: 28px;
  line-height: 1.36;
  letter-spacing: -0.9px;
  color: #333333;
  padding: 0px 0 40px 0;
}

.blogs-future-communication-lavender {
  background-color: #d9e4ff;
  border-radius: 15px;
  padding: 40px 30px !important;
  margin-bottom: 30px;
}

.blogs-future-lavender-right img {
  width: 100%;
}

.assiatant-press-release-left {
  background-color: #fef8e1;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 30px;
}

.assiatant-press-release-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 30px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  text-align: left;
  margin-bottom: 0;
}

.hdi-ai-project-right {
  background: #e1edfe;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 30px;
}

.assiatant-press-release-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.assiatant-press-release-left img {
  width: 100%;
}

.why-assistant-left {
  background-color: #d6ffff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 30px;
}

.why-assistant-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  text-align: left;
  margin-bottom: 0;
}

.why-assistant-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.why-assistant-left img {
  width: 100%;
}

.what-is-assistant-left {
  background-color: #f5f6fd;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.what-is-assistant-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 0px;
  text-align: left;
}

.what-is-assistant-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.what-is-assistant-left img {
  width: 100%;
}

.what-is-assistant-left h3 a {
  color: #333333;
  text-decoration: none;
}

.why-assistant-left h3 a {
  color: #333333;
  text-decoration: none;
}

.blogs-future-lavender-left h3 {
  font-family: Montserrat-Semibold;
  font-size: 30px;
  line-height: 1.36;
  letter-spacing: -0.9px;
  color: #333333;
  padding: 0px 0 40px 0;
}

.blogs-future-communication-pink {
  background-color: #ffebed;
  border-radius: 15px;
  padding: 40px 30px !important;
  margin-bottom: 30px;
}

.blogs-future-pink-right img {
  width: 100%;
}

.blogs-future-pink-left h3 {
  font-family: Montserrat-Semibold;
  font-size: 28px;
  line-height: 1.36;
  letter-spacing: -0.9px;
  color: #333333;
  padding: 0 0 40px 0;
}

.blogs-future-communication-yellow h3 a {
  color: #333333;
}

.blogs-future-cyan-left h3 a {
  color: #333333;
  text-decoration: none;
}

/*** 

====================================================================
	Latest Event
====================================================================

	***/

.latest-events-image img {
  width: 100%;
}

.latest-events-content h6 {
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #adbec6;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.latest-events-content h1 {
  font-family: "Roboto-Bold";
  font-size: 50px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 40px;
}

.latest-events-content p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.latest-events-content p b {
  font-family: "Roboto-Bold";
}

.latest-events-button ul li {
  display: inline-block;
}

.latest-events-button ul {
  padding-left: 0;
}

.latest-events-button ul li a {
  text-transform: uppercase;
}

.latest-events-button .free-trial a {
  color: #2468f6 !important;
  font-size: 16px;
}

.latest-events-button .free-trial {
  font-family: "Roboto-Medium";
  background: #ffffff;
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  border: #2468f6 2px solid;
}

.latest-events-button .free-trial:hover {
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  border: #1757dc 2px solid;
  transform: scale(1.012);
}

.latest-events-button .free-trial a:hover {
  color: #ffffff !important;
  font-size: 16px;
}

.latest-events-image {
  padding: 2em 0;
}

.latest-events {
  margin-bottom: 100px;
}

/*** 

====================================================================
	How it Works
====================================================================

	***/

.how_its_works_form .modal-header .close {
  width: 22px;
  height: 22px;
  position: relative;
  left: -15px;
}

.how-it-works-content img {
  width: 100%;
  margin-bottom: 0px;
}

.how-it-works-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 25px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
}

.how-it-works-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
}

.how-it-works-content h5 {
  font-size: 28px;
  font-family: Roboto-Medium;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: -0.1px;
  color: #333333;
}

.how-it-works {
  padding: 150px 0;
}

.how-it-pointer img {
  width: 4%;
  margin-bottom: 30px;
}

.how-it-pointer {
  text-align: center;
}

.left-works {
  margin-top: 40px;
}

.right-works {
  margin-top: 40px;
}

.center-works {
  margin-top: 40px;
}

/*** 

====================================================================
	Try Now
====================================================================

	***/

.try-now-content h2 {
  font-family: "Roboto-Bold";
  font-size: 40px;
  color: #333333;
  letter-spacing: 1.5;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 2em;
}

.try-now-content p {
  font-size: 16px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 11em;
}

.try-now-content img {
  width: 100%;
  margin-bottom: 35px;
}

.try-now-button ul {
  padding-left: 0;
}

.try-now-button ul li {
  display: inline-block;
}

.free-try-now {
  background: #1757dc;
  color: #fff;
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
}

.free-try-now a {
  color: #fff !important;
  font-size: 16px;
}

.try-now-button ul li a {
  text-transform: uppercase;
}

.try-now-button {
  text-align: center;
}

.try-now-content h5 {
  color: #adbec6;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 3px;
}

/*** 

====================================================================
	Signup Free Demo
====================================================================

	***/

.signup-left-demo h2 {
  font-family: "Roboto-Bold";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 30px;
}

.signup-right-demo img {
  width: 100%;
}

.signup-left-demo p {
  font-size: 16px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 30px;
}

.signup-demo {
  padding: 180px 0;
}

.free-trial:hover {
  color: #fff;
  border-radius: 0px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  transform: scale(1.012);
}

.free-trial a {
  color: #fff !important;
  font-size: 16px;
}

.signup-slider-button ul {
  padding-left: 0;
}

.signup-slider-button ul li a {
  text-transform: uppercase;
}

.download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.signup-slider-button ul li {
  display: inline-block;
}

.signup-slider-button ul li a {
  color: #1757dc;
}

.contact_form_success p {
  padding: 0 10px;
}

/*** 

====================================================================
	Main Footer
====================================================================

	***/

.main-footer {
  background: rgb(246, 251, 252);
  width: 100%;
  float: left;
}

.text-center {
  text-align: center;
}

.footer-border {
  border-top: #d0d8db 1.5px solid;
}

.text-footer {
  color: #485a63;
  font-family: "Roboto-Regular";
  font-size: 14px;
  text-transform: uppercase;
  padding: 30px 0 30px 0;
}

.footer-content h3 {
  font-family: "Roboto-Bold";
  color: #485a63;
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 40px;
  margin-bottom: 40px;
}

.widget-content ul li {
  list-style-type: none;
  margin-bottom: 15px;
}

.widget-content ul li a {
  list-style-type: none;
  font-size: 14px;
  color: #485a63;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-decoration: none;
}

.widget-content ul li a:hover {
  color: rgba(0, 0, 0, 0.7);
}

.main-footer .social {
  position: relative;
  margin-left: 33px;
  margin-bottom: 15px;
}

.main-footer .social a {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 22px;
  padding: 3px;
  text-align: center;
  color: #485a63;
  background: none;
  margin-right: 3px;
  text-decoration: none;
}

.footer-content a img {
  width: 220px;
  /*padding-left: 40px;*/
  margin-bottom: 30px;
}

/*** 

====================================================================
	Design Transform
====================================================================

    ***/

.design-transform {
  padding: 80px 0 100px 0;
}

.design-transform h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 70px;
  padding: 0 40px;
  margin-bottom: 40px;
}

.design-transform p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
}

/*** 

====================================================================
	Explore our best features
====================================================================

    ***/

.explore-best-fetures {
  text-align: center;
}

.explore-best-fetures h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.explore-best-fetures img {
  margin-bottom: 40px;
}

.features_tab_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 100px;
}

.features_tab_inner .features_tab_left {
  width: 40%;
  float: left;
}

.features_tab_inner .features_tab_left .features_tab {
  max-width: 620px;
  margin-right: 70px;
  border: 0px;
  position: relative;
  width: auto;
  left: 0;
  bottom: 0;
  text-align: left;
  display: block;
  margin-left: 15em;
  padding: 3em 0;
}

.features_tab_inner .features_tab_left .features_tab li {
  margin: 0px 0px 10px;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 25px 25px 25px 25px;
  height: 100%;
  text-indent: inherit;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 0px solid #eee;
  position: relative;
}

.features_tab_inner .features_tab_left .features_tab li .tab_icon {
  position: absolute;
  left: 30px;
  top: 30px;
}

.features_tab_inner .features_tab_left .features_tab li .heading {
  font-size: 18px;
  line-height: 28px;
  color: #555555;
  font-weight: 600;
  display: block;
  position: relative;
  padding-bottom: 5px;
}

.features_tab_inner .features_tab_left .features_tab li .summary {
  font-size: 15px;
  line-height: 25px;
  color: #555555;
}

.features_tab_inner .features_tab_left .features_tab li.active,
.features_tab_inner .features_tab_left .features_tab li:hover,
.features_tab_inner .features_tab_left .features_tab li:focus {
  background-color: white;
  /* -webkit-box-shadow: 0px 8px 26px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 8px 26px 2px rgba(0, 0, 0, 0.05); */
}

.features_tab_inner .features_tab_right {
  width: 60%;
  float: left;
}

.features_tab_inner .features_tab_right .carousel-inner {
  padding-right: 10px;
  text-align: center;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  padding-left: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.features_tab_inner .features_tab_right .carousel-inner .carousel-item {
  opacity: 0;
  left: 30px;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  padding-bottom: 23px;
  padding-top: 10px;
}

.features_tab_inner .features_tab_right .carousel-inner img {
  -webkit-box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.25);
  position: relative;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  max-width: 100%;
}

.features_tab_inner .features_tab_right .carousel-inner .active {
  opacity: 1;
  visibility: visible;
  display: block;
  left: 0;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

.features_tab_inner .features_tab_right .carousel-inner .active img {
  left: 0;
  opacity: 1;
}

/*** 

====================================================================
	Capabilities
====================================================================

	***/

.capabilities-separator {
  text-align: center;
}

.capabilities-workplace h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.capabilities-workplace .capabilities-content img {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.capabilities-workplace img {
  margin-bottom: 40px;
}

.capabilities-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
  text-align: left;
}

.capabilities-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 100px;
  text-align: left;
}

/*** 

====================================================================
	Intergations
====================================================================

    ***/

.interations-content h3 {
  color: #333333;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.interations-content p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  text-align: center;
  padding: 0 12em;
  margin-bottom: 0;
}

.interations-separator h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.interations-separator img {
  margin-bottom: 40px;
}

.interations-separator-center {
  text-align: center;
}

.integration-project-wrap {
  padding: 100px 0 150px 0;
}

.integration-content-wrap ul li {
  display: inline-block;
  padding: 0 0.5px;
}

.integration-jira {
  background-color: #eceff1;
  padding: 20px 25px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #e4e4e4;
}

.integration-jira p {
  font-size: 10px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-amazon {
  background-color: #eceff1;
  padding: 20px 35px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  border: 1px solid #e4e4e4;
}

.integration-amazon p {
  font-size: 10px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-slack {
  background-color: #eceff1;
  padding: 22px 30px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 1px solid #e4e4e4;
}

.integration-slack p {
  font-size: 10px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-atlassian {
  background-color: #eceff1;
  padding: 15px 22px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #e4e4e4;
}

.integration-atlassian p {
  font-size: 10px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-cognidesk {
  background-color: #eceff1;
  padding: 40px;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  border: 1px solid #e4e4e4;
  box-shadow: 10px 20px 80px 0 #cfd8dc;
  margin: 50px 0;
}

.integration-cognidesk p {
  font-size: 12px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-content-wrap ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.integration-content-wrap ul li {
  display: table-cell;
  vertical-align: top;
  opacity: 0;
  transform: scale(0.6, 1);
}

.integration-content-wrap ul li:nth-child(even) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(1) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(2) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(3) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(4) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(6) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(7) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(8) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(9) {
  vertical-align: bottom;
}

.integration-content-wrap ul li {
  opacity: 1;
  transform: scale(1, 1);
}

/*** 

====================================================================
	Know How Cognidesk
====================================================================

    ***/

.know-how-assistant {
  background-color: #eceff1;
  padding: 40px 0;
}

.know-how-assistant-content h3 {
  font-size: 50px;
  font-family: "Roboto-Bold";
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: left;
  color: #333333;
  margin-bottom: 40px;
}

.know-how-assistant-content .start-free-trail {
  text-align: left;
}

.start-free-trail a {
  font-family: "Gordita-Medium";
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background-color: #2468f6;
  padding: 16px 50px;
  border-radius: 30px !important;
  /* text-transform: uppercase; */
}

.start-free-trail a:hover {
  color: #ffffff;
  text-decoration: none;
}

.know-how-assistant-image img {
  width: 100%;
}

.know-how-assistant-content p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 1.54;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  margin-right: 6em;
}

/*** 

====================================================================
	Future Communication
====================================================================

    ***/

.future-communication {
  padding: 100px 0 150px 0;
}

.future-communication h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: Montserrat-SemiBold;
  font-size: 64px;
  padding: 0 0px;
  margin-bottom: 40px;
}

.future-communication p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
}

/*** 

====================================================================
	AI Adoption Space
====================================================================

    ***/

.ai-adoption-content {
  background-color: #ffffdb;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.ai-adoption-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 38px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.ai-adoption-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button ul {
  padding-left: 0px;
}

.ai-adoption-left {
  padding: 20px 0;
}

.ai-adoption-right {
  text-align: center;
}

.ai-adoption-right img {
  width: 100%;
}

/*** 

====================================================================
	Internet of Everything
====================================================================

    ***/

.internet-of-everything-content {
  background-color: #daf8ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.internet-of-everything-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 38px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.internet-of-everything-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button ul {
  padding-left: 0px;
}

.internet-of-everything-left {
  padding: 20px 0;
}

.internet-of-everything-right {
  text-align: center;
}

.internet-of-everything-right img {
  width: 72%;
}

/*** 

====================================================================
	Killer AI Project
====================================================================

    ***/

.killer-ai-project-left {
  background-color: #ffebed;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.container.killer-ai-project-padding {
  padding: 0px;
}

.read-more-button {
  text-align: left;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button ul {
  padding-left: 0px;
}

.killer-ai-project-left img {
  margin-bottom: 40px;
}

.killer-ai-project-left {
  text-align: center;
}

.killer-ai-project-right {
  background-color: #d9e4ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-right h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-right p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-right img {
  margin-bottom: 40px;
}

.killer-ai-project-right {
  text-align: center;
}

.killer-ai-project-left-green {
  background-color: #a7ffeb;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-left-green h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-left-green p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project {
  margin-bottom: 110px;
}

.killer-ai-project-left img {
  width: 100%;
}

.killer-ai-project-right img {
  width: 100%;
}

/*** 

====================================================================
	Space Explorations
====================================================================

    ***/

.space-explorations {
  padding: 25px 0 40px 0;
  width: 100%;
  float: left;
}

.space-explorations-content {
  text-align: center;
}

.space-explorations h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Gordita-Bold";
  font-size: 64px;
  padding: 0 0px;
  margin-bottom: 0px;
}

.space-explorations h6 {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 40px;
}

.space-explorations p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: left;
  color: #485a63;
  margin-bottom: 40px;
  padding: 0 7.5em;
}

.read-more-button-center {
  text-align: center;
}

.read-more-button-center ul li {
  display: inline-block;
}

.read-more-button-center ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button-center ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button-center ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

/*** 

====================================================================
	Statistics Boggle
====================================================================

    ***/

.statistics-boggle-content p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 1.67;
  text-align: left;
  color: #485a63;
  margin-bottom: 40px;
  padding: 0 7.5em;
}

.statistics-boggle-content h3 {
  font-family: "Roboto-Bold";
  font-size: 28px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 40px;
  padding: 0 4.2em;
}

/*** 

====================================================================
	Whooping Percent
====================================================================

    ***/

.whooping-percent-bg {
  background-color: #ffffdb;
  margin-bottom: 40px;
}

.whooping-percent-content h4 {
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.58;
  letter-spacing: -1.6px;
  text-align: center;
  color: #333333;
  padding: 53px 120px;
}

ul.statistics-boggle-list {
  padding-bottom: 40px;
  width: 60%;
  margin: 0 auto;
  color: #485a63;
}

.john-joe-list {
  padding: 40px 0;
  border-top: 1px solid #cfd8dc;
  border-bottom: 1px solid #cfd8dc;
  margin-bottom: 100px;
}

.row-picture-left {
  float: left;
  display: inline-block;
  padding-right: 16px;
}

.row-picture-left a img {
  width: 100px;
  height: 100px;
}

.row-text-content h5 {
  font-family: Roboto-Medium;
  font-size: 18px;
  color: #485a63;
  margin-bottom: 12px;
}

.statistics-boggle-content span {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 1.67;
  text-align: left;
  color: #485a63;
}

.row-text-content {
  padding: 10px 0;
}

a.share-text {
  font-family: "Roboto-Regular";
  font-size: 16px;
  color: #485a63;
  text-transform: uppercase;
}

.row-social-content a {
  color: #485a63;
  padding-left: 20px;
}

.row-social-content {
  padding: 40px 0;
  text-align: right;
}

.read-next-content {
  font-family: "Roboto-Regular";
  font-size: 24px;
  text-align: center;
  color: #485a63;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.space-explorations-content img {
  width: 100%;
}

.blog_details_bg {
  height: 100%;
  background: linear-gradient(180deg, #ffffdb 70%, #ffffff 30%);
}

/*** 

====================================================================
	We believe in making
====================================================================

    ***/

.we-believe-in-making {
  padding: 60px 0 100px 0;
}

.we-believe-in-making h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 65px;
  margin-bottom: 40px;
}

.we-believe-in-making p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
}

/*** 

====================================================================
    Timeline Year
====================================================================

    ***/

.pos-rel {
  position: relative;
}

.pos-rel .nav-item {
  margin: 0 1.5em;
}

.connected-line {
  border-bottom: 2px solid #eceff1;
  position: relative;
  top: -10px;
  z-index: -1;
}

.seminor-login-modal-body .close {
  position: relative;
  top: -45px;
  left: 10px;
  color: #1cd8ad;
}

.seminor-login-modal-body .close {
  opacity: 0.75;
}

.seminor-login-modal-body .close:focus,
.seminor-login-modal-body .close:hover {
  color: #39e8b0;
  opacity: 1;
  text-decoration: none;
  outline: 0;
}

.seminor-login-modal .modal-dialog .modal-content {
  border-radius: 0px;
}

.seminor-login-form .form-group {
  position: relative;
  margin-bottom: 1.5em !important;
}

.seminor-login-form .form-control {
  border: 0px solid #ced4da !important;
  border-bottom: 1px solid #adadad !important;
  border-radius: 0 !important;
}

.seminor-login-form .form-control:focus,
.seminor-login-form .form-control:active {
  outline: none !important;
  outline-width: 0;
  border-color: #adadad !important;
  box-shadow: 0 0 0 0.2rem transparent;
}

.seminor-login-form {
  padding: 2em 0 0;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  border-top: 0px;
  border-left: 0;
  border-right: 0;
}

.form-control:focus+.form-control-placeholder,
.form-control:valid+.form-control-placeholder {
  font-size: 75%;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-box {
  position: absolute;
  top: -5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #adadad;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.1;
}

.container-checkbox input:checked~.checkmark-box:after {
  color: #fff;
}

.container-checkbox input:checked~.checkmark-box:after {
  display: block;
}

.container-checkbox .checkmark-box:after {
  left: 10px;
  top: 4px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark:after,
.checkmark-box:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked~.checkmark-box {
  background-color: #f58220;
  border: 0px solid transparent;
}

.btn-check-log .btn-check-login {
  font-size: 16px;
  padding: 10px 0;
}

button.btn-check-login:hover {
  color: #fff;
  background-color: #f58220;
  border: 2px solid #f58220;
}

.btn-check-login {
  color: #f58220;
  background-color: transparent;
  border: 2px solid #f58220;
  transition: all ease-in-out 0.3s;
}

.btn-check-login {
  display: inline-block;
  padding: 12px 0;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 0;
  width: 100%;
}

.forgot-pass-fau a {
  text-decoration: none !important;
  font-size: 14px;
}

.text-primary-fau {
  color: #1959a2;
}

.select-form-control-placeholder {
  font-size: 100%;
  padding: 7px 0 0 13px;
  margin: 0;
}

.timeline-year-workplace {
  margin-bottom: 100px;
}

.timeline-year-workplace .nav-link.active {
  background-color: #eceff1 !important;
  color: #273237 !important;
  border-color: #eceff1 !important;
  border-radius: 0px;
  padding: 1rem 1rem;
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0) !important;
}

.timeline-year-workplace .nav-link {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #273237 !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 1rem 1rem;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0) !important;
}

/*** 

====================================================================
    Skitter was born out
====================================================================

    ***/

.workativ-separator {
  text-align: center;
}

.workativ-was-born-out h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.workativ-was-born-out h2 {
  font-family: "Roboto-Bold";
  font-size: 50px;
  letter-spacing: -0.2px;
  color: #333333;
  text-align: left;
  margin-bottom: 48px;
}

.workativ-was-born-out .workativ-content img {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.workativ-was-born-out img {
  margin-bottom: 40px;
}

.workativ-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
  text-align: left;
}

.workativ-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 50px;
  text-align: left;
}

/*** 

====================================================================
    Our Mission
====================================================================

    ***/

.our-mission-workplace {
  padding: 100px 0;
}

.our-mission-workplace h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.our-mission-workplace h2 {
  font-family: "Roboto-Bold";
  font-size: 50px;
  letter-spacing: -0.2px;
  color: #333333;
  text-align: left;
  margin-bottom: 40px;
}

.our-mission-workplace p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 30px;
  text-align: left;
}

.our-mission-images img {
  width: 100%;
  margin: 40px 0;
}

/*** 

====================================================================
    Make an impact
====================================================================

    ***/

.make-an-impact-workplace {
  text-align: center;
  margin-bottom: 30px;
}

.make-an-impact-workplace h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.make-an-impact-workplace h2 {
  font-family: "Roboto-Bold";
  font-size: 50px;
  letter-spacing: -0.2px;
  color: #333333;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 40px;
}

.make-an-impact-workplace p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 40px;
  padding: 0 13.5em;
  text-align: center;
}

.submit-your-resume a {
  font-family: "Roboto-Regular";
  font-size: 16px;
  text-align: center;
  color: #2468f6;
  background-color: #ffffff;
  border: 2px solid #2468f6;
  padding: 12px 30px;
  border-radius: 6px;
  text-transform: uppercase;
}

.submit-your-resume a:hover {
  color: #ffffff;
  border: 2px solid #1757dc;
  background-color: #1757dc;
  text-decoration: none;
}

/*** 

====================================================================
	Timeline
====================================================================

    ***/

.MultiCarousel {
  float: left;
  overflow: visible;
  padding: 15px;
  width: 100%;
  position: relative;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
  width: 4000px !important;
  overflow: hidden;
  padding: 40px 0 0;
  border-bottom: 1px solid #adbec6;
  margin-bottom: 40px;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
  width: 316px !important;
}

.MultiCarousel .MultiCarousel-inner .item>div {
  text-align: left;
  padding: 0 10px;
  margin: 0 10px;
  color: #485a63;
}

.MultiCarousel .leftLst,
.MultiCarousel .rightLst {
  border-radius: 50%;
  top: calc(50% - 20px);
}

.MultiCarousel .leftLst {
  left: 0;
}

.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over,
.MultiCarousel .rightLst.over {
  pointer-events: none;
  background: #ccc;
}

.lead-image img {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.lead-image p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 1.5;
  color: #485a63;
  margin-right: 1em;
  text-align: left;
  min-height: 50px;
}

.lead-image p b {
  font-family: "Roboto-Bold";
}

.timeline-top-content {
  margin-top: 100px;
}

.MultiCarousel {
  text-align: center;
}

.MultiCarousel span {
  font-family: "Roboto-Bold";
  font-size: 24px;
  text-align: center;
  padding: 0 20px;
  color: #4548ff;
}

span.separator-timeline {
  padding: 0;
}

.separator-timeline img {
  height: 140px;
  width: 0.5%;
  margin-bottom: 0px;
}

span.separator-timeline-height {
  padding: 0;
}

.separator-timeline-height img {
  height: 40px;
  width: 0.5%;
  margin-bottom: 0px;
}

/*** 

====================================================================
	Automation Catalog
====================================================================

    ***/

.automation-catalog-workplace {
  padding: 80px 0 100px 0;
}

.automation-catalog-workplace h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 65px;
  margin-bottom: 40px;
}

.automation-catalog-workplace p {
  font-family: "Roboto-Regular";
  font-size: 22px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
  padding: 0px 8em;
}

/*** 

====================================================================
	Designed Transform Support
====================================================================

    ***/

.designed-transform-support {
  padding: 80px 0 60px 0;
}

.designed-transform-support h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 65px;
  margin-bottom: 40px;
}

.designed-transform-support p {
  font-family: "Roboto-Regular";
  font-size: 22px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
  padding: 0px 8em;
}

/*** 

====================================================================
	Digial Channels
====================================================================

    ***/

.digital-channels {
  margin-bottom: 100px;
}

ul.digital-channels-list li {
  width: 190px;
  height: 190px;
  border-radius: 30px;
  background-color: #eceff1;
}

.digital-channels-content ul li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 40px;
  align-items: center;
  padding: 50px;
}

.digital-channels-content ul li img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

/*** 

====================================================================
	Digial Channels Integrate
====================================================================

    ***/

.digital-channels-integrate {
  margin-bottom: 100px;
}

ul.digital-channels-integrate-list li {
  width: 190px;
  height: 190px;
  border-radius: 30px;
  background-color: #eceff1;
}

.digital-channels-integrate-content ul li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 40px;
  align-items: center;
  padding: 50px;
}

.digital-channels-integrate-content ul li img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.digital-channels-integrate-content h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

.digital-channels-integrate-content {
  text-align: center;
}

.digital-channels-integrate-content img {
  margin-bottom: 100px;
}

.digital-channels-integrate-content p {
  font-family: "Roboto-Regular";
  font-size: 22px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 40px;
  padding: 0px 8em;
}

/*** 

====================================================================
	Active Directory Tabs
====================================================================

    ***/

.vertical-tabs {
  padding: 60px;
  background: #f4f4f4;
  position: relative;
}

.nav-tabs .nav-item {
  margin-bottom: 0px !important;
}

.active-directory-tabs {
  margin-bottom: 80px;
}

.vertical-tabs .nav-tabs .nav-link {
  text-align: left;
  border: none;
  height: 60px;
  width: 280px;
  background-color: #ffffff;
  color: #333333;
  border-color: #ffffff;
  font-size: 14px;
  display: flex;
  padding: 0px;
  padding-left: 15px;
  align-items: center;
  border-radius: 0px;
  border-bottom: 1px solid #ccc;
}

.vertical-tabs .nav-tabs .nav-link:last-child {
  border-bottom: 1px solid #f4f4f4;
}

.vertical-tabs .row {
  margin: 0px;
}

.sv-tab-panel h5 {
  font-size: 18px;
  font-family: Roboto-Medium;
  color: #494949;
  margin: 30px 0px;
}

.sv-tab-panel h4 {
  font-size: 24px;
  font-family: Roboto-Medium;
}

.sv-tab-panel p svg {
  width: 22px;
  height: 22px;
  color: #4e97f3;
  margin-right: 10px;
}

.vertical-tabs .nav-tabs .nav-link:hover {
  border-bottom: 1px solid #f4f4f4;
}

.vertical-tabs .nav-tabs .nav-link.active {
  background-color: #f4f4f4;
  color: #333333;
  border: none;
  display: flex;
  align-items: center;
  border-left: 3px solid #ff9f02;
  border-radius: unset;
  color: #ff9f02;
}

.nor_active {
  display: none;
  width: 40px;
}

.vertical-tabs .nav-tabs .nav-link.active>.nor_active {
  display: block !important;
}

.vertical-tabs .nav-tabs .nav-link.active>.nor_hide {
  display: none !important;
}

.nor_active {
  padding-right: 15px;
}

.nor_hide {
  padding-right: 15px;
  width: 40px;
}

.vertical-tabs .tab-content>.active {
  background: #fff;
  display: block;
}

.vertical-tabs .nav.nav-tabs {
  border-bottom: 0;
  display: block;
  /* float: left; */
  /* margin-right: 40px; */
  /* padding-right: 0px; */
  /* background: #fff; */
  /* border-radius: 8px; */
  width: 300px;
  float: left;
}

.vertical-tabs .sv-tab-panel {
  background: #f4f4f4;
  height: 100%;
  padding-top: 10px;
}

.vertical-tabs h3 {
  font-family: "Roboto-Regular";
  font-size: 14px;
  line-height: 1.88;
  color: #485a63;
  margin-bottom: 5px;
}

.vertical-tabs .tab-content {
  padding: 0px;
  margin-left: 20px;
}

.vertical-tabs p {
  font-family: "Roboto-Regular";
  font-size: 14px;
  line-height: 1.88;
  color: #485a63;
  margin-bottom: 10px;
}

.card_office {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px; */
  float: left;
  margin-right: 30px;
}

.automated_catalog_svg {
  width: 100%;
  float: left;
  padding: 30px;
  background: #deedfc;
  border-radius: 8px;
  margin-top: 40px;
}

.automated_catalog_svg h4 {
  font-size: 20px;
  font-family: Roboto-Medium;
  color: #494949;
  margin-bottom: 20px;
}

.card_office svg {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  fill: #fff;
}

.service_now_desk {
  background: #2d3d3f;
}

.service_now_desk img {
  width: 44px;
  margin: 0 auto;
}

.slack_desk {
  background: #441f49;
}

.slack_desk svg {
  width: 80px;
  height: 80px;
}

.ibm_desk {
  background: #1f70c1;
}

.ibm_desk svg {
  color: #fff;
}

.twilio_desk {
  background: #cf272d;
}

.ad_Desk {
  background: #6a8ffa;
}

.office_365 {
  background: #eb3c00;
}

.zen_desk {
  background: #04353d;
}

.zen_desk svg {
  width: 44px;
  height: 44px;
}

.box_desk {
  background: #3e84c0;
}

.ms_exchange {
  background: #0072c6;
}

.ms_exchange img {
  width: 44px;
  margin: 0 auto;
}

.ms_exchange1 {
  background: #25c16f;
}

.ms_exchange1 img {
  width: 44px;
  margin: 0 auto;
}

.ibm_desk img {
  width: 44px;
  margin: 0 auto;
}

.teams_desk {
  background: #5557af;
}

@media only screen and (max-width: 991px) {
  .card_office {
    margin-bottom: 30px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .vertical-tabs .nav.nav-tabs {
    width: 100%;
  }

  .active-directory-tabs {
    margin-top: 80px;
  }
}

/*** 

====================================================================
   Contact Us
====================================================================

***/

.add_bar {
  height: 1px !important;
  background: red !important;
}

.add_bar::after {
  height: 1px !important;
  background: red !important;
}

.add_bar_error {
  font-family: "Roboto-Regular";
  font-size: 12px;
  margin: 10px 0px;
  color: red;
}

.col-100 {
  width: 100% !important;
  float: left !important;
}

.skit_contactus .contact-form {
  border: 0px solid #979797;
}

#field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9 {
  font-family: "Roboto-Regular";
}

.form-element {
  width: 50%;
  float: left;
  padding: 0px 20px 0px 0px;
  position: relative;
  margin-bottom: 50px;
}

.m-t-40 {
  margin-top: 40px;
}

.skit_contactus {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  float: left;
}

.container_trial .skit_contactus .contact-form h1 {
  font-size: 54px;
}

.sign_up-form_us {
  margin-bottom: 0px !important;
}

.signup_note .signup_note_pad {
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.signup_note p {
  font-size: 16px;
  font-family: "Roboto-Regular";
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #485a63;
}

.contact-form h1 {
  font-family: "Gordita-Bold";
  font-size: 70px;
  color: #333333;
  margin-bottom: 40px;
}

.contact-form h5 {
  font-family: "Gordita-Regular";
  font-size: 24px;
  color: #485a63;
  margin-bottom: 40px;
}

.form-element-hint {
  font-weight: 400;
  font-size: 0.6875rem;
  color: #a6a6a6;
  display: block;
}

.form-button .btn-primary {
  width: 178px;
  height: 50px;
  border-radius: 8px;
  background-color: #2468f6;
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.form-button {
  margin-top: 65px;
}

.form-button .btn-primary:hover {
  background-color: #2468f6;
  color: #ffffff;
}

.form-button .btn-primary:active {
  background-color: #2468f6 !important;
  border-color: #2468f6 !important;
  color: #ffffff;
}

.form-textarea {
  margin-bottom: 0px !important;
}

.form-button .btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
  outline: 0;
  background-color: #2468f6;
}

.form-element-bar {
  position: relative;
  height: 1px;
  background: #979797;
  display: block;
}

.form-element-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #212529;
  height: 1px;
  display: block;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  will-change: transform;
}

.form-element-label {
  position: absolute;
  top: 12px;
  line-height: 1.5rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: #a6a6a6;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition: color 0.28s linear, opacity 0.28s linear,
    -webkit-transform 0.28s ease;
  transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear;
  transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear,
    -webkit-transform 0.28s ease;
  will-change: transform, color, opacity;
}

.form-element-field {
  outline: none;
  height: 40px;
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 14px;
  border: 0 solid transparent;
  line-height: 1.5;
  width: 100%;
  color: #1763ff;
  box-shadow: none;
  opacity: 0.001;
  transition: 0.28s ease;
  text-transform: capitalize;
}

.form-element-field:-ms-input-placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field::-webkit-input-placeholder {
  color: #a6a6a6;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.form-element-field::-ms-input-placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field::placeholder {
  color: #a6a6a6;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.form-element-field:focus~.form-element-bar::after {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.form-element-field:focus~.form-element-label {
  color: #333333;
}

.form-element-field.-hasvalue,
.form-element-field:focus {
  opacity: 1;
}

.form-element-field.-hasvalue~.form-element-label,
.form-element-field:focus~.form-element-label {
  -webkit-transform: translateY(-100%) translateY(-0.5em) translateY(3px) scale(0.9);
  transform: translateY(-100%) translateY(-0.5em) translateY(3px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
  font-size: 12px;
  color: #cfd8dc;
  text-transform: capitalize;
}

input.form-element-field:not(:placeholder-shown),
textarea.form-element-field:not(:placeholder-shown) {
  opacity: 1;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -2.25rem !important;
}

input.form-element-field:not(:placeholder-shown)~.form-element-label,
textarea.form-element-field:not(:placeholder-shown)~.form-element-label {
  -webkit-transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
  font-size: 12px;
}

textarea.form-element-field {
  height: auto;
  min-height: 3rem;
}

select.form-element-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select-placeholder {
  color: #a6a6a6;
  display: none;
}

.form-select .form-element-bar::before {
  content: "";
  position: absolute;
  height: 0.5em;
  width: 0.5em;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  display: block;
  right: 0.5em;
  bottom: 0;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  -webkit-transform: translateY(-100%) rotateX(0deg) rotate(45deg);
  transform: translateY(-100%) rotateX(0deg) rotate(45deg);
  will-change: transform;
}

.form-select select:focus~.form-element-bar::before {
  -webkit-transform: translateY(-50%) rotateX(180deg) rotate(45deg);
  transform: translateY(-50%) rotateX(180deg) rotate(45deg);
}

.form-element-field[type="number"] {
  -moz-appearance: textfield;
}

.form-element-field[type="number"]::-webkit-outer-spin-button,
.form-element-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-right-img img {
  width: 100%;
  float: left;
}

.error {
  color: darkred;
  position: relative;
}

.error .input-group-addon {
  background: rgba(139, 0, 0, 0.32);
  border: 1px solid darkred;
  border-left: none;
}

.error input {
  transition: all 400ms;
  border-color: darkred !important;
}

.error input::-webkit-input-placeholder {
  opacity: 0.6;
  color: darkred;
}

.error:after {
  content: "\f00d";
  font-family: "FontAweSome";
  font-size: 18px;
  position: absolute;
  right: 45px;
  z-index: 9;
  top: 5px;
}

.success {
  color: darkgreen;
}

.success .input-group-addon {
  background: rgba(0, 139, 0, 0.32);
  border: 1px solid darkgreen;
  border-left: none;
}

.success input {
  transition: all 400ms;
  border-color: darkgreen !important;
}

.success input::-webkit-input-placeholder {
  opacity: 0.6;
  color: darkgreen;
}

.success:after {
  content: "\f00c";
  font-family: "FontAweSome";
  font-size: 18px;
  position: absolute;
  right: 45px;
  z-index: 9;
  top: 5px;
}

.form-title {
  padding: 5px 10px;
}

.log {
  display: none;
  font-weight: bold;
  font-size: 13px;
  background: rgba(255, 50, 2, 0.7);
  padding: 10px 30px;
}

.log p {
  display: list-item;
  color: black;
}

.our_location_places {
  width: 100%;
  float: left;
}

.our_location_places ul {
  padding-left: 0px;
  min-height: 120px;
}

.our_location_places ul li {
  list-style: none;
  font-family: "Gordita-Regular";
  font-size: 18px;
  color: #485a63;
  line-height: 30px;
}

.location_places h2 {
  text-align: center;
  font-family: "Gordita-Bold";
  font-size: 50px;
  margin-bottom: 40px;
  color: #333333;
}

.our_location_places h4 {
  font-family: "Gordita-Bold";
  font-size: 22px;
  color: #485a63;
  margin-bottom: 30px;
}

.our_location_number p {
  font-family: "Gordita-Regular";
  font-size: 18px;
  color: #485a63;
}

.our_locations .location_places {
  float: left;
  margin: 0 auto;
  flex: 0 0 90%;
  max-width: 90%;
  margin-bottom: 40px;
}

/*** 

====================================================================
	Signup form
====================================================================

 ***/

.skit_contactus .signup_form .contact-form {
  border: 1px solid #979797;
}

.sign_up-form_us {
  margin-top: 0px;
}

.signup_form h2 {
  text-align: center;
  width: 100%;
  float: left;
  margin-bottom: 20px;
  font-size: 30px;
  color: #333333;
  font-family: "Roboto-Bold";
}

.contact-form h4 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #485a63;
}

.form-element:last-child {
  margin-bottom: 0px !important;
}

.comtact_or {
  margin-bottom: 40px;
  margin-top: 40px;
}

.comtact_or p {
  color: #485a63;
  font-size: 16px;
}

.contact_form_success {
  background: #fff;
  padding: 22px 0px;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  margin-top: 40px;
}

.contact_form_success p {
  text-align: center;
  font-size: 12px;
  color: #2468f6 !important;
  font-family: "Roboto-Regular";
  margin-bottom: 0px !important;
}

.form-check-label {
  margin-bottom: 0;
  margin-left: 27px;
}

select::-ms-expand {
  display: none;
}

.signup_form .form-element {
  width: 100%;
  float: left;
}

.signup_form .form-button .btn-primary {
  width: 100%;
  float: left;
}

.signup_form .form-element {
  padding: 0px;
}

.option-input.radio {
  border-radius: 50%;
  border: 1px solid #979797;
}

.option-input:checked {
  background: #2468f6 !important;
  border: 1px solid #fff !important;
}

.form-check {
  outline: 0px solid #2468f6;
  padding: 15px 30px;
  margin-bottom: 0px;
}

.form-check label:focus {
  border: 1px solid red !important;
  outline: 0;
  padding: 5px 25px;
}

.form-check-input:checked::before {
  height: 10px;
  width: 10px;
  position: absolute;
  content: "";
  background-image: url(../images/tick.svg);
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  top: 5px;
  left: 2px;
  z-index: 999;
}

.option-input.radio::after {
  border-radius: 50%;
}

.form-check-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #2468f6;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 18px;
  transition: all 0.15s ease-out 0s;
  background: #fff;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  /* z-index: 1000; */
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -1px !important;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border-radius: 50%;
}

.sign_up-form {
  padding: 60px 40px !important;
  border: 1px solid #979797;
  border-radius: 5px;
}

.sign_up-form h4 {
  font-size: 24px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: center;
}

.form-input p {
  margin-bottom: 40px;
  font-weight: 500;
  font-family: "Roboto-Regular";
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #485a63;
  font-size: 14px;
  margin-top: 20px;
}

.dropdown_selct_opt p {
  margin-bottom: 0px;
}

.select-block:last-child {
  margin-bottom: 0px;
}

/*** 

====================================================================
	Select Box
====================================================================

 ***/

.material-form {
  background-color: #fff;
}

.material-form .input-block {
  background-color: #fff;
  border-bottom: solid 1px #c8c8c8;
  width: 100%;
  height: 50px;
  padding: 0;
  position: relative;
  margin-bottom: 20px;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.material-form .input-block::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 0%;
  bottom: 0;
  height: 1px;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background-color: #979797;
}

.material-form .input-block.focus {
  border-color: #fff;
}

.material-form .input-block.focus label {
  color: #2196f3;
  top: 0;
  font-size: 13px;
}

.material-form .input-block.focus::before {
  left: 0%;
  width: 100%;
}

.material-form .input-block.has-value {
  background-color: #fff;
}

.material-form .input-block.has-value label {
  top: 0;
  font-size: 13px;
}

.material-form .input-block.error {
  border-color: #fff;
}

.material-form .input-block.error::before {
  left: 0%;
  width: 100%;
  background-color: #d50000;
}

.material-form .input-block.textarea {
  height: auto;
}

.material-form .input-block.textarea .form-control {
  height: auto;
  resize: none;
  padding-top: 20px;
  font-family: "Roboto-Regular";
}

.material-form .input-block.floating-field {
  padding: 2px 0 0;
  height: 62px;
}

.material-form .input-block.floating-field.textarea {
  height: auto;
}

.material-form .input-block label {
  position: absolute;
  left: 0;
  top: 29px;
  width: 100%;
  height: 20px;
  margin: 0;
  font-weight: 500;
  z-index: 1;
  color: #939393;
  font-size: 13px;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
}

.material-form .input-block .form-control {
  background-color: transparent;
  padding: 4px 0 0;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
  color: #3c3c3c;
}

.material-form .input-block .form-control:focus {
  outline: none;
}

.material-form .input-block .form-control:focus label {
  top: 0;
}

.material-form .error-label {
  color: #d50000;
  margin-top: -15px;
  font-size: 14px;
  margin-bottom: 10px;
}

.material-form .form-note {
  font-size: 13px;
  color: #939393;
  margin-top: -15px;
  margin-bottom: 15px;
}

.material-form .square-button {
  background-color: #39a1f4;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0;
  height: 60px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.material-form .square-button:hover,
.material-form .square-button:focus {
  background-color: #0d8aee;
}

/*** 

====================================================================
	Dropdown list as ul li method
====================================================================

 ***/

.select-block {
  background-color: #fff;
  width: 100%;
  /* 	padding: 12px 0 0;
 */
  position: relative;
  margin-bottom: 50px;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.select-block label {
  position: absolute;
  left: 0;
  top: 40px;
  height: 20px;
  width: 100%;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: 0;
  font-weight: 500;
  z-index: 1;
  color: #939393;
  font-size: 12px;
  line-height: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.select-block .active-list {
  width: 100%;
  height: 52px;
  padding: 0;
  color: #c9c9c9;
  position: relative;
  z-index: 2;
  font-weight: 500;
  cursor: pointer;
  font-size: 0;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.select-block .active-list:after {
  content: ".";
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  top: 50%;
  text-align: left;
  height: 50px;
  line-height: 52px;
  color: #939393;
  font-size: 30px;
  width: 20px;
  margin-top: 0px;
  transform: rotate(0deg);
  background-image: url(../images/arrow.png);
  background-repeat: no-repeat;
}

.select-block.added label {
  top: 0;
  font-size: 13px;
}

.select-block.added .active-list {
  color: #2468f6;
  font-size: 12px;
}

.select-block.added.focus label {
  top: 0;
}

.select-block.focus label {
  top: 10px;
  font-size: 12;
  color: #2196f3;
  font-size: 12px;
  color: #cfd8dc;
  text-transform: capitalize;
}

.select-block.focus .active-list {
  font-size: 12px;
}

.select-block.focus .custom-select {
  border-color: #fff;
}

.select-block.focus .active-list:after {
  content: "";
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  top: 50%;
  text-align: left;
  height: 50px;
  line-height: 52px;
  color: #3c3c3c;
  font-size: 30px;
  width: 24px;
  margin-top: 0px;
  transform: rotate(-180deg);
  background-image: url(../images/arrow.png);
  background-repeat: no-repeat;
  margin-top: -36px;
}

.select-block.focus .custom-select::before {
  left: 0%;
  width: 100%;
}

.select-block .custom-select {
  display: inline-block;
  height: 52px;
  position: relative;
  padding: 0;
  width: 100%;
  line-height: 52px;
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-radius: 0px;
  background: none;
}

.select-block .custom-select::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 0%;
  bottom: 0;
  height: 1px;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background-color: #979797;
}

.select-block .custom-select .list-field {
  width: 100%;
  height: 52px;
  padding: 0 15px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.select-block .custom-select .drop-down-list {
  width: 100%;
  height: auto;
  max-height: 250px;
  overflow-x: auto;
  z-index: 99;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  left: 0;
  background-color: white;
  display: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
    rgba(0, 0, 0, 0.11765) 0px 1px 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
    rgba(0, 0, 0, 0.11765) 0px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
    rgba(0, 0, 0, 0.11765) 0px 1px 4px;
  border: 1px solid #9b9b9b;
}

.select-block .custom-select .drop-down-list li {
  padding: 10.5px 15px;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  background-color: #fafafa;
  background-color: #fff;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.select-block .custom-select .drop-down-list li input[type="checkbox"] {
  margin: 0;
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.select-block .custom-select .drop-down-list li label {
  font-weight: normal;
  cursor: pointer;
  display: block;
}

.select-block .custom-select .drop-down-list li:hover {
  color: #3c3c3c;
  background-color: #eceff1;
}

.select-block .custom-select .drop-down-list .add-new {
  border-top: solid 1px #c8c8c8;
  padding: 15px;
  color: #3c3c3c;
}

.select-block .custom-select .drop-down-list .add-new i {
  font-size: 16px;
  margin-top: 3px;
  color: #939393;
}

@media (min-width: 768px) {
  .contact-wrap {
    width: 100%;
    margin: auto;
  }

  .material-form .input-block label {
    font-size: 14px;
    line-height: 14px;
  }

  .material-form .input-block .form-control {
    font-size: 16px;
  }

  .select-block label {
    font-size: 14px;
    line-height: 14px;
    font-family: "Roboto-Regular";
    text-transform: capitalize;
  }

  .select-block.added .active-list {
    font-size: 14px;
    font-family: "Roboto-Regular";
  }

  .select-block.focus .active-list {
    font-size: 14px;
    font-family: "Roboto-Regular";
    text-transform: capitalize;
  }

  .select-block .custom-select .drop-down-list li {
    font-size: 14px;
    font-family: "Roboto-Regular";
  }
}

.contact_send {
  background: #ffffdb;
  padding: 100px 0px;
}

.contact_send img {
  width: 100%;
  float: left;
}

.contact_send h5 {
  font-family: "Roboto-Regular";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #2468f6;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  margin-top: 40px;
}

.contact_send p {
  font-family: "Roboto-Regular";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.2px;
  color: #485a63;
  width: 100%;
  float: left;
  padding: 0px 30px;
  text-align: justify;
}

.contact_send a {
  font-family: "Roboto-Regular";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #2468f6;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  margin-top: 40px;
  text-transform: uppercase;
}

.contact_send {
  margin-bottom: 150px;
}

/*** 

====================================================================
	Home Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .header-logo img {
    width: 120px;
    position: relative;
    top: 4px;
  }

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  /* .header-logo {
    margin: 40px 0px;
  } */
  .hamburger svg {
    display: block;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 15px 0;
    text-align: center;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 15px 15px;
  }

  .dialog-editor-list ul li img {
    width: 125px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 0 0 40px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 0em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 14px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 80%;
  }

  .main-slider-right {
    text-align: center;
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 50%;
    float: left;
  }

  .integration-jira {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-amazon {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-slack {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-atlassian {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-cognidesk {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .know-how-assistant-content h3 {
    font-size: 32px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 40px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 80%;
    margin-top: 50px;
  }

  .know-how-assistant-image {
    text-align: center;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 0px !important;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .latest-events-image img {
    width: 80%;
  }

  .latest-events-image {
    text-align: center;
  }

  .latest-events-content h6 {
    font-size: 14px;
    text-align: center;
  }

  .latest-events-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
    text-align: center;
  }

  .latest-events-content p {
    text-align: justify;
    margin-bottom: 30px;
  }

  .latest-events-button {
    text-align: center;
  }

  .latest-events-button .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial a {
    font-size: 14px;
  }

  .latest-events-button .free-trial a:hover {
    font-size: 14px;
  }

  .latest-events {
    margin-bottom: 40px;
  }

  .analysts-perspective-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .blogs-future-communication-content h6 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .blogs-future-communication-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .blogs-future-communication {
    padding: 40px 0;
  }

  .blogs-future-communication-yellow h3 {
    font-size: 26px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-communication-yellow p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .blogs-future-cyan-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-lavender-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-pink-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-right.sm-align-left p {
    text-align: center !important;
    margin-top: 10px;
  }
}

.contact-right-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 481px) and (max-width: 768px) {
  .form-card {
    padding: 0px 40px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header-logo img {
    width: 120px;
    position: relative;
    top: 4px;
  }

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  /* .header-logo {
    margin: 40px 0px;
  } */
  .hamburger svg {
    display: block;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 15px 0;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 15px 15px;
  }

  .dialog-editor-list ul li img {
    width: 125px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 0 0 30px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 0em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 14px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 70%;
    display: none !important;
  }

  .main-slider-right {
    text-align: center;
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 50%;
    float: left;
  }

  .integration-jira {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-amazon {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-slack {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-atlassian {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-cognidesk {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .know-how-assistant-content h3 {
    font-size: 32px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 40px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 70%;
  }

  .know-how-assistant-image {
    text-align: center;
    margin-top: 60px;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 30px;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .latest-events-image img {
    width: 70%;
  }

  .latest-events-image {
    text-align: center;
  }

  .latest-events-content h6 {
    font-size: 14px;
    text-align: center;
  }

  .latest-events-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
    text-align: center;
  }

  .latest-events-content p {
    text-align: justify;
    margin-bottom: 30px;
  }

  .latest-events-button {
    text-align: center;
  }

  .latest-events-button .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial a {
    font-size: 14px;
  }

  .latest-events-button .free-trial a:hover {
    font-size: 14px;
  }

  .latest-events {
    margin-bottom: 40px;
  }

  .analysts-perspective-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .blogs-future-communication-content h6 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .blogs-future-communication-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .blogs-future-communication {
    padding: 40px 0;
  }

  .blogs-future-communication-yellow h3 {
    font-size: 26px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-communication-yellow p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .blogs-future-cyan-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }

  .blogs-future-lavender-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }

  .blogs-future-pink-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .header-logo ul li a {
    font-size: 14px;
    padding: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-logo img {
    width: 140px;
    position: relative;
    top: 4px;
  }

  .main-slider-left h1 {
    font-size: 32px;
    margin-bottom: 10px;
    text-align: left;
  }

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  /* .header-logo {
    margin: 40px 0px;
  } */
  .hamburger svg {
    display: block;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 15px 0;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 20px 20px;
  }

  .dialog-editor-list ul li img {
    width: 125px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 10px 0 30px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 1em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 14px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 100%;
  }

  .main-slider-right {
    text-align: center;
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 50%;
    float: left;
  }

  .integration-jira {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-amazon {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-slack {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-atlassian {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-cognidesk {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .know-how-assistant-content h3 {
    font-size: 32px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 40px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 100%;
  }

  .know-how-assistant-image {
    text-align: center;
    margin-top: 0px;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 30px;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .latest-events-image img {
    width: 60%;
  }

  .latest-events-image {
    text-align: center;
  }

  .latest-events-content h6 {
    font-size: 14px;
    text-align: center;
  }

  .latest-events-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
    text-align: center;
  }

  .latest-events-content p {
    text-align: justify;
    margin-bottom: 30px;
  }

  .latest-events-button {
    text-align: center;
  }

  .latest-events-button .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .latest-events-button .free-trial a {
    font-size: 14px;
  }

  .latest-events-button .free-trial a:hover {
    font-size: 14px;
  }

  .latest-events {
    margin-bottom: 40px;
  }

  .analysts-perspective-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .blogs-future-communication-content h6 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .blogs-future-communication-content h1 {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .blogs-future-communication {
    padding: 40px 0;
  }

  .blogs-future-communication-yellow h3 {
    font-size: 26px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-communication-yellow p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .blogs-future-cyan-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }

  .blogs-future-lavender-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }

  .blogs-future-pink-left h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .main-slider-left h1 {
    text-align: left;
  }

  .main-slider-left p {
    text-align: left;
  }
}

/*** 

====================================================================
	Automation Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .automation-catalog-workplace h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .automation-catalog-workplace p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .automation-catalog-workplace {
    padding: 40px 0 40px 0;
  }

  .vertical-tabs .nav.nav-tabs {
    float: inherit;
    border-right: 2px solid #eceff1;
    margin-right: 0px;
  }

  .vertical-tabs {
    padding: 0 20px;
  }

  .vertical-tabs .sv-tab-panel {
    height: auto;
    padding-top: 40px;
  }

  .active-directory-tabs {
    margin-bottom: 60px;
  }

  .vertical-tabs .nav-tabs .nav-link {
    width: auto;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .automation-catalog-workplace h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .automation-catalog-workplace p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .automation-catalog-workplace {
    padding: 40px 0 40px 0;
  }

  .vertical-tabs .nav.nav-tabs {
    float: inherit;
    border-right: 2px solid #eceff1;
    margin-right: 0px;
  }

  .vertical-tabs {
    padding: 0 20px;
  }

  .vertical-tabs .sv-tab-panel {
    height: auto;
    padding-top: 40px;
  }

  .active-directory-tabs {
    margin-bottom: 60px;
  }

  .vertical-tabs .nav-tabs .nav-link {
    width: auto;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .automation-catalog-workplace h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .automation-catalog-workplace p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .automation-catalog-workplace {
    padding: 40px 0 40px 0;
  }

  .vertical-tabs .nav.nav-tabs {
    float: inherit;
    border-right: 2px solid #eceff1;
    margin-right: 0px;
  }

  .vertical-tabs {
    padding: 0 20px;
  }

  .vertical-tabs .sv-tab-panel {
    height: auto;
    padding-top: 40px;
  }

  .active-directory-tabs {
    margin-bottom: 60px;
  }

  .vertical-tabs .nav-tabs .nav-link {
    width: auto;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

/*** 

====================================================================
	Integrations Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .designed-transform-support h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .designed-transform-support p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .designed-transform-support {
    padding: 40px 0 40px 0;
  }

  .digital-channels-content ul {
    padding-left: 0;
    text-align: center;
  }

  .digital-channels-content ul li {
    margin-right: 10px;
    margin-bottom: 25px;
    margin-left: 10px;
    padding: 30px;
  }

  .digital-channels-content ul li img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  ul.digital-channels-list li {
    width: auto;
    height: auto;
    float: none;
    text-align: center;
  }

  .digital-channels {
    margin-bottom: 40px;
  }

  .digital-channels-integrate-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .image-responsive {
    display: none;
  }

  .digital-channels-integrate-content p {
    font-size: 18px;
    margin-bottom: 30px;
    padding: 0;
  }

  .digital-channels-integrate ul {
    padding-left: 0;
  }

  .digital-channels-integrate ul li {
    margin-right: 10px;
    margin-bottom: 25px;
    margin-left: 10px;
    padding: 30px;
  }

  .digital-channels-integrate-content ul li img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  ul.digital-channels-integrate-list li {
    width: auto;
    height: auto;
  }

  .digital-channels-integrate {
    margin-bottom: 40px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .designed-transform-support h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .designed-transform-support p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .designed-transform-support {
    padding: 40px 0 40px 0;
  }

  .digital-channels-content ul {
    padding-left: 0;
    text-align: center;
  }

  .digital-channels-content ul li {
    margin-right: 15px;
    margin-bottom: 25px;
    margin-left: 15px;
    padding: 50px;
  }

  ul.digital-channels-list li {
    width: 44%;
    height: 44%;
  }

  .digital-channels {
    margin-bottom: 40px;
  }

  .digital-channels-integrate-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .image-responsive {
    display: none;
  }

  .digital-channels-integrate-content p {
    font-size: 18px;
    margin-bottom: 30px;
    padding: 0;
  }

  .digital-channels-integrate ul {
    padding-left: 0;
  }

  .digital-channels-integrate ul li {
    margin-right: 15px;
    margin-bottom: 25px;
    margin-left: 15px;
    padding: 40px;
  }

  ul.digital-channels-list li {
    width: auto;
    height: auto;
    text-align: center;
  }

  .digital-channels-integrate {
    margin-bottom: 40px;
  }

  .col-lg-12.col-md-12.col-sm-12.col-xs-12.digital-channels-content {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .designed-transform-support h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .designed-transform-support p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
    padding: 0;
  }

  .designed-transform-support {
    padding: 40px 0 40px 0;
  }

  .digital-channels-content ul {
    padding-left: 0;
    text-align: center;
  }

  .digital-channels-content ul li {
    margin-right: 15px;
    margin-bottom: 25px;
    margin-left: 15px;
    padding: 40px;
  }

  ul.digital-channels-list li {
    width: 44%;
    height: 44%;
  }

  .digital-channels {
    margin-bottom: 40px;
  }

  .digital-channels-integrate-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .image-responsive {
    display: none;
  }

  .digital-channels-integrate-content p {
    font-size: 18px;
    margin-bottom: 30px;
    padding: 0;
  }

  .digital-channels-integrate ul {
    padding-left: 0;
  }

  .digital-channels-integrate ul li {
    margin-right: 15px;
    margin-bottom: 25px;
    margin-left: 15px;
    padding: 40px;
  }

  ul.digital-channels-list li {
    width: auto;
    height: auto;
    text-align: center;
  }

  .digital-channels-integrate {
    margin-bottom: 40px;
  }

  .col-lg-12.col-md-12.col-sm-12.col-xs-12.digital-channels-content {
    padding: 0;
  }
}

/*** 

====================================================================
	Contact Us Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .skit_contactus h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
    text-align: center;
  }

  .skit_contactus h5 {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 30px;
    padding: 0;
  }

  .skit_contactus {
    padding: 20px 0 40px 0;
  }

  .skit_contactus {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .form-button .btn-primary {
    height: 45px;
  }

  .form-button {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .our_locations .location_places {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .location_places h2 {
    font-size: 32px;
  }

  .our_location_places h4 {
    font-size: 22px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .our_location_places ul li {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .our_location_places ul {
    min-height: auto;
  }

  .our_location_number p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .form-element {
    width: 100%;
    float: left;
    padding: 0px 20px 0px 0px;
    position: relative;
    margin-bottom: 30px;
  }

  .m-t-40 {
    margin-top: 0px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .skit_contactus h1 {
    font-size: 34px;
    margin-bottom: 25px;
    line-height: 1.5;
    text-align: center;
  }

  .skit_contactus h5 {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 30px;
    padding: 0;
  }

  .skit_contactus {
    padding: 40px 0 40px 0;
  }

  .skit_contactus {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .form-button .btn-primary {
    height: 45px;
  }

  .form-button {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .our_locations .location_places {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .location_places h2 {
    font-size: 32px;
  }

  .our_location_places h4 {
    font-size: 22px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .our_location_places ul li {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .our_location_places ul {
    min-height: auto;
  }

  .our_location_number p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .form-element {
    width: 100%;
  }

  .m-t-40 {
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .skit_contactus h1 {
    font-size: 34px;
    margin-bottom: 25px;
    line-height: 1.5;
    text-align: center;
  }

  .skit_contactus h5 {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 30px;
    padding: 0;
  }

  .skit_contactus {
    padding: 40px 0 40px 0;
  }

  .skit_contactus {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .form-button .btn-primary {
    height: 45px;
  }

  .form-button {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .our_locations .location_places {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .location_places h2 {
    font-size: 32px;
  }

  .our_location_places h4 {
    font-size: 22px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .our_location_places ul li {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .our_location_places ul {
    min-height: auto;
  }

  .our_location_number p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .contact-right-img img {
    width: 100%;
    float: none;
  }

  .contact-right-img {
    text-align: center;
  }
}

/*** 

====================================================================
	Sign-up Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .skit_contactus.sign_up-form_us {
    padding: 40px 15px;
  }

  .sign_up-form {
    padding: 40px 30px !important;
    border: 1px solid #979797;
    border-radius: 5px;
  }

  .signup_note .signup_note_pad {
    padding: 0px 15px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .contact_send {
    padding: 50px 0px;
    margin: 15px;
    margin-bottom: 60px;
  }

  .contact_send a {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .skit_contactus.sign_up-form_us {
    padding: 40px 15px;
  }

  .sign_up-form {
    padding: 40px 30px !important;
    border: 1px solid #979797;
    border-radius: 5px;
  }

  .signup_note .signup_note_pad {
    padding: 0px 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .contact_send {
    padding: 50px 0px;
    margin: 15px 0;
    margin-bottom: 60px;
  }

  .contact_send a {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

/*** 

====================================================================
	About Us Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .we-believe-in-making {
    padding: 40px 0 40px 0;
  }

  .we-believe-in-making h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .we-believe-in-making p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
  }

  .workativ-was-born-out h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .workativ-content {
    text-align: center;
  }

  .workativ-was-born-out h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .workativ-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .timeline-year-workplace {
    margin-bottom: 60px;
  }

  .our-mission-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .our-mission-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .our-mission-workplace {
    padding: 40px 0;
  }

  .our-mission-workplace p {
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .our-mission-images img {
    width: 80%;
    margin: 0;
  }

  .our-mission-images {
    text-align: center;
  }

  .make-an-impact-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .make-an-impact-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
    padding: 0;
  }

  .make-an-impact-workplace p {
    line-height: 30px;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
  }

  .submit-your-resume a {
    font-size: 14px;
    padding: 10px 25px;
  }

  .make-an-impact-workplace {
    margin-bottom: 70px;
  }

  .MultiCarousel .MultiCarousel-inner .item {
    width: 350px !important;
  }

  .timeline-top-content {
    margin-top: 0px;
  }

  .separator-timeline-height img {
    height: 140px;
  }

  .read-more-button ul {
    margin-bottom: 40px;
    padding-left: 0px;
    text-align: center;
  }

  .blogs-future-communication-blue p {
    text-align: justify;
  }

  .blogs-future-communication-blue h3 {
    font-size: 26px;
    line-height: 1.5;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .blogs-future-communication-blue {
    margin-bottom: 40px;
  }

  .assiatant-press-release-left {
    margin-bottom: 40px;
  }

  .why-assistant-left {
    margin-bottom: 40px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .we-believe-in-making {
    padding: 40px 0 40px 0;
  }

  .we-believe-in-making h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .we-believe-in-making p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
  }

  .workativ-was-born-out h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .workativ-content {
    text-align: center;
  }

  .workativ-was-born-out h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .workativ-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .timeline-year-workplace {
    margin-bottom: 60px;
  }

  .our-mission-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .our-mission-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .our-mission-workplace {
    padding: 40px 0;
  }

  .our-mission-workplace p {
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .our-mission-images img {
    width: 70%;
    margin: 0;
  }

  .our-mission-images {
    text-align: center;
  }

  .make-an-impact-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .make-an-impact-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
    padding: 0;
  }

  .make-an-impact-workplace p {
    line-height: 30px;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
  }

  .submit-your-resume a {
    font-size: 14px;
    padding: 10px 25px;
  }

  .make-an-impact-workplace {
    margin-bottom: 70px;
  }

  .MultiCarousel .MultiCarousel-inner .item {
    width: 350px !important;
  }

  .timeline-top-content {
    margin-top: 0px;
  }

  .separator-timeline-height img {
    height: 140px;
  }

  .pos-rel .nav-item {
    margin: 0 0.5em;
  }

  .read-more-button ul {
    margin-bottom: 40px;
    padding-left: 0px;
  }

  .blogs-future-communication-blue {
    margin-bottom: 40px;
  }

  .assiatant-press-release-left {
    margin-bottom: 40px;
  }

  .why-assistant-left {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .we-believe-in-making {
    padding: 40px 0 40px 0;
  }

  .we-believe-in-making h1 {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .we-believe-in-making p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: justify;
    line-height: 30px;
  }

  .workativ-was-born-out h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .workativ-content {
    text-align: center;
  }

  .workativ-was-born-out h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .workativ-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .timeline-year-workplace {
    margin-bottom: 60px;
  }

  .our-mission-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .our-mission-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .our-mission-workplace {
    padding: 40px 0;
  }

  .our-mission-workplace p {
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .our-mission-images img {
    width: 60%;
    margin: 0;
  }

  .our-mission-images {
    text-align: center;
  }

  .make-an-impact-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }

  .make-an-impact-workplace h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
    padding: 0;
  }

  .make-an-impact-workplace p {
    line-height: 30px;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
  }

  .submit-your-resume a {
    font-size: 14px;
    padding: 10px 25px;
  }

  .make-an-impact-workplace {
    margin-bottom: 70px;
  }

  .MultiCarousel .MultiCarousel-inner .item {
    width: 350px !important;
  }

  .timeline-top-content {
    margin-top: 0px;
  }

  .separator-timeline-height img {
    height: 140px;
  }

  .pos-rel .nav-item {
    margin: 0 0.5em;
  }

  .read-more-button ul {
    margin-bottom: 40px;
    padding-left: 0px;
  }

  .blogs-future-communication-blue {
    margin-bottom: 40px;
  }

  .assiatant-press-release-left {
    margin-bottom: 40px;
  }

  .why-assistant-left {
    margin-bottom: 40px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .hamburger svg {
    display: none;
  }
}

.active-list {
  font-size: 16px !important;
}

.sign-up-success {
  display: none;
}

.greeting-msg {
  margin-top: 35px;
  text-align: center !important;
}

#show_offer {
  display: none;
}

#sign-up-success-message {
  padding-top: 20px;
  color: green;
}

.email {
  text-transform: lowercase;
}

::placeholder {
  text-transform: initial;
}

.err_position {
  position: absolute;
}

.note_form_trial {
  font-size: 14px;
  text-align: center;
  color: rgb(153, 153, 153);
  margin-bottom: 0;
}

.form-button button {
  margin-bottom: 20px;
}

.note_form_trial a {
  color: #08c7fb;
  text-transform: capitalize;
}

/*** 

====================================================================
	404 Error Page
====================================================================

    ***/

.error-page-workplace .error-content img {
  margin-bottom: 40px;
  width: 100%;
  height: auto;
  background: rgb(207, 216, 220, 0.35);
}

.error-content h3 {
  color: #333333;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
  text-align: left;
}

.error-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 50px;
  text-align: left;
}

/*** 

====================================================================
	Top Header
====================================================================

    ***/

.top-header-logo {
  padding: 0px !important;
  margin: 0px 0px;
  width: 100%;
  z-index: 9;
}

.top-header-logo img {
  width: 90px;
  cursor: pointer;
}

/* .top-header-logo ul li {
  padding: 10px 0px 10px 22px;
} */

.top-header-logo ul li a {
  font-size: 13px;
  color: #4e5257 !important;
  font-family: "Roboto-Regular";
  /* text-transform: uppercase; */
  padding: 0;
}

.top-header {
  border-bottom: 1px solid #f5f5f5;
  height: 36px;
  display: flex;
  align-items: center;
}

.extend-trial-error {
  font-size: 15px;
  position: relative;
  top: 40px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

/*** 

====================================================================
	Use cases new page design  
====================================================================

***/

.mob_res_accordian {
  display: none;
}

.mob_res_accordian .card {
  margin-bottom: 15px;
}

.mob_res_accordian #accordion {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.main-slider.careers_head .main-slider-left {
  padding: 40px 0px;
}

.main-slider.careers_head .main-slider-right img {
  padding: 40px 0px;
}

.mob_res_accordian .btn-link:before {
  content: "\2212";
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  font-size: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-top: 10px;
  color: #ff9f02;
}

.mob_res_accordian .collapsed:after {
  content: "\2b";
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  font-size: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-top: 10px;
  color: #999;
}

.mob_res_accordian .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.mob_res_accordian .btn-link.collapsed:before {}

.mob_res_accordian .btn-link.collapsed {
  color: #494949;
  width: 100%;
  float: left;
  font-family: "Gordita-Bold";
  text-decoration: none !important;
  font-size: 18px;
}

.mob_res_accordian .btn-link {
  font-weight: 400;
  color: #ff9f02;
  font-family: Roboto-Medium;
  width: 100%;
  float: left;
  text-decoration: none !important;
  text-align: left;
  font-size: 18px;
}

.mob_res_accordian .btn-link:hover {
  color: #ff9f02;
  text-decoration: none;
  font-size: 18px;
}

.mob_res_accordian .automated_catalog_svg {
  margin-bottom: 60px;
}

.mob_res_accordian .sv-tab-panel h4 {
  font-size: 30px;
  font-family: "Roboto-Bold";
}

@media only screen and (max-width: 1100px) {
  .mob_res_accordian {
    display: block;
    padding: 0px 15px;
  }

  .active-directory-tabs {
    display: none;
  }

  .mob_res_accordian .btn-link {
    font-weight: 400;
    color: #ff9f02;
    font-family: "Gordita-Medium";
    width: 100%;
    float: left;
    text-decoration: none !important;
    text-align: left;
  }
}

/*** 

====================================================================
	Call Deflection new page design  
====================================================================

***/

.call_deflection h4 {
  font-size: 24px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
}

.call_deflection p {
  font-size: 18px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 15px;
}

.career_skit h6 {
  font-size: 22px;
  color: #485a63;
  font-family: "Roboto-Bold";
  line-height: 1.5;
  margin-bottom: 15px;
  margin: 0 10%;
  text-align: center;
}

.call_deflection_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.call_deflection_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.call_deflection_bg {
  background: #f5f5f5;
  padding: 60px 40px !important;
}

.call_deflection_img img {
  padding: 0px;
  width: 80%;
}

.call_deflection_content p {
  font-size: 18px;
}

.call_deflection_content h5 {
  font-size: 24px;
  color: #485a63;
  font-family: "Roboto-Bold";
  margin-bottom: 30px;
  margin-top: 40px;
}

.call_deflection_content h5 span {
  color: #3c5aff;
  font-size: 32px;
}

.call_delection_card {
  padding-bottom: 100px;
  padding-top: 60px;
}

.call_delection_card h4 {
  color: #333333;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 40px;
}

.call_delection_card .cogni_workspace_content {
  padding: 0px 15px;
  background: #fff;
  box-shadow: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.call_delection_card_h {
  width: 100%;
  float: left;
}

.call_delection_card_h h6 {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 50px;
}

.call_delection_card_h .start-free-trail {
  margin: 40px auto;
  display: block;
  text-align: center;
}

.img_line {
  width: 100%;
  float: left;
  margin: 50px 0px;
}

.img_line img {
  width: 100%;
  float: left;
  padding: 0px;
}

.call_deflection_bg .main-slider-left {
  padding: 0px !important;
}

.cogni_fav_apps {
  padding-bottom: 80px;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .call_deflection_content {
    margin-top: 80px;
  }

  .call_deflection_group .call_deflection_content p {
    padding: 0px !important;
  }

  .call_deflection h4 {
    text-align: center;
  }

  .call_deflection_content {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .call_deflection_content h5 {
    text-align: center;
  }

  .call_delection_card_h h6 {
    padding: 0px 50px;
    line-height: 25px;
  }

  .call_deflection_bg {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .call_deflection_h1 {
    text-align: left !important;
  }

  .call_deflection h4 {
    text-align: left;
    margin-top: 20px;
  }

  .careers_head .main-slider-left p {
    text-align: left;
  }

  .career_skit h6 {
    margin: 0px;
  }

  .call_deflection_img {
    margin: 40px 0px;
  }

  .call_delection_card_h h6 {
    padding: 0px 20px;
    line-height: 25px;
    margin-top: 30px;
  }

  .call_deflection_bg {
    padding: 0px !important;
  }
}

/*** 

====================================================================
	beta landing page design  
====================================================================

***/

.beta_landing_head {
  padding: 80px 0px;
}

.beta_landing_head_link {
  width: 100%;
  float: left;
  text-align: center;
}

.beta_landing_head_link img {
  width: 18px;
  height: 18px;
  margin: 0px;
}

.beta_landing_head_link span {
  width: auto;
  text-align: center;
  font-size: 16px;
  color: #4095e9;
  font-family: "Roboto-Regular";
  cursor: pointer;
}

.cogni_fav_apps_head_img_left {
  width: 50%;
  float: left;
}

.cogni_fav_apps_head_img_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.cogni_fav_apps_head_img_left img {
  position: relative;
  top: 15px;
}

.cogni_fav_apps_head_img_right img {
  position: relative;
  top: 0px;
  width: 55%;
}

.landing_card {
  background: #d5e3ec;
  padding-bottom: 80px;
}

.beta_landing_card h4 {
  margin-bottom: 50px;
  text-align: center;
  font-size: 30px;
}

.beta_landing_card {
  margin-top: -55px;
}

.beta_landing_change_img {
  width: 70%;
  margin: 0 auto;
  position: relative;
  margin-top: -80px;
  top: -135px;
}

.carousel-item img {
  width: 100%;
}

.carousel-indicators .active {
  background: #2468f6 !important;
  border: none;
}

.carousel-control-next-icon {
  background: url(../images/left_arrow.svg) no-repeat !important;
  transform: rotate(-90deg);
}

.carousel-control-prev-icon {
  background: url(../images/left_arrow.svg) no-repeat !important;
  transform: rotate(90deg);
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  border: none !important;
  background: #2468f6 !important;
  opacity: 0.1;
}

.landing_expand {
  background: #3a4aff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.landing_expand h3 {
  color: #fff;
}

.landing_expand p {
  color: #fff;
  margin: 0px;
  cursor: pointer;
  width: auto;
  float: left;
}

.accordian_landing {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.landing_contact_img {
  position: absolute;
  right: 50px;
  margin-top: -160px;
}

.landing_contact h2 {
  text-align: center;
  font-size: 30px;
  color: #333333;
  font-family: Roboto-Medium;
  margin-bottom: 20px;
}

.landing_contact_left {
  padding-right: 25% !important;
}

.landing_contact_left h4 {
  text-align: left;
  font-size: 20px;
  color: #333333;
  font-family: Roboto-Medium;
  margin-bottom: 20px;
}

.beta_landing_card h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.beta_landing_card p {
  font-size: 16px;
}

.landing_contact_left p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
}

.landing_contact_left .start-free-trail a {
  width: 100%;
  float: left;
  margin-top: 20px;
}

/* .landing_contact {
  margin: 80px 0px;
} */

.landing_contact_right {
  padding: 40px !important;
  background: #fffcef;
}

.landing_contact_right p {
  color: #888989;
  font-size: 14px;
  font-family: "Roboto-Regular";
  line-height: 30px;
}

.landing_contact_right h5 {
  text-align: left;
  font-size: 16px;
  color: #888989;
  font-family: Roboto-Medium;
  margin-bottom: 20px;
}

.accordian_landing .accordion {
  width: 100%;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

._field-wrapper select {
  border-radius: 0px !important;
  -webkit-appearance: inherit;
}

.accordian_landing .accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 42px;
  color: #4095e9;
  font-size: 18px;
  font-family: "Gordita-Medium";
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-bottom: 1px solid #ccc;
}

.accordian_landing .accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #4095e9;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordian_landing .accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}

.accordian_landing .accordion li.open1 .link {
  color: #4095e9;
}

.accordian_landing .accordion li.open1 i {
  color: #4095e9;
}

.accordian_landing .accordion li.open1 i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordian_landing .submenu {
  font-size: 14px;
  padding: 0px;
}

.accordian_landing .submenu li {
  min-height: 30px;
  display: flex;
  align-items: center;
  border-left: 1px solid #ccc;
  padding-left: 20px;
  font-size: 16px;
  font-family: "Gordita-Regular";
  color: #333333;
}

.accordian_landing .submenu a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 12px;
  padding-left: 42px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.accordian_landing .submenu a:hover {
  background: #b63b4d;
  color: #fff;
}

.accordian_landing .open1 ul {
  display: block;
}

.submenu_div {
  padding: 20px 30px;
  border-bottom: 1px solid #ccc;
}

.hide_data_landing {
  margin-top: 80px;
}

.hide_data_landing h2 {
  color: #333333;
  font-size: 30px;
  font-family: "Roboto-Bold";
  text-align: left;
  margin-bottom: 80px;
}

.hide_data_landing img {
  margin-bottom: 20px;
  width: 140px;
  height: 140px;
}

.hide_data_landing .landing_expand {
  padding: 30px;
}

.data_landing_success img {
  width: 24px;
  height: 24px;
  margin: 0px;
}

.data_landing_success ul {
  padding: 0px;
}

.data_landing_success ul li {
  margin-bottom: 20px;
  color: #333333;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-align: left;
  list-style: none;
}

.landing_collapse {
  width: 100%;
  float: left;
}

.accordian_landing {
  width: 100%;
  float: left;
}

.beta_landing_head_link a {
  text-decoration: none !important;
}

.data_landing_success .col-lg-4 {
  margin-bottom: 30px;
}

.landing_expand_p img {
  width: 15px;
  height: 15px;
  margin: 0px;
}

.landing_expand_para {
  cursor: pointer;
}

.landing_expand_para img {
  width: 15px;
  height: 15px;
  margin: 0px;
}

.accordian_landing h2 {
  color: #333333;
  font-size: 30px;
  font-family: "Gordita-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.accordian_landing ul li {
  list-style: none;
}

.beta_landing_head h1 {
  color: #333333;
  font-size: 46px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
  line-height: 55px;
}

.beta_landing_head p {
  color: #333333;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 30px;
  padding: 0px 12rem;
}

.landing_expand_hide {
  cursor: pointer;
}

.landing_expand {
  padding: 30px !important;
}

.landing_contact_img img {
  /* animation: MoveUpDown 3s linear infinite; */
  position: relative;
  width: 300px;
}

/* @keyframes MoveUpDown {
    0%, 100% {
        bottom: -30px;
    }
    50% {
        bottom: 0px;
    }
} */

/*** 

====================================================================
	signup page form  
====================================================================

***/

#_form_1_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: "Roboto-Regular";
  margin: 0;
}

#_form_1_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_1_._form-top {
  top: 0;
}

#_form_1_._form-bottom {
  bottom: 0;
}

#_form_1_._form-left {
  left: 0;
}

#_form_1_._form-right {
  right: 0;
}

#_form_1_ input[type="text"],
#_form_1_ input[type="date"],
#_form_1_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 0px;
  color: #273237 !important;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_1_ textarea {
  resize: none;
}

#_form_1_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  color: #fff !important;
  padding: 10px !important;
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 40px;
  border-radius: 0px;
  background-color: #2468f6;
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #2468f6;
}

#_form_1_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_1_ ._close-icon:before {
  position: relative;
}

#_form_1_ ._form-body {
  margin-bottom: 30px;
}

#_form_1_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_1_ ._form-content-right {
  margin-left: 164px;
}

#_form_1_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_1_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_1_ ._form-label,
#_form_1_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_1_._dark ._form-branding {
  color: #333333;
}

#_form_1_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_1_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_1_ ._form_element * {
  font-size: 14px;
}

#_form_1_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_1_ ._form_element._clear:after {
  clear: left;
}

#_form_1_ ._form_element input[type="text"],
#_form_1_ ._form_element input[type="date"],
#_form_1_ ._form_element select,
#_form_1_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 34px;
  padding: 0px 10px;
  background: #fff;
  border: #979797 1px solid;
  border-radius: 0px;
  outline: 0;
}

#_form_1_ ._field-wrapper {
  position: relative;
}

#_form_1_ ._inline-style {
  float: left;
}

#_form_1_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_1_ ._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_1_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_1_ ._clear-element {
  clear: left;
}

#_form_1_ ._full_width {
  width: 100%;
}

#_form_1_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_1_ input[type="text"]._has_error,
#_form_1_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_1_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_1_ ._error {
  display: block;
  position: absolute;
  font-size: 13px;
  z-index: 10000001;
}

#_form_1_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_1_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_1_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_1_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_1_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 13px;
  font-family: "Roboto-Regular";
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#_form_1_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_1_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_1_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_1_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_1_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  #_form_1_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #_form_1_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }

  #_form_1_ ._form-content {
    margin: 0;
    width: 100%;
  }

  #_form_1_ ._form-inner {
    display: block;
    min-width: 100%;
  }

  #_form_1_ ._form-title,
  #_form_1_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #_form_1_ ._form-title {
    font-size: 1.2em;
  }

  #_form_1_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }

  #_form_1_ ._form-element,
  #_form_1_ ._inline-style,
  #_form_1_ input[type="text"],
  #_form_1_ label,
  #_form_1_ p,
  #_form_1_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }

  #_form_1_ ._row._checkbox-radio label {
    display: inline;
  }

  #_form_1_ ._row,
  #_form_1_ p,
  #_form_1_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }

  #_form_1_ ._row input[type="checkbox"],
  #_form_1_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }

  #_form_1_ ._row input[type="checkbox"]+span label {
    display: inline;
  }

  #_form_1_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }

  #_form_1_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }

  #_form_1_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }

  #_form_1_ input[type="radio"],
  #_form_1_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }

  #_form_1_ button[type="submit"] {
    padding: 20px;
    font-size: 1.5em;
  }

  #_form_1_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}

#_form_1_ {
  position: relative;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  background: #fff !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #494949 !important;
}

#_form_1_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_1_:before,
#_form_1_:after {
  content: " ";
  display: table;
}

#_form_1_:after {
  clear: both;
}

#_form_1_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_1_._inline-style input[type="text"],
#_form_1_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_1_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_1_._inline-style p {
  margin: 0;
}

#_form_1_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_1_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

#_form_1_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #485a63;
  margin-bottom: 40px;
  font-family: "Roboto-Regular";
}

.scroll-prompt {
  position: relative;
  top: -15px;
}

.scroll-prompt .scroll-prompt-arrow-container {
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -18px;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.scroll-prompt .scroll-prompt-arrow {
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  margin-top: -3px;
}

.scroll-prompt .scroll-prompt-arrow:last-child {
  animation-direction: reverse;
  margin-top: -3px;
}

.scroll-prompt .scroll-prompt-arrow>div {
  width: 15px;
  height: 15px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(45deg) translateZ(1px);
  transform: rotate(45deg) translateZ(1px);
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }

  20% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }

  30% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }

  40% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  60% {
    -webkit-transform: translateY(18px);
    transform: translateY(18px);
  }

  70% {
    -webkit-transform: translateY(21px);
    transform: translateY(21px);
  }

  80% {
    -webkit-transform: translateY(24px);
    transform: translateY(24px);
  }

  90% {
    -webkit-transform: translateY(27px);
    transform: translateY(27px);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }

  20% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }

  30% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }

  40% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  60% {
    -webkit-transform: translateY(18px);
    transform: translateY(18px);
  }

  70% {
    -webkit-transform: translateY(21px);
    transform: translateY(21px);
  }

  80% {
    -webkit-transform: translateY(24px);
    transform: translateY(24px);
  }

  100% {
    -webkit-transform: translateY(27px);
    transform: translateY(27px);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

.scroll-prompt_dropdown {
  position: relative;
  top: 70px;
  transform: rotate(-180deg);
  left: -50px;
}

#_form_1_ ._form-thank-you {
  margin-bottom: 0px !important;
}

.beta_link .main-download-broucher a {
  color: #2468f6 !important;
}

.main-download-broucher a:hover {
  color: #1757dc !important;
  text-decoration: underline;
}

.center_contactus {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-evenly;
}

/* integratons */

.bg_img_assistant_top {
  background-size: cover;
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  top: -130px;
  background: linear-gradient(to bottom,
      rgba(207, 240, 253, 1) 0%,
      rgba(207, 240, 253, 1) 19%,
      rgba(246, 246, 246, 1) 75%,
      rgba(255, 255, 255, 1) 100%);
  z-index: -1;
}

.main-slider .container {
  position: relative;
}

.header-logo.mobile-logo {
  height: 80px;
}

.header_feature {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.25) !important;
}

.mob_menu {
  display: none;
}

/* contact us form */

#_form_10_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: "Gordita-Regular";
  margin: 0;
}

#_form_10_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_10_._form-top {
  top: 0;
}

#_form_10_._form-bottom {
  bottom: 0;
}

#_form_10_._form-left {
  left: 0;
}

#_form_10_._form-right {
  right: 0;
}

#_form_10_ input[type="text"],
#_form_10_ input[type="date"],
#_form_10_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 0px;
  color: #273237 !important;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_10_ textarea {
  resize: none;
}

#_form_10_ ._submit:hover {
  transform: scale(1.012);
}

#_form_10_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  -moz-border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 0px !important;
  transition: transform 0.5s ease;
  width: 180px;
  margin-top: 35px;
  margin-left: 20px;
  background-color: #2468f6;
  font-family: "Gordita-Regular";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #2468f6;
  height: 42px;
}

#_form_10_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_10_ ._close-icon:before {
  position: relative;
}

#_form_10_ ._form-body {
  margin-bottom: 30px;
}

#_form_10_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_10_ ._form-content-right {
  margin-left: 164px;
}

#_form_10_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_10_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_10_ ._form-label,
#_form_10_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_10_._dark ._form-branding {
  color: #333333;
}

#_form_10_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_10_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 44%;
  float: left;
  margin: 7px 20px;
}

#_form_10_ ._form_element * {
  font-size: 14px;
}

#_form_10_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_10_ ._form_element._clear:after {
  clear: left;
}

#_form_10_ ._form_element input[type="text"],
#_form_10_ ._form_element input[type="date"],
#_form_10_ ._form_element select,
#_form_10_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_10_ ._field-wrapper {
  position: relative;
}

#_form_10_ ._inline-style {
  float: left;
}

#_form_10_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_10_ ._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_10_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_10_ ._clear-element {
  clear: left;
}

#_form_10_ ._full_width {
  width: 100%;
}

#_form_10_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_10_ input[type="text"]._has_error,
#_form_10_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_10_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_10_ ._error {
  display: block;
  position: absolute;
  font-size: 13px;
  z-index: 10000001;
}

#_form_10_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_10_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_10_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_10_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_10_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 13px;
  font-family: "Roboto-Regular";
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#_form_10_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_10_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_10_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_10_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_10_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  #_form_10_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #_form_10_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }

  #_form_10_ ._form-content {
    margin: 0;
    width: 100%;
  }

  #_form_10_ ._form-inner {
    display: block;
    min-width: 100%;
  }

  #_form_10_ ._form-title,
  #_form_10_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #_form_10_ ._form-title {
    font-size: 1.2em;
  }

  #_form_10_ ._form-element,
  #_form_10_ ._inline-style,
  #_form_10_ input[type="text"],
  #_form_10_ label,
  #_form_10_ p,
  #_form_10_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }

  #_form_10_ ._row._checkbox-radio label {
    display: inline;
  }

  #_form_10_ ._row,
  #_form_10_ p,
  #_form_10_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }

  #_form_10_ ._row input[type="checkbox"],
  #_form_10_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }

  #_form_10_ ._row input[type="checkbox"]+span label {
    display: inline;
  }

  #_form_10_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }

  #_form_10_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }

  #_form_10_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }

  #_form_10_ input[type="radio"],
  #_form_10_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }

  #_form_10_ button[type="submit"] {
    padding: 20px;
    font-size: 1.5em;
  }

  #_form_10_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}

#_form_10_ {
  position: relative;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  background: #fff !important;
  border: 0px solid #b0b0b0 !important;
  width: 100%;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #494949 !important;
}

#_form_10_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_10_:before,
#_form_10_:after {
  content: " ";
  display: table;
}

#_form_10_:after {
  clear: both;
}

#_form_10_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_10_._inline-style input[type="text"],
#_form_10_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_10_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_10_._inline-style p {
  margin: 0;
}

#_form_10_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_10_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  padding: 30px;
  border: 1px solid #ccc;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_10_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

._form_element._field5._full_width_text_area {
  max-width: 93.3% !important;
  width: 93.3%;
  margin-right: 0px;
}

._button-wrapper._full_width.w_100 {
  width: 96%;
  float: left;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  #_form_10_ ._form_element {
    margin: 0px 0px 10px 20px;
    padding: 0;
    font-size: 0;
    max-width: 45%;
    float: left;
  }

  #_form_10_ ._submit {
    -webkit-appearance: none;
    cursor: pointer;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    color: #fff !important;
    padding: 0px !important;
    width: 93%;
    margin-top: 20px;
    margin-left: 0px;
    background-color: #2468f6;
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #2468f6;
    height: 40px;
  }
}

@media only screen and (max-width: 415px) {
  #_form_10_ ._form_element {
    margin: 12px 0px 0px 0px !important;
    padding: 0;
    font-size: 0;
    max-width: 100%;
    float: left;
  }

  #_form_10_ ._submit {
    -webkit-appearance: none;
    cursor: pointer;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    color: #fff !important;
    padding: 0px !important;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
    background-color: #2468f6;
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #2468f6;
    height: 40px;
  }

  ._form_element._field5._full_width_text_area {
    max-width: 100% !important;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .--quick-link ul {
    display: none;
  }

  .--quick-link ul.show {
    display: block;
  }

  .--quick-link h4:after {
    content: "\f107";
    float: right;
    font-family: FontAwesome;
    font-size: 20px;
  }

  .--quick-link h4.arrow-up:after {
    transform: rotate(180deg);
  }
}

/* Features page ReDesign Start */

.bg_feature_slider {
  background: #edf1ff 0% 0% no-repeat padding-box;
  margin-top: 0px;
  position: relative;
}

.bg_feature_slider .main-slider-left {
  padding-bottom: 0px;
  padding-left: 90px;
}

.bg_feature_slider .main-slider-left p {
  margin-bottom: 10px;
  padding-right: 30px;
}

/* .bg_feature_slider .features_change_img_header {
  position: absolute;
} */

/* .features_change_img-center img{
  border:1px solid #fff;
  border-radius: 15px;
} */

.features_change_img {
  padding-left: 0px;
}

.features_content_left {
  width: 100%;
  float: left;
  padding-bottom: 40px;
}

.main-slider.feature_inner_page.bg_feature_slider {
  margin-bottom: 0px;
  padding-top: 0px;
  position: relative;
  top: -1px;
}

.feature_bg_img:before {
  position: absolute;
  background: #edf1ff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 70%;
  content: "";
  top: 0;
  left: 0;
}

.features_img_left {
  width: 100%;
  float: left;
  padding-bottom: 40px;
}

.pl-20-tage .feature_page_content_left {
  padding-left: 105px;
}

.pl-10-tage .feature_page_content_left {
  padding-left: 90px;
}

.feature_page_content_left h6 {
  height: 14px;
  text-align: left;
  font-family: "Roboto-Regular";
  font-size: 12px;
  letter-spacing: 0.84px;
  color: #354250;
  text-transform: uppercase;
  padding-bottom: 10px;
  width: 100%;
  float: left;
}

.feature_page_content_left h3 {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 32px;
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 10px;
  width: 100%;
  float: left;
  font-size: 28px;
}

.feature_page_content_left h2 {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 38px;
  letter-spacing: -1.14px;
  color: #333;
  padding-bottom: 10px;
  width: 100%;
  float: left;
}

.feature_page_content_left h1 {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 38px;
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 10px;
  margin-bottom: 0px;
  width: 100%;
  float: left;
}

.feature_page_content_left p {
  text-align: left;
  font-size: 17px;
  font-family: "Roboto-Regular";
  letter-spacing: 0;
  color: #354250;
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.container_trial .trial_page_img.integrations_page_form .main-slider-button.beta-button {
  margin-top: 4px;
}

.feature_page_content_left p.basic_plans {
  margin-top: 40px;
}

.feature_page_content_left a {
  padding-top: 0px;
  text-align: left;
  text-decoration: underline;
  font-size: 15px;
  font-family: "Roboto-Medium";
  letter-spacing: 0;
  color: #2e6dfa;
  width: 100%;
  float: left;
}

.feature_page_img_right img {
  width: 75%;
  float: left;
  height: 100%;
}

.feature_pageinner_content_img img {
  width: 75%;
  float: left;
  height: 100%;
}

.feature_pageinner_content_img .feature_page_img_right {
  padding-right: 40px;
}

.w-100-fl {
  width: 100%;
  float: left;
}

.feature_page_img_right {
  display: flex;
  justify-content: flex-end;
}

.features_img_left .feature_page_img_right {
  display: flex;
  justify-content: flex-start;
}

.pl-20-tage.mt-0 {
  margin-top: 0px !important;
  padding-top: 40px;
  background-color: white;
}

.new_design_landing_footer .know-how-assistant-content p {
  margin-right: 12rem;
}

.new_design_landing_footer {
  background: #fff;
}

.wv-footer.--dark-footer {
  background: #101629;
}

.wv-footer.--dark-footer .--quick-link h4 {
  color: #fff;
}

.wv-footer.--dark-footer .--quick-link ul li a {
  color: #a7abb4;
}

.wv-footer.--dark-footer p {
  color: #6a6f79;
}

.wv-footer.--dark-footer .--footer-sublink ul li a {
  color: #6a6f79;
}

.wv-footer.--dark-footer .footer_social_icons span {
  color: #6a6f79;
}

.wv-footer.--dark-footer .wv-social-link img {
  filter: none;
}

.know-how-assistant-image.mobile_change_footer_img {
  display: none;
}

.know-how-assistant-image {
  display: block;
}

.new_design_landing_footer .start-free-trail a {
  font-family: "Roboto-Medium";
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background-color: #2468f6;
  padding: 0px 48px;
  border-radius: 0px;
  height: 56px;
  display: inline-flex;
  align-items: center;
}

.back_features {
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #333333;
  padding-bottom: 18px;
  width: 100%;
  float: left;
}

.back_features_page {
  width: auto;
  float: left;
  cursor: pointer;
}

.back_features_page a {
  text-decoration: none;
  color: #333333;
}

.back_features_page a:hover {
  text-decoration: none;
  color: #333333;
}

.back_features span {
  margin-right: 5px;
}

.features_content_innerpage {
  margin-top: 150px;
  width: 100%;
  float: left;
}

.col-sm-12.cards_features_header {
  width: 100%;
  float: left;
}

.cards_features_header_div img {
  width: 84px;
  height: 84px;
  margin-bottom: 30px;
}

.cards_features_header_div h5 {
  font-family: "Roboto-Medium";
  font-size: 22px;
  color: #333333;
  padding-bottom: 20px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.cards_features_header_div p {
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: #333333;
  padding-bottom: 20px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.cards_features_header_div a {
  font-family: "Roboto-Medium";
  font-size: 15px;
  color: #2e6dfa;
  padding-bottom: 24px;
  width: auto;
  float: left;
  margin-bottom: 80px;
  text-decoration: none;
}

.cards_features_header_div {
  width: 100%;
  float: left;
  padding-left: 90px;
}

.cards_features_header_div p {
  padding-right: 24%;
}

.cards_features_header_div a:hover {
  text-decoration: none;
  color: #2e6dfa;
}

.p_t_bg_remove {
  background: inherit;
  padding-top: 0px;
}

.flex_container_center {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.features_inner_page_footer {
  display: none;
}

.build_needs {
  width: 100%;
  float: left;
}

.build_needs h2 {
  font-family: "Gordita-Medium";
  font-size: 38px;
  color: #333333;
  padding-bottom: 10px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.build_needs p {
  font-family: "Gordita-Regular";
  font-size: 17px;
  color: #354250;
  padding-bottom: 60px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.buton_all_features_page {
  width: 100%;
  float: left;
}

.buton_all_features {
  width: 100%;
  float: left;
}

.button_fetaurs_toogle {
  font-size: 16px;
  padding: 0px 30px;
  width: 100%;
  float: left;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2e6dfa;
  border: 1px solid #4095e9;
  text-decoration: underline;
  cursor: pointer;
}

.buton_all_features_page {
  margin-bottom: 80px;
}

.buton_all_features_page {
  display: none;
}

/* .feature_pageinner_content_left ul li span svg {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  color: #42b777;
} */

.feature_pageinner_content_img {
  padding-bottom: 0px;
}

.m_b_80 {
  margin-bottom: 40px;
}

.training_feature_h4 {
  width: 100%;
  float: left;
  padding-left: 105px;
  text-align: left;
  font-family: "Roboto-Bold";
  font-size: 38px;
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 24px;
  margin-bottom: 40px;
}

.img_absolute {
  width: 88%;
  top: 17%;
  left: 22%;
  position: absolute;
}

.bg_p_wihout_padding {
  background: #f8f8f8;
  padding: 15px;
  color: #416183;
  min-height: 237px;
  border-radius: 20px;
}

.bg_p_wihout_padding h3 {
  padding-bottom: 3px;
}

.bg_p_wihout_padding p {
  line-height: inherit;
}

.feature_page_content_left .bg_p_wihout_padding a {
  padding-top: 3px;
}

.bg_p {
  background: #5558af;
}

.bg_p.teams_card {
  background: #5558af;
}

.bg_pw {
  background: #373dd4;
}

.bg_pw.widget_card {
  background: #3789e1;
}

.features_content_left_cc .row {
  display: flex;
  justify-content: space-between;
}

.bg_p h3 {
  color: #fff !important;
}

.bg_p p {
  color: #fff;
}

.bg_p.bg_p_wihout_padding p {
  color: #fff;
}

.bg_p_wihout_padding_slack.bg_p {
  background: #8b4d8b;
}

.bg_p_wihout_padding_widget.bg_p {
  background: #3789e1;
}

.bg_p_wihout_padding_slack.bg_p h3 {
  color: #fff !important;
}

.bg_p_wihout_padding_slack.bg_p p {
  color: #fff !important;
}

.bg_p_wihout_padding {
  padding: 30px;
  /* cursor: pointer; */
}

/* .features_content_left.features_content_left_cc {
  margin-top: 230px;
} */

.features_content_left .img_mob_res {
  display: none !important;
}

.cogni_fav_apps.cc_feature_apps {
  background: #f5f6f8;
  width: 100%;
  float: left;
  margin-bottom: 80px;
  padding-top: 80px;
}

.cc_feature_apps_header h3 {
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 38px;
  color: #000;
  margin-bottom: 30px;
  width: 100%;
  float: left;
  padding: 0px 20%;
}

.cc_feature_apps .cogni_fav_apps_color {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cc_feature_apps .card_office {
  background: #fff;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  float: left;
  margin-right: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cc_feature_apps .card_office img {
  width: 36px;
  height: 36px;
}

.cc_feature_apps .card_office.ibm_img img {
  width: 36px;
  height: 30px;
}

.cc_feature_apps .card_office_cc h6 {
  text-align: left;
  font-family: "Gordita-Medium";
  font-size: 14px;
  letter-spacing: 0;
  color: #354250;
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 0px;
}

.new_automate_background .cc_feature_apps .card_office_cc h6 {
  margin-top: 10px;
}

.automate-slider-bottom-left-div .automate-slider-left-scnd-div .automate-slider-bottom-image {
  width: 13.7%;
}

.card_office_cc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cc_feature_apps h5 {
  width: 100%;
  float: left;
}

.cc_feature_apps h5 a {
  font-family: "Roboto-Medium";
  font-size: 15px;
  width: 100%;
  float: left;
  margin-top: 60px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #2e6dfa;
  cursor: pointer;
}

.cc_feature_apps h5:hover a {
  text-decoration: none;
  color: #2e6dfa;
  cursor: pointer;
}

/* Features page ReDesign End */

/* Demo registration form start */

#_form_13_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: "Roboto-Regular";
  margin: 0;
}

#_form_13_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_13_._form-top {
  top: 0;
}

#_form_13_._form-bottom {
  bottom: 0;
}

#_form_13_._form-left {
  left: 0;
}

#_form_13_._form-right {
  right: 0;
}

#_form_13_ input[type="text"],
#_form_13_ input[type="date"],
#_form_13_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 0px;
  color: #273237 !important;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_13_ textarea {
  resize: none;
}

#_form_13_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  color: #fff !important;
  padding: 10px !important;
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 40px;
  border-radius: 8px;
  background-color: #2468f6;
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #2468f6;
}

#_form_13_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_13_ ._close-icon:before {
  position: relative;
}

#_form_13_ ._form-body {
  margin-bottom: 30px;
}

#_form_13_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_13_ ._form-content-right {
  margin-left: 164px;
}

#_form_13_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_13_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_13_ ._form-label,
#_form_13_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_13_._dark ._form-branding {
  color: #333333;
}

#_form_13_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_13_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_13_ ._form_element * {
  font-size: 14px;
}

#_form_13_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_13_ ._form_element._clear:after {
  clear: left;
}

#_form_13_ ._form_element input[type="text"],
#_form_13_ ._form_element input[type="date"],
#_form_13_ ._form_element select,
#_form_13_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 34px;
  padding: 0px 10px;
  background: #fff;
  border: #979797 1px solid;
  border-radius: 0px;
}

#_form_13_ ._field-wrapper {
  position: relative;
}

#_form_13_ ._inline-style {
  float: left;
}

#_form_13_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_13_ ._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_13_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_13_ ._clear-element {
  clear: left;
}

#_form_13_ ._full_width {
  width: 100%;
}

#_form_13_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_13_ input[type="text"]._has_error,
#_form_13_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_13_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_13_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}

#_form_13_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_13_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_13_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_13_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_13_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#_form_13_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_13_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_13_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_13_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_13_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

#_form_13_ {
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

#_form_13_ * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1em;
}

#_form_13_ ._form-content {
  margin: 0;
  width: 100%;
}

#_form_13_ ._form-inner {
  display: block;
  min-width: 100%;
}

#_form_13_ ._form-title,
#_form_13_ ._inline-style {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

#_form_13_ ._form-title {
  font-size: 1.2em;
}

#_form_13_ ._form_element {
  margin: 0 0 20px;
  padding: 0;
  width: 100%;
}

#_form_13_ ._form-element,
#_form_13_ ._inline-style,
#_form_13_ input[type="text"],
#_form_13_ label,
#_form_13_ p,
#_form_13_ textarea:not(.g-recaptcha-response) {
  float: none;
  display: block;
  width: 100%;
}

#_form_13_ ._row._checkbox-radio label {
  display: inline;
}

#_form_13_ ._row,
#_form_13_ p,
#_form_13_ label {
  margin-bottom: 0.7em;
  width: 100%;
}

#_form_13_ ._row input[type="checkbox"],
#_form_13_ ._row input[type="radio"] {
  margin: 0 !important;
  vertical-align: middle !important;
}

#_form_13_ ._row input[type="checkbox"]+span label {
  display: inline;
}

#_form_13_ ._row span label {
  margin: 0 !important;
  width: initial !important;
  vertical-align: middle !important;
}

#_form_13_ ._form-image {
  max-width: 100%;
  height: auto !important;
}

#_form_13_ input[type="text"] {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  line-height: 1.3em;
  -webkit-appearance: none;
}

#_form_13_ input[type="radio"],
#_form_13_ input[type="checkbox"] {
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  font-size: 1em;
  margin: 0 0.3em 0 0;
  vertical-align: baseline;
}

#_form_13_ button[type="submit"] {
  padding: 20px;
  font-size: 1.5em;
}

#_form_13_ ._inline-style {
  margin: 20px 0 0 !important;
}

#_form_13_ {
  position: relative;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  background: #fff !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #494949 !important;
}

#_form_13_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_13_:before,
#_form_13_:after {
  content: " ";
  display: table;
}

#_form_13_:after {
  clear: both;
}

#_form_13_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_13_._inline-style input[type="text"],
#_form_13_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_13_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_13_._inline-style p {
  margin: 0;
}

#_form_13_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_13_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_13_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

.pika-single {
  z-index: 9999;
  display: block;
  position: relative;
  color: #333333;
  background: #fff;
  border: 1px solid #ccc;
  border-bottom-color: #bbb;
  font-family: "Roboto-Regular";
}

.pika-single:before,
.pika-single:after {
  content: " ";
  display: table;
}

.pika-single:after {
  clear: both;
}

.pika-single {
  zoom: 1;
}

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}

.pika-lendar {
  float: left;
  width: 240px;
  margin: 8px;
}

.pika-title {
  position: relative;
  text-align: center;
}

.pika-label {
  display: inline-block;
  display: inline;
  position: relative;
  z-index: 9999;
  overflow: hidden;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: #fff;
}

.pika-title select {
  cursor: pointer;
  position: absolute;
  z-index: 9998;
  margin: 0;
  left: 0;
  top: 5px;
  filter: alpha(opacity=0);
  opacity: 0;
}

.pika-prev,
.pika-next {
  display: block;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 0;
  padding: 0;
  width: 20px;
  height: 30px;
  /* hide text using text-indent trick, using width value (it's enough) */
  text-indent: 20px;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  opacity: 0.5;
  position: absolute;
  top: 0;
}

.pika-prev:hover,
.pika-next:hover {
  opacity: 1;
}

.pika-prev,
.is-rtl .pika-next {
  float: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
  left: 0;
}

.pika-next,
.is-rtl .pika-prev {
  float: right;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
  right: 0;
}

.pika-prev.is-disabled,
.pika-next.is-disabled {
  cursor: default;
  opacity: 0.2;
}

.pika-select {
  display: inline-block;
  display: inline;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.pika-table th,
.pika-table td {
  width: 14.285714285714286%;
  padding: 0;
}

.pika-table th {
  color: #999;
  font-size: 12px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
}

.pika-button {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  color: #666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  background: #f5f5f5;
}

.pika-week {
  font-size: 11px;
  color: #999;
}

.is-today .pika-button {
  color: #33aaff;
  font-weight: bold;
}

.is-selected .pika-button {
  color: #fff;
  font-weight: bold;
  background: #33aaff;
  box-shadow: inset 0 1px 3px #178fe5;
  border-radius: 3px;
}

.is-inrange .pika-button {
  background: #d5e9f7;
}

.is-startrange .pika-button {
  color: #fff;
  background: #6cb31d;
  box-shadow: none;
  border-radius: 3px;
}

.is-endrange .pika-button {
  color: #fff;
  background: #33aaff;
  box-shadow: none;
  border-radius: 3px;
}

.is-disabled .pika-button {
  pointer-events: none;
  cursor: default;
  color: #999;
  opacity: 0.3;
}

.pika-button:hover {
  color: #fff;
  background: #ff8000;
  box-shadow: none;
  border-radius: 3px;
}

/* styling for abbr */

.pika-table abbr {
  border-bottom: none;
  cursor: help;
}

#registration .spotpicker .spotpicker-dates {
  padding-bottom: 0px !important;
  max-width: 100%;
}

.sign_up-form_us.demo_page {
  padding-top: 40px;
  width: 100%;
  float: left;
}

.sign_up-form_us.demo_page h1 {
  text-align: left;
  width: 100%;
  float: left;
  color: #4d5055;
  font-size: 26px;
  font-family: "Roboto-Medium";
}

.sign_up-form_us.demo_page p {
  color: #193a5d;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  text-align: left;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  float: left;
  margin-top: 0px;
}

.sign_up-form_us.demo_page h6 {
  color: #4d5055;
  font-size: 20px;
  font-family: "Roboto-Medium";
  line-height: 34px;
  text-align: left;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  float: left;
}

.webianr_ul {
  width: 100%;
  float: left;
}

.webianr_ul ul {
  padding-left: 15px;
  width: 100%;
  float: left;
}

.webianr_ul ul li {
  color: #4d5055;
  font-size: 15px;
  font-family: "Roboto-Regular";
  text-align: left;
  padding: 0;
  width: 100%;
  margin-bottom: 10px;
  list-style: disc;
}

.demo_page_form {
  border: 1px solid #c7c7c7;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  position: relative;
  z-index: 10;
  width: 75%;
  height: 560px;
}

.demo_page_form.assitants-demo-page{
  width:100%;
}

.demo_page_form h4 {
  color: #4d5055;
  font-size: 26px;
  font-family: "Roboto-Medium";
  line-height: 30px;
  text-align: center;
  padding: 0;
  width: 100%;
  float: left;
  margin-bottom: 15px;
  background: #fff;
}

.demo_page_right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  float: left;
}

.demo_page_right ul {
  width: 100%;
  float: left;
}

.demo_page_right h6 {
  width: 100%;
  float: left;
}

.icon_arrow {
  position: absolute;
  top: 5%;
  left: 2%;
  right: 0;
  width: 60px;
  height: 60px;
  background: url("../images/arrow.svg") no-repeat;
  animation: fadeInRight 2s infinite ease-in-out;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(15px);
  }
}

.sign_up-form_us.demo_page {
  margin-bottom: 40px !important;
  margin-top: 0px !important;
  padding-bottom: 40px;
}

.btn-secondary.btn-large {
  border-radius: 0px;
}

/* Demo registration form end */

.features_change_img_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.features_change_img-center {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.features_change_img-center video {
  width: 82%;
  float: left;
  box-shadow: 0 2px 10px 0 rgba(138, 168, 230, 0.2);
  border-radius: 12px;
  margin: 0 auto;
}

.features_change_img-center img {
  width: 82%;
  float: left;
  margin: 0 auto;
}

.assistant_change_img.feature_cc_slider {
  width: 82%;
  margin: 0 auto;
  display: block;
}

.assistant_change_img.feature_cc_slider img {
  filter: none;
  box-shadow: 0px 3px 5px rgb(104, 103, 103, 0.1);
}

.cogni_fav_apps.cc_feature_apps .row {
  width: 100%;
  float: left;
}

/* how it works page start */

.how_it_section {
  width: 100%;
  float: left;
}

.how_it_section .container {
  padding: 0px 5%;
}

.how_it_section .feature_page_content_left {
  padding-left: 0px;
}

.how_it_section .feature_page_content_left p {
  padding-left: 0px;
  padding-right: 0px;
}

.how_it_section .feature_page_content_left h3 span {
  margin-right: 10px;
}

.how_it_content img {
  width: 100%;
  height: 100%;
}

.how_it_works_header .how_it_content p {
  font-size: 22px;
  color: #416183;
  line-height: inherit;
  text-align: center;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
}

.how_it_content h3 {
  font-size: 24px;
  color: #333333;
  font-family: "Roboto-Medium";
  margin-bottom: 15px;
  text-align: center;
  margin-top: 10px;
}

.how_it_section h1 {
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

.how_it_section.how_it_page_content {
  background: #e7f2fd;
  padding-top: 20px;
  padding-bottom: 60px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.how_it_section.how_it_page_content .main-slider-left {
  padding: 0px;
}

.how_it_section_bg {
  background: #e7f2fd;
}

.trial_page_img.how_it_works_pad_top {
  padding-top: 80px;
}

.x_scroll_page {
  width: 100%;
  float: left;
  padding: 0px 30px;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
}

.how_it_works_header {
  flex-basis: 25%;
  position: relative;
}

.bg_how_it_works {
  background: #fff;
  padding-top: 80px;
}

.bg_not_how_it_works {
  padding-top: 80px;
}

.how_it_landing_footer {
  background: #f7f7f7;
}

.wv-footer.wv-footer-white {
  background: #fff;
}

.know-how-assistant-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.how_it_landing_footer.know-how-assistant {
  padding-top: 60px;
  padding-bottom: 80px;
}

.how_it_content_before:before {
  content: "";
  position: absolute;
  top: 20%;
  left: 108%;
  background: url(../img/how-it-works/arrow.png);
  right: 0;
  width: 100px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* how it works page end */

/* trial landing page start */

/* Beta Landing */

body {
  overflow-x: hidden;
}

#myBtn {
  display: none;
}

.more-faqs-content p {
  text-align: center;
  font-weight: 600;
  color: #4095e9;
  padding: 1em 0;
  font-family: "Roboto-Regular";
  letter-spacing: 0.5px;
  font-size: 17px;
}

#myBtn .myButton:hover {
  color: rgba(0, 0, 0, 0);
}

.mybtn-beta {
  display: none;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9999;
}

#text {
  display: none;
  width: 100%;
}

.beta-content {
  background: none;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.cognii_work_content .cogni_works h1 {
  color: #333333;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.cognii_work_content .cogni_works p {
  color: #333333;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 80px;
  padding: 0px 12%;
}

.beta-slider img {
  padding: 5em 0 !important;
}

.beta-main-slider {
  margin-bottom: 0px !important;
}

.automate-slider-right h1 {
  font-family: "Roboto-Bold";
  font-size: 26px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 15px;
  width: 100%;
  float: left;
}

.automate-slider-right p {
  width: 100%;
  float: left;
  font-size: 17px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
}

.automate-slider-left img {
  width: 100%;
}

.beta-charging ul li {
  display: inline-block;
  padding: 0 2.5em;
  text-align: center;
}

.beta-charging {
  padding-top: 15px;
  margin: 0 auto;
}

.beta-charging img {
  width: auto;
}

.beta-charging p {
  color: #485a63;
  font-size: 14px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 0px;
  padding: 1.5em 0rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.cogni_fav_apps {
  padding-bottom: 0px !important;
}

.myButton {
  background: linear-gradient(to bottom, #3d94f6 5%, #1e62d0 100%);
  background-color: #3d94f6;
  border-radius: 50%;
  border: 1px solid #337fed;
  display: inline-block;
  cursor: pointer;
  color: rgba(255, 255, 255, 0);
  font-family: Arial;
  font-size: 10px;
  padding: 20px;
  text-decoration: none;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0);
  position: absolute;
  right: 0;
  bottom: 0;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
}

.myButton:before {
  content: "\f0f3";
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 20px;
  line-height: 17px;
  position: absolute;
  padding: 0px;
}

@media (max-width: 480px) and (min-width: 320px) {
  #myBtn {
    border: none;
    background: none;
    display: block;
  }

  input[type="text"],
  select {
    width: 100% !important;
    margin: 8px 0px 8px 0px !important;
    padding: 7px 15px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .main-slider-button button {
    margin-top: 10px;
    width: 100%;
  }

  .cognii_work_content .cogni_works h1 {
    font-size: 28px !important;
    margin-bottom: 30px;
  }

  .automate-slider-right h1 {
    font-size: 28px;
    line-height: 1.5;
  }

  .automate-slider-right p {
    font-size: 17px;
    margin-bottom: 0px;
    line-height: 30px;
  }

  /* .beta-slider img {
  display: block !important;
  width: 100% !important;
  padding: 2em 0 !important;
} */
  .cogni_fav_apps h1 {
    font-size: 22px !important;
    line-height: 1.5;
    padding: 0 15px;
    text-align: left;
  }

  .cogni_fav_apps_header p {
    font-size: 17px;
    text-align: left;
    padding: 0 15px;
    line-height: 30px;
  }

  .beta-charging img {
    width: 45% !important;
  }

  .beta-charging p {
    font-size: 12px;
  }

  .accordian_landing h2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .beta-charging ul li {
    display: inline-block;
    padding: 0 2.5em;
    text-align: center;
    width: 32.3333%;
  }
}

.landing_page_charging img {
  width: 84px !important;
  height: 84px;
}

.modal-content-popup ._field-wrapper input {
  width: 100%;
  padding: 6px 7px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 15px;
}

.modal-content-popup ._form_element {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  text-align: left;
}

._row._checkbox-radio span {
  margin-left: 3px;
}

.modal-content-popup ._field-wrapper input {
  outline: 0;
}

.modal-content-popup ._field-wrapper select {
  width: 100%;
  height: 35px;
  background: #fff;
  padding: 0px 10px;
  font-size: 15px;
}

#exampleModalCenter .modal-dialog {
  max-width: 56%;
}

#exampleModalCenter1 .modal-dialog {
  max-width: 56%;
}

.modal-body-header {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
}

#exampleModalCenter .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter .modal_body_right {
  width: 50%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exampleModalCenter .modal_body_right img {
  width: 70%;
  float: left;
  height: auto;
}

#exampleModalCenter1 .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter1 .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter1 .modal_body_right {
  width: 50%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exampleModalCenter1 .modal_body_right img {
  width: 70%;
  float: left;
}

.modal_submit_form .modal-header {
  border-bottom: 1px solid #fff !important;
  padding-bottom: 0px;
}

.modal_submit_form .modal-header h5 {
  font-size: 24px;
  font-family: "Roboto-Medium";
  padding-top: 15px;
  text-align: center;
}

.modal_submit_form ._button-wrapper._full_width button {
  border-radius: 50px;
  margin-bottom: 20px;
  width:60%;
  background: #2e6dfa;
  border: 1px solid #2e6dfa;
  color: #fff;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.modal-content.modal-content-popup {
  padding: 0px 15px;
  border-radius: 12px;
}

.cogni_fav_apps_header h3 {
  color: #333333;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.cogni_fav_apps_header h1 {
  color: #333333;
  font-size: 40px;
  font-family: "Gordita-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.cogni_fav_apps_header p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 30px;
  padding: 0px 12rem;
}

.cogni_fav_apps_landing {
  width: 100%;
  float: left;
  padding-bottom: 40px !important;
  padding-top: 0px;
  margin-bottom: 0px;
}

.content_left_img_right {
  width: 100%;
  float: left;
  padding-top: 0px;
}

.pl-10-tage .feature_page_content_left {
  padding-left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pl-20-tage .feature_page_content_left {
  padding-left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.landing_page .feature_page_content_left h3 {
  font-size: 30px;
}

.align_center_content {
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
}

.pad_right_20 {
  padding-right: 20%;
}

/* trial landing page end */

/* Webinar page start */

#_form_14_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: "Roboto-Regular";
  margin: 0;
}

#_form_14_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_14_._form-top {
  top: 0;
}

#_form_14_._form-bottom {
  bottom: 0;
}

#_form_14_._form-left {
  left: 0;
}

#_form_14_._form-right {
  right: 0;
}

#_form_14_ input[type="text"],
#_form_14_ input[type="date"],
#_form_14_ textarea {
  padding: 6px;
  height: auto;
  border: #999 1px solid;
  border-radius: 0px;
  color: #333 !important;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_14_ textarea {
  resize: none;
}

#_form_14_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: "Roboto-Regular";
  font-size: 16px;
  border: 0 !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  background: #2e6dfa !important;
  color: #fff;
  width: 160px;
  text-align: center;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

#_form_14_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_14_ ._close-icon:before {
  position: relative;
}

#_form_14_ ._form-body {
  margin-bottom: 30px;
}

#_form_14_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_14_ ._form-content-right {
  margin-left: 164px;
}

#_form_14_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_14_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_14_ ._form-label,
#_form_14_ ._form_element ._form-label {
  color: #4d5055;
  font-weight: bold;
  margin-bottom: 0px;
  display: block;
}

#_form_14_._dark ._form-branding {
  color: #333333;
}

#_form_14_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_14_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_14_ ._form_element * {
  font-size: 14px;
}

#_form_14_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_14_ ._form_element._clear:after {
  clear: left;
}

#_form_14_ ._form_element input[type="text"],
#_form_14_ ._form_element input[type="date"],
#_form_14_ ._form_element select,
#_form_14_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_14_ ._field-wrapper {
  position: relative;
}

#_form_14_ ._inline-style {
  float: left;
}

#_form_14_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_14_ ._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_14_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_14_ ._clear-element {
  clear: left;
}

#_form_14_ ._full_width {
  width: 100%;
  float: left;
}

#_form_14_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_14_ input[type="text"]._has_error,
#_form_14_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_14_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_14_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}

#_form_14_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_14_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_14_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_14_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_14_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#_form_14_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_14_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_14_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_14_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_14_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  #_form_14_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #_form_14_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }

  #_form_14_ ._form-content {
    margin: 0;
    width: 100%;
  }

  #_form_14_ ._form-inner {
    display: block;
    min-width: 100%;
  }

  #_form_14_ ._form-title,
  #_form_14_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #_form_14_ ._form-title {
    font-size: 1.2em;
  }

  #_form_14_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }

  #_form_14_ ._form-element,
  #_form_14_ ._inline-style,
  #_form_14_ input[type="text"],
  #_form_14_ label,
  #_form_14_ p,
  #_form_14_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }

  #_form_14_ ._row._checkbox-radio label {
    display: inline;
  }

  #_form_14_ ._row,
  #_form_14_ p,
  #_form_14_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }

  #_form_14_ ._row input[type="checkbox"],
  #_form_14_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }

  #_form_14_ ._row input[type="checkbox"]+span label {
    display: inline;
  }

  #_form_14_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }

  #_form_14_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }

  #_form_14_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }

  #_form_14_ input[type="radio"],
  #_form_14_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }

  #_form_14_ button[type="submit"] {
    padding: 20px;
    font-size: 1.5em;
  }

  #_form_14_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}

#_form_14_ {
  position: relative;
  text-align: left;
  margin: 0px auto 0;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  background: #fff !important;
  border: 0px solid #b0b0b0 !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #273237 !important;
  width: 100%;
}

#_form_14_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_14_:before,
#_form_14_:after {
  content: " ";
  display: table;
}

#_form_14_:after {
  clear: both;
}

#_form_14_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_14_._inline-style input[type="text"],
#_form_14_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_14_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_14_._inline-style p {
  margin: 0;
}

#_form_14_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_14_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

#_form_14_ ._form_element input[type="text"],
#_form_14_ ._form_element input[type="date"],
#_form_14_ ._form_element select,
#_form_14_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #999;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_14_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

.webinar_form #_form_14_ button[type="submit"]:hover {
  background: #ff7900 !important;
  color: #fff !important;
  border: 1px solid #ff7900 !important;
}

.webinar_form {
  width: 100%;
  float: left;
  padding-left: 5%;
}

.webinar_form h2 {
  text-align: left;
  width: 100%;
  float: left;
  color: #4d5055;
  font-size: 26px;
}

.webinar_form_head_photo img {
  width: 100px;
  height: 100px;
  margin: 0 0 10px 0;
}

.webinar_name {
  flex-basis: 0;
  flex-grow: 1;
  flex-direction: column;
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  color: #3d3f42;
  font-weight: 600;
  font-family: "Roboto-Regular";
}

.webinar_title {
  flex-basis: 0;
  flex-grow: 1;
  flex-direction: column;
  text-align: left;
  line-height: 1.5;
  font-size: 13px;
  color: #3d3f42;
  opacity: 0.8;
  margin-bottom: 20px;
  font-family: "Roboto-Regular";
}

.webinar_form_head_photo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  float: left;
}

.webinar_forms_head.demo_page h1 {
  font-size: 40px;
  margin-top: 20px;
  color: #333333;
  margin-bottom: 20px;
}

.demo_page.webinar_forms_head {
  padding-top: 0px;
}

.bg_header_whitepaper h2 {
  text-align: left;
  color: #4d5055;
}

.webinar_forms_head h1.webinar_head {
  font-size: 40px;
  color: #333333;
  font-family: "Roboto-Bold";
  margin-bottom: 0px;
}

.webinar_forms_head h1.webinar_header-bottom {
  font-size: 30px;
  color: #4d5055;
}

.webinar_forms_head.demo_page ul li:last-child {
  margin-bottom: 0px;
}

.webinar_forms_head.demo_page p {
  font-size: 17px;
}

.webinar_forms_head.demo_page h6 {
  font-size: 26px;
}

.webinar_forms_head.demo_page ul li {
  font-size: 17px;
  margin-bottom: 15px;
}

#_form_14_ ._full_width {
  margin-bottom: 20px;
}

#_form_14_ ._form-thank-you {
  width: 100%;
  min-height: 120px;
  border: 1px solid #ccc;
  margin-top: 100px;
  font-size: 35px;
  padding: 7%;
  text-align: center;
}

.webinar_form.webinar_form_mobile {
  display: none;
}

.demo_page_right.demo_page_responsive {
  display: none;
}

/* webinar page end */

/* Landing zendesk page start */

.trial_mb_80.bg_landing_zendesk {
  width: 100%;
  float: left;
  margin-bottom: 80px !important;
}

.trial_mb_80.bg_landing_freshservice button {
  background: #2468f6 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.trial_mb_80.bg_landing_zendesk sup {
  color: #fff;
}

.trial_mb_80.bg_landing_zendesk h6 {
  color: #fff;
}

.trial_mb_80.bg_landing_zendesk h1 {
  color: #fff;
}

.trial_mb_80.bg_landing_zendesk p {
  color: #fff;
  word-break: break-word;
  padding-right: 5%;
}

.trial_mb_80.bg_landing_zendesk .beta-button input[type="text"] {
  background: #fff;
  color: #494949;
  border: 1px solid #fff;
}

.trial_mb_80.bg_landing_zendesk .beta-button input::-webkit-input-placeholder {
  color: #494949 !important;
}

.trial_mb_80.bg_landing_zendesk .beta-button input:-moz-placeholder {
  color: #494949 !important;
}

.trial_mb_80.bg_landing_zendesk .beta-button input[type="text"]::placeholder {
  color: #fff;
}

.trial_mb_80.bg_landing_zendesk .beta-button input[type="text"]::placeholder {
  color: #fff;
}

.trial_mb_80.bg_landing_zendesk .beta-button button {
  background: #2468f6;
  color: #fff;
  border: 1px solid #2468f6;
}

.bg_landing_zendesk_img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bg_landing_zendesk_img .assistant_right_img.beta-slider {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.bg_landing_zendesk_img img {
  width: 65%;
}

.bg_landing_zendesk .main-slider-button.beta-button {
  width: 100%;
  float: left;
  margin-bottom: 70px;
}

/* Landing zendesk page end */

/* teams and slack page start*/

.apps_integrations.apps_integrations_chat .cc_feature_apps_header h3 {
  padding: 0px 12%;
}

.apps_integrations.apps_integrations_chat .cc_feature_apps .card_office_cc h6 {
  text-align: center;
}

.apps_integrations.apps_integrations_chat .cc_feature_apps .card_office.ibm_img img {
  width: 36px;
  height: 36px;
}

.apps_integrations.apps_integrations_chat .cogni_fav_apps_color .card_office {
  margin: 0px;
  margin-bottom: 10px;
}

.apps_integrations.apps_integrations_chat {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}

.apps_integrations.apps_integrations_chat .cogni_fav_apps.cc_feature_apps {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background: #fff;
}

.marketplace_integrations_icons {
  width: 100%;
  float: left;
  padding: 40px;
  background: #f8f8f8;
}

.add-to-slack_wrapper {
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-start;
}

.add-to-slack_wrapper .add-to-slack img {
  width: 22px;
  height: 22px;
  margin-right: 7px;
}

.add-to-slack_wrapper a.add-to-slack {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #555555;
  border-radius: 50px;
  cursor: pointer;
  padding: 0px !important;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 190px;
  text-decoration: none;
  color: #333333;
}

.apps_integrations_chat .cc_feature_apps_header h3 {
  margin-bottom: 30px;
}

.apps_integrations_chat .cc_feature_apps_header p {
  padding: 0px 15%;
  text-align: center;
  margin-bottom: 40px;
}

.feature_page_img_right.feature_page_img_center {
  display: flex;
  justify-content: flex-start;
}

.feature_pageinner_content_img.mob_chat_flex {
  display: flex;
}

.img_mob_res_right {
  display: none;
}

.landing_page_charging.beta-charging ul {
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.beta-charging.landing_page_charging ul li {
  padding: 0px;
  margin-top: 20px;
}

/* teams and slack page end*/

/* features page start */

.features_header_home {
  margin-bottom: 40px;
  padding: 20px 0px 65px 0px;
  background: #e7f2fd;
}

.flex_home {
  display: flex;
}

.flex_home video {
  width: 100%;
}

/* features page end */

/* Integrations page start */

.integrations_pages .beta-charging ul li {
  border: 1px solid #dedede;
  width: 31.33%;
  float: left;
  padding: 30px;
}

/* Integrations page start */

.integrations_pages_img {
  width: 64px !important;
  height: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.integrations_pages ul li img {
  width: 100% !important;
  height: 100%;
}

.integrations_pages ul li p {
  width: 100%;
  float: left;
  text-align: left;
  display: flex;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 10px;
  min-height: 115px;
  font-family: "Roboto-Regular";
  color: #494949;
  padding: 0;
  font-weight: 100;
  letter-spacing: inherit;
}

.integrations_pages ul li h4 {
  width: 100%;
  float: left;
  text-align: left;
  display: flex;
  font-family: "Roboto-Medium";
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.integrations_pages ul li a {
  width: auto;
  float: left;
  text-align: left;
  display: flex;
  font-size: 15px;
  line-height: 24px;
  font-family: "Roboto-Regular";
}

.trial_mb_80.bg_landing_zendesk .beta-button input[type="text"]::placeholder {
  color: #494949;
}

.integration_more span {
  margin-left: 2px;
}

.integration_more {
  width: 100%;
  float: left;
}

.feature_page_content_left .integration_more a {
  width: auto;
  float: left;
  text-decoration: none;
}

.feature_page_content_left .integration_more a:hover {
  color: #2e6dfa;
}

.inter_marlet_link {
  width: 100%;
  float: left;
}

.feature_page_content_left .inter_marlet_link a {
  color: #fff;
  height: 48px;
  padding: 0px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e6dfa;
  text-decoration: none;
}

/* Integrations page end */

/* Marketplace  Start*/

.bg_landing_zendesk.bg_market_Webinar {
  background-size: cover;
  background-position: center;
  margin-bottom: 40px !important;
  background: #ce86a0;
}

.bg_market_webinar.bg_video {
  width: 100%;
  float: left;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px !important;
  background: transparent linear-gradient(69deg, #fff2f7 0%, #f1ffe2 100%) 0% 0% no-repeat padding-box;
}

.bg_videos {
  background: transparent linear-gradient(69deg, #fff2f7 0%, #f1ffe2 100%) 0% 0% no-repeat padding-box;
}

.bg_video_img beta-slider img {
  padding: 4em 0 !important;
}

.bg_market_webinar.bg_video .col-lg-7 {
  padding-right: 40px;
}

.bg_landing_zendesk.bg_market_Webinar_page {
  background: url("../../react/images/videos_page/webinar_2.png") #9c85d7 no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px !important;
}

.bg_market_Webinar_page img {
  width: 90%;
  padding: 0px !important;
}

.webinar_page {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.webinar_img_container {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webinar_img_container img {
  width: 100%;
  float: left;
  height: 100%;
}

.webinar_img_container .para_center_videos {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  position: absolute;
  font-family: "Roboto-Bold";
  font-size: 42px;
  text-align: center;
  letter-spacing: 0px;
  color: #af5676;
  text-shadow: 0px 1px 1px #ffffffe6;
}

.webinar_img_container .webinar_center_content {
  letter-spacing: 0px;
  color: #aa5faf;
  text-shadow: 0px 1px 1px #ffffffe6;
}

.bg_market_Webinar img {
  width: 90%;
  padding: 0px !important;
}

.video_lists {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.video_svg_iocn svg {
  width: 18px;
  height: 18px;
  fill: #1f3750;
}

.video_list_ul {
  display: grid;
  align-content: center;
  grid-gap: 10px;
  grid-template-columns: 15.66% 15.66% 15.66% 15.66% 15.66% 15.66%;
  justify-content: space-between;
  padding-left: 0px;
}

.video_list_ul li {
  background: #fff;
  list-style: none;
  border-radius: 6px;
  border: 1px solid #999;
  min-width: 130px;
  height: 60px;
  cursor: pointer;
  color: #1f3750;
}

.video_list_ul li:hover {
  background: #0a89e8;
  border: 1px solid #0a89e8;
}

.video_list_ul li:hover .video_svg_iocn svg {
  fill: #fff;
}

.video_list_ul li:hover .video_content_li {
  color: #fff;
}

.video_svg_iocn {
  width: 100%;
  float: left;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  margin-top: 10px;
}

.video_content_li {
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: #1f3750;
  margin-top: 3px;
}

/****** button active class Start *******/

.video_list_li.video_list_li_active {
  background: #0a89e8;
  border: 1px solid #0a89e8;
}

.video_list_li.video_list_li_active .video_svg_iocn svg {
  fill: #fff;
}

.video_list_li.video_list_li_active .video_content_li {
  color: #fff;
}

/****** button active class End *******/

.utube_videos {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}

.utube_rows {
  display: grid;
  align-content: center;
  grid-gap: 20px;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
}

.utube_col_4 {
  display: grid;
}

.utube_rows>.utube_col_4 p {
  text-align: left;
  font-size: 13px;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  margin-bottom: 15px;
  min-height: 20px;
  margin-top: 15px;
  color: #1f3750;
}

.utube_iframe {
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.utube_iframe iframe {
  width: 100%;
  float: left;
  height: 180px;
  border-radius: 12px;
}

.utube_iframe img {
  width: 100%;
  float: left;
  height: 180px;
  border-radius: 12px;
}

.utube_iframe span {
  position: absolute;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.utube_iframe span svg {
  fill: #fff;
  width: 36px;
  height: 36px;
  z-index: 1;
}

.utube_content {
  width: 100%;
  float: left;
  display: grid;
  align-items: flex-start;
}

.utube_iframe:hover span svg {
  transform: scale(1.1);
  cursor: pointer;
}

/* video modal popup css */

.modal_popup {
  position: fixed;
  width: 100%;
  float: left;
  top: 0;
  background: #0000008a;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
}

.modal_popup_video {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_popup_video .video_iframe {
  width: 85%;
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
}

.modal_popup_video .video_iframe iframe {
  width: 100%;
  height: calc(100vh - 35vh);
}

.video_iframe span {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  top: -45px;
  margin-left: 20px;
  cursor: pointer;
  position: absolute;
  right: -20px;
}

.video_iframe span svg {
  width: 13px;
  height: 13px;
  fill: #fff;
  position: relative;
  top: 0;
  margin-left: 0;
}

/* video  modal popup css */

/******8 Dropdown webinar page for mobile Start */

.select_application {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 15px;
}

.select_work_form h4 {
  font-family: "Seravek-Bold";
  font-size: 14px;
  color: #494949;
  margin-bottom: 0px;
  margin-top: 0px;
}

.select_work_form input {
  width: 100%;
  float: left;
  height: 30px;
  font-size: 12px;
  padding: 0px 10px;
  padding-left: 10px;
  font-family: "Seravek-Medium";
  border-radius: 6px;
  border: 0.5px solid #ccc;
  outline: 0;
}

.arrow_up_form {
  position: relative;
  right: 0;
  margin-top: -24px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  float: right;
  left: -10px;
}

.dialog_call_automation_popup .autoamtion_connection .automation_scroly {
  width: 270px;
  position: absolute;
  margin-top: 30px;
  border-radius: 8px;
  right: 0px;
  left: 0%;
  cursor: pointer;
}

.select_work_form ul {
  padding: 0px;
  height: 100%;
  overflow-y: scroll;
  max-height: 305px;
  margin-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  border-radius: 6px;
  position: relative;
  width: 100%;
  float: left;
  z-index: 1;
}

.select_work_form ul li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
}

.select_work_form ul li {
  width: 100%;
  float: left;
  display: inline-flex;
  align-items: center;
  border-bottom: 0.5px solid #e6e6e6;
  font-size: 14px;
  font-family: "Seravek-light";
  height: 30px;
  padding: 0 10px;
  background: #fff;
  cursor: pointer !important;
  position: relative;
  list-style: none;
}

.select_work_form ul li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #fff;
  border: none;
}

.automation_scroly {
  width: 100%;
  position: absolute;
  margin-top: 30px;
  border-radius: 6px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.mobile_dropdown {
  display: none;
}

/****** Dropdown webinar page for mobile End */

/* Marketplace  End*/

/* Trial landing page Start */

.grid_card_trial {
  width: 100%;
  float: left;
  display: flex;
}

.card_landing_section {
  max-width: 32%;
  flex: 0 0 32%;
  float: left;
  border-radius: 12px;
}

.grid_card_trial .row {
  display: flex;
  justify-content: flex-start;
}

.grid_card_trial .row .card_landing_section:nth-child(1) {
  margin-right: 20px;
}

.grid_card_trial .row .card_landing_section:nth-child(2) {
  margin-right: 20px;
}

.grid_card_trial .row .card_landing_section:nth-child(3) {
  margin-right: 0px;
}

.trial_cards_page {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.card_landing_trial {
  width: 100%;
  float: left;
  padding: 40px;
}

.card_landing_trial img {
  width: 64px;
  height: 64px;
}

.card_landing_trial h4 {
  font-family: "Gordita-Medium";
  font-size: 30px;
  margin: 20px 0px 10px;
}

.card_landing_trial p {
  font-family: "Gordita-Regular";
  font-size: 20px;
  color: #333333;
  line-height: 34px;
  margin-bottom: 0px;
}

.card_landing_section:nth-child(1) {
  background: #ffeddb;
}

.card_landing_section:nth-child(1) h4 {
  color: #e36503;
}

.card_landing_section:nth-child(2) {
  background: #e2f5ff;
}

.card_landing_section:nth-child(2) h4 {
  color: #004c70;
}

.card_landing_section:nth-child(3) {
  background: #effce6;
}

.card_landing_section:nth-child(3) h4 {
  color: #007100;
}

/* Trial landing page End */

/* Trial landing page Start */

.bg_header_trial {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.bg_header_trial.sticky {
  background: #fff;
}

.bg_header_trial .beta-slider img {
  padding: 0 !important;
}

.bg_header_trial.trial_mb_80 {
  margin-bottom: 40px !important;
}

.bg_header_trial.trial_mb_80.marketplace-assist-header-bottom {
  margin-bottom: 0px !important;
}

.landing_page.trial_page_img .feature_page_content_left h3 {
  font-size: 42px;
  font-family: "Gordita-Medium";
}

.landing_page.trial_page_img .feature_page_content_left h2 {
  font-size: 42px;
  font-family: "Gordita-Medium";
}

.landing_page.trial_page_img .feature_page_content_left h1 {
  font-size: 42px;
  font-family: "Gordita-Medium";
}

.trial_page_img img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
  -webkit-appearance: none;
  -moz-appearance: none;
}

.img_position {
  position: relative;
  left: 60px;
}

.container_trial .beta-charging {
  padding-top: 15px;
  margin: 0 3%;
}

.trial_page_img.bg_trial_page {
  background: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.chat_channels_trial {
  width: 100%;
  float: left;
}

.chat_channels_trial_btn {
  width: auto;
  float: left;
  font-size: 20px;
  font-family: "Roboto-Bold";
  color: #416183;
}

.chat_channels_trial_btn:first-child {
  margin-right: 20px;
}

.chat_channels_trial_btn img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}

.container_trial .pl-20-tage.trial_page_img .feature_page_img_right {
  padding-left: 30px;
}

.container_trial .pl-20-tage .feature_page_img_right {
  padding-right: 15px;
  padding-left: 30px;
}

.container_trial .pl-20-tage .feature_page_content_left {
  padding-left: 15px;
  padding-right: 30px;
}

.container_trial .pl-10-tage .feature_page_img_right {
  padding-right: 30px;
}

.container_trial .pl-10-tage .feature_page_content_left {
  padding-left: 30px;
}

/* .bg_header_trial.trial_mb_80 .main-slider-left h1 {
  font-family: "Roboto-Bold";
  font-size: 45px;
} */

.container_trial .main-slider-left h1 {
  font-size: 42px;
}

.helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 20px;
}

.container_trial .main-slider-left p {
  font-size: 22px;
}

.container_trial .feature_page_content_left p,
.card_link_landing a {
  font-size: 22px;
}

.trial_header_left {
  display: flex;
  align-items: center;
}

.chatbot_integration_left_container .trial_header_left {
  align-items: baseline;
  margin-top: 30px;
}
.chatbot_integration_left_container .request_form_assist_left{
  margin-top: 0px;
} 

.container_trial .trail_apps_landing h1 {
  font-size: 42px;
}

.trial_accordian_landing h2 {
  font-size: 42px;
}

.container_trial p {
  color: #416183;
  font-size: 18px;
  text-align: left;
  font-family: "Gordita-Regular";
  line-height: 35px;
  padding-bottom: 10px;
}

.container_trial .header_menu p {
  color: #193a5d;
  font-size: 12px;
}

.container_trial .cognii_work_content .cogni_works p {
  color: #416183;
  font-size: 22px;
}

.container_trial .beta-charging p {
  color: #485a63;
  font-size: 14px;
}

.trial_cards_page p {
  color: #416183;
  font-size: 20px;
}

.trail_apps_landing .cogni_fav_apps_header p {
  color: #416183;
}

.bg_header_trial .assistant_right_img img {
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
  width: 100%;
}

.bg_header_trial.trial_mb_80 {
  padding-bottom: 60px;
  padding-top: 30px;
  position: relative;
  top: -1px;
  width: 100%;
  float: left;
}

.page_header_center {
  width: 100%;
  float: left;
}

.wrapper_page {
  width: 100%;
  float: left;
}

.bg_header_trial.trial_mb_80 .assistant_right_img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container_trial .top-header {
  border: none;
}

.container_trial .accordian_landing .submenu li {
  color: #416183;
}

/* Trial landing page End */

/* Webinar Page Start */

.webinar_footer_img {
  margin-top: 20px;
  width: 100%;
  float: left;
}

.webinar_footer_img h6 {
  margin-bottom: 10px !important;
  font-size: 18px !important;
}

p.mb_0_webianr {
  margin-bottom: 5px !important;
}

/* Webinar Page End */

/* White Paper Start */

.bg_header_whitepaper {
  background: transparent linear-gradient(235deg, #f9f5e5 0%, #dbfcf9 100%) 0% 0% no-repeat padding-box;
}

.bg_header_whitepaper.sign_up-form_us.demo_page {
  padding-top: 20px;
  padding-bottom: 30px;
}

.sign_up-form_us.demo_page.bg_header_whitepaper {
  margin-top: 0px !important;
}

.bg_header_whitepaper.sticky {
  background: #fff;
}

.bg_header_whitepaper h1 {
  text-align: left;
  font-family: "Roboto-Medium";
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin: 0px;
  font-size: 36px;
}

.para_whitepaer {
  width: 100%;
  float: left;
}

.whitepaer_wrapper_form .para_whitepaer p {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
  text-align: left;
  font-family: "Roboto-Regular";
  letter-spacing: 0px;
  font-size: 13px;
  line-height: 22px;
  color: #888888;
  padding-top: 10px;
}

.whitepaer_wrapper_form img {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  display: block;
}

.whitepaer_wrapper_form h3 {
  font-size: 24px;
  justify-content: center;
  display: flex;
  font-family: "Roboto-Medium";
  margin: 15px 0px;
}

.wrapper_be_paper {
  width: 100%;
  float: left;
  background: #f7f7f7;
  padding: 25px;
}

/* White Paper End */

/* 10 new pages start */

.bg_header_it_support {
  background: transparent linear-gradient(64deg, #f4fff5 0%, #fff7e8 100%) 0% 0% no-repeat padding-box;
}

.bg_header_it_support.sticky {
  background: #fff;
}

.bg_header_suppport_one {
  background: transparent linear-gradient(63deg, #fffbf4 0%, #fff1ed 100%) 0% 0% no-repeat padding-box;
}

.bg_header_suppport_two {
  background: transparent linear-gradient(65deg, #f8f4ff 0%, #e8e9ff 100%) 0% 0% no-repeat padding-box;
}

.bg_header_hr_support {
  background: transparent linear-gradient(64deg, #f4fcf4 0%, #e8f3fd 100%) 0% 0% no-repeat padding-box;
}

.bg_usp_page_one {
  background: transparent linear-gradient(66deg, #effffe 0%, #f9f5e5 100%) 0% 0% no-repeat padding-box;
}

.bg_usp_page_one .main-slider-left {
  padding: 0px;
}

.bg_usp_page_two {
  background: transparent linear-gradient(66deg, #effffe 0%, #f9f5e5 100%) 0% 0% no-repeat padding-box;
}

.bg_it_support_three {
  background: transparent linear-gradient(62deg, #fff4f4 0%, #f0fff8 100%) 0% 0% no-repeat padding-box;
}

.bg_header_hr_one {
  background: transparent linear-gradient(64deg, #f4f4ff 0%, #f9f2ff 100%) 0% 0% no-repeat padding-box;
}

.bg_hr_suppport_three {
  background: transparent linear-gradient(63deg, #f6f4ff 0%, #eaffe8 100%) 0% 0% no-repeat padding-box;
}

.bg_header_suppport_two {
  background: transparent linear-gradient(64deg, #f4fcff 0%, #ffefef 100%) 0% 0% no-repeat padding-box;
}

.bg_hr_trial {
  background: transparent linear-gradient(62deg, #fff4f4 0%, #eaedfd 100%) 0% 0% no-repeat padding-box;
}

.bg_con_ai_trial {
  background: transparent linear-gradient(64deg, #f4fcff 0%, #edebff 100%) 0% 0% no-repeat padding-box;
}

.bg_ticket_autoamtion {
  background: transparent linear-gradient(61deg, #fcfced 0%, #e2f5f3 100%) 0% 0% no-repeat padding-box;
}

.page_header_center h1 {
  font-size: 40px;
  padding: 0px 10%;
  font-family: "Roboto-Medium";
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

/**** Integrations new UI designs Start ****/

.inegrations_header {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.integration_header_top h3.header_integrations {
  color: #416183;
  font-family: "Roboto-Medium";
  font-size: 30px;
}

.integration_header_top h3.header_integrations_name {
  margin-bottom: 0px;
}

.zoho_desk_bg {
  width: 100%;
  float: left;
  background: url("../img/integration/header_bg_web.png") no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
  padding: 20px 0px;
}

.inegrations_header_right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inegrations_header_right img {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
}

.landing_integration_img {
  width: 100%;
  float: left;
}

.landing_integration_img {
  width: 100%;
  height: 100%;
  float: left;
}

.container_trial .integration_header_top h3.header_integrations_name {
  font-size: 46px;
  font-family: "Roboto-Bold";
  margin-top: 10px;
  color: #000;
}

.integration_header_top {
  width: 100%;
  align-items: center;
  padding: 2rem 0px;
}

.integrations_page_form .beta-button input,
button {
  width: 33%;
}

.integrations_page_form .main-slider-button.beta-button form {
  justify-content: left;
}

.awards_img {
  width: 100%;
  float: left;
}

.header.res_header {
  width: 100%;
  float: left;
}

.awards_img .main-slider-left {
  padding: 0px;
  text-align: center;
}

.awards_img .main-slider-left h3 {
  font-family: "Roboto-Bold";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 30px;
  text-align: center;
}

/* Featured Content Slider Start */

.slick-carousel {
  width: 100%;
  margin: 0px auto;
}

.slick-carousel {
  margin: 0px;
}

.cogni_workspace_content.home_slider p {
  padding: 0px 20px;
}

.home_link {
  width: 100%;
  float: left;
  text-align: left;
  padding: 15px 20px 30px 20px;
  color: #2468f6;
  font-family: "Roboto-Medium";
  font-size: 14px;
}

.home_link:hover .slider_span {
  text-decoration: underline;
}

.featured_content .slick-slide {
  padding-right: 20px;
}

.featured_content .slick-slide:last-child {
  padding-right: 0px;
}

.featured_content .slick-slide:first-child {
  padding-left: 0px;
}

.featured_content {
  width: 100%;
  float: left;
  padding-top: 0px;
  margin-bottom: 40px;
  background: #f7f7f7;
  padding: 50px 0px;
  overflow: hidden;
}

.featured_content .cogni_workspace_content {
  box-shadow: 0px 5px 20px #0000001a;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: move;
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.featured_content .benefits-workplace-content img {
  width: 30px;
  height: 30px;
  margin-bottom: 0px;
  /* margin-right: 10px; */
}

.featured_content .cogni_workspace_content h3,
a {
  text-align: left;
  font-family: "Gordita-Medium";
}

.featured_content .cogni_workspace_content h3 {
  font-size: 16px;
  margin-bottom: 0px;
  position: relative;
  top: 5px;
}

.featured_content .cogni_workspace_content p {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 25px;
  min-height: 50px;
  text-align: left;
  color: #416183;
  display: none;
}

.cogni_workspace_content.home_slider p {
  font-size: 14px;
  min-height: 100px;
  display: block;
}

.featured_content .cogni_workspace_content.home_slider {
  padding: 0px;
}

.featured_content .cogni_workspace_content.home_slider img {
  width: 100%;
  height: 144px;
}

.featured_content .drag {
  position: absolute;
  right: 10px;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #fff;
}

.featured_content .slick-list {
  overflow: unset;
}

.featured_content .container {
  position: relative;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.featured_content h3 {
  color: #333333;
  font-size: 42px;
  font-family: "Gordita-Medium";
  margin-bottom: 0px;
  text-align: left;
}

.featured_content h4 {
  color: #333333;
  font-size: 42px;
  font-family: "Roboto-Medium";
  margin-bottom: 0px;
  text-align: left;
}

.bg_card_1 {
  background: #eaf6ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg_card_2 {
  background: #eaffea 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg_card_3 {
  background: #f1faf4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg_card_4 {
  background: #fbf9f4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.helpdesk_solutions {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 80px;
}

.helpdesk_solutions .suprcharging_benefits {
  background: #fff;
  padding: 0px;
}

.helpdesk_solutions .main-slider-left {
  padding-top: 0px;
  width: 100%;
  float: left;
  padding-bottom: 20px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content {
  box-shadow: none;
  padding: 0px;
  text-align: left;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-family: "Roboto-Regular";
  margin-bottom: 0;
}

.apps_integrations .cogni_fav_apps_color .card_office {
  margin: 0px;
  margin-bottom: 10px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content p {
  min-height: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  flex: 1 1 auto;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content p:first-child {
  flex: 1 1 auto !important;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content h6 {
  min-height: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #000000;
  font-family: "Gordita-Regular";
  line-height: inherit;
}

.helpdesk_solutions .cogni_workspace_content h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content a {
  text-align: left;
  font-family: "Gordita-Medium";
  font-size: 15px;
  color: #2e6dfa;
  padding-bottom: 0px;
  width: auto;
  float: left;
  margin-top: 10px;
  text-decoration: none;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content img {
  width: 64px;
  height: 64px;
}

.helpdesk_solutions .bg_card_1 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px 60px 40px 40px;
  width: 100%;
  float: left;
}

.bg_card_2 {
  background: #eaffea 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.helpdesk_solutions .bg_card_2 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.bg_card_3 {
  background: transparent linear-gradient(234deg, #fff0f0 0%, #edf1ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg_card_4 {
  background: transparent linear-gradient(232deg, #f9f5e5 0%, #dbfcf9 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.col-xs-12.bg_card_1 {
  margin-right: 20px;
}

.col-xs-12.bg_card_2 {
  margin-left: 20px;
}

.col-xs-12.bg_card_3 {
  margin-right: 20px;
}

.col-xs-12.bg_card_4 {
  margin-left: 20px;
}

.helpdesk_solutions .bg_card_3 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.helpdesk_solutions .bg_card_4 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.cards_wrapper_home {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.helpdesk_solutions .main-slider-left h1 {
  width: 100%;
  float: left;
  text-align: center;
}

.cards_wrapper_home {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.cards_wrapper_home .col-md-6 {
  flex-basis: 48.2%;
  display: flex;
}

.helpdesk_solutions.how-it-works-solutions {
  padding-top: 0px;
  margin-bottom: 40px;
}

.apps_integrations_home .cc_feature_apps .cogni_fav_apps_color {
  flex-wrap: wrap;
}

.apps_integrations_home .card_office_cc {
  width: 16.66%;
  margin-bottom: 40px;
}

.apps_links_home {
  width: 100%;
  float: left;
  justify-content: center;
  display: flex;
}

.apps_links_home a:first-child {
  margin-right: 12%;
}

.apps_links_home a {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #2e6dfa;
  padding-bottom: 0px;
  width: auto;
  float: left;
  margin-top: 0px;
  text-decoration: none;
}

.apps_links_home a:hover {
  text-decoration: none;
  color: #2e6dfa;
}

.center_image_home .main-slider-left {
  padding: 80px 10%;
  padding-bottom: 0px;
  padding-top: 0px;
}

.center_image_home .main-slider-left h2 {
  padding: 0px;
  text-align: center;
  font-family: "Gordita-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 10px;
}

.ahref-wrapper {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.iso {
  width: 60%;
  float: "left";
  display: "flex";
  justify-content: center;
  flex-direction: column;
}

.center_image_home .main-slider-left a {
  width: auto;
  float: left;
}

.center_image_home {
  width: 100%;
  float: left;
  padding-bottom: 0px;
  margin-bottom: 40px;
}

.automation_steps.bg_grey {
  background: #f8f8f8;
  padding: 40px 0px;
}

.center_image_home .main-slider-left img {
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
}

.left-whitespace {
  position: absolute;
  left: -15%;
  width: 12vw;
  background: #f7f7f7;
  height: 100%;
  top: 15%;
  opacity: 1;
  z-index: 9;
}

.integrations_card_slider {
  width: 100%;
  float: left;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  min-height: 50px;
}

.cards_dateime {
  width: 100%;
  float: left;
  text-align: left;
  border-top: 1px solid #dad9d9;
  padding-top: 10px;
}

.cards_dateime span {
  color: #666666;
  font-size: 12px;
}

.featured_content .slick-prev,
.featured_content .slick-next {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
}

.featured_content .slick-prev:before {
  padding: 4px;
  left: 2px;
  position: relative;
  border: solid #555;
  border-width: 0 2px 2px 0;
}

.featured_content .slick-next:before {
  padding: 4px;
  left: -2px;
  position: relative;
  border: solid #555;
  border-width: 0 2px 2px 0;
}

.featured_content .slick-prev:hover,
.featured_content .slick-prev:focus,
.featured_content .slick-next:hover,
.featured_content .slick-next:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
}
.featured_content .slick-prev:hover, .featured_content .slick-next:hover{
  background: #CBFFEC 0% 0% no-repeat padding-box;
  border: 1px solid #46B18A;
} 

.featured_content .card_link_landing {
  margin-top: 10px;
  font-family: "Roboto-Medium";
  font-size: 18px;
}

.card_link_landing a {
  text-decoration: none;
  color: #2e6dfa;
  font-family: "Gordita-Medium";
  margin-top: 0px;
}

.card_landing_section.integrations_popular_bg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.card_landing_section.integrations_popular_bg svg {
  width: 84px;
  height: 84px;
  margin-bottom: 10px;
}

.card_landing_section.integrations_popular_bg img {
  width: 84px;
  height: 84px;
  margin-bottom: 0px;
}

.card_landing_section.integrations_popular_bg h4 {
  font-size: 22px;
  color: #333333;
  margin: 10px 0px;
}

.card_landing_section.integrations_popular_bg p {
  font-size: 18px;
  color: #416183;
  font-family: "Gordita-Regular";
  margin-bottom: 10px;
  line-height: 30px;
  min-height: 60px;
}

.card_landing_section.integrations_popular_bg h6 {
  font-size: 18px;
}

.card_link_landing a {
  font-size: 18px;
}

.card_landing_section.integrations_popular_bg .card_link_landing {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  font-family: "Gordita-Medium";
  font-size: 18px;
}

.card_landing_section.integrations_popular_bg .card_link_landing a svg {
  width: 12px;
  height: 12px;
  margin-bottom: 0px;
}

.trial_cards_page_img .card_landing_section.integrations_popular_bg h4 {
  min-height: auto;
  margin-top: 0px;
  flex: 1 1 auto;
  line-height: 1.3;
  font-weight: bold;
}

.trial_cards_page_img .card_landing_section.integrations_popular_bg img {
  width: 100%;
  height: auto;
  float: left;
}

.trial_cards_page h4 {
  color: #333333;
  font-size: 42px;
  font-family: "Gordita-Medium";
  margin-bottom: 30px;
  text-align: left;
}

.trial_cards_page.trial_cards_page_img .card_landing_trial {
  width: 100%;
  float: left;
  padding: 30px;
  flex-wrap: wrap;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.trial_cards_page.trial_cards_page_img .card_link_landing {
  margin-top: 5px;
  margin-bottom: 5px;
}

.container_trial.integrations_container .pl-10-tage.landing_page .row {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
}

.container_trial.integrations_container .pl-10-tage .feature_page_content_left {
  padding-left: 15px;
  display: flex;
  flex-basis: 49%;
}

.container_trial.integrations_container .pl-10-tage .feature_page_img_right {
  padding-right: 15px;
  display: flex;
  flex-basis: 49%;
  justify-content: center;
  align-self: center;
}

.container_trial.integrations_container .pl-20-tage.landing_page .row {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
}

.container_trial.integrations_container .pl-20-tage .feature_page_content_left {
  padding-left: 15px;
  display: flex;
  flex-basis: 49%;
  padding-right: 15px;
}

.container_trial.integrations_container .pl-20-tage .feature_page_img_right {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-basis: 49%;
}

.card_link_landing.jira_landing_link a {
  margin-right: 0px;
}

/* Featured Content Slider End */

/**** Integrations new UI designs End ****/

/* feature and how-it-works ui design changes */

.container_trial.integrations_container .features_header_home .row {
  display: flex;
  justify-content: space-between;
}

.how_it_section_page .how_3_steps {
  font-size: 18px;
  color: #000000;
  line-height: inherit;
  font-family: "Roboto-Medium";
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.container_trial.integrations_container .features_header_home .align_center_content {
  padding-right: 15px;
  display: flex;
  flex-basis: 48%;
}

.container_trial.integrations_container .features_header_home .flex_home {
  padding-left: 15px;
  display: flex;
  flex-basis: 48%;
}

.x_scroll_page .scroll_page {
  text-decoration: none;
  color: #333333;
}

.x_scroll_page .scroll_page:hover {
  color: #2e6dfa;
}

/* Feature inner page Ui design updated */

.feature_inner_page.bg_feature_slider {
  width: 100%;
  float: left;
  padding-top: 10px;
}

.feature_inner_page.bg_feature_slider .main-slider-left {
  width: 100%;
  float: left;
  padding: 0px 3%;
  text-align: center;
}

.feature_inner_page.bg_feature_slider .main-slider-left h1 {
  text-align: center;
  width: 100%;
  float: left;
}

.feature_inner_page.bg_feature_slider .main-slider-left p {
  text-align: center;
  width: 100%;
  float: left;
  padding-right: 0px;
}

.feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
  width: 100%;
  float: left;
  padding: 0px 15%;
}

.container_trial .automate_homepage .cta_autoamte {
  width: 100%;
  float: left;
  margin-top: 16px;
  color: #000;
  font-size: 16px;
  font-family: "Roboto-Regular";
}

.feature_inner_page.bg_feature_slider .features_change_img-center img {
  width: 85%;
  float: left;
  margin: 0 auto;
}

.feature_inner_page.bg_feature_slider .main-slider-button.beta-button .form_below_content {
  margin-bottom: 20px;
}

.feature_inner_page.bg_feature_slider .back_features {
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #333333;
  padding: 0px 15px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.modal_submit_form .modal-header button {
  width: 20px;
  height: 20px;
  position: relative;
  left: -15px;
  top: 5px;
  padding: 0px;
}

.cards_features .cards_features_header_div p {
  padding-right: 0px;
  font-size: 18px;
  flex: 1 1 auto;
  color: #416183;
  font-family: "Roboto-Regular";
}

.cards_features .cards_features_header_div .card_link_landing,
.cards_features .cards_features_header_div a {
  margin-bottom: 0px;
  justify-content: left;
}

.cards_features .cards_features_header_div {
  margin-bottom: 20px;
}

.cards_features {
  width: 100%;
  float: left;
  padding: 0px 15px;
  padding-top: 40px;
  padding-bottom: 0px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.container_trial .features_content_left .m-b-10 b {
  width: 100%;
  float: left;
  color: #333333;
  font-size: 22px;
}

.apps_integrations_home .cc_feature_apps .card_office_cc h6 {
  text-align: center;
}

.apps_integrations_home .cogni_fav_apps.cc_feature_apps {
  padding-bottom: 40px !important;
  padding-top: 0px;
}

.apps_integrations_home .cc_feature_apps_header h3 {
  padding: 0px 8%;
}

.integration_homepage .apps_integrations .cogni_fav_apps.cc_feature_apps {
  background: #f7f7f7;
  padding-bottom: 0px !important;
}

.homepage_slider.slider_homepage .featured_content {
  background: #fff;
}

.homepage_slider.slider_homepage .left-whitespace {
  background: #fff;
}

.center_features_header {
  width: 100%;
  float: left;
}

.center_features_header h2 {
  text-align: center;
}

.container_trial .center_features_header p {
  text-align: center;
  padding-bottom: 20px;
}

.pl-10-tage h3 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.pl-10-tage h2 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.pl-10-tage h1 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.integrations_page_form h3 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.integrations_page_form h2 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.integrations_page_form h1 .icon-header-left {
  width: 80px;
  height: 80px;
  position: absolute;
  filter: none;
  left: -20px;
  top: -22px;
}

.integrations_page_form h3,
.integrations_page_form h3 span {
  position: relative;
  z-index: 0;
}

.integrations_page_form h2,
.integrations_page_form h2 span {
  position: relative;
  z-index: 0;
}

.integrations_page_form h1,
.integrations_page_form h1 span {
  position: relative;
  z-index: 0;
}

.pl-10-tage h3,
.pl-10-tage h3 span {
  position: relative;
  z-index: 0;
}

.pl-10-tage h2,
.pl-10-tage h2 span {
  position: relative;
  z-index: 0;
}

.pl-10-tage h1,
.pl-10-tage h1 span {
  position: relative;
  z-index: 0;
}

.cards_features.cards_features_cc {
  width: 100%;
  float: left;
  padding: 0px 15px;
  background: #fff;
  padding-top: 0px;
  padding-bottom: 16px;
}

.carousel-indicators.mob_res_slide {
  padding-left: 15px !important;
  width: 100%;
  margin: 0 auto;
  display: none;
}

.feature_page_content_left ol li {
  list-style: none !important;
}

.feature_page_content_left ol {
  padding-left: 0px;
}

.mob_res_slider {
  display: none;
}

.carosoul_mob_res {
  width: 100%;
  float: left;
  position: relative;
}

.img_slider_round {
  width: 100%;
  float: left;
  position: relative;
}

.bg_p_wihout_padding {
  width: 100%;
  float: left;
  position: relative;
}

.feature_page_content_left .jira_landing_link.how_ts_link {
  justify-content: left;
  width: 100%;
  float: left;
}

.card_link_landing.how_ts_link a {
  margin-right: 50px !important;
}

/* whitepaper form css */

#_form_24_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: arial, helvetica, sans-serif;
  margin: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 30px;
}

#_form_24_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_24_._form-top {
  top: 0;
}

#_form_24_._form-bottom {
  bottom: 0;
}

#_form_24_._form-left {
  left: 0;
}

#_form_24_._form-right {
  right: 0;
}

#_form_24_ input[type="text"],
#_form_24_ input[type="date"],
#_form_24_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 0px;
  color: #494949 !important;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_24_ textarea {
  resize: none;
}

#_form_24_ ._submit {
  width: auto;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-align: center;
  background: #2e6dfa !important;
  border: 0 !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #fff !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-bottom: 0px;
}

#_form_24_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_24_ ._close-icon:before {
  position: relative;
}

#_form_24_ ._form-body {
  margin-bottom: 30px;
}

#_form_24_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_24_ ._form-content-right {
  margin-left: 164px;
}

#_form_24_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_24_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_24_ ._form-label,
#_form_24_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_24_._dark ._form-branding {
  color: #333333;
}

#_form_24_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_24_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_24_ ._form_element * {
  font-size: 14px;
}

#_form_24_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: left;
}

#_form_24_ ._form_element._clear:after {
  clear: left;
}

#_form_24_ ._form_element input[type="text"],
#_form_24_ ._form_element input[type="date"],
#_form_24_ ._form_element select,
#_form_24_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_24_ ._field-wrapper {
  position: relative;
}

#_form_24_ ._inline-style {
  float: left;
}

#_form_24_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_24_ ._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_24_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_24_ ._clear-element {
  clear: left;
}

#_form_24_ ._full_width {
  width: 100%;
}

#_form_24_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_24_ input[type="text"]._has_error,
#_form_24_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_24_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_24_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}

#_form_24_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_24_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_24_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_24_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_24_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#_form_24_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_24_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_24_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_24_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_24_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  #_form_24_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #_form_24_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }

  #_form_24_ ._form-content {
    margin: 0;
    width: 100%;
  }

  #_form_24_ ._form-inner {
    display: block;
    min-width: 100%;
  }

  #_form_24_ ._form-title,
  #_form_24_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #_form_24_ ._form-title {
    font-size: 1.2em;
  }

  #_form_24_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }

  #_form_24_ ._form-element,
  #_form_24_ ._inline-style,
  #_form_24_ input[type="text"],
  #_form_24_ label,
  #_form_24_ p,
  #_form_24_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }

  #_form_24_ ._row._checkbox-radio label {
    display: inline;
  }

  #_form_24_ ._row,
  #_form_24_ p,
  #_form_24_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }

  #_form_24_ ._row input[type="checkbox"],
  #_form_24_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }

  #_form_24_ ._row input[type="checkbox"]+span label {
    display: inline;
  }

  #_form_24_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }

  #_form_24_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }

  #_form_24_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }

  #_form_24_ input[type="radio"],
  #_form_24_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }

  #_form_24_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}

#_form_24_ {
  position: relative;
  text-align: left;
  margin: 25px auto 0;
  padding: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  background: #fff !important;
  border: 0px solid #b0b0b0 !important;
  width: 100%;
  color: #494949 !important;
}

#_form_24_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_24_:before,
#_form_24_:after {
  content: " ";
  display: table;
}

#_form_24_:after {
  clear: both;
}

#_form_24_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_24_._inline-style input[type="text"],
#_form_24_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_24_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_24_._inline-style p {
  margin: 0;
}

#_form_24_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_24_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_24_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

.bg_slider_home.m_b_80 {
  margin-bottom: 80px;
  padding: 80px 0px;
  background: #e7f2fd;
}

.resource_bg_header {
  background: #e8eeff;
}

.resources_card {
  width: 100%;
  float: left;
  margin-top: 16px;
}

.home_bg_header.resource_bg_header {
  background: #e8eeff;
}

.resources_card .whitepaper_cards .card__title {
  color: #333333;
  font-size: 18px;
}

.resources_card .whitepaper_cards .card__title {
  color: #333333;
  font-size: 18px;
}

.resources_card .whitepaper_cards .card__text {
  flex: 1 1 auto;
  color: #416183;
  font-family: "Gordita-Regular";
  line-height: inherit;
  margin-bottom: 10px;
  font-size: 15px;
}

.whitepaper_cards {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.whitepaper_cards .cards {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}

.whitepaper_cards .cards__item {
  display: -webkit-box;
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .whitepaper_cards .cards__item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .whitepaper_cards .cards__item {
    width: 33.3333%;
  }
}

.whitepaper_cards .card {
  background-color: white;
  border-radius: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow: hidden;
  border: none;
}

.whitepaper_cards .card__image {
  border: none;
}

.whitepaper_cards .card__image img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.whitepaper_cards .card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-family: "Gordita-Regular";
  margin-bottom: 0px;
  padding: 20px;
  font-size: 14px;
  border: 1px solid #dad9d9;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.whitepaper_cards .card__title {
  color: #333333;
  font-size: 12px;
  font-weight: 300;
  font-family: "Gordita-Medium";
  margin-bottom: 10px;
}

.whitepaper_cards .card__text {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  color: #416183;
  font-family: "Gordita-Regular";
  line-height: 1.5;
  margin-bottom: 10px;
  font-size: 16px;
}

.cards__item .card_link_landing {
  font-size: 15px;
  justify-content: left;
  display: flex;
}

.cards__item .card_link_landing a {
  font-size: 15px;
  font-weight: 300;
  font-family: "Gordita-Medium";
  margin-bottom: 10px;
}

.resource_bg_header .btn_read {
  cursor: pointer;
  border-radius: 0px !important;
  color: #fff !important;
  padding: 8px !important;
  transition: transform 0.5s ease;
  width: 140px;
  margin-top: 10px;
  margin-left: 0px;
  background-color: #2468f6;
  border-radius:30px !important;
  font-family: "Gordita-Regular";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #2468f6;
}

.resource_bg_header .btn_read:hover {
  transform: scale(1.012);
}

.assistant_slider.resource_bg_header img {
  filter: inherit;
}

.two_links_a {
  display: flex;
  width: 100%;
  float: left;
  justify-content: flex-start;
}

.feature_page_content_left .two_links_a .card_link_landing {
  width: 100%;
  float: left;
  justify-content: flex-start;
}

.feature_page_content_left .two_links_a .card_link_landing a:first-child {
  margin-right: 40px;
}

.bg_slider_home.features_header_home {
  padding-top: 40px;
  padding-bottom: 80px;
}

.features_updated_nov .features_header_home {
  padding-bottom: 40px;
}

.container_trial p.wv-md-heading {
  text-align: left;
  color: #333333;
  font-family: "Roboto-Regular";
  font-size: 18px;
  margin-bottom: 20px;
}

.main-slider-button.beta-button .wv-md-heading {
  text-align: center;
}

.-call-reflection .main-slider-left {
  padding: 40px 0px;
}

.wv-heading-h4 {
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: #333333;
  font-family: "Roboto-Medium";
}

.wv-inner-banner.wv-landing-page-1.mt-40 {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  float: left;
}

.width_container {
  width: 100%;
  float: left;
}

.wv-inner-banner.wv-landing-page-1.mt-20 {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  float: left;
}

.wv-inner-banner.wv-landing-page-1.mt-20 .wv-banner-text-medium {
  font-size: 30px;
  line-height: 42px;
  font-family: "Roboto-MEdium";
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.content_para.content_ul {
  width: 100%;
  float: left;
  padding-bottom: 40px;
}

.wv-inner-banner.wv-landing-page-1.mt-20 p {
  color: #333333;
  font-size: 16px;
  text-align: left;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 0px;
}

.wv-lg-heading {
  font-family: "Roboto-Medium";
  font-size: 42px;
}

.container_trial .content_para p {
  text-align: left;
  color: #333333;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.container_trial .wv-content-section p {
  text-align: left;
  color: #333333;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.knowledge_header_form .main-slider-button.beta-button form {
  justify-content: center;
}

.bg_section_knowledge {
  background: #e8eeff;
  width: 100%;
  float: left;
}

.container_trial .bg_section_knowledge .main-slider-left h1 {
  padding-right: 15px;
  margin-bottom: 0px;
  line-height: inherit;
  font-size: 32px;
}

.cards__item.card_one {
  background: #fff6e5;
}

.cards__item.card_two {
  background: #f4eaff;
}

.cards__item.card_three {
  background: #e6fff4;
}

.knowledge_cards .card {
  background: inherit;
}

.knowledge_cards .card__content {
  background: inherit;
  border: none;
}

.knowledge_cards .whitepaper_cards .cards__item {
  width: 30.3333%;
}

.resources_card.knowledge_cards .whitepaper_cards .card__text {
  color: #333333;
}

.knowledge_cards .whitepaper_cards .cards {
  justify-content: space-between;
  padding: 0px 15px;
}

.knowledge_cards .whitepaper_cards .cards__item:hover a {
  text-decoration: none;
}

.knowledge_cards .whitepaper_cards .card__text {
  margin-bottom: 0px;
  margin-top: 10px;
}

.bg_section_knowledge {
  margin-top: 10px;
  margin-bottom: 40px;
}

.wv-section-mv-p0 .wv-content-area {
  padding: 0px 20px;
}

.cards_without_bg h4 {
  font-size: 22px;
  line-height: 28px;
  color: #333333;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-bottom: 20px !important;
}

.cards_without_bg .header_h4 {
  font-size: 22px;
  line-height: 28px;
  color: #333333;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-bottom: 40px !important;
  margin-top: 40px;
}

.container_trial .cards_without_bg p {
  text-align: left;
  color: #333333;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.wv-content-section {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.container_trial .wv-sm-heading {
  color: #333333;
}

.container_trial .wv-sidenav ul li a {
  color: #898989;
  font-size: 15px;
  font-family: "Gordita-Regular";
}

.wv-banner .back_features_page {
  font-family: "Gordita-Medium";
  font-size: 18px;
  color: #333333;
  padding: 0px 15px;
  margin-bottom: 20px;
}

.mar_bot_1 h4 {
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.mar_bot_1 img {
  margin-top: 15px !important;
}

.wv-content-section.mar_bot_40 p {
  margin-bottom: 20px;
}

.wv-content-area .wv-content-section {
  margin-bottom: 40px;
}

.wv-content-area .wv-content-section:last-child {
  margin-bottom: 0px;
}

.wv-main-content.wv-section-mv-p0 {
  padding-bottom: 40px;
}

a.nav-link.js-scroll-trigger.active {
  color: #2468f6 !important;
}

a.url_manipulation.active {
  color: #2468f6 !important;
}

.container_trial .bg_section_knowledge.left_to_right .main-slider-left h1 {
  padding-right: 0px;
  margin-bottom: 0px;
  line-height: inherit;
  font-size: 32px;
  padding-left: 27.5%;
}

.tree_li h4 {
  color: #333333;
  font-family: "Roboto-Medium";
  font-size: 22px;
}

.tree_li ul li {
  color: #333333;
  font-family: "Roboto-Medium";
  font-size: 18px;
  line-height: inherit;
  margin-bottom: 15px;
}

.wv-section-mv-p .mar_bo_10 {
  margin-bottom: 30px !important;
}

.mb-2.mt-40px {
  margin-top: 40px !important;
}

.wv-section-mv-p.pb-200.content_para {
  width: 100%;
  float: left;
}

.full_width_li {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  float: left;
}

.full_width_li .col-12 {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.full_width_li .col-12 h4 {
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  font-family: "Roboto-Regular";
  margin-left: 15px;
  margin-bottom: 20px;
}

.cards_center {
  width: 100%;
  float: left;
}

.cards_center .knowledge_cards .whitepaper_cards .cards {
  justify-content: center;
}

.cards_center .knowledge_cards .whitepaper_cards .cards .cards__item.card_one {
  margin-right: 80px;
}

.content_full.col {
  width: 100%;
  float: left;
}

.content_full.col .wv-heading-h4-h4 {
  margin-top: 20px;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: #333333;
  font-family: "Roboto-Medium";
  margin-bottom: 10px;
}

.gen_vs_true {
  width: 100%;
  float: left;
}

.gen_vs_true .bg_section_knowledge {
  margin-top: 0px;
}

.gen_vs_true .cards_without_bg p {
  margin-bottom: 0px !important;
}

.gen_vs_true .resources_card.whitepaper_cards {
  margin-bottom: 40px;
  margin-top: 0px;
}

.container_trial .gen_vs_true .bg_section_knowledge .main-slider-left h1 {
  padding-left: 0px;
  line-height: inherit;
  padding-right: 0px;
  font-size: 32px;
  margin-bottom: 0px;
}

.container_trial .gen_vs_true .bg_section_knowledge .main-slider-left ul {
  padding-left: 20px;
}

.container_trial .gen_vs_true .bg_section_knowledge .main-slider-left ul li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  font-family: "Roboto-Regular";
}

.container_trial .gen_vs_true .main-slider-left {
  padding: 40px;
}

.col.content_full .wv-heading-h4 {
  padding-bottom: 20px;
}

.container_trial .gen_vs_true.traditional_support .main-slider-left p {
  font-size: 16px;
  color: #333333;
  font-family: "Roboto-Regular";
}

.container_trial .bg_section_knowledge.bg_section_1 .row.justify-content-center p {
  margin-bottom: 0px;
}

.benefits_knowledge {
  width: 100%;
  float: left;
}

.container_trial .gen_vs_true.autoamted_resolution .bg_section_knowledge.bg_section_1 .main-slider-left h1 {
  margin-bottom: 0px;
}

.container_trial .gen_vs_true.autoamted_resolution .bg_section_knowledge.mb-0 p {
  margin-bottom: 0px;
}

.container_trial .gen_vs_true.autoamted_resolution .bg_section_knowledge.mb-0 {
  margin-bottom: 40px !important;
}

.container_trial .gen_vs_true.autoamted_resolution .bg_section_knowledge.mb-0 {
  margin-bottom: 40px !important;
}

.gen_vs_true.cost_reduction .wv-limited-content.m-b_30 {
  margin-bottom: 40px;
}

.inno_tech .pad_le_riht {
  padding: 0px 20px;
}

.inno_tech .mt-40.-innovative {
  margin-top: 0px !important;
}

.inno_tech .-innovative.mt-20 {
  padding: 40px 55px;
}

/* security page */

.security_wrapper {
  width: 100%;
  float: left;
}

.resources_card.bg_ccc {
  background: #f8f8f8;
}

.security_wrapper .whitepaper_cards .card__content {
  border: none;
}

.security_wrapper .card__image img {
  width: 84px;
  height: 84px;
}

.security_wrapper .whitepaper_cards .card {
  background: inherit;
}

.security_wrapper .whitepaper_cards {
  margin-bottom: 0px;
}

.security_wrapper .resources_card .whitepaper_cards .card__text {
  margin-bottom: 0px;
  font-family: "Gordita-Regular";
  font-size: 18px;
  color: #416183;
  text-align: center;
}

.security_wrapper .whitepaper_cards .card__content {
  padding: 5px;
}

.security_wrapper .resources_card {
  margin-top: 0px;
}

.security_wrapper .resources_card {
  padding: 40px 0px;
}

.security_wrapper .whitepaper_cards .card__title {
  font-size: 18px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Regular";
}

.security_wrapper .whitepaper_cards .card__title {
  font-size: 20px;
  text-align: center;
  color: #333333;
  font-family: "Gordita-Medium";
}

.security_wrapper .card__image {
  text-align: center;
  margin-bottom: 10px;
}

.tab_desktop {
  width: 100%;
  float: left;
  height: 60px;
  display: flex;
  align-items: center;
}

.tab_desktop .container {
  border-bottom: 1px solid #c7c7c7;
  justify-content: center;
  display: flex;
}

.top_section {
  width: 100%;
  float: left;
  background: #e8eeff;
  margin-bottom: 20px;
}

.top_section h1 {
  text-align: center;
}

.top_section p {
  text-align: center;
  color: #333333;
  padding: 0px 10%;
}

.tab_resposnive {
  display: none;
}

.container-fluid.security_page {
  width: 100%;
  float: left;
}

.container_trial .enterprise_message {
  font-size: 14px;
  text-align: center;
  color: #333333;
}

.mob_res_menu {
  width: 100%;
  float: left;
}

.table-bordered.table-responsive-sm td span svg {
  margin-left: 3px;
}

.pricing-chart {
  width: 100%;
  float: left;
}

.pricing-chart ul {
  width: 100%;
  float: left;
  height: -webkit-max-content;
  max-height: 410px;
  overflow-y: hidden;
}

.container_trial .pricing-columns .enterprise_message {
  padding-top: 60px;
  text-decoration: none;
  color: #494949;
  font-size: 15px;
}

.pricing-columns .pricing-chart .plan:hover .enterprise_message,
.pricing-columns-both .pricing-chart .plan:hover .enterprise_message {
  color: #fff;
}

.plan.plan_nterprise ul {
  margin-bottom: 0px;
}

.table_mob_comapre {
  display: none;
  width: 100%;
  float: left;
}

.show_button_full_comaparison.open~.table_mob_comapre {
  width: 100%;
  display: block;
}

.show_button_full_comaparison button:focus {
  color: #fff;
}

.pricing-section {
  width: 100%;
  float: left;
}

.pricing-section [tooltip] {
  position: absolute;
}

.wv-main-content.wv-section-mv-p0 .col-md-9 .wv-content-area .container {
  max-width: inherit;
}

.gen_vs_true .col-md-9 .wv-content-area .container {
  max-width: inherit;
}

.pricing-columns.pricing-section p {
  text-align: center;
  margin: 20px 0px;
}

.wv-limited-content.wv-section-mv-m {
  max-width: 100%;
}

.pb-200.content_para {
  padding: 0px 12%;
}

.pb-200.content_para .wv-md-heading1 {
  padding-bottom: 30px;
}

.top_section.pricing_page {
  padding: 40px 0px;
}

.top_section.pricing_page .main-slider-left {
  margin: 0px;
  padding: 0px;
  width: 100%;
  float: left;
}

.resolution_automate_first_automate_pricing .top_section.pricing_page .main-slider-button.beta-button.popup_form_pricing {
  padding: 0px !important;
}

.top_section.pricing_page .main-slider-button.beta-button {
  width: 100%;
  float: left;
  padding: 0px 28%;
}

.top_section.pricing_page .main-slider-left h1 {
  margin: 0px;
  margin-bottom: 20px;
}

.top_section.pricing_page .form_below_content {
  top: 0px;
  text-align: center;
}

.pricing-columns.pricing-section .plan {
  padding-top: 20px;
  padding-bottom: 20px;
}

.plan.plan_child {
  margin-top: 0px !important;
}

.wv-main-content {
  width: 100%;
  float: left;
  position: relative;
}

.dropdown.wv-dropdown {
  font-family: 'Gordita-Regular';
  width: 100%;
  float: left;
  position: sticky;
}

.show_button_full_comaparison.open {
  margin-bottom: 40px;
}

.accordion-wrapper.pricing_faq {
  width: 100%;
  float: left;
}

.accordion-wrapper.pricing_faq li {
  display: flex;
  flex-wrap: wrap;
}

.accordion-wrapper.pricing_faq li .faq_span {
  margin-top: 15px;
}

.col-12.pl-0.faq_landing {
  width: 100%;
  float: left;
  padding-right: 0px;
}

.mob_accordian.mob_accordian_faq {
  display: flex;
}

.mob_accordian.mob_accordian_faq .accordion__button {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 30px;
  display: flex;
  align-items: center;
  position: relative;
  font-family: "Gordita-Medium";
}

.mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
  color: #2468f6 !important;
  padding-bottom: 10px;
  font-size: 24px;
}

.mob_accordian.mob_accordian_faq .accordion__button {
  font-size: 24px;
  color: #000;
}

.mob_accordian.mob_accordian_faq .accordion__item {
  border-top: 1px solid #dedede;
}

.mob_accordian.mob_accordian_faq p {
  margin-bottom: 0px;
  color: #193a5d;
  font-size: 20px;
  padding-left: 11px;
}

.mob_accordian.mob_accordian_faq .accordion.pl-0:last-child .accordion__item {
  border-bottom: 1px solid #dedede;
}

.mob_accordian.mob_accordian_faq .accordion__button:hover {
  background-color: #fff;
}

.accordion__button:hover {
  background-color: inherit !important;
}

.accordion__button[aria-expanded="true"]:focus {
  outline: 0 !important;
}

.accordion__button[aria-expanded="false"]:focus {
  outline: 0 !important;
}

.top_section.top_section_bottom.pricing_page {
  margin-bottom: 40px;
}

.mob_accordian.mob_accordian_faq .container {
  padding: 0px 12%;
}

.mob_accordian.mob_accordian_faq .container h2 {
  font-family: "Roboto-Medium";
  text-align: center;
}

.mob_accordian.mob_accordian_faq .accordion__button:before {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBpZD0iZmFxX2ljb25fMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcwNyAtNDgyKSI+CiAgICA8ZyBpZD0iR3JvdXBfNzE2OCIgZGF0YS1uYW1lPSJHcm91cCA3MTY4Ij4KICAgICAgPHBhdGggaWQ9IlBhdGhfMzk5NCIgZGF0YS1uYW1lPSJQYXRoIDM5OTQiIGQ9Ik03MjMsNTE0YTE2LDE2LDAsMSwxLDExLjMxNC00LjY4NkExNS45LDE1LjksMCwwLDEsNzIzLDUxNFptMC0zMGExNCwxNCwwLDEsMCw5LjksNC4xQTEzLjkwNSwxMy45MDUsMCwwLDAsNzIzLDQ4NFoiLz4KICAgIDwvZz4KICAgIDxnIGlkPSJHcm91cF83MTY5IiBkYXRhLW5hbWU9Ikdyb3VwIDcxNjkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8zOTk1IiBkYXRhLW5hbWU9IlBhdGggMzk5NSIgZD0iTTczMCw0OTlINzE2YTEsMSwwLDAsMSwwLTJoMTRhMSwxLDAsMCwxLDAsMloiLz4KICAgIDwvZz4KICAgIDxnIGlkPSJHcm91cF83MTcwIiBkYXRhLW5hbWU9Ikdyb3VwIDcxNzAiPgogICAgICA8cGF0aCBpZD0iUGF0aF8zOTk2IiBkYXRhLW5hbWU9IlBhdGggMzk5NiIgZD0iTTcyMyw1MDZhMSwxLDAsMCwxLTEtMVY0OTFhMSwxLDAsMCwxLDIsMHYxNEExLDEsMCwwLDEsNzIzLDUwNloiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=);
  width: 22px;
  height: 22px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  left: 0;
  top: 26px;
}

.automate_homepage .mob_accordian.mob_accordian_faq .accordion__button:before {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBpZD0iZmFxX2ljb25fMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcwNyAtNDgyKSI+CiAgICA8ZyBpZD0iR3JvdXBfNzE2OCIgZGF0YS1uYW1lPSJHcm91cCA3MTY4Ij4KICAgICAgPHBhdGggaWQ9IlBhdGhfMzk5NCIgZGF0YS1uYW1lPSJQYXRoIDM5OTQiIGQ9Ik03MjMsNTE0YTE2LDE2LDAsMSwxLDExLjMxNC00LjY4NkExNS45LDE1LjksMCwwLDEsNzIzLDUxNFptMC0zMGExNCwxNCwwLDEsMCw5LjksNC4xQTEzLjkwNSwxMy45MDUsMCwwLDAsNzIzLDQ4NFoiLz4KICAgIDwvZz4KICAgIDxnIGlkPSJHcm91cF83MTY5IiBkYXRhLW5hbWU9Ikdyb3VwIDcxNjkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8zOTk1IiBkYXRhLW5hbWU9IlBhdGggMzk5NSIgZD0iTTczMCw0OTlINzE2YTEsMSwwLDAsMSwwLTJoMTRhMSwxLDAsMCwxLDAsMloiLz4KICAgIDwvZz4KICAgIDxnIGlkPSJHcm91cF83MTcwIiBkYXRhLW5hbWU9Ikdyb3VwIDcxNzAiPgogICAgICA8cGF0aCBpZD0iUGF0aF8zOTk2IiBkYXRhLW5hbWU9IlBhdGggMzk5NiIgZD0iTTcyMyw1MDZhMSwxLDAsMCwxLTEtMVY0OTFhMSwxLDAsMCwxLDIsMHYxNEExLDEsMCwwLDEsNzIzLDUwNloiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=);
  width: 22px;
  height: 22px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  left: 0;
  top: 23px !important;
}

.mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:before {
  background: url("../images/pricing/faq_icon_1.svg");
  width: 22px;
  height: 22px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  left: 0;
}

/* Dynamic integrations page */

.automation_lists {
  width: 100%;
  float: left;
}

.icons_wrapper {
  width: 100%;
  float: left;
  padding: 0px 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.icons_wrapper_img {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.icons_wrapper_img img {
  width: 90px;
  height: 90px;
  margin: 0px 20px;
}

.icons_wrapper_img span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons_wrapper_img span svg {
  fill: #90b4ff;
  width: 24px;
  height: 24px;
}

.automation_lists .main-slider-left {
  width: 100%;
  float: left;
  padding: 0px 40px;
  text-align: center;
}

.automation_lists .main-slider-left h1 {
  margin-bottom: 15px;
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Roboto-Medium";
}

.automation_lists .main-slider-left p {
  margin-bottom: 0px;
  width: 100%;
  float: left;
  text-align: center;
  padding-right: 0px;
  font-family: "Roboto-Regular";
}

.cards_apps_wrapper {
  width: 48%;
  float: left;
  padding: 0px 15px;
  display: flex;
}

.apps_md_12 {
  width: 100%;
  float: left;
}

.haeder_img {
  width: 100%;
  float: left;
  border: 1px solid #dad9d9;
  border-radius: 6px;
  overflow: hidden;
}

.haeder_img h4 {
  color: #333333;
  font-family: "Roboto-Medium";
  font-size: 20px;
  background: #e7f2fd;
  padding: 0px 15px;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dad9d9;
}

.haeder_img h4 img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.automations_list_wrapper {
  width: 100%;
  float: left;
  padding: 40px 30px;
}

.automations_list_wrapper h5 {
  font-size: 18px;
  color: #416183;
  font-family: "Roboto-Medium";
  margin-bottom: 20px;
}

.automations_list_li:last-child p {
  margin-bottom: 0px;
}

.automations_list_li {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.automations_list_li h6 {
  font-size: 15px;
  color: #333333;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
  line-height: inherit;
}

.automations_list_li p {
  font-size: 13px;
  color: #416183;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
  line-height: inherit;
}

.apps_center_wrapper {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.show_all {
  width: 100%;
  float: left;
  color: #2468f6;
  font-size: 13px;
  font-family: "Roboto-Regular";
  margin-top: 15px;
  cursor: pointer;
}

.two_apps_wrapper_center {
  width: 100%;
  float: left;
}

.two_apps_wrapper_center .two_apps_wrapper {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.two_apps_wrapper_center .two_apps_wrapper .icons_wrapper_img {
  width: auto;
  margin-bottom: 0px;
}

.two_apps_wrapper_center .two_apps_wrapper .icons_wrapper_img span {
  width: 15px;
  height: 15px;
  margin: 0px 5px;
}

.two_apps_wrapper_center .two_apps_wrapper .icons_wrapper_img img {
  margin: 0px;
}

.two_apps_wrapper_center .two_apps_wrapper .icons_wrapper_img label {
  margin-bottom: 0px;
  margin-left: 10px;
}

.modal_ui {
  width: 100%;
  float: left;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: #0000004f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_ui .cards_apps_wrapper .apps_md_12 {
  background: #fff;
}

.modal_ui .cards_apps_wrapper .show_all {
  display: none;
}

.modal_ui .cards_apps_wrapper .automations_list_wrapper {
  width: 100%;
  float: left;
  padding: 40px 30px;
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.modal_ui .cards_apps_wrapper {
  width: 42%;
}

.haeder_img .icon_right {
  display: none;
}

.modal_ui .haeder_img span svg {
  display: block;
  width: 10px;
  height: 10px;
  fill: #6d859d;
}

.modal_ui .apps_name {
  width: 88%;
  float: left;
}

.modal_ui .icon_right {
  width: 22px;
  float: left;
  display: flex;
  justify-content: center;
  height: 22px;
  align-items: center;
  border: 1px solid #6d859d;
  border-radius: 50%;
  cursor: pointer;
}

.haeder_img h4 label {
  margin-bottom: 0px;
  margin-left: 12px;
}

.featured_content.slider_two_apps .integrations_card_slider {
  min-height: 50px;
  flex-direction: column;
  margin-bottom: 10px;
}

.featured_content.slider_two_apps .icons_wrapper_img {
  margin-bottom: 10px;
}

.featured_content.slider_two_apps .home_slider img {
  width: 64px;
  height: 64px;
}

.featured_content.slider_two_apps .icons_wrapper_img span svg {
  fill: #c6c6c6;
}

.featured_content.slider_two_apps .cogni_workspace_content.home_slider {
  padding: 25px;
}

.featured_content.slider_two_apps .cogni_workspace_content p {
  min-height: auto;
  padding: 0px;
  margin-bottom: 10px;
  text-align: center;
}

.featured_content.slider_two_apps .home_link {
  padding: 0px;
  text-align: center;
}

.featured_content.slider_two_apps .benefits-workplace-content img {
  margin: 0px 5px;
}

.featured_content.slider_two_apps h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  color: #333333;
  font-family: "Roboto-Medium";
  line-height: 1.3;
}

.featured_content.slider_two_apps h5 span {
  display: flex;
  align-items: center;
}

.featured_content.slider_two_apps h5 span svg {
  width: 12px;
  height: 12px;
  fill: #000;
  margin: 0px 7px;
}

.whitepaper_cards.newpages_cards .cards__item {
  width: 30.3333%;
  padding: 0px;
}

.whitepaper_cards.newpages_cards .card__content {
  border: none;
  padding: 0px 15px;
}

.whitepaper_cards.newpages_cards .card__title {
  color: #333333;
  font-size: 18px;
  text-align: center;
}

.whitepaper_cards.newpages_cards .card__text {
  font-size: 16px;
  text-align: center;
}

.whitepaper_cards.newpages_cards .cards {
  justify-content: space-between;
}

.select_work_form {
  width: 100%;
  float: left;
  position: relative;
}

.select_work_form input {
  width: 100%;
  float: left;
  height: 44px;
  font-size: 14px;
  padding: 0px 15px;
  font-family: "Roboto-Medium";
  border-radius: 20px;
  border: 0.5px solid #dad9d9;
  outline: 0;
}

.automation_scroly {
  width: 100%;
  position: absolute;
  margin-top: 0px;
  border-radius: 6px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.select_work_form ul {
  padding: 0px;
  height: 100%;
  overflow-y: scroll;
  max-height: 305px;
  margin-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  border-radius: 20px;
  position: relative;
  width: 100%;
  float: left;
  z-index: 1;
}

.select_work_form ul li {
  width: 100%;
  float: left;
  display: inline-flex;
  align-items: center;
  border-bottom: 0.5px solid #dad9d9;
  font-size: 14px;
  font-family: "Roboto-light";
  height: 44px;
  padding: 0 15px;
  background: #fff;
  cursor: pointer !important;
}

.select_work_form ul li:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
}

.select_work_form ul li:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fff;
  border: none;
}

.select_work_form ul li {
  position: relative;
  list-style: none;
}

.select_work_form .arrow_up_form {
  position: absolute;
  width: 15px;
  height: 15px;
  cursor: pointer;
  float: right;
  margin-top: 8px;
  right: 10px;
  left: inherit;
  z-index: 1;
}

.connection_form {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.connection_formwrapper {
  background: #fff6e5;
  width: 100%;
  float: left;
  padding: 40px;
}

.connection_formwrapper h2 {
  font-size: 28px;
  padding: 0px;
  font-family: "Roboto-Medium";
  text-align: center;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.dropdown_apps {
  width: 40%;
  float: left;
}

.form_wrapper_apps {
  width: 100%;
  float: left;
  padding: 0px 10%;
  display: flex;
}

.connection_formwrapper .dropdown_app_icon {
  position: relative;
  margin-top: 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  float: left;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.connection_formwrapper .application_dropdown_content {
  width: max-content;
  float: left;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Roboto-Regular";
  margin-left: 7px;
}

.connection_formwrapper button {
  color: rgb(255, 255, 255);
  width: 20%;
  text-align: center;
  height: 44px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto-Regular";
  background: rgb(46, 109, 250);
  border-radius: 0px;
  outline: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: transform 0.5s ease 0s;
  margin-left: 20px;
  border: none;
}

.connection_formwrapper .add_icons {
  width: 30px;
  height: 30px;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connection_formwrapper .add_icons svg {
  width: 18px;
  height: 18px;
  fill: #9b9b9b;
}

.automation_scroly input {
  border-radius: 0px;
  border: none;
  border-bottom: 0.5px solid #dad9d9;
  position: sticky;
  top: 0;
  z-index: 9;
}

.dynamic_cards {
  width: 100%;
  float: left;
  margin-top: 0px;
}

.dynamic_cards .benefits-workplace-content.cogni_workspace_content a {
  display: none;
}

.dynamic_cards .helpdesk_solutions.how-it-works-solutions p {
  margin-bottom: 0px;
  width: 100%;
  float: left;
  font-size: 16px;
}

.dynamic_cards .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content {
  width: 100%;
  float: left;
}

.dynamic_cards .cards_wrapper_home {
  flex-wrap: wrap;
}

.bg_slider_home.m_t_20 {
  padding-top: 60px;
}

.modal_ui .cards_apps_wrapper .apps_md_12 {
  border-radius: 4px;
}

.modal_ui .cards_apps_wrapper .haeder_img {
  border: none;
}

.modal_ui .cards_apps_wrapper .haeder_img h4 {
  border: none;
}

.main-slider-button.beta-button.popup_form_pricing {
  flex-direction: column;
}

.main-slider-button.beta-button.popup_form_pricing input {
  width: 100%;
  margin-bottom: 20px;
}

.main-slider-button.beta-button.popup_form_pricing button {
  width: 100%;
}

.main-slider-button.beta-button.popup_form_pricing form {
  width: 100%;
  float: left;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
}

.main-slider-button.beta-button.popup_form_pricing .modal-header {
  flex-wrap: wrap;
}

.main-slider-button.beta-button.popup_form_pricing .modal-body-header {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.first_popup_form .modal-body-header {
  align-items: center;
  padding-bottom: 20px;
}

.first_popup_form .modal-header h5 {
  margin-bottom: 30px;
}

.modal_submit_form {
  width: 100%;
  float: left;
}

.second_form .modal-content.second-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: max-content;
}

.second_form #exampleModalCenter {
  justify-content: center;
  align-items: center;
}

.container_trial .top_section.pricing_page .main-slider-left p {
  padding: 0px;
  margin-bottom: 0px;
}

.mob_pricing_page {
  display: none;
}

.sticky_header {
  width: 100%;
  float: left;
}

.card_apps_cc {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.apps_integrations .cogni_fav_apps_color .card_apps_cc .card_office {
  background: none;
  box-shadow: none;
  margin-right: 20px;
}

.apps_integrations .cogni_fav_apps_color .card_apps_cc .card_office:last-child {
  margin-right: 0px;
}

.cc_feature_apps .card_apps_cc .card_office img {
  width: 72px;
  height: 72px;
}

.cc_feature_apps_header.usecases_header h2 {
  text-align: center;
  font-family: "Roboto-Medium";
  padding: 0px;
}

.marketplace_integrations_icons.usecases_integrations_icons {
  width: 100%;
  float: left;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 20px;
}

.bg_apps_usecaes {
  width: 100%;
  float: left;
  background: #e8eeff;
}

.sticky_menu {
  width: 100%;
  float: left;
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #93a0c6;
}

.top_menu_sticky.top_menu_sticky_fixed .sticky_menu {
  border: none;
}

.top_menu_sticky .knowledge_cards .whitepaper_cards .cards__item a {
  cursor: auto;
}

.sticky_menu ul {
  padding-left: 15px;
  margin-bottom: 0px;
  display: flex;
}

.sticky_menu ul li {
  list-style: none;
  color: #333333;
  font-size: 14px;
  font-family: "Roboto-Regular";
  padding: 0px 7px;
}

.sticky_menu ul li a {
  text-decoration: none;
  color: #333333;
}

.sticky_menu span {
  color: #333333;
  font-size: 14px;
  font-family: "Roboto-Medium";
}

.resources_card.knowledge_cards h2 {
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 42px;
  color: #333333;
  margin-bottom: 30px;
  width: 100%;
  float: left;
  padding: 0px 20%;
}

.automation_steps {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.automation_steps h2 {
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 42px;
  color: #333333;
  margin-bottom: 30px;
  width: 100%;
  float: left;
  padding: 0px 20%;
}

.boarding_wrapper_container {
  width: 100%;
  float: left;
  margin-bottom: 40px;
  padding: 0px 15px;
}

.boarding_wrapper_container h4 {
  font-weight: 300;
  font-family: "Roboto-Medium";
  margin-bottom: 10px;
  font-size: 30px;
  color: #333333;
}

.boarding_wrapper_container p {
  font-weight: 300;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
  font-size: 18px;
  color: #416183;
}

.top_menu_sticky .resources_card.knowledge_cards {
  margin-top: 0px;
}

.slack_teams_wrapper .pl-20-tage.mt-0 {
  padding-top: 0px !important;
}

.boarding_wrapper_container .pad_left_30 {
  padding-left: 30px;
}

.boarding_wrapper_container .pad_right_30 {
  padding-right: 30px;
}

.top_menu_sticky {
  width: 100%;
  float: left;
  position: relative;
}

.top_menu_sticky {
  width: 100%;
  float: left;
  position: relative;
}

.top_menu_sticky_fixed .container-fluid.bg_apps_usecaes {
  position: sticky;
  top: 78px;
  z-index: 9;
}

.top_menu_sticky_fixed {
  width: 100%;
  float: left;
}

.slack_teams_wrapper {
  width: 100%;
  float: left;
}

.mob_sticky_wrapper {
  width: 100%;
  float: left;
  position: relative;
}

.mob_sticky_wrapper .dropdown.wv-dropdown {
  width: 100%;
  float: left;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 1;
  background: #fff;
  padding: 0px 15px;
  display: none;
  margin-bottom: 40px;
}

.mob_sticky_wrapper .dropdown.wv-dropdown h4 {
  font-weight: 300;
  font-family: "Roboto-Medium";
  font-size: 20px;
  color: #333333;
  margin-bottom: 12px;
}

.top_menu_sticky .resources_card .whitepaper_cards .card__title {
  text-align: center;
}

.top_menu_sticky .resources_card .whitepaper_cards .card__text {
  text-align: center;
}

.top_menu_sticky .featured_content .cogni_workspace_content h3 {
  padding: 0px 20px;
  position: relative;
  top: 0px;
  margin-bottom: 12px;
  font-family: "Roboto-Medium";
  font-size: 16px;
}

.top_menu_sticky .bg_header_trial.trial_mb_80 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.top_menu_sticky .feature_page_content_left h3 {
  color: #273237 !important;
}

/* Remove close mark in chrome input box*/

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Remove close mark in chrome input box*/

.boarding_wrapper {
  width: 100%;
  float: left;
}

.top_menu_sticky .wv-dropdown .link {
  border: 2px solid #2468f6;
  padding: 8px 12px;
  font-size: 16px;
  font-family: "Roboto-Regular";
  cursor: pointer;
  color: #2468f6;
}

.top_menu_sticky .wv-dropdown .link:after {
  content: "";
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhLS1HZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCktLT48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBmaWxsPSIjMjQ2OEY2IiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ1MS44NDdweCIgaGVpZ2h0PSI0NTEuODQ3cHgiIHZpZXdCb3g9IjAgMCA0NTEuODQ3IDQ1MS44NDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1MS44NDcgNDUxLjg0NyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTIyNS45MjMsMzU0LjcwNmMtOC4wOTgsMC0xNi4xOTUtMy4wOTItMjIuMzY5LTkuMjYzTDkuMjcsMTUxLjE1N2MtMTIuMzU5LTEyLjM1OS0xMi4zNTktMzIuMzk3LDAtNDQuNzUxDQoJCWMxMi4zNTQtMTIuMzU0LDMyLjM4OC0xMi4zNTQsNDQuNzQ4LDBsMTcxLjkwNSwxNzEuOTE1bDE3MS45MDYtMTcxLjkwOWMxMi4zNTktMTIuMzU0LDMyLjM5MS0xMi4zNTQsNDQuNzQ0LDANCgkJYzEyLjM2NSwxMi4zNTQsMTIuMzY1LDMyLjM5MiwwLDQ0Ljc1MUwyNDguMjkyLDM0NS40NDlDMjQyLjExNSwzNTEuNjIxLDIzNC4wMTgsMzU0LjcwNiwyMjUuOTIzLDM1NC43MDZ6Ii8+PC9nPjwvc3ZnPg==);
}

.left-whitespace {
  top: 10%;
  z-index: 8;
}

.featured_content .cogni_workspace_content {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.pad-left-15 {
  padding-left: 15px;
}

.top_menu_sticky .trial_page_img .chat_channels_trial img {
  filter: inherit;
}

.cards_apps_wrapper.cards__item .container {
  padding: 0px;
}

.apps_center_wrapper.whitepaper_cards {
  width: 100%;
}

.apps_center_wrapper.whitepaper_cards .cards {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  justify-content: space-between;
}

.whitepaper_cards.apps_center_wrapper .cards__item {
  width: 45%;
  display: flex;
  flex-direction: column;
  font-family: Roboto-Regular;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  border-top: none;
  border: 1px solid #dad9d9;
  border-radius: 6px;
  overflow: hidden;
}

.whitepaper_cards .automations_list_wrapper.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-family: Roboto-Regular;
  margin-bottom: 0;
  padding: 20px;
  font-size: 14px;
  border: none;
}

.whitepaper_cards.apps_center_wrapper .haeder_img {
  border: none;
}

.key_integrations_bg.card_landing_section.integrations_popular_bg {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  align-self: auto;
}

.key_integrations_bg.card_landing_section.integrations_popular_bg img {
  display: flex;
  margin-top: 12px;
  margin-left: 30px;
}

.card_landing_section.integrations_popular_bg.key_integrations_bg .card_landing_trial {
  padding-top: 0px;
}

.card_landing_section.integrations_popular_bg .card_landing_trial {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.card_landing_section.integrations_popular_bg .card_link_landing,
.card_landing_section.integrations_popular_bg p {
  word-break: break-word;
}

.card_landing_section.integrations_popular_bg h4 {
  word-break: break-word;
}

.featured_content.slider_two_apps .cogni_workspace_content p {
  min-height: 50px;
}

.featured_content.slider_two_apps .icons_wrapper_img span svg {
  fill: #c6c6c6;
  width: 18px;
  height: 18px;
}

.automation_lists {
  width: 100%;
  float: left;
}

.automation_lists .apps_integrations.bg_marketpalce {
  margin-top: 0px;
}

.automation_lists .apps_integrations.bg_marketpalce .main-slider-left p {
  margin-bottom: 20px;
}

.icons_wrapper .main-slider-left h2 {
  font-family: "Roboto-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 30px;
  text-align: center;
}

.icons_wrapper .main-slider-left p {
  padding-right: 0px !important;
}

.helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
  font-family: "Roboto-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: left;
}

.helpdesk_solutions.how-it-works-solutions .suprcharging_benefits h2 {
  text-align: center;
}

.footer_form_left .main-slider-left h3 {
  font-family: "Gordita-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: left;
}

.footer_form_left .main-slider-left h5 {
  font-family: "Gordita-Medium";
  font-size: 30px;
  color: #000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: left;
}

.bg_header_workplace_support {
  background: transparent linear-gradient(63deg, #f6fff4 0%, #fff1ed 100%) 0% 0% no-repeat padding-box;
}

.onprem_connect {
  width: 100%;
  float: left;
}

.onprem_connect .automation_steps {
  width: 100%;
  float: left;
  margin-bottom: 40px;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 40px 0px;
  padding-bottom: 0px;
}

.onprem_connect .bg_conversation_ai {
  margin-bottom: 0px;
}

.onprem_connect .automation_steps p {
  width: 100%;
  float: left;
  font-size: 18px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  padding: 0px 4%;
  text-align: center;
  margin-bottom: 40px;
}

.onprem_connect .bg_header_trial.trial_mb_80 {
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  top: 0px;
  width: 100%;
  float: left;
  background: #e7f2fd;
}

.onprem_connect .cards_wrapper_home {
  flex-wrap: wrap;
}

.onprem_connect .cards_wrapper_home .pad_0 {
  padding-left: 0px;
}

.onprem_connect .cards_wrapper_home .pad_0 a {
  display: none;
}

.onprem_connect .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
  text-align: center;
}

.onprem_connect .onprem_cards .helpdesk_solutions .main-slider-left {
  display: none;
}

.onprem_connect .onprem_cards .helpdesk_solutions.how-it-works-solutions {
  background: transparent linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 0px;
}

.onprem_connect .onprem_cards .helpdesk_solutions .suprcharging_benefits {
  background: transparent linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  padding: 40px 0px;
}

.onprem_connect .onprem_cards .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content {
  background: none;
}

.how_works_wrapper {
  width: 100%;
  float: left;
}

.bg_header_workplace_support .main-slider-left h1 {
  padding-right: 10%;
}

.workflow_automation_wrapper {
  width: 100%;
  float: left;
}

.workflow_automation_wrapper .feature_page_content_left span {
  width: auto;
  line-height: inherit;
}

.workflow_automation_wrapper .feature_page_content_left span a {
  width: auto;
  float: inherit;
  text-decoration: none;
  padding-top: 0px;
}

.workflow_automation_wrapper .feature_page_content_left span a:hover {
  text-decoration: underline !important;
}

.whitepaper_cards.three_cards_wrapper img {
  width: 64px;
  height: 64px;
}

.whitepaper_cards.three_cards_wrapper .card__title {
  color: #333333;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-family: "Roboto-Medium";
  line-height: 1.3;
}

.whitepaper_cards.three_cards_wrapper .card {
  background-color: inherit;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  padding: 30px;
}

.whitepaper_cards.three_cards_wrapper .card__content {
  border: none;
  padding: 0px;
}

.whitepaper_cards.three_cards_wrapper .cards__item {
  padding: 0px;
}

.whitepaper_cards.three_cards_wrapper .card__image {
  padding-top: 0px;
}

.whitepaper_cards.three_cards_wrapper .cards {
  display: flex;
  justify-content: space-between;
}

.whitepaper_cards.three_cards_wrapper .cards__item {
  width: 31.33%;
  border-radius: 8px;
}

.whitepaper_cards.three_cards_wrapper .cards__item .card_link_landing {
  margin-top: 10px;
  margin-bottom: 0px;
}

.whitepaper_cards.three_cards_wrapper .cards__item .card_link_landing a {
  margin-bottom: 0px;
}

.bg_card_5 {
  background: #ffeee5;
}

.whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
  font-family: "Roboto-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 30px;
  text-align: center;
}

.whitepaper_cards.three_cards_wrapper .card__content .card__text:nth-child(3) {
  color: #333333;
}

.roi-form-right {
  width: 100%;
  float: left;
}

.roi-form-right .roi_form_wrapper {
  width: 100%;
  float: left;
  background: transparent linear-gradient(63deg, #5f92f8 0%, #719ffa 100%) 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 30px;
}

.roi-form-right ._full_width {
  width: 50%;
  float: left;
  padding: 0px 15px;
  margin-bottom: 16px;
}

.roi-form-right ._full_width input {
  width: 100%;
  float: left;
  border: inherit;
  border-radius: 4px;
  padding: 0px 10px;
  height: 38px;
  font-size: 14px;
  font-family: "Gordita-Regular";
  color: #fff;
  background: rgb(132 173 255);
  outline: 0;
}

.roi-form-right ._full_width ._form-label {
  color: #fff;
  font-family: "Gordita-Regular";
  font-size: 14px;
  margin-bottom: 2px;
}

.roi-form-right ._full_width input:focus {
  background: rgb(132 173 255);
  border: 1px solid rgb(132 173 255);
  outline: 0;
}

.roi-form-right p {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  color: #fff;
  font-family: "Gordita-Regular";
}

.roi-form-right form ._button-wrapper._full_width {
  width: 100%;
  float: left;
}

._button-wrapper._full_width button {
  background: #fff;
  border: 1px solid #fff;
  width: 100%;
  float: left;
  color: rgb(37 104 247);
  border-radius: 4px;
  height: 38px;
  cursor: pointer;
  font-family: "Gordita-Regular";
}

._button-wrapper._full_width:hover button {
  background: #719ffa;
  border: 1px solid #fff;
  color: #fff;
  animation: 0.5s ease-in;
}

.roi-form-right h2 {
  font-size: 22px;
  color: #fff;
  font-family: "Gordita-Medium";
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 20px;
}

.roi-form-right input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.7;
  /* Firefox */
}

.roi-form {
  width: 100%;
  float: left;
}

.roi-form h1 {
  width: auto;
  float: left;
  font-size: 20px;
  font-family: "Gordita-Regular";
  text-align: left;
  color: #333333;
  margin-bottom: 12px;
  position: relative;
}

.roi-form h1:before {
  content: "";
  position: absolute;
  border-bottom: 0.5px solid #333333;
  width: 100%;
  bottom: 2px;
}

.roi-form h2 {
  width: 100%;
  float: left;
  font-size: 40px;
  font-family: "Gordita-Medium";
  text-align: left;
  color: #333333;
  margin-bottom: 20px;
}

.roi-form p {
  width: 100%;
  float: left;
  font-size: 18px;
  font-family: "Gordita-Regular";
  text-align: left;
  color: #333333;
  margin-bottom: 12px;
  line-height: 30px;
}

.roi-form ul {
  margin-bottom: 12px;
  width: 100%;
  float: left;
  padding-left: 20px;
}

.roi-form ul li {
  width: 100%;
  float: left;
  font-size: 18px;
  color: #333333;
  margin-bottom: 6px;
  text-align: left;
  font-family: "Gordita-Regular";
  line-height: 30px;
}

.roi_form_results_left h4 span {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Roboto-Regular";
  text-align: left;
  padding: 0px;
  margin-left: -3px;
}

.roi_form_results_right h4 span {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Roboto-Regular";
  text-align: left;
  padding: 0px;
  margin-left: -3px;
}

.roi-form-right .roi_form_wrapper.roi_form_wrapper_results {
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.roi_form_results {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-around;
  color: #fff;
}

.roi_form_results h4 {
  font-size: 34px;
  font-family: "Roboto-Medium";
  text-align: left;
  margin-bottom: 0px;
}

.roi_form_results p {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Roboto-Regular";
  text-align: left;
  padding: 0px;
}

.skit_contactus.roi_page_contact .roi-form-right {
  width: 100%;
  float: left;
  padding-left: 30px;
  margin-top: 40px;
}

.skit_contactus.roi_page_contact .roi-form {
  width: 100%;
  float: left;
  padding-right: 30px;
}

._submit.btn-deactivated {
  pointer-events: none;
  opacity: 0.7;
}

._button-wrapper._full_width {
  width: 100%;
  float: left;
}

._button-wrapper._full_width:hover ._submit.btn-deactivated {
  background: #fff;
  border: 1px solid #fff;
  width: 100%;
  float: left;
  color: rgb(37 104 247);
  /* border-radius: 4px; */
  height: 38px;
  cursor: pointer;
}

._field-wrapper.error_msg input {
  border: 1px solid #ff8f00;
}

._field-wrapper.error_msg input:focus {
  border: 1px solid #ff8f00;
}

._field-wrapper.error_msg {
  position: relative;
  width: 100%;
  float: left;
}

._field-wrapper.error_msg span {
  position: absolute;
  width: 100%;
  float: left;
  top: 39px;
  margin-bottom: 0px;
  display: block;
  color: #ff8f00;
  font-family: "Roboto-Regular";
  font-size: 12px;
}

._field-wrapper span {
  display: none;
}

/* styled components css classname pblm*/

.nav-bar-left {
  width: auto;
  float: left;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .nav-bar-left {
    width: 100%;
    float: left;
    display: block;
  }
}

.card_head {
  width: 100%;
  float: left;
  position: relative;
}

.card_head .coming_soon {
  position: absolute;
  right: -20px;
  background: #e6ffe0;
  color: #1aa75b;
  font-size: 15px;
  font-family: "Roboto-Medium";
  width: auto;
  height: 30px;
  padding: 0px 12px;
  display: inline-flex;
  align-items: center;
  top: 18px;
}

.integrations_cards_wrapper:hover .card_head .coming_soon {
  position: absolute;
  right: -21px;
}

.col-md-6.feature_page_content_left {
  position: relative;
}

.col-md-6.feature_page_content_left .coming_soon_wrapper {
  width: 100%;
  float: left;
  position: relative;
  top: -24px;
  left: -20px;
}

.col-md-6.feature_page_content_left .coming_soon {
  background: #e6ffe0;
  color: #1aa75b;
  font-size: 18px;
  font-family: "Gordita-Medium";
  width: max-content;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  top: 0;
}

/* styled components css classname pblm*/

/* workflow Automate css Start */

.bg_header_automation {
  background: #f2fffa;
}

.new_automate_background .bg_header_automation {
  background: #f2fffa;
}

.new_automate_background .bg_header_trial.trial_mb_80.bg_header_automation {
  margin-bottom: 46px !important;
}

.automate-center-content-main-container {
  width: 100%;
  margin-bottom: 30px;
}

.new_automate_background .container-fluid.autoamte_apps {
  margin-bottom: 46px;
}

.new_automate_background .iso-section-automate {
  margin-bottom: 46px;
}

.integrations-apps-paragaraph-automate {
  text-align: center !important;
  padding: 0 80px;
}

.new_automate_background .footer_form {
  margin-bottom: 46px;
}

.automate_onboard_here_signup {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.onboard_here_arrow_automate {
  width: 50px;
  height: auto;
  position: absolute;
  left: -5px;
  top: -5px;
}

.new_automate_background .homepage_slider.slider_homepage .featured_content {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
  padding: 30px 0;
}

.new_automate_background .homepage_slider.slider_homepage .featured_content .left-whitespace {
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.container_trial.integrations_container .automate_homepage .bg_feature_slider.bg_feature_slider_wrapper {
  background: transparent linear-gradient(70deg, #effffe 0%, #f9f5e5 100%) 0% 0% no-repeat padding-box !important;
  padding: 40px 0px;
}

.chatbots_wrapper {
  width: 100%;
  float: left;
  display: flex;
}

.chatbots_wrapper_row {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
}

.trial_page_img .chatbots_wrapper_row img {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .chatbots_wrapper_row .chatbots_name {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #416183 !important;
  font-family: "Roboto-Regular";
}

.workflow_automation_wrapper.trigger_page .chatbots_wrapper_row .chatbots_name {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #416183 !important;
  font-family: "Roboto-Regular";
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .apps_integrations .cogni_fav_apps.cc_feature_apps {
  background: #f8f8f8;
  padding-bottom: 0px !important;
}

.automate-new-integrations-apps {
  background: #fff5f5 !important;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .trial_page_img {
  background: transparent linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%) 0% 0% no-repeat padding-box !important;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper:last-child .trial_page_img {
  background: #f8f8f8;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .support_chatbot .trial_page_img.bg_trial_page {
  background: #fff !important;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .featured_content {
  background: #fff;
  margin-bottom: 0px;
}

.workflow_automation_wrapper.workflow_autoamte_wrapper .left-whitespace {
  background: #fff;
}

.col-lg-6.trial_header_left .coming_soon_wrapper {
  width: 100%;
  float: left;
  position: relative;
  top: 0px;
  left: 0px;
}

.col-lg-6.trial_header_left .coming_soon {
  background: #fff2e6 0% 0% no-repeat padding-box;
  color: #ff7900;
  font-size: 18px;
  font-family: "Roboto-Medium";
  width: max-content;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  top: 0;
}

.bg_header_trigger {
  background: #f4fff4 0% 0% no-repeat padding-box;
}

.workflow_automation_wrapper.trigger_page .trial_header_left .main-slider-left {
  padding: 1rem 0;
}

.workflow_automation_wrapper.trigger_page .trial_header_left {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* workfloe Automate css End */

/* Homepage cards changes */

.cards_soon_wrapper {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.cards_soon_wrapper span.cards_soon {
  width: max-content;
  position: relative;
  top: -10px;
}

.cards_soon_wrapper span.cards_soon .coming_soon {
  background: #fff2e6 0 0 no-repeat padding-box;
  color: #ff7900;
  font-size: 18px;
  font-family: Roboto-Medium;
  width: -webkit-max-content;
  width: max-content;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  top: 0;
  margin-left: 15px;
}

.skit_contactus.roi_page_contact {
  margin-top: 0px;
  margin-bottom: 40px;
  width: 100%;
  float: left;
  background: #e7efff;
  padding-top: 10px;
  padding-bottom: 40px;
}

.skit_contactus.roi_page_contact .roi_form_wrapper p {
  color: #fff;
}

.market_place {
  width: 100%;
  float: left;
}

.market_place .loader_demo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  background: #fff;
  opacity: 1;
  top: 0;
  left: 0px;
}

.jumpstart_loader .loader_demo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
  background: inherit;
  opacity: 0.8;
  padding: 40px 0px;
}

.integrations_wrapper_inner .trial_page_img.bg_trial_page:last-child {
  margin-bottom: 0px;
}

.integrations_slider_bg {
  width: 100%;
  float: left;
}

.integrations_slider_bg .featured_content {
  background: #e8f3ff;
}

.integrations_slider_bg .featured_content .left-whitespace {
  background: #e8f3ff;
}

/* Landing Page css */

.landing_bg {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.landing_bg .main-slider-left {
  padding: 0px;
}

.header_landing img {
  width: 140px;
  height: auto;
  position: relative;
  z-index: 10;
}

.header_landing.sticky .landing_bg {
  background: #fff;
}

.header_landing ul li a {
  width: auto;
  list-style: none;
  font-size: 15px;
  font-family: "Roboto-Medium";
  display: inline-block;
  color: rgb(51, 51, 51);
  position: relative;
}

.header_landing.sticky .fixed-top {
  height: 80px;
  top: 0px;
}

.header_landing .fixed-top {
  height: 80px;
  top: 0px;
}

.header_landing_wrapper .center_image_home {
  background: #fff;
  margin-bottom: 0px;
}

.header_landing_wrapper .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0px;
  padding-left: 25px;
}

.center_image_home .main-slider-left h3 {
  padding: 0px;
  text-align: center;
  font-family: "Gordita-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  line-height: 1.2;
}

.center_image_home .main-slider-left p {
  text-align: center;
}

.center_image_home.cards_landing_page .main-slider-left {
  padding: 0px 15%;
}

.center_image_home.cards_landing_page .card_landing_trial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center_image_home.cards_landing_page .card_landing_section {
  background: #fff;
  border: 1px solid #67a2cc;
}

.center_image_home.cards_landing_page .card_landing_section h4 {
  color: #333333;
  font-size: 20px;
}

.center_image_home.cards_landing_page .card_landing_section p {
  font-size: 20px;
  text-align: center;
}

.header_landing_wrapper .center_image_home.cards_landing_page {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.trial_page_img.bg_trial_page.bg_landing_green {
  background: #effff0;
}

.trial_page_img.bg_landing_yellow {
  background: #f9f5e5;
  padding-top: 40px;
}

.container_trial .trial_page_img.bg_landing_yellow .second_p {
  color: #000000;
}

.apps_landing_page {
  width: 100%;
  float: left;
  background: transparent linear-gradient(180deg, #f4faff 0%, #f4faff 100%) 0% 0% no-repeat padding-box;
  padding: 50px 0px;
  margin-bottom: 40px;
}

.apps_landing_header_wrapper {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}

.apps_landing_header_wrapper h3 {
  text-align: center;
  font-family: "Gordita-Medium";
  font-size: 40px;
  color: #000000;
  letter-spacing: -1.5;
  margin-bottom: 20px;
  line-height: inherit;
}

.apps_landing_header_wrapper h5 {
  padding: 5px 0 0;
  text-align: center;
  font-family: "Gordita-Medium";
  font-size: 13px;
  color: #555555;
  letter-spacing: -1.5;
  margin-bottom: 0px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #b1b1b1;
  border-radius: 6px 6px 0px 0px;
  border-bottom: none;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 25%);
}

.apps_landing_cards_wrapper ul li span {
  padding: 0px;
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 16px;
  color: #333333;
  margin-left: 8px;
}

.apps_landing_cards_wrapper ul li span a:hover {
  color: #2e6dfa !important;
  text-decoration: none;
}

.landing_content_p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  letter-spacing: -1.5;
  padding: 0px 12%;
  padding-top: 30px;
  text-align: center !important;
  width: 100%;
  margin-bottom: 0px;
}

.apps_landing_cards_wrapper ul li img {
  width: 35px;
  height: 55px;
}

.apps_landing_cards_wrapper ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.apps_landing_cards_wrapper ul li {
  list-style: none;
}

.apps_landing_cards_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.apps_landing_cards_wrapper {
  width: 100%;
  float: left;
  background: #fff;
  padding: 10px 20px;
  box-shadow: 10px 10px 20px #00000040;
  border: 1px solid #b1b1b1;
  border-radius: 0px 0px 6px 6px;
}

.apps_landing_cards_wrapper .col-lg-3 {
  flex: 0 0 23%;
  max-width: 23%;
  padding: 0px;
}

.footer_landing {
  width: 100%;
  float: left;
  background: #f2f2f2;
}

.footer_landing img {
  width: 140px;
  height: 100%;
}

.footer_landing p {
  width: auto;
  float: left;
  font-weight: normal;
  color: rgb(78, 82, 87);
  display: block;
  font-size: 13px;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
}

.footer_landing .--footer-border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}

/* .landing_bg.container_trial.integrations_container .container{
  max-width:90%;
} */

.form_update_plans {
  display: none;
}

.form_footer_plans {
  display: none;
}

.form_span_none {
  display: none;
}

.landing_bg .form_update_plans {
  width: 100%;
  float: left;
  padding-top: 45px;
  margin-bottom: 0px;
  font-family: "Roboto-Regular";
  font-size: 16px !important;
  color: #273237 !important;
  display: block;
}

.landing_bg .form_span_none {
  display: inline-block;
}

.landing_bg .center_image_home {
  background: #fff;
  margin-bottom: 0px;
}

.landing_bg .center_image_home .main-slider-left img {
  filter: inherit;
}

.center_image_home.cards_landing_page .trial_cards_page {
  margin-bottom: 0px;
}

.landing_bg .footer_form .form_update_plans {
  display: none;
}

.landing_bg .form_below_content span {
  font-family: "Roboto-Regular";
  font-size: 15px;
  color: #333333;
  margin-right: 30px;
}

.bg_landing_green p:last-child {
  color: #333333;
}

.bg_landing_yellow p:last-child {
  color: #333333;
}

.container_trial .bg_landing_white .second_p {
  color: #273237 !important;
}

.mobile_menu_page {
  display: none;
}

.landing_bg .bg_header_trial.trial_mb_80 {
  margin-bottom: 60px !important;
}

.landing_bg .center_image_home {
  padding-bottom: 60px;
}

.landing_bg .col-md-6.feature_page_content_left span {
  width: auto;
  display: inline-block;
}

.landing_bg .col-md-6.feature_page_content_left span a {
  padding-top: 0px;
  line-height: inherit;
  width: auto;
  float: inherit;
  font-family: "Roboto-Regular";
  font-size: inherit;
}

.landing_bg button:focus {
  outline: none;
}

.landing_bg .trial_page_img.bg_trial_page {
  padding: 60px 0px;
  margin-bottom: 60px;
}

.landing_bg .features_img_left {
  padding-bottom: 60px;
}

.landing_bg .footer_form {
  margin-bottom: 60px;
}

.landing_bg .footer_landing {
  width: 100%;
  float: left;
  background: #f2f2f2;
  padding: 25px 0px;
  display: flex;
  align-items: center;
}

.landing_bg .footer_landing_row {
  display: flex;
  justify-content: space-between;
}

.vertical_tabs {
  width: 100%;
  float: left;
}

.landing_bg.bg_header_trial.trial_mb_80 {
  padding-top: 140px;
  padding-bottom: 60px;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 48px !important;
  border: none !important;
  background: #2c7357 !important;
  z-index: -1;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
  padding: 0px 20px !important;
  color: #fff;
  cursor: pointer;
}

.rwt__tab {
  border-radius: 10px 0px 0px 10px;
  min-width: 210px;
  max-width: 230px;
  background: #ededed !important;
  padding: 0px 30px !important;
  height: 48px;
  text-align: left;
  font-size: 16px !important;
  font-family: "Roboto-Regular";
  z-index: 1;
  color: #333333;
  cursor: pointer;
}

.rwt__tab[aria-selected="true"] {
  color: #fff !important;
}

.rwt__tablist[aria-orientation="vertical"] {
  border: none !important;
  margin-right: 25px !important;
  justify-content: space-evenly;
}

.rwt__tab:hover {
  background: #2c7357 !important;
  color: #fff !important;
}

/* slider css */

.slider_wrapper {
  width: 100%;
  float: left;
  display: none;
}

.slider_container {
  width: 100%;
  height: auto;
}

.landing_bg .slider_container img {
  width: 100%;
  height: auto;
  padding: 0px 30px;
}

.controls_wrapper {
  background: #2c7357;
  color: #fff;
  justify-content: space-between;
  padding: 0px 15px;
  height: 48px;
  display: flex;
  align-items: center;
  font-family: "Roboto-Medium";
  font-size: 18px;
  margin-top: 25px;
  width: 100%;
  float: left;
}

.controls_wrapper svg {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

/*it_support_chatbot layout */

.it_support_chatbot {
  width: 100%;
  float: left;
}

.it_support_chatbot .landing_bg.bg_header_trial.trial_mb_80 {
  padding-top: 30px;
  margin-bottom: 0px !important;
}

.bg_fff {
  background: #f8f8f8;
}

.it_support_chatbot .js-scroll-trigger span {
  width: auto;
  list-style: none;
  font-size: 20px;
  font-family: "Roboto-Medium";
  display: inline-block;
  color: rgb(51, 51, 51);
  position: relative;
}

.it_support_chatbot .trial_page_img.bg_landing_white {
  background: #fff;
  margin-bottom: 0px;
}

.it_support_chatbot .header_landing.sticky .landing_bg {
  background: #fff;
  height: 80px;
}

.it_support_chatbot .mob_sticky_wrapper {
  width: 100%;
  float: left;
  position: relative;
  top: 20px;
}

.it_support_chatbot .top_menu_sticky_fixed .mob_sticky_wrapper {
  top: 80px;
  background: #fff;
  position: fixed;
}

.it_support_chatbot .mob_sticky_wrapper .dropdown.wv-dropdown {
  margin-bottom: 10px;
}

.it_support_chatbot .center_image_home.cards_landing_page span a {
  text-decoration: underline;
}

#chatbot .second_p {
  color: #273237 !important;
}

.it_support_chatbot .navbar-brand.js-scroll-trigger {
  display: none;
}

.it_support_chatbot .header_landing.header.sticky .navbar-brand.js-scroll-trigger {
  display: block;
}

.horizontal_slider .rwt__tabs[data-rwt-vertical="true"] {
  display: flex;
  flex-direction: column;
}

.horizontal_slider .rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.horizontal_slider .rwt__tab {
  border-radius: 10px;
  min-width: 31.33%;
  text-align: center;
  padding: 0px 15px !important;
}

.horizontal_slider .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  border-radius: 10px;
}

.header_content {
  text-align: left;
  font-family: "Roboto-Bold";
  font-size: 42px;
  color: #333333;
  margin-bottom: 30px;
  width: 100%;
  float: left;
  padding: 0px 15px;
}

.it_support_chatbot.ai-self-service-chatbot .pl-10-tage .feature_page_content_left.align_top {
  justify-content: flex-start;
}

.it_support_chatbot.ai-self-service-chatbot .landing_page.trial_page_img .feature_page_content_left.align_top h3 {
  font-size: 32px;
}

.it_support_chatbot.ai-self-service-chatbot .landing_bg .slider_container .chat_channels_trial_btn img {
  width: 64px;
  height: 64px;
  padding: 0px;
}

.it_support_chatbot.ai-self-service-chatbot .trial_cards_page {
  margin-bottom: 40px;
}

.it_support_chatbot.ai-self-service-chatbot .controls_wrapper {
  padding: 0px 10px;
  font-size: 14px;
}

.it_support_chatbot .desktop_menu_page {
  padding-top: 10px;
}

.it_support_chatbot .header_landing.header.sticky .desktop_menu_page {
  padding-top: 0px;
}

.desktop_menu_page .navbar-nav.ml-auto button {
  width: auto !important;
  background: inherit !important;
  padding: 0px !important;
  height: inherit;
  border-radius: 0px;
  min-width: auto;
  max-width: max-content;
  font-size: inherit !important;
}

.desktop_menu_page .navbar-nav.ml-auto button.rwt__tab[aria-selected="true"] {
  color: #2468f6 !important;
}

.desktop_menu_page .navbar-nav.ml-auto .rwt__tab:hover {
  color: #2468f6 !important;
}

.navbar-nav.ml-auto li:hover {
  color: #2468f6 !important;
}

.landing_page_chatbot .form_below_content.form_span_none {
  display: flex;
  justify-content: space-between;
}

.landing_page_chatbot .form_below_content span {
  margin-right: 0px;
}

.landing_page_chatbot .form_below_content.form_span_none {
  display: flex;
}

.landing_page_chatbot .footer_form_container .footer_form_left p.form_footer_plans {
  width: 100%;
  float: left;
  padding-top: 0px;
  margin-bottom: 0px;
  font-family: "Roboto-Regular";
  font-size: 16px !important;
  color: #193a5d !important;
  display: block;
  padding-right: 0px;
}

.landing_page_chatbot .assistant_slider .assistant_right_img img {
  filter: inherit;
  width: 100%;
}

/* .landing_page_chatbot .btn.btn-primary {
  display: none;
}
.btn.btn-primary.btn-chatbot {
  display: none;
} */

.landing_page_chatbot .btn.btn-primary.btn-chatbot {
  display: block;
}

.landing_page_chatbot .navbar-brand.js-scroll-trigger {
  cursor: default;
}

.landing_page_chatbot .desktop_tabs {
  width: 100%;
  display: flex;
}

.landing_page_chatbot .feature_page_content_left .button_list_li {
  width: auto;
  display: inline-block;
}

.landing_page_chatbot .feature_page_content_left .rwt__tab {
  border-radius: 0px;
  min-width: auto;
  max-width: auto;
  width: auto !important;
  background: inherit !important;
  padding: 0px !important;
  height: auto;
  text-align: left;
  font-size: 16px !important;
  font-family: "Roboto-Regular";
  z-index: 1;
  color: #2e6dfa !important;
  cursor: pointer;
  display: contents;
  text-decoration: underline;
}

.space_left {
  padding-right: 3px;
  position: relative;
  left: -3px;
}

.pad_content {
  padding-right: 3px;
}

.button_list_li:last-child .space_left.last_child {
  display: none;
}

.form_below_content.form_span_none {
  display: none !important;
}

.landing_page_chatbot .form_below_content.form_span_none {
  display: flex !important;
  justify-content: flex-start;
}

.landing_page_chatbot .footer_form_right .form_below_content.form_span_none {
  justify-content: flex-start;
}

.landing_page_chatbot .footer_form_right .form_below_content.form_span_none span {
  margin-right: 25px;
}

.landing_page_chatbot .footer_form_right .form_below_content.form_span_none span:last-child {
  margin-right: 0px;
}

.ai-self-service-chatbot.it_support_chatbot .center_image_home {
  padding-bottom: 0px;
}

.ai-self-service-chatbot.it_support_chatbot #marketplace {
  padding-bottom: 0;
}

.landing_page_chatbot .form_below_content span {
  margin-right: 25px;
}

.landing_page_chatbot .footer_form_container {
  padding: 40px 20px !important;
}

/*  */

.feature_inner_page .bg_feature_slider .main-slider-left .basic_plans {
  display: none !important;
}

.center_feature_wrapper {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  padding: 0px 20px;
}

.center_feature_wrapper h2 {
  font-size: 42px;
  font-family: "Gordita-Medium";
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 10px;
}

.center_feature_wrapper p {
  text-align: center;
}

.bg_conversation_ai .trial_page_img.bg_trial_page {
  background: #f8f8f8;
  padding-top: 0px;
  padding-bottom: 40px;
  margin-bottom: 0px;
}

.bg_conversation_ai {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.bg_conversation_ai .center_feature_wrapper h2 {
  margin-top: 40px;
}

.container_trial .center_features_header p {
  padding-bottom: 20px;
}

.home_page_sign {
  background: rgb(46, 109, 250);
  color: rgb(255, 255, 255);
  border-radius: 0px;
  width: auto;
  text-align: center;
  height: 38px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: transform 0.5s ease;
  font-size: 14px;
  font-family: "Roboto-Regular";
  padding: 0px 12px;
  border: 1px solid rgb(46, 109, 250);
}

.home_page_sign:hover {
  transform: scale(1.012);
  background: rgb(46, 109, 250);
  color: rgb(255, 255, 255);
  line-height: inherit;
  text-decoration: none;
  outline: none;
  border: 1px solid rgb(46, 109, 250);
}

.home_page_sign:focus {
  outline: none;
}

.homepage_wrapper {
  width: 100%;
  display: flex;
}

.homepage_wrapper .trial_header_left {
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card_landing_trial .href_link {
  text-decoration: underline;
  color: #2e6dfa;
  width: auto;
}

.card_landing_trial .href_link:hover {
  color: #2e6dfa;
}

.cards_soon_cc {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 40px;
}

.cards_soon_cc .coming_soon_cc {
  background: #e6ffe0;
  color: #1aa75b;
  padding: 12px 25px;
  font-size: 14px;
  font-family: "Roboto-Medium";
}

.center_image_home_cc {
  width: 100%;
  float: left;
  margin-top: 12px;
}

.center_image_home_cc h6 {
  padding: 0px;
  text-align: center;
  font-family: "Roboto-Bold";
  font-size: 22px;
  color: #333333;
  margin-top: 40px;
  margin-bottom: 30px;
}

.center_image_home_cc .cc_card_1 h5 {
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #333333;
  padding-bottom: 12px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.center_image_home_cc .cc_card_1 p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  color: #416183;
  padding-bottom: 0px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  line-height: inherit;
}

.center_image_home_cc .cc_card_1 {
  max-width: 48%;
  float: left;
  /* background: #F1FAF4 0% 0% no-repeat padding-box; */
  border-radius: 20px;
  padding: 30px;
}

.center_image_home_cc .cc_card_1.cc_card_2 {
  /* background: #FBF9F4 0% 0% no-repeat padding-box; */
}

.cards_cc_card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bg_p_wihout_padding a {
  text-decoration: none;
  color: #2468f6;
}

.bg_p_wihout_padding a:hover {
  text-decoration: none;
  color: #2468f6;
}

.bg_p.bg_p_wihout_padding a {
  color: #fff;
}

.logs_wrapper {
  width: 100%;
  float: left;
  display: block;
}

.onprem_connect .bg_conversation_ai .trial_page_img.bg_trial_page {
  padding-top: 40px;
}

.roi-form.roi-form-demo h1 {
  width: auto;
  margin-bottom: 30px;
  font-size: 18px;
  font-family: "Roboto-Regular";
}

.roi_bg {
  background: #e8eeff;
}

.feature_page_img_right.single_iamge_wrapper img {
  width: 80%;
}

/* .cards_features.bg_fff {
    padding-top: 0px;
} */

.isfooter_form {
  width: 100%;
  float: left;
  margin-top: 40px;
}

.trial_page_img.bg_trial_page.bg_transparent {
  background: transparent linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%) 0% 0% no-repeat padding-box;
}

.bg_feature {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.trial_page_img.bg_assist_grey {
  background: transparent linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%) 0% 0% no-repeat padding-box;
}

.trial_page_img.bg_assist_white {
  background: #fff;
  margin-bottom: 0px;
}

.apps_landing_page.apps_agent_page {
  margin-bottom: 40px;
}

.wrapper_page .mb_footer {
  width: 100%;
  float: left;
  margin-top: 40px;
}

.security_wrapper._wrapper_support .card__image {
  text-align: left;
}

.security_wrapper._wrapper_support .whitepaper_cards .card__title {
  text-align: left;
}

.security_wrapper._wrapper_support .whitepaper_cards .card__text {
  text-align: left;
}

.security_wrapper._wrapper_support .whitepaper_cards .cards {
  justify-content: space-between;
}

.security_wrapper._wrapper_support .whitepaper_cards .cards__item {
  width: 30.3333%;
  padding: 0px;
}

.security_wrapper._wrapper_support h4 {
  font-size: 34px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Medium";
  margin-bottom: 30px;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(225deg) !important;
}

.accordion__button:before {
  transform: rotate(45deg) !important;
}

.mob_accordian {
  width: 100%;
  float: left;
  display: none;
}

.mob_accordian h4 {
  font-size: 22px;
  text-align: left;
  color: #333333;
  font-family: "Roboto-Medium";
  margin-bottom: 10px;
  padding: 0px 20px;
  padding-top: 20px;
}

.mob_accordian p {
  margin-bottom: 0px;
}

.accordion__button {
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #000000;
}

.mob_accordian .accordion__button {
  background-color: inherit;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 98%;
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.mob_accordian .accordion__panel {
  padding-top: 0px;
  padding-bottom: 15px;
}

.mob_accordian .accordion {
  border: none;
}

.accordion__item+.accordion__item {
  border-top: none !important;
}

.accordion__button[aria-expanded="true"] {
  color: #0a89e8 !important;
}

.mob_accordian .bg_grey {
  background: #f8f8f8;
  padding: 20px 0px;
}

.mob_accordian .bg_white {
  padding: 20px 0px;
}

.benefits-workplace-content.cogni_workspace_content h4 {
  min-height: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
  font-family: "Gordita-Medium";
  line-height: inherit;
}

.top_section.pricing_page {
  background: #edf1ff 0% 0% no-repeat padding-box;
  position: relative;
  top: -1px;
}

.top_section.pricing_page._assistant_pricing {
  background: none;
  padding-top: 20px;
  padding-bottom: 0px;
}

.top_section.pricing_page._assistant_pricing .form_below_content {
  margin-top: 0px;
}

.top_section.pricing_page._assistant_pricing:before {
  content: "";
  position: absolute;
  background: #edf1ff 0% 0% no-repeat padding-box;
  width: 100%;
  height: calc(74% - 148px);
  top: 0;
  margin-top: -80px;
}

.full_comaparision._assistant_pricing .show_button_full_comaparison button {
  width: 300px;
  height: 48px;
  border: 1px solid #2468f6;
  border-radius: 24px;
}

.top_section.pricing_page .main-slider-left {
  padding: 0px 10%;
}

.top_section.pricing_page .form_below_content {
  width: 100%;
  float: left;
  padding-top: 0px;
  position: relative;
  top: 0px;
  display: flex;
  justify-content: center;
}

.top_section.pricing_page .form_below_content span {
  margin-right: 40px;
  color: #333333;
  font-family: "Gordita-Medium";
}

.top_section.pricing_page .form_below_content span:last-child {
  margin-right: 0px;
}

.top_section.pricing_page p {
  color: #193a5d;
}

.pricing-columns.pricing-section {
  position: relative;
}

.table_full_comapare {
  border: 1px solid #d5d5d5;
  border-radius: 10px;
}

.table_full_comapare .table-bordered {
  border: 1px solid #dee2e6;
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  overflow: auto;
  margin-bottom: 0px;
}

.pricing-columns.pricing-section {
  position: relative;
  top: -1px;
}

.pricing-columns.pricing-section .assistant_pricing:before {
  background-image: url("../images/pricing/pricing_header.png");
  height: 360px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.top_section.pricing_page {
  padding-bottom: 30px;
  margin-bottom: 0px;
}

.pricing-columns .pricing-chart,
.pricing-columns-both .pricing-chart {
  margin-top: 0px;
}

.feature_card_content {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  width: 100%;
}

.cards_features_header_div {
  display: flex;
  flex-direction: column;
}

.cards_features .whitepaper_cards .card__image img {
  width: 64px;
  height: 64px;
  border-radius: 0px;
  margin-bottom: 20px;
}

.cards_features .whitepaper_cards .card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-family: "Gordita-Regular";
  margin-bottom: 0px;
  padding: 0;
  font-size: 14px;
  border: none;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cards_features .whitepaper_cards .card {
  background: inherit;
}

.cards_features .whitepaper_cards .card__title {
  font-family: "Gordita-Medium";
  font-size: 20px;
  color: #333333;
  padding-bottom: 10px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  line-height: initial;
}

.cards_features .cards__item .card_link_landing a {
  font-size: 15px;
  font-weight: 300;
  font-family: "Gordita-Medium";
  margin-bottom: 0px;
}

.cards_features .whitepaper_cards .cards__item {
  display: flex;
  padding: 20px;
}

.cards_features .whitepaper_cards .cards__item:nth-child(1) {
  padding-left: 0px;
}

.cards_features .whitepaper_cards .cards__item:nth-child(3) {
  padding-right: 0px;
}

.cards_features .whitepaper_cards .cards__item:nth-child(4) {
  padding-left: 0px;
}

.cards_features .whitepaper_cards .cards__item:nth-child(6) {
  padding-right: 0px;
}

.cards_features .whitepaper_cards .cards__item:nth-child(7) {
  padding-left: 15px;
}

.cards_features .whitepaper_cards .card__text {
  margin-bottom: 15px;
  font-size: 22px;
}

.container_trial.integrations_container .bg_feature_slider.bg_feature_slider_wrapper {
  background: inherit;
  position: relative;
  margin-bottom: 40px;
  top: -1px;
}
.container_trial.integrations_container .bg_feature_slider.bg_feature_slider_wrapper.features_indvidual_dec{
  margin-bottom: 30px;
}

/* .bg_feature_slider.bg_feature_slider_wrapper .feature_bg_img:before{
  height: calc(100vh - 20vh);
} */

.feature_page_content_left h3 {
  text-align: left;
  font-family: "Gordita-Medium";
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 0px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.feature_page_content_left h2 {
  text-align: left;
  font-family: "Roboto-Medium";
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 0px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.build_needs .center_features_header {
  padding: 0px 0% !important;
}

.center_image_home_cc .main-slider-left .cc_para_1 {
  margin-bottom: 15px;
}

.center_image_home_cc .main-slider-left .cc_para_2 {
  margin-bottom: 15px;
}

.center_image_home_cc .main-slider-left .cc_para_3 {
  margin-bottom: 10px;
}

.cards_features .whitepaper_cards {
  margin-bottom: 20px;
}

.container_trial p {
  color: #193a5d;
  font-size: 22px;
}

.cards_features .cards__item .card_link_landing a {
  font-size: 18px;
}

.apps_landing_page .apps_landing_cards_section .apps_landing_cards {
  padding: 0px 10px;
}

.back_features_hide.cc_teams_wrapper .feature_inner_page.bg_feature_slider .main-slider-left {
  width: 100%;
  float: left;
  padding: 0px;
  text-align: center;
  margin-top: 20px;
}

.back_features_hide.cc_teams_wrapper .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
  width: 100%;
  float: left;
  padding: 0px 26%;
}

.trial_header_left h1 {
  font-family: "Gordita-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5;
}

.cc_teams_wrapper.cc_slack_wrapper p {
  padding: 0px 15% !important;
}

.mobile_h2 {
  display: none;
}

/* autoamte css */

.navbar_right .autoamte_header_top {
  top: 0px !important;
}

.navbar_right .autoamate_demo button {
  background: #b5ffe3 0% 0% no-repeat padding-box;
  border: 1px solid #15c381;
  border-radius: 4px;
  color: #000;
}

.footer_menu.coming_soon_center {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  float: left;
}

.footer_menu.coming_soon_center .coming_soon_wrapper {
  background: #fff2e6 0% 0% no-repeat padding-box;
  color: #ff7900;
  font-size: 18px;
  font-family: "Roboto-Medium";
  width: -webkit-max-content;
  width: max-content;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  top: 0;
  margin-top: 10px;
}

.footer_right.automate_footer {
  width: 100%;
  float: left;
  position: relative;
}

.footer_right.automate_footer .footerlogo_left {
  position: absolute;
  bottom: 0;
}

.container-fluid.autoamte_apps {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.container-fluid.autoamte_apps .apps_links_home {
  display: none;
}

.workflow_autoamte_wrapper .container-fluid.autoamte_apps .marketplace_integrations_icons {
  padding-bottom: 20px;
}

.footer_right.automate_footer:nth-child(2):before {
  content: "";
  border-right: 1px solid #999;
  position: absolute;
  left: -20px;
  height: 100%;
}

.footer_right.automate_footer:nth-child(2) {
  position: relative;
}

.autoamte_img img {
  padding-top: 0px;
}

/* .footer_right.automate_footer:nth-child(3) {
  display: flex;
  align-items: center;
} */

/* .footer_right.automate_footer:nth-child(3) .footer_menu.coming_soon_center {
  position: absolute;
  left: 65%;
  display: none;
} */

/* .footer_right.automate_footer:nth-child(3) .wrapperlink_autoamte {
  display: none;
}
.footer_right.automate_footer:nth-child(4) .wrapperlink_autoamte {
  display: none;
}
.footer_right.automate_footer:nth-child(5) .wrapperlink_autoamte {
  display: none;
}
.footer_right.automate_footer:nth-child(6) .wrapperlink_autoamte {
  display: none;
} */

.autoamte_img .autoamte_imglogo {
  padding-top: 0px !important;
}

.feature_pageinner_content_img.m_b_80 {
  position: relative;
}

/* .feature_pageinner_content_img.m_b_80 .feature_bg_img:before{
 display:none;
} */

.rwt__tablist.horizontal_slider {
  display: flex;
  flex-direction: row !important;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
  justify-content: space-between;
}

.landing_page_chatbot.ai_cards_service {
  padding-top: 40px;
}

.features_img_left .button_list_li .rwt__tab {
  font-size: 22px !important;
}

.landing_page_chatbot.ai_cards_service .desktop_tabs {
  justify-content: space-between;
}

.benefits-workplace-content.cogni_workspace_content p {
  color: #416183;
}

.roi_ul_wrapper ul li {
  font-size: 22px;
  color: #000000;
}

.cc_bg_feature_slider.trial_page_img .feature_bg_img:before {
  height: 40%;
}

.feature_pageinner_content_img .feature_bg_img {
  display: none;
}

.feature_page_content_left .button_list_li a {
  text-decoration: none;
  float: inherit;
}

.mobile_tab_list {
  display: none;
}

.inter_slider_assist {
  width: 100%;
  float: left;
}

.inter_slider_assist .header.res_header {
  background: #f8f8f8;
}

.inter_slider_assist .top_header.header.res_header {
  background: #fff;
}

.inter_slider_assist .landing_page_chatbot {
  padding-top: 40px;
}

.container_trial .inter_slider_assist .basic_plans {
  color: #000000;
}

.inter_slider_assist .feature_page_content_left p {
  color: #416183;
}

.inter_slider_assist .feature_page_content_right p {
  color: #416183;
}

.inter_slider_assist .feature_page_content_right p.second_p {
  color: #000000;
}

.inter_slider_assist .feature_page_content_left p.second_p {
  color: #000000;
}

.inter_slider_assist .card_landing_section.integrations_popular_bg {
  max-width: 31.5%;
  flex: 0 0 31.5%;
}

.inter_slider_assist .grid_card_trial .row .card_landing_section:nth-child(1) {
  margin-right: 30px;
}

.inter_slider_assist .grid_card_trial .row .card_landing_section:nth-child(2) {
  margin-right: 30px;
}

.container_trial .header_menu p {
  line-height: 1.4;
}

.inter_slider_assist .button_list_li:last-child .space_left.last_child {
  display: inherit;
  left: 0px;
  padding-right: 0px;
  position: relative;
}

.button_form_automate_popup button.autoamte_button {
  background-color: #2468F6 !important;
  border-color: #2468F6 !important;
}

.button_form_automate_popup button.autoamte_button:active {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.beta-button button.autoamte_button {
  background: #b5ffe3 0% 0% no-repeat padding-box;
  border: 1px solid #15c381;
}

.padding-integrations-automate {
  padding: 50px 0 !important;
}

.automate_homepage .footer_form_container {
  background: #e7fdf4;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.iso-section-automate {
  width: 100%;
  margin-bottom: 40px;
}

.automate_homepage .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
  color: #33906b !important;
}

.automate_homepage .grid_card_trial .row {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(30.33%, 1fr));
  grid-gap: 40px;
  float: left;
}

.automate_homepage .card_landing_trial img {
  width: 100px;
  height: 100px;
}

.automate_homepage .center_image_home.cards_landing_page .main-slider-left {
  width: 100%;
  margin-bottom: 10px;
}

.automate_homepage .grid_card_trial .row .card_landing_section {
  max-width: 100%;
  flex: 0 0 100%;
  border-radius: 20px;
  border: 1px solid #67a2cc;
  margin-bottom: 0px;
}

.automate_homepage .grid_card_trial .row .card_landing_section:nth-child(1) {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.automate_homepage .grid_card_trial .row .card_landing_section:nth-child(2) {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.automate_homepage .card_landing_trial img {
  margin-bottom: 12px;
}

.automate_homepage .card_landing_trial {
  width: 100%;
  float: left;
  padding: 50px;
}

.automate_homepage .how_it_section.how_it_page_content {
  background: #fff;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 60px;
}

.automate_homepage .center_image_home {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  padding-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 60px;
}

.bg_header_trial.trial_mb_80.bg_header_automation {
  margin-bottom: 60px !important;
}

.automate_homepage .how_it_section h1 {
  margin-bottom: 20px;
}

.tabgroup_wrapper {
  width: 100%;
  float: left;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 60px;
}

.automate_homepage .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:before {
  background: url("../automate/minus.svg");
  width: 22px;
  height: 22px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  left: 0;
  top: 23px !important;
}

.automate_homepage .mob_accordian.mob_accordian_faq .accordion__button {
  font-size: 24px;
}

.automate_homepage .how_it_content_before:before {
  left: 105%;
}

.tabgroup_wrapper h2 {
  font-family: "Roboto-Medium";
  font-size: 42px;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}

.how_it_section.how_it_page_content .main-slider-left h2 {
  font-family: "Roboto-Medium";
  font-size: 42px;
  color: #000000;
  margin-bottom: 30px;
  text-align: center;
}

.automate_homepage .center_image_home .main-slider-left h3 {
  margin-bottom: 20px;
}

.automate_homepage .main-slider-button.beta-button form {
  text-align: center;
  justify-content: center;
}

.automate_homepage .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
  width: 100%;
  float: left;
}

.automate_homepage .workflow_automation_wrapper.trigger_page .trial_header_left {
  width: 100%;
}

.automate_homepage .main-slider-button.beta-button {
  width: 100%;
}

.automate_homepage .footer_form_right {
  display: grid;
}

.automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:before {
  right: 7px;
  left: inherit;
  background: url("../automate/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
}

.automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq .accordion__button:before {
  right: 7px;
  left: inherit;
  background: url("../automate/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(-90deg) !important;
}

.automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
  color: #000 !important;
  background: #f2fffa 0% 0% no-repeat padding-box !important;
  border: none;
  padding-bottom: 3px;
}

.automate_homepage .mobile_view_tab .mob_accordian .accordion__panel {
  background: #f2fffa 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 25px;
}

.automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq .container {
  padding: 0px;
}

.automate_homepage .mobile_view_tab .mob_accordian .accordion__button {
  width: 100%;
  padding-left: 15px;
}

.mobile_view_tab .mob_accordian.mob_accordian_faq p {
  color: #416183;
  margin-bottom: 12px;
  padding-left: 0px;
}

.automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq .accordion__button {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Roboto-Medium";
}

.mobile_view_tab .mob_accordian.mob_accordian_faq .accordion.pl-0:last-child .accordion__item {
  border: none;
}

.mobile_view_tab {
  display: none;
}

.container_trial .automate_homepage .mobile_view_tab .cta_autoamte {
  margin-bottom: 0px;
}

.automate_homepage .col-12.grid_card_trial {
  display: grid;
}

.automate_homepage .mob_accordian.mob_accordian_faq .container h2 {
  text-align: center;
}

.automate_homepage .center_image_home.cards_landing_page .card_landing_section p {
  color: #416183;
}

.automate_homepage .header.res_header.bg_header_automation .navbarlogo_img .url_manipulation img {
  width: 220px !important;
}

.container_trial .automate_homepage .modal_submit_form .main-slider-left p {
  padding-right: 0px;
}

.beta-button button.autoamte_button:active {
  background: #b5ffe3 0 0 no-repeat padding-box;
  border: 1px solid #15c381;
  color: #000;
}

.container_trial .automate_homepage .footer_form_left .main-slider-left p {
  padding-right: 100px;
}

.container_trial .main-slider-left .modal-content.modal-content-popup .thankyou-page-img p {
  padding-right: 0px;
}

.tabgroup_bg .tabgroup_wrapper {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding-top: 60px;
  padding-bottom: 0px;
}

.workflow_autoamte_wrapper .marketplace_integrations_icons {
  padding: 60px 40px;
  padding-bottom: 20px;
}

.tabgroup_bg_white .col-lg-6.col-md-6.col-12.pl-0 {
  align-items: center;
  height: 100%;
  align-self: center;
}

.tabgroup_bg_white .tabgroup_wrapper {
  background: #fff;
  margin-bottom: 0px;
}

.automate_demo .roi_bg {
  background: #f2fffa;
}

.automate_demo .sign_up-form_us.demo_page h1 {
  color: #000;
}

.automate_demo .sign_up-form_us.demo_page h6 {
  color: #000;
}

.automate_demo .row.beta_change_form ul li {
  color: #000;
}

.automate_demo .sign_up-form_us.demo_page {
  margin-bottom: 40px !important;
}

.automate_homepage.automate_homepage .main-slider-button.beta-button form {
  justify-content: left;
}

.automate_homepage.automate_homepage_home .form_below_content {
  display: none;
}

.automate_homepage.automate_homepage_home .footer_form_container .form_below_content {
  display: flex;
  width: 100%;
}

.footer_form_container .form_below_content {
  display: flex;
  width: 100%;
}

.col-lg-6.trial_header_left {
  max-width: 52%;
  flex: 0 0 52%;
}

.col-lg-6.cont_right {
  max-width: 48%;
  flex: 0 0 48%;
  align-items: center;
  display: flex;
}

.assistant_slider .row {
  justify-content: space-between;
}

.automate_center_wrapper .bg_feature {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.automate_center_wrapper .feature_bg_img:before {
  background: url("../automate/header_bg.png");
}

.image_tabgroup img {
  -webkit-filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
  width: 100%;
  height: 100%;
}

.container_trial .automate_triggers_center .page_header_center p {
  padding: 0px 12%;
  text-align: center;
  margin-bottom: 40px;
}

.container_trial .automate_triggers_center .page_header_center h1 {
  padding: 0px 12%;
  text-align: center;
  margin-bottom: 10px;
}

.container_trial .automate_homepage .page_header_center h1 {
  padding: 0px 12%;
  text-align: center;
  margin-bottom: 10px;
}

.automate_homepage .page_header_center {
  margin-bottom: 50px;
}

.container_trial.integrations_container .automate_homepage .bg_feature_slider.bg_feature_slider_wrapper {
  margin-bottom: 60px;
}

.automate_center_wrapper .center_image_home {
  width: 100%;
  float: left;
  padding-bottom: 40px;
  margin-bottom: 0px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 40px 0px;
  margin-bottom: 40px;
}

.automate_tabslider_wrapper .trial_page_img.bg_landing_white_autoamte {
  margin-top: 40px;
}

.container_trial .automate_tabslider_wrapper .feature_page_content_left .second_p {
  color: #000 !important;
}

.workflow_autoamte_wrapper .ahref-wrapper {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: "Roboto-Medium";
}

.tabgroup_wrapper h2 {
  justify-content: left;
  width: 100%;
}

.how_it_page_content.automate_homepage {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.how_it_section_bg.automate_homepage {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.automate_pricing .pricing-columns .pricing-chart .plan,
.automate_pricing .pricing-columns-both .pricing-chart .plan {
  width: 25%;
  transition: transform 0.2s;
}

.automate_pricing .pricing-columns .pricing-chart .plan a.sign-up,
.automate_pricing .pricing-columns-both .pricing-chart .plan a.sign-up {
  border: 1px solid #15c381;
  border-radius: 4px;
  color: #33906b;
  text-transform: inherit;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover,
.automate_pricing .pricing-columns-both .pricing-chart .plan:hover {
  background: #b5ffe3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #00000029;
  border: 1px solid #b5ffe3;
  -ms-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transition: transform 2s linear;
  z-index: 9;
  -webkit-transition: transform 2s;
  -ms-transition: transform 2s;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover ul li,
.automate_pricing .pricing-columns-both .pricing-chart .plan:hover ul li {
  color: #000;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover svg,
.automate_pricing .pricing-columns-both .pricing-chart .plan:hover svg {
  fill: #000;
  color: #000;
}

.automate_pricing .pricing-columns-both .pricing-chart .plan:hover a.sign-up,
.automate_pricing .pricing-columns .pricing-chart .plan:hover a.sign-up {
  background-color: #fff;
  border: 1px solid #fff;
  color: #33906b;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  transition-timing-function: linear;
}

.automate_pricing .mob_accordian.mob_accordian_faq p a {
  color: #33906b !important;
  text-decoration: none;
}

.automate_pricing .mob_accordian.mob_accordian_faq p a:hover {
  color: #33906b !important;
  text-decoration: none;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover .users_upto {
  color: #000;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover h4,
.automate_pricing .pricing-columns-both .pricing-chart .plan:hover h4 {
  color: #000;
}

.automate_pricing .pricing-columns .pricing-chart .plan:hover ul li label,
.automate_pricing .pricing-columns-both .pricing-chart .plan:hover ul li label {
  border: 1px solid #33906b;
}

.automate_pricing .pricing-columns .pricing-chart .plan svg,
.automate_pricing .pricing-columns-both .pricing-chart .plan svg {
  width: 72px;
  margin-bottom: 12px;
  margin-top: 0px;
  height: 72px;
  fill: #15c381;
}

.automate_pricing .pricing-columns .pricing-chart .plan ul li svg,
.automate_pricing .pricing-columns-both .pricing-chart .plan ul li svg {
  width: 14px;
  height: 14px;
  color: #b7b7b7;
  margin-bottom: 0;
  margin-left: 5px;
  cursor: pointer;
}

.automate_pricing .pricing-columns .pricing-chart .plan ul li label,
.automate_pricing .pricing-columns-both .pricing-chart .plan ul li label {
  border: 1px solid #b7b7b7;
}

.automate_pricing .show_button_full_comaparison button {
  border: 1px solid #15c381;
  border-radius: 24px;
  color: #15c381;
  text-transform: inherit;
  font-family: 'Gordita-Regular';
}

.automate_pricing .show_button_full_comaparison button:hover {
  background-color: #fff;
  border: 1px solid #15c381;
  color: #15c381 !important;
}

.automate_pricing .pricing-columns.pricing-section .assistant_pricing::before {
  background-image: url("../automate/header_bg.png");
}

.automate_pricing .top_section.pricing_page {
  background: none;
}

.automate_pricing .bg_feature {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.automate_pricing thead .tr_bg {
  background: #f2fffa 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
}

.table_full_comapare .table thead tr th:nth-child(1) {
  border-top-left-radius: 9px;
  background: #edf1ff;
}

.table_full_comapare .table thead tr th:nth-child(6) {
  border-top-right-radius: 9px;
  background: #edf1ff;
}

.automate_pricing .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
  color: #33906b !important;
  padding-bottom: 10px;
}

.automate_pricing .table_full_comapare .table thead tr th:nth-child(1) {
  border-top-left-radius: 9px;
  background: inherit;
}
.automate_pricing .table_full_comapare .table thead tr th:nth-child(4) {
  border-top-right-radius: 9px;
  background: inherit;
}

.automate_pricing .table_full_comapare .table thead tr th:nth-child(5) {
  border-top-right-radius: 0px;
  background: inherit;
}

.automate_pricing .table_full_comapare .table thead tr th:nth-child(6) {
  border-top-right-radius: 9px;
  background: inherit;
}

.automate_pricing .table_full_comapare .table thead tr th {
  background: #f2fffa;
}

.automate_pricing .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:before {
  background: url("../automate/minus.svg");
  width: 22px;
  height: 22px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  left: 0;
  top: 26px;
}

.automate_pricing .pricing-columns .pricing-chart .plan h4,
.automate_pricing .pricing-columns-both .pricing-chart .plan h4 {
  margin-bottom: 12px;
  text-transform: inherit;
  font-size: 20px;
}

.automate_pricing .mob_accordian.mob_accordian_faq .container h2 {
  font-family: "Roboto-Medium";
  text-align: center;
}

.automate_pricing .full_comaparision {
  margin-top: 40px;
}

.automate_pricing .show_button_full_comaparison button:focus {
  background-color: #fff;
  border: 1px solid #15c381;
  color: #15c381 !important;
}

.automate_pricing .table_full_comapare .table tbody tr td {
  vertical-align: middle;
}

.enterprise_wrapper {
  width: 100%;
  display: grid;
  padding-top: 18px;
}

.enterprise_wrapper_content {
  width: 100%;
  display: grid;
  grid-template-columns: 26% 48% 26%;
  justify-content: space-between;
  border: 1px solid #d5d5d5;
  padding: 20px 0px;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 5px 10px #00000029;
}

.enterprise_wrapper_left svg {
  width: 100px;
  height: auto;
  fill: rgb(21, 195, 129);
}

.enterprise_wrapper_center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}

.enterprise_wrapper_center h4 {
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
  font-family: "Roboto-Medium";
  margin-top: 0px;
  color: #333333;
}

.enterprise_wrapper_center p {
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
}

.enterprise_wrapper_left {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.enterprise_wrapper_left h6 {
  font-size: 18px;
  width: 100%;
  margin-top: 10px;
  font-family: "Roboto-Medium";
  text-align: center;
  margin-bottom: 0px;
}

.enterprise_wrapper_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
}

.enterprise_wrapper_right button {
  background: #fff;
  border: 1px solid rgb(21, 195, 129);
  border-radius: 4px;
  color: rgb(21, 195, 129);
  font-size: 18px;
  letter-spacing: 0;
  height: 44px;
  border-radius: 4px;
  text-transform: inherit;
  margin-bottom: 0px;
  width: 90%;
  padding: 0px 25px;
  font-family: "Gordita-Regular";
}

.enterprise_wrapper_right button a {
  color: #333333;
  text-decoration: none;
}

.enterprise_wrapper_content:hover {
  background: #defff2 0% 0% no-repeat padding-box;
  -ms-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transition: transform 2s linear;
  -webkit-transition: transform 2s;
  -ms-transition: transform 2s;
}

.enterprise_wrapper_content:hover .enterprise_wrapper_right button {
  background: #fff;
}

.enterprise_wrapper_content:hover .enterprise_wrapper_left svg {
  fill: #000000;
}

.automate_pricing .full_comaparision h1 {
  margin-top: 0px;
}

.bg_feature._assistant_pricing {
  background: #edf1ff;
}

.automate_pricing .top_section_bottom.pricing_page {
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.automate_homepage.workflow_autoamtion .bg_header_automation {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.automate_homepage.workflow_autoamtion .bg_header_trial.trial_mb_80.bg_header_automation {
  margin-bottom: 40px !important;
}

.pricing-columns .pricing-chart .plan:hover p a,
.pricing-columns-both .pricing-chart .plan:hover p a {
  color: #fff;
}

.enterprise_wrapper_center a {
  color: rgb(21, 195, 129);
  text-decoration: none;
  background-color: transparent;
}

.enterprise_wrapper_center a:hover {
  color: rgb(21, 195, 129);
  text-decoration: none;
  background-color: transparent;
}

.color-new-enterprise {
  color: #333333 !important;
}

.automate_product .navbar_right ul .sticky_pad_0:hover {
  color: #15c381 !important;
}

.automate_product .navbar_right ul li a:hover {
  color: #15c381 !important;
}

.automate_product .navbar_right .sticky_pad_0:hover svg {
  color: #15c381 !important;
  fill: #15c381 !important;
}

.automate_product .navbar_right ul .sticky_pad_0:hover .submenu_ul svg {
  color: #333 !important;
  fill: #333 !important;
}

.automate_product .navbar_right .sticky_pad_0:hover .submenu_ul svg {
  color: #333 !important;
  fill: #333 !important;
}

.automate_product .navbar_right ul .sticky_pad_0 .submenu_ul:hover svg {
  color: #15c381 !important;
  fill: #15c381 !important;
}

.automate_product .navbar_right ul li.sticky_pad_0 {
  top: 0px !important;
}

.automate_product .navbar_right ul {
  top: 7px !important;
}

.container_trial .para_2_pricing {
  width: 100%;
  margin-top: 12px;
}

.mob_accordian.mob_accordian_faq p a {
  text-decoration: none;
  color: #2468f6;
}

.mob_accordian.mob_accordian_faq p a:hover {
  text-decoration: none;
  color: #2468f6;
}

.container_trial p.second_p {
  color: #000;
}

.grid_card_trial .row {
  justify-content: space-between;
}

.inter_slider_assist .chat_channels_trial_btn {
  color: #333333;
}

.inter_slider_assist .grid_card_trial {
  width: 100%;
  display: grid;
}

.inter_slider_assist .grid_card_trial .row {
  display: flex;
  justify-content: space-between;
}

.inter_slider_assist .col-md-6.feature_page_content_left .coming_soon_wrapper {
  width: 100%;
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-bottom: 0px;
}

.inter_slider_assist .m-b-20 {
  margin-bottom: 20px;
  width: 100%;
  float: left;
}

/* Videos Page new Ui */

.videos_wrapper {
  width: 100%;
  float: left;
}

.videos_wrapper .footer_form {
  margin-bottom: 60px;
}

.videos_wrapper .main-slider-left .footer_form {
  margin-bottom: 40px;
}

.category_wrapper {
  width: 100%;
  float: left;
  margin-bottom: 60px;
  margin-top: 20px;
}

.videos_wrapper .beta-slider img {
  padding: 4em 0 !important;
}

.category_lsiting {
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 30px;
}

.category_wrapper h3 {
  color: #000;
  font-size: 42px;
  font-family: "Roboto-Medium";
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  float: left;
}

.category_lsiting_card {
  width: 100%;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0px;
  cursor: pointer;
}

.category_lsiting_card:hover {
  background: #eff4ff;
}

.category_lsiting_card img {
  width: 86px;
  height: 86px;
}

.category_lsiting_card h4 {
  font-size: 28px;
  width: 100%;
  margin-top: 10px;
  font-family: "Roboto-Medium";
  text-align: center;
  margin-bottom: 0px;
  color: #000;
}

.videos_container .select_work_form ul li.video_list_li_mob {
  font-size: 16px;
  width: 100%;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #000;
}

.videos_container .select_work_form h4 {
  font-family: "Seravek-Medium";
  font-size: 18px;
  color: #494949;
}

.category_lsiting_card h6 {
  font-size: 24px;
  width: 100%;
  margin-top: 5px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 0px;
  color: #e57700;
}

.videos_container .video_list_ul {
  grid-gap: 0px;
  grid-template-columns: 100%;
  border: 1px solid #c4c2c2;
  border-radius: 6px;
}

.video_list_ul_header {
  width: 100%;
  height: 60px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #c4c2c2;
  font-size: 22px;
  font-family: "Roboto-Medium";
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
}

.videos_container .video_list_ul li {
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
}

.videos_container .video_list_li {
  padding: 15px 0px 15px 20px;
  width: 100%;
  float: left;
}

.container_trial .videos_wrapper .main-slider-left h1 {
  padding-top: 4rem;
}

.videos_container .videos_submenu ul {
  padding-left: 20px;
}

.videos_submenu .active {
  color: #0a89e8 !important;
}

.videos_container .videos_submenu ul li {
  font-family: "Roboto-Regular";
  font-size: 18px;
  list-style: none;
  cursor: pointer;
  color: #000;
  line-height: 28px;
  width: 100%;
  float: left;
  padding: 5px 0px 5px 27px;
  display: flex;
  align-items: center;
  background: inherit;
  background: inherit;
}

.videos_container .videos_submenu ul li span {
  color: #e57700;
  margin-left: 3px;
}

.videos_container .video_svg_iocn {
  width: auto;
  margin: inherit;
}

.videos_container .video_svg_iocn svg {
  width: 24px;
  height: 24px;
}

.videos_container .video_content_li {
  width: auto;
  margin-top: 0px;
  margin-left: 10px;
  font-size: 20px;
}

.videos_container .video_content_li span {
  color: #e57700;
}

.videos_container .video_list_li.video_list_li_active .video_content_li {
  color: #0a89e8;
}

.videos_container .video_list_li.video_list_li_active .video_svg_iocn svg {
  fill: #0a89e8;
}

.videos_container .video_list_li.video_list_li_active {
  background: #eff4ff;
  border-radius: 0px;
  border: none;
}

.videos_container .video_list_li.video_list_li_active:last-child {
  background: #eff4ff;
  border-radius: 0;
  border: none;
}

.videos_container .video_list_ul li:hover {
  background: #eff4ff;
  border-radius: 0px;
}

.videos_container .video_list_ul li:last-child:hover {
  background: #eff4ff;
  border-radius: 0;
  border: none;
}

.videos_container .video_list_li:hover .video_content_li {
  color: #0a89e8;
}

.videos_container .video_list_li:hover .video_svg_iocn svg {
  fill: #0a89e8;
}

.videos_container .utube_rows {
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

.videos_container .utube_content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 0px 10px;
  font-size: 18px;
}

.videos_container .utube_iframe img {
  border-radius: 10px 10px 0px 0px;
  height: 100%;
}

.utube_iframe .pos_rel:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 0.3;
}

.videos_container .utube_rows>.utube_col_4 p {
  line-height: inherit;
  font-size: 18px;
}

.videos_container .utube_iframe {
  flex-direction: column;
}

.videos_container .utube_rows>.utube_col_4 p {
  text-align: left;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: inherit;
  height: 100%;
}

.videos_container .utube_iframe span {
  height: 100%;
}

.videos_container .utube_videos {
  flex-direction: column;
  display: grid;
  padding-right: 0px;
  margin-bottom: 60px;
}

.videos_container .video_lists {
  padding-left: 0px;
}

.videos_container .search_marketpalce {
  margin-bottom: 30px;
}

.videos_container .search_marketpalce .search_icon {
  height: 90%;
  top: 2px;
}

.videos_container .search_marketpalce .search_click {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 20px;
  height: 60px;
}

.show_more_videos {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.show_more_videos button {
  width: auto;
  padding: 0px 20px;
  height: 42px;
  background: #2e6dfa 0% 0% no-repeat padding-box;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  color: #fff;
}

/* .show_more_videos button:hover {
  background: #eff4ff;
  border: 1px solid #eff4ff;
} */

/* .show_more_videos button:focus {
  outline: none;
} */

.feature_bg_img.bg_videos:before {
  height: 65%;
  background: transparent linear-gradient(69deg, #fff2f7 0%, #f1ffe2 100%) 0% 0% no-repeat padding-box;
}

.videos_wrapper .feature_inner_page.bg_feature_slider .main-slider-left h1 {
  padding: 0px 10%;
  margin-bottom: 30px;
}

.videos_wrapper .features_change_img {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

.videos_wrapper .feature_inner_page.bg_feature_slider .features_change_img-center iframe {
  width: 74%;
  height: 619px;
}

.videos_wrapper .features_change_img-center {
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.videos_wrapper .back_features_hide .back_features {
  display: block;
  margin-bottom: 0px;
}

.videos_wrapper .back_features_page a {
  font-size: 22px;
}

.videos_wrapper .sign_up-form_us.demo_page {
  padding-bottom: 20px;
}

.resource_wrapper {
  background: #eff4ff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 4px;
  width: 40%;
  float: left;
  padding: 55px 40px;
}

.resource_wrapper ul li a {
  color: #000 !important;
}

.resource_wrapper ul li a:hover {
  color: #2468f6 !important;
}

.videos_wrapper .row.beta_change_form ul li:last-child {
  margin-bottom: 0px;
}

.row.beta_change_form .resource_wrapper ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.row.beta_change_form .resource_wrapper ul li {
  list-style: none;
  text-decoration: underline;
}

.get_started_wrapper {
  background: #ffeff0 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 4px;
  width: 40%;
  float: left;
  padding: 55px 40px;
}

.get_started_wrapper button {
  width: 100%;
  float: left;
  height: 52px;
  background: #2468f6 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  font-size: 20px;
  margin-top: 10px;
  border: none;
}

.videos_wrapper .sign_up-form_us.demo_page p {
  font-size: 22px;
  color: #193a5d;
  line-height: inherit;
  margin-bottom: 12px;
}

.videos_wrapper .sign_up-form_us.demo_page .last_child_p {
  width: 100%;
  float: left;
}

.videos_wrapper .sign_up-form_us.demo_page .last_child_p p:last-child {
  margin-bottom: 20px;
  line-height: 28px;
  color: #193a5d;
}

.overview_wrapper .col-lg-8 {
  padding-right: 60px;
}

.videos_wrapper .overview_wrapper h3 {
  font-size: 42px;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 10px;
  color: #000;
}

.overview_wrapper .sign_up-form_us.demo_page h6 {
  font-size: 26px;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 10px;
  color: #000;
  line-height: inherit;
}

.overview_wrapper .sign_up-form_us.demo_page .get_started_wrapper h5 {
  font-size: 22px;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 10px;
  color: #000;
  line-height: inherit;
  text-align: center;
}

.overview_wrapper .row.beta_change_form ul li {
  font-size: 22px;
  line-height: inherit;
  color: #193a5d;
}

.overview_wrapper .row.beta_change_form ul li b {
  color: #000;
}

.overview_wrapper .sign_up-form_us.demo_page {
  padding-top: 0px;
}

.similar_videos_wrapper {
  width: 100%;
  float: left;
  background: #f8f8f8;
  padding-top: 60px;
  margin-bottom: 60px;
}

.similar_videos_wrapper .utube_rows {
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 30px;
  width: 100%;
}

.similar_videos_wrapper .utube_iframe {
  flex-direction: column;
}

.similar_videos_wrapper .utube_iframe img {
  width: 100%;
  float: left;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}

.similar_videos_wrapper .utube_iframe span {
  height: 100%;
}

.similar_videos_wrapper .utube_rows>.utube_col_4 p {
  margin: 0px;
  padding: 30px;
  font-size: 22px;
  font-family: "Roboto-Medium";
  color: #000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 10px;
  height: inherit;
}

.similar_videos_wrapper .utube_videos {
  margin-bottom: 60px;
}

.videos_wrapper .similar_videos_wrapper h3 {
  font-size: 42px;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 20px;
  color: #000;
}

.videos_wrapper .video_lists {
  display: block;
}

.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode,
.html5-video-player.ytp-fullscreen {
  background-color: #fff !important;
}

.overview_wrapper .btn_close_popup {
  font-size: 22px;
  color: #000;
}

.submenu_active {
  width: 100%;
  float: left;
}

.submenu_active ul {
  padding-left: 0px;
  background: #fff;
}

.videos_container .submenu_active ul .submenu_active_li {
  background: #fff;
  width: 85%;
  display: flex;
  margin: 0 auto;
  height: 48px;
  font-size: 18px;
  padding-left: 30px;
  border-radius: 0px;
  border: none;
}

.videos_container .submenu_active ul .submenu_active_li.active {
  background: #eff4ff;
}

.videos_container .submenu_active ul li:hover {
  background: #eff4ff;
}

.videos_container .video_list_ul .wrapper_left.close {
  opacity: 1;
}

.videos_container .video_list_ul .wrapper_left.close h4 {
  color: #000;
}

.videos_container .video_list_ul.video_list_ul_mobile {
  border-radius: 0px;
}

.videos_container .wrapper_left.open .wrapper_left_mobile {
  display: block;
  transform: rotate(180deg);
  padding-right: 0px;
  padding-left: 15px;
}

.videos_container .video_list_ul li.last_menu_li {
  width: 85%;
  margin: 0px auto;
  padding-left: 30px;
  font-size: 14px;
  color: #2468f6;
  text-decoration: underline;
  padding-top: 3px;
}

.videos_container .video_list_ul li.last_menu_li:hover {
  background: #fff !important;
  border-radius: 0;
  border: none;
  color: #2468f6;
}

.submenu_active_li span {
  color: #e57700;
  margin-left: 3px;
}

.videos_container .video_list_ul li .submenu_active ul li.last_menu_li {
  display: none;
}

.videos_container .video_list_ul li:nth-child(6) .submenu_active ul li.last_menu_li {
  display: none;
}

.bg_p_wihout_padding h5 {
  text-align: left;
  font-family: "Roboto-Medium";
  letter-spacing: -1.14px;
  color: #000;
  padding-bottom: 0px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  font-size: 32px;
}

.bg_p h5 {
  color: #fff;
}

.get_started_wrapper .modal-content.modal-content-popup {
  display: flex !important;
}

.get_started_wrapper #exampleModalCenter .modal-body {
  padding-right: 0px;
}

.get_started_wrapper .modal_submit_form .modal-header button {
  left: 0px;
  top: 10px;
}

.videos_wrapper .get_started_wrapper .sign_up-form_us.demo_page p {
  text-align: center;
}

.mob_automate_search .search_marketpalce {
  display: none !important;
}

.automate_marketplace .market_place .loader_demo {
  margin-top: 40px;
}

.automate_marketplace .show_more_videos {
  display: none;
}

.automate_marketplace.automate_integrations .show_more_videos {
  display: flex;
}

.homepage_slider.slider_homepage .featured_content {
  padding: 0px;
}

/* Landing page start */

.int_landing_page {
  width: 100%;
  float: left;
}

.int_landing_page .rwt__tablist.horizontal_slider {
  width: 100%;
  float: left;
  overflow-x: inherit;
}

.int_landing_page .horizontal_slider .rwt__tab {
  border-radius: 0px;
  min-width: max-content;
  text-align: center;
  padding: 0px 20px 0px 20px !important;
}

.int_landing_page .horizontal_slider.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  height: 100% !important;
  border: none !important;
  background: #fff !important;
  z-index: -1;
  border-radius: 0px;
  width: 100%;
  padding: 0px !important;
  color: #000;
  cursor: pointer;
  border-bottom: 4px solid #2468f6 !important;
  top: 1px;
}

.int_landing_page .horizontal_slider .rwt__tab {
  background: #fff !important;
  color: #555555 !important;
}

.int_landing_page .horizontal_slider .rwt__tab:hover {
  color: #000 !important;
}

.int_landing_page .horizontal_slider .rwt__tab[aria-selected="true"] {
  color: #2468f6 !important;
  cursor: pointer;
}

.int_landing_page .horizontal_slider .rwt__tab {
  width: max-content;
  border-bottom: 2px solid #dddddd;
  height: inherit;
  padding-bottom: 20px !important;
  font-family: "Roboto-Medium";
  font-size: 22px !important;
}

.int_landing_page .rwt__tablist.horizontal_slider {
  justify-content: center;
}

.integrations_slider_bg.landing_int_slider_bg .featured_content .card_link_landing {
  display: none;
}

.int_landing_page .trial_page_img.bg_trial_page {
  background: #fff;
}

.container_trial .servicenow_virtual .integration_header_top h3.header_integrations_name {
  margin-top: 0px;
  margin-bottom: 10px;
}

.servicenow_virtual {
  width: 100%;
  float: left;
}

.servicenow_virtual .integration_header_top {
  padding: 20px 0px;
}

.servicenow_virtual .integration_header_top h3.header_integrations {
  font-family: "Roboto-Medium";
}

.servicenow_virtual .pl-20-tage.mt-0 {
  padding-top: 40px;
}

.servicenow_virtual .features_content_left {
  padding-bottom: 40px;
}

.servicenow_virtual .center_image_home {
  width: 100%;
  float: left;
  background: #f8f8f8;
  padding: 60px 0px;
  margin-bottom: 60px;
}

.servicenow_virtual .center_image_home.cards_landing_page {
  padding: 40px 0px;
  margin-bottom: 40px;
}

.servicenow_virtual .features_img_left {
  padding-bottom: 40px;
}

.servicenow_virtual .int_landing_page .trial_page_img.bg_trial_page {
  background: #fff;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-top: 40px;
}

.servicenow_virtual .apps_landing_page {
  margin-bottom: 40px;
}

.servicenow_virtual .landing_content_p {
  padding: 30px 20% 0px;
  font-weight: 200;
}

.servicenow_virtual .header.sticky {
  background: #f8f8f8 !important;
}

.servicenow_virtual .landing_page_chatbot.ai_cards_service {
  padding-top: 40px;
  padding-bottom: 0px;
}

.servicenow_virtual .integrations_slider_bg .featured_content {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 0px;
}

.servicenow_virtual .apps_landing_page {
  padding-top: 40px;
  padding-bottom: 40px;
}

.servicenow_virtual .center_image_home.cards_landing_page {
  background: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
}

.servicenow_virtual .footer_form_container {
  padding: 40px !important;
}

.servicenow_virtual .footer_form {
  margin-bottom: 40px;
}

.servicenow_virtual .apps_landing_cards_wrapper ul li {
  margin-bottom: 20px;
}

.servicenow_virtual .apps_landing_cards_wrapper ul li:last-child {
  margin-bottom: 0px;
}

.iocn_virtual {
  width: 100%;
  margin-bottom: 15px;
  float: left;
}

.iocn_virtual img {
  width: 84px;
  height: 84px;
}

.col-md-6.feature_page_img_right.mob_slider_pad_0 .rwt__tabpanel {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

.servicenow_virtual .video_section {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 40px;
  margin-top: 40px;
}

.servicenow_virtual .video_section .cogni_fav_apps_header h3 {
  font-family: "Roboto-Medium";
}

.servicenow_virtual .center_feature_wrapper {
  margin-bottom: 10px;
}

.servicenow_virtual .bg_landing_white .center_feature_wrapper {
  margin-bottom: 20px;
}

.servicenow_virtual .video_section .flex_home {
  padding: 0px;
}

.servicenow_virtual .flex_home video {
  width: 86.721%;
}

.servicenow_virtual .video_section .features_change_img-center img {
  width: 86.721%;
  cursor: pointer;
}

.horizontal_slider {
  width: max-content;
  overflow-x: scroll;
  white-space: nowrap;
}

.horizontal_slider.ai_self_horizontal_slider {
  width: 100%;
  overflow: hidden;
  white-space: pre;
}

.rwt__tablist.horizontal_slider.ai_self_horizontal_slider .rwt__tab[aria-selected="true"]:after {
  border-radius: 10px;
}

.landing_it_virtual .landing_bg .form_below_content span {
  margin-right: 0px;
}

.container_trial .servicenow_virtual .bg_landing_white .second_p {
  font-family: "Roboto-Regular";
}

.container_trial .servicenow_virtual .feature_page_content_left p.basic_plans {
  font-family: "Roboto-Medium";
  color: #000;
}

.servicenow_virtual .center_image_home .main-slider-left picture img {
  filter: none;
}

.landing_it_virtual .center_image_home .main-slider-left picture img {
  filter: none;
}

.secure_nolink a:hover {
  text-decoration: none;
  cursor: inherit;
  color: #007bff;
}

.top_section.pricing_page._assistant_pricing .main-slider-button.beta-button {
  padding: 0px !important;
}

.top_section.pricing_page._assistant_pricing .second_form {
  position: absolute;
}

.top_section.pricing_page._assistant_pricing .modal_submit_form.first_popup_form {
  position: absolute;
}

/* Landing page end */

.grid_container_chatbot {
  width: 100%;
  float: left;
  display: grid;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
}

.sticky_right_img {
  width: 100%;
  float: left;
  cursor: pointer;
}

.sticky_right_img img {
  position: sticky;
  top: 160px;
}

._bg_market {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.grid__wrapper {
  width: 90%;
  float: left;
  display: grid;
  padding-left: 8.33%;
  padding-right: 0px;
}

.grid_container_chatbot_wrapper {
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 1fr 22%;
}

.grid_container_chatbot_wrapper .trial_header_left {
  padding: 0px;
}

.grid_container_chatbot_wrapper .main-slider-left-market h1 {
  font-size: 64px;
  font-family: "Roboto-Bold";
  color: #1d2b36;
  position: relative;
  top: -9px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.market_wrapper_page {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.market_wrapper_page h2 {
  font-family: "Roboto-Bold";
  font-size: 46px;
  color: #1d2b36;
  margin-bottom: 30px;
  width: 100%;
  float: left;
}

.grid_container_chatbot_wrapper h3 {
  font-family: "Roboto-Bold";
  font-size: 36px;
  color: #1d2b36;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.table_contents_wrapper {
  width: 100%;
  float: left;
  padding: 50px;
  background: #f1faf4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.table_contents_wrapper a {
  width: 100%;
  float: left;
  text-decoration: none;
  color: #3c78f7;
  font-size: 24px;
  margin-bottom: 20px;
}

.table_contents_wrapper a:hover {
  text-decoration: none;
  color: #3c78f7;
}

.table_contents_wrapper a:last-child {
  margin-bottom: 0px;
}

.container_trial .market_wrapper_page p {
  margin-bottom: 40px;
  color: #1d2b36;
  line-height: 48px;
  font-size: 24px;
  font-family: "Roboto-Regular";
  width: 100%;
  float: left;
}

/* .container_trial .market_wrapper_page p:last-child{
  margin-bottom:0px;
} */

.section__ul li {
  color: #1d2b36;
  line-height: 36px;
  font-size: 24px;
  font-family: "Roboto-Regular";
  width: 100%;
  float: left;
  list-style-type: disc;
  margin-bottom: 24px;
}

.section__ul li:last-child {
  margin-bottom: 0px;
}

.section__ul li b {
  font-family: "Roboto-Medium";
  color: #1d2b36;
}

.market_sub_wrapper h4 {
  font-family: "Roboto-Bold";
  font-size: 30px;
  color: #1d2b36;
  margin-bottom: 20px;
  padding-top: 10px;
  width: 100%;
  float: left;
}

.market_wrapper_page h5 {
  font-family: "Roboto-Bold";
  font-size: 24px;
  color: #1d2b36;
  margin-bottom: 20px;
  width: 100%;
  float: left;
}

.market_wrapper_page h4 {
  font-family: "Roboto-Bold";
  font-size: 30px;
  color: #1d2b36;
  margin-bottom: 20px;
  width: 100%;
  float: left;
}

#section5 #section12 .p_with_ul p {
  margin-bottom: 20px;
}

.p_with_ul.section_5 ul li {
  list-style: none;
}

.p_with_ul.section_5 {
  margin-bottom: 40px;
}

.section__ul li span a {
  color: #2468f6;
  font-family: "Roboto-Medium";
}

.section__ul li span a:hover {
  color: #2468f6;
}

.top_ul .table_contents_wrapper {
  margin-top: 10px;
}

#section3 .img_source_erapper img {
  margin-bottom: 40px;
}

#section6 .section__ul li {
  margin-bottom: 0px;
  list-style: none;
}

/* #section6 .section__ul li p {
  margin-bottom:20px;
} */

#section8 .section__ul {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

#section9 .section__ul {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.market_wrapper_page .p_with_ul p {
  width: 100%;
  float: left;
  /* margin-bottom:20px; */
}

.p_with_ul {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.market_wrapper_page p span a {
  color: #2468f6;
  font-family: "Roboto-Medium";
}

.market_main_wrapper .footer_form {
  margin-bottom: 80px;
}

.container_trial #section4 .p_with_ul p {
  margin-bottom: 20px;
}

.section__ul {
  width: 100%;
  float: left;
}

.container_trial .grid_container_chatbot #section5 {
  margin-bottom: 20px;
}

.container_trial .grid_container_chatbot #section5 ul {
  margin-bottom: 0px;
  width: 100%;
  float: left;
}

.container_trial #section5 .section__ul p {
  margin-bottom: 20px;
}

.container_trial #section5 .section__ul {
  margin-bottom: 20px;
}

.container_trial #section4 .p_with_ul {
  margin-bottom: 40px;
}

.container_trial #section6 .p_with_ul:first-child {
  margin-bottom: 0px;
}

.market_sub_wrapper {
  width: 100%;
  float: left;
}

.section__ul ul {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

ul.section__ul {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.ul_list_number {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.ul_list_number li {
  color: #1d2b36;
  line-height: 40px;
  font-size: 24px;
  font-family: "Roboto-Regular";
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.ul_list_number li:last-child {
  margin-bottom: 0px;
}

.most_popular_links {
  width: 100%;
  float: left;
}

.most_popular_links .most_popular_header {
  width: 100%;
  float: left;
  text-align: left;
  font-family: "Roboto-Bold";
  letter-spacing: 0px;
  font-size: 30px;
  color: #1d2b36;
  margin-bottom: 20px;
}

.most_popular_ul {
  width: 100%;
  float: left;
}

.most_popular_ul ul {
  width: 100%;
  float: left;
  padding-left: 20px;
  margin-bottom: 0px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding-top: 30px;
  padding-bottom: 40px;
}

.most_popular_ul ul li a {
  color: #2468f6;
}

.most_popular_ul ul li a:hover {
  color: #2468f6;
}

.button_last {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.button_last button {
  height: 48px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #1d2b36;
  text-transform: uppercase;
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 20px;
  width: 270px;
  border: none;
  margin: 0;
  cursor: pointer;
}

.button_last button:focus {
  outline: 0;
}

.button_last button a {
  color: #1d2b36;
}

.button_last button a:hover {
  text-decoration: none;
}

.button_last button:hover {
  background: #2e6dfa;
  color: #fff;
}

.button_last button:hover a {
  color: #fff;
}

.market_main_wrapper .container {
  max-width: 88.2%;
}

.market_main_wrapper #section11 .nocode_wrapper {
  margin-top: 0px;
}

.market_main_wrapper .nocode_wrapper {
  width: 100%;
  float: left;
  background: #fff1e6 0% 0% no-repeat padding-box;
  padding: 40px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.market_main_wrapper .nocode_wrapper h4 {
  font-family: "Roboto-Medium";
  font-size: 30px;
  color: #000000;
  margin-right: 60px;
  margin-bottom: 0px;
  width: auto;
  float: left;
}

.market_main_wrapper .nocode_wrapper button {
  min-width: 180px;
  height: 64px;
  background: #2468f6 0% 0% no-repeat padding-box;
  border-radius: 32px;
  padding: 0px 55px;
  font-family: "Roboto-Medium";
  font-size: 24px;
  color: #fff;
  border: none;
  width: auto;
  cursor: pointer;
}

.market_main_wrapper .nocode_wrapper button a {
  text-decoration: none;
  color: #fff;
}

.market_main_wrapper .nocode_wrapper button a:hover {
  text-decoration: none;
  color: #fff;
}

.market_main_wrapper .nocode_wrapper button:focus {
  outline: 0;
}

.market_main_wrapper .nocode_wrapper.helpdesk_wrapper {
  background: #f1faf4 0% 0% no-repeat padding-box;
}

.market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 {
  color: #2468f6;
  line-height: 34px;
  padding-top: 0px;
}

.market_main_wrapper .nocode_wrapper h4 span {
  width: 48px;
  height: 48px;
  float: left;
}

.market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 span img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.market_main_wrapper .mob_accordian .accordion__panel {
  padding-bottom: 0px;
}

.container_trial .market_wrapper_page .accordion-wrapper.pricing_faq p {
  margin-bottom: 40px;
  padding: 0px 24px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__button {
  background: #ededed;
  width: 100%;
  padding-left: 40px;
  padding-right: 70px;
  font-size: 30px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:before {
  background: url("../images/dropdown_icon_1.svg");
  width: 28px;
  height: 28px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(1800deg) !important;
  position: absolute;
  right: 30px;
  left: inherit;
  margin-right: 0px;
  top: 48px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"]:hover {
  background-color: #fff !important;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__button:before {
  background: url("../images/dropdown_icon.svg");
  width: 28px;
  height: 28px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg) !important;
  position: absolute;
  right: 15px;
  left: inherit;
  top: 28px;
}

.container_trial .market_wrapper_page .accordion__button:hover {
  background-color: #ededed !important;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion.pl-0:last-child .accordion__item {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  margin-bottom: 12px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__panel {
  border: 1px solid #c6c6c6;
  border-top: none;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
  border: 1px solid #c6c6c6 !important;
  border-bottom: none !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  float: left;
  padding-top: 40px;
  font-size: 30px;
  padding-bottom: 20px;
}

.container_trial .market_wrapper_page .mob_accordian.mob_accordian_faq .accordion__panel img {
  width: 100%;
  height: 100%;
  float: left;
  margin-bottom: 40px;
}

.link_card_wrapper {
  width: 100%;
  float: left;
  background: #fff7ef 0% 0% no-repeat padding-box;
  padding: 35px 70px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.link_card_img {
  width: 88px;
  height: 88px;
  float: left;
  margin-right: 25px;
}

.link_card_img img {
  width: 100%;
  height: 100%;
}

.link_card_content {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.link_card_content h4 {
  font-family: "Roboto-Medium";
  font-size: 30px;
  color: #000000;
  margin-bottom: 10px;
  width: 100%;
  float: left;
  line-height: 45px;
}

.link_card_content p {
  color: #416183;
  margin-bottom: 0px;
  width: max-content;
}

.link_card_content .link_card__link span {
  margin-left: 5px;
  min-width: 100px;
  float: left;
  font-family: "Roboto-Medium";
}

.link_card__link {
  width: 100%;
  float: left;
  display: flex;
  line-height: 32px;
  align-items: baseline;
}

.link_card__link p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  max-width: 90%;
}

.link_card__link span p {
  white-space: wrap;
  max-width: 100%;
  font-family: "Roboto-Medium";
}

.main-slider-button.beta-button form.sign_up_form {
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 1fr 40%;
}

.beta-button .sign_up_form input {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #afafaf;
  border-radius: 32px 0px 0px 32px;
  border-right: none;
  height: 64px;
  font-size: 22px;
  font-family: Gordita-Regular;
  padding: 0px 20px;
}

.beta-button .sign_up_form button {
  width: 100%;
  border-radius: 0px 32px 32px 0px;
  background: #2468f6;
  border: 1px solid #2468f6;
  height: 64px;
  font-size: 22px;
  font-family: Gordita-Medium;
}

.form_below_content.signup_cta {
  justify-content: center;
}

.form_below_content.signup_cta span {
  margin-right: 40px;
  font-family: "Gordita-Medium";
  font-size: 20px;
}

.container_trial .main-slider-left .basic_plans.signup_cta_p {
  font-family: "Gordita-Medium";
  font-size: 30px;
  width: 100%;
  float: left;
  margin-top: 60px;
  color: #333333;
}

.feature_inner_page.bg_feature_slider .main-slider-button.beta-button .form_below_content {
  justify-content: center;
}

.flex-0-0 {
  flex: 0 0 auto !important;
}

.line-height-1 {
  line-height: 1.6;
  font-family: "Gordita-Medium";
}

.center {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.chat_channels_trial_btn1 {
  width: 124px;
  float: left;
  font-size: 18px;
  font-family: Roboto-Bold;
  color: #000000;
  min-width: 124px;
}

.chat_channels_trial_btn1 img {
  width: 29px;
  height: 29px;
  margin-right: 10px;
}

.marginbottom0px {
  margin-bottom: 0px;
}

.lineheight3rem {
  line-height: 3rem;
}

.kBQotH {
  height: 100px;
}

/* Main container  */

.features_main_container {
  width: 100%;
  /* display: grid; */
  margin-bottom: 40px;
  /* grid-template-columns: 32.5% 67.5%; */
}

/* Left side container styling starts here */

.heading_feature {
  padding-top: 90px;
  /* margin-left: 150px; */
  opacity: 1;
  padding-right: 70px;
  margin-bottom: 0px;
  font-family: Gordita-Medium;
  font-size: 24px;
}

/* .heading_feature_down{
   
    font-weight: bold;
    margin-left:150px;

} */

.features_content_left {
  background: #fff6f0;
}

/* .features_content_left .features_content_left_box{
    margin-left:32%;
} */

.features_list {
  margin-top: 10px;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 25px;
  list-style: none;
  margin-bottom: 0px;
  font-family: Gordita-Medium;
}

.features_list>li {
  padding: 25px 0px 0px 2px;
  opacity: 1;
  font-size: 20px;
}

.arrow_svg_list svg {
  margin-top: 8px;
  height: 17px;
  color: #2468f6;
  float: right;
}

.see_all_features {
  color: #2468f6;
  opacity: 1;
  padding-top: 25px;
  font-family: Gordita-Medium;
}

/* Right side container styling starts here */

.features_content_right {
  background: #f8f8f8;
  width: 100%;
  padding-right: 16%;
  padding-left: 44px !important;
}

.right_header_content {
  /* width : 80% */
}

.right_header_content h5 {
  text-align: left;
  /* margin-left:44px; */
  margin-top: 40px;
  font-size: 24px;
  font-weight: bold;
  font-family: Gordita-Medium;
}

.right_header_content>p {
  text-align: left;
  letter-spacing: 0px;
  color: #193a5d;
  opacity: 1;
  /* margin-left:44px; */
  margin-top: 30px;
  font-family: Gordita-Regular;
}

.url_manipulation_learnmore_link {
  /* margin-left: 44px; */
  margin-bottom: 20px;
  letter-spacing: 0px;
  color: #2468f6;
  opacity: 1;
  display: block;
  font-family: Gordita-Medium;
}

.features_content_right>img {
  /* width:80%; */
  /* margin-top: 20px; */
  /* margin-left:44px; */
  margin-bottom: 40px;
}

/* feature contents icons */

.icons_container {
  margin-top: 5px;
  margin-bottom: 15px;
}

.icons_container>h6 {
  display: inline;
  color: #333333;
  margin-left: 10px;
}

.slack_feature_icon {
  width: 30px;
  height: 30px;
}

.teams_feature_icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.chat_feature_icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

/* Progress bar styling starts here */

#Progress_Status1 {
  width: 9.9%;
  background-color: #ddd;
  position: absolute;
  margin-left: 33px;
  margin-top: -13px;
}

#Progress_Status2 {
  width: 14.6%;
  background-color: #ddd;
  position: absolute;
  margin-left: 35px;
  margin-top: -13px;
}

#Progress_Status3 {
  width: 15.4%;
  background-color: #ddd;
  position: absolute;
  margin-left: 35px;
  margin-top: -13px;
}

#Progress_Status4 {
  width: 11.9%;
  background-color: #ddd;
  position: absolute;
  margin-left: 35px;
  margin-top: -13px;
}

#Progress_Status.mobile {
  width: 7.3%;
  background-color: #ddd;
  position: absolute;
  margin-left: 23px;
  margin-top: -18px;
}

#stepprogressBar0,
#stepprogressBar1,
#stepprogressBar2,
#stepprogressBar3,
#stepprogressBar4 {
  width: 0%;
  height: 2px;
  background-color: #4cbf96;
}

#Progress_Status {
  width: 100%;
  /* margin-left:-13px; */
  background-color: #ddd;
  margin-top: 25px;
  min-height: 2px;
}

.mob_main_container #Progress_Status {
  margin-top: 0px;
}

#myprogressBar0,
#myprogressBar1,
#myprogressBar2,
#myprogressBar3,
#myprogressBar4,
#myprogressBar5 {
  width: 0%;
  height: 2px;
  background-color: #2468f6;
}

#myprogressBar6,
#myprogressBar7,
#myprogressBar8,
#myprogressBar9,
#myprogressBar10,
#myprogressBar11 {
  width: 0%;
  height: 2px;
  background-color: #2468f6;
}

.slider-header-top {
  display: flex;
}

.margin-left-auto {
  margin-left: auto;
}

.slider-header-top p {
  margin-bottom: 0px;
  font-family: Gordita-Medium;
  font-size: 15px;
}

.url_manipulation_features_link {
  font-family: Gordita-Medium;
}

.right_header_content h5 {
  margin-bottom: 0px;
}

.right_header_content p {
  margin-top: 10px;
  margin-bottom: 0px;
}

/* Onboarding Slider styling starts here */

.onboarding_background {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  border-color: 2px black;
  display: grid;
  position: relative;
}

.onboarding_all_content {
  margin-bottom: 35px;
}

.onboarding_heading {
  text-align: center;
}

.onboarding_heading>h2 {
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #333333;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.onboarding_heading>h5 {
  font-size: 30px;
  font-family: "Gordita-Medium";
}

.onboarding_heading>h5>a {
  text-decoration: none;
}

.bar_container {
  margin-top: 30px;
}

.bar_list {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
}

.bar_list>div>li>h5 {
  margin-top: 7px;
}

.bar_list>div>li img {
  width: 25px;
  margin-left: 10px;
}

.bar_list1 {
  margin-left: -10px;
}

.bar_list2 {
  margin-left: -75px;
}

.bar_list3 {
  margin-left: -75px;
}

.bar_list4 {
  margin-left: -30px;
}

.bar_list5 {
  margin-left: -10px;
}

.circle {
  border-radius: 50%;
  margin-left: 10px;
  width: 25px;
  border: 2px solid #ddd;
  height: 25px;
  cursor: pointer;
  background-color: #ddd;
}

.onboarding_section_container {
  display: grid;
  grid-template-columns: 40% 60%;
}

.left_section {
  min-height: 100%;
}

.left_section>h3 {
  margin-top: 230px;
}

.left_section>img {
  width: 150px;
  height: 130px;
  float: right;
  margin-top: 90px;
  margin-right: 50px;
}

.right_section>img {
  padding-left: 20px;
  height: 100%;
}

.icons_container_onboarding {
  margin-left: 0px;
  display: flex;
  align-items: baseline;
  column-gap: 10px;
  /* margin-top:20px; */
}

.icons_container_onboarding>h6 {
  display: inline;
  color: #333333;
  margin-left: 10px;
}

.icon_text {
  display: flex;
  justify-content: initial;
  column-gap: 5px;
  align-items: center;
}

.slack_feature_icon_onboarding {
  width: 28px;
  height: 28px;
}

.teams_feature_icon_onboarding {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.chat_feature_icon_onboarding {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

/* Onboarding Mobile Screens Starts Here */

@media (min-width: 300px) and (max-width: 600px) {
  .onboarding_mobile_background {
    width: 100%;
    background-color: white;
  }

  .onboarding_mobile_all {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  .onboarding_mobile_heading {
    text-align: center;
    font-family: "Gordita-Medium";
  }

  .onboarding_mobile_heading>h5 {
    margin-top: 20px;
    font-family: "Gordita-Medium";
  }

  .onboarding_mobile_heading>h6 {
    color: #355d8b;
    font-size: 1.2rem;
    margin-top: 20px;
    font-family: "Gordita-Medium";
  }

  .onboarding_mobile_progress {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
    width: 92%;
    margin: 20px auto;
  }

  .onboarding_mob_arrow_right {
    color: #2468f6;
    cursor: pointer;
  }

  .onboarding_mob_arrow_left {
    color: #d1cfd2;
    cursor: pointer;
  }

  .circle_mobile {
    border-radius: 50%;
    width: 25px;
    border: 2px solid #afafaf;
    height: 25px;
    text-align: center;
  }

  .green_circle_mobile {
    border-radius: 50%;
    width: 25px;
    background: #4cbf96;
    height: 25px;
    text-align: center;
  }

  .green_circle_mobile>h6 {
    color: white;
    margin-top: 3px;
  }

  .circle_mobile>h6 {
    color: #afafaf;
  }

  .onboarding_mobile_section_container {
    margin-top: 20px;
  }

  .mobile_section {
    text-align: center;
  }

  .mobile_section>p {
    text-align: center;
  }

  .icons_container_onboarding_mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }

  .icons_container_onboarding_mobile>img {
    width: 20px;
    height: 20px;
  }
}

/* Features Mobile screen styles starts here */

@media (min-width: 300px) and (max-width: 768px) {
  .mob_main_container {
    width: 100%;
    display: grid;
    margin-bottom: 35px;
    background: #fff6f0;
    opacity: 1;
  }

  #Progress_Status {
    width: 100%;
    margin-left: 0px;
    background-color: #ddd;
  }

  .heading_mob {
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 20px;
    font-weight: bold;
  }

  .all_features_container {
    margin-bottom: 50px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .features_categories_list {
    line-height: 3.5em;
    text-align: left;
    font-size: 18px;
    font-family: "Gordita-Medium";
    color: #333333;
    opacity: 1;
    cursor: pointer;
  }

  .icons_container {
    margin: 0px;
    padding-bottom: 20px;
  }

  .features_content {
    margin-top: 20px;
  }

  .features_content>h5 {
    font-weight: bold;
  }

  .url_img_container {
    margin-top: -10px;
  }

  .mob_feature_img {
    margin-top: 8px;
  }

  .arrow_svg_list {
    float: right;
    transition: all 0.7s ease;
    margin-top: 18px;
  }

  .arrow_svg_list:hover {
    transform: rotateZ(90deg);
  }

  .arrow_svg_list.arrow_down {
    transform: rotateZ(90deg);
  }
}

.background-two-color {
  background: linear-gradient(to right,
      #fff6f0 0%,
      #fff6f0 50%,
      #f8f8f8 50%,
      #f8f8f8 100%) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 40px;
}

.onboarding_slider_step {
  cursor: pointer;
}

.onboarding_slider_step_number {
  font-family: Gordita-Regular;
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(53, 93, 139);
}

.sign_up_font_size_reduce {
  font-size: 18px !important;
  color: rgb(53, 93, 139);
}

.onboarding_slider_step_text {
  font-family: "Gordita-Medium";
  font-size: 16px;
  color: #1857dc;
  text-align: center;
}

.onboarding_slider_content_header {
  font-family: "Gordita-Medium";
  font-size: 36px;
}

.font-medium {
  font-family: "Gordita-Medium" !important;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.color-black {
  color: black;
}

.pl-20px {
  padding-left: 20px;
}

.mobile_step_header {
  font-family: "Gordita-Medium";
  color: rgb(24, 87, 220);
}

.arrow {
  background: #fff;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.up-above {
  position: relative;
  z-index: 10;
}

.arrow::before,
.arrow::after {
  content: "";
  background: #fff;
  position: absolute;
  height: 3px;
  width: 15px;
}

.arrow::before {
  right: -3px;
  bottom: -4px;
  transform: rotate(-45deg);
}

.arrow::after {
  right: -3px;
  top: -4px;
  transform: rotate(45deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.onboaring_arrow {
  height: 35%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.onboaring_arrow img {
  height: 8em;
  width: 8em;
}

.responsive-p-slider {
  font-size: 16px !important;
}

.feature_unicode {
  font-family: "Gordita-Regular";
  letter-spacing: 0px;
  color: #193a5d;
  opacity: 1;
}

.logs_new_sec1 {
  margin-top: 20px;
  cursor: pointer;
  padding: 25px;
  outline: none;
}

.logs_new_sec2 {
  margin-top: 20px;
  cursor: pointer;
  padding: 25px;
  outline: none;
}

.logs_box_shadow {
  background: #f1faf4 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 40px #0000001a;
  border-radius: 4px;
  opacity: 1;
  padding: 25px;
  outline: none;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.backgroung-color-white {
  background-color: white !important;
}

.width-100 {
  width: 100% !important;
}

.padding-bottom-40-imp {
  padding-bottom: 40px !important;
}

.min-height-50 {
  min-height: 50px;
}

.text-align-left {
  text-align: left !important;
}

.justify-content-center {
  justify-content: center !important;
}

.text-section {
  padding-bottom: 20px !important;
}

.float-none {
  float: none !important;
}

.background-white {
  background-color: white !important;
}

.min-text-height {
  min-height: 150px;
}

.color-black {
  color: black !important;
}

.color-black-resource {
  color: #000000 !important
}

.text-align-left {
  text-align: left !important;
}

.usecase_bottom_blog>ul {
  list-style-type: disc;
  padding-left: 13px;
}

.usecase_bottom_blog>ul li::marker {
  font-size: 1.5em;
  color: #5F5F5F;
}

.usecase_bottom_blog ul li {
  padding: 10px 0;
}

.blog_usecase_link {
  color: #2468f6 !important;
}

.usecase_bottom_blog {
  margin: 15px 0px 40px 0px;
  padding-top: 13px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
}

.right-feature-bottom {
  padding-bottom: 0px !important;
}

.new_padding {
  padding: 35px 15% 20px 15% !important;
}

.new_margin_top {
  margin-top: -20px !important;
}

.onboarding_slider_step_text.bar_list3 {
  width: 191px;
  line-height: 1.5;
}

.onboarding_slider_step_text.bar_list2 {
  width: 180px;
  line-height: 1.5;
}

.landing-page-vi-agent .bar_list4 {
  width: 180px;
  line-height: 1.5;
  margin-left: -65px;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.position-initial {
  position: initial !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.text-black {
  color: black !important;
}

.digital-assistant .rwt__tab {
  height: 45px !important;
}

.right-feature-bottom {
  padding-bottom: 0px !important;
}

.display-flex {
  display: flex;
}

.color_black_span {
  color: black;
}

.bg_usp_updated {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.text-align-center {
  text-align: center !important;
}

.image-width {
  width: 75%;
}

.margin-bottom-20 {
  margin-bottom: -20px;
}

.min-height-60 {
  min-height: 60px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.line-height-2 {
  line-height: 2.1 !important;
}

.margin-no-code {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.background-color-slider {
  background-color: #edf1ff !important;
}

.color-chat-channel {
  color: #333333 !important;
}

.bamboo_logo_image {
  display: block !important;
  width: 10% !important;
  height: 10% !important;
  margin-bottom: 20px !important;
}

.margin-left-slide {
  margin-left: 10px !important;
}

.paid-campaign-left .basic_plans {
  display: none !important;
}

.paid-campaign-left {
  text-align: center;
  padding: 50px 40px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paid-campaign-left .sign_up_form {
  width: 100% !important;
}

.paid-campaign-left .beta-button .sign_up_form button {
  font-size: 12px !important;
}

.paid-landing-page .container .form_below_content {
  left: 0px !important;
  width: 100% !important;
  justify-content: center !important;
}

.paid_main_con .paid_ad_remove_footer {
  display: none;
}

.paid_main_con .footer_background {
  background-color: #f8f8f8 !important;
}

.paid_main_con .automation_steps {
  margin-bottom: 40px;
}

.bottom-text-paid-landing-page {
  align-self: center;
}

.paid_main_con .apps_landing_page {
  margin-bottom: 0px;
}

.paid_main_con .apps_landing_page .chatbot_align1 {
  display: none;
}

.paid_main_con .apps_landing_page .chatbot_align2 {
  display: none;
}

.list_paid_camp {
  padding: 0px;
  margin: 0px;
}

.list_paid_camp li {
  list-style-type: none;
  padding: 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.paid_main_con .onboarding_slider_step_number {
  font-size: 16px;
}

.paid-campaign-right {
  padding: 50px 40px 50px !important;
}

.paid_main_con .landing_page_head {
  display: none;
}

.paid_main_con .NavbarProduct {
  display: none !important;
}

.paid_main_con .linkwrapper {
  display: none !important;
}

.paid_main_con .nav-bar-left {
  display: none !important;
}

.paid_main_con .navbar_right {
  display: none !important;
}

.list_paid_camp img {
  width: 03%;
  height: 03%;
  display: inline-block;
}

.list_paid_camp span {
  margin: 0px 10px;
}

.swipe-chat-channel {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}

/* .swipe-chat-channel div {
    border: 1px solid #B7B7B7;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.swipe-left-arrow {
  cursor: pointer;
  color: #b7b7b7;
}

.swipe-left-arrow:focus {
  color: #2468f6;
  border: 1px solid #2468f6;
}

.swipe-right-arrow {
  cursor: pointer;
  color: #b7b7b7;
}

.swipe-right-arrow:focus {
  color: #2468f6;
  border: 1px solid #2468f6;
}

/* 
.swipe-chat-channel div {
    border: 1px solid #B7B7B7;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.swipe-one {
  border: 1px solid #b7b7b7;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}

.swipe-one.active {
  border: 1px solid #2468f6 !important;
}

.swipe-one.active span {
  background: #2468f6;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #2468f6;
}

.padding-top-bottom-20 {
  padding: 10px 0;
}

.rotate-180 {
  transform: rotate(180deg);
}

.swipe-icon {
  width: 12px;
  height: 12px;
  outline: none !important;
}

.feature_page_content_left_landing {
  padding-right: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.padding-top-20 {
  padding-top: 20px;
}

.icon_container_chatbot_integration {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.chat_channels_trial .chat_channels_trial_btn:nth-child(2) {
  margin-right: 20px;
}

.line-height-chatbot {
  line-height: 1.5;
}

.paid-landing-page-banner-img {
  width: 85%;
}

.paid_main_con .paid-landing-page-icon-list {
  display: flex;
  align-items: center;
}

.paid_main_con .app-integrations-links,
.paid-landing-page-anchorLink {
  display: none;
}

.paid_main_con .paid-landing-page-anchorLink {
  display: block;
}

.background-paid-image {
  background: #f8f8f8;
  padding-top: 60px;
}

.paid_main_con .servicenow_virtual .int_landing_page .trial_page_img.bg_trial_page,
.paid_main_con .int_landing_page .horizontal_slider .rwt__tab,
.paid_main_con .int_landing_page .horizontal_slider.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  background: #f8f8f8 !important;
}

.paid_main_con .servicenow_virtual .int_landing_page .trial_page_img.bg_trial_page {
  padding-bottom: 60px;
}

.paid_main_con .servicenow_virtual {
  margin-top: 20px;
  margin-bottom: 45px;
}

.width-100-percent {
  width: 100%;
}

.blog_gif {
  width: 100%;
  text-align: center;
}

.blog_gif img {
  max-width: 40%;
  height: auto;
}

.popup-background .form_below_content {
  display: none;
}

.popup-background .basic_plans {
  display: none;
}

.popup-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  z-index: 999999;
}

.popup {
  background: url("../../assets/images/popup/popup_background.png") no-repeat;
  border-radius: 5px;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  margin: 70px 0px;
}

.popup-background .container {
  position: relative;
  margin-top: 6%;
}

.content-left-popup {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px 50px !important;
  width: 50%;
}

.content-left-popup .popup-logo {
  position: absolute;
  top: 0;
  width: 50%;
}

.content-left-popup .beta-button .sign_up_form button {
  font-size: 18px !important;
}

.content-right-popup {
  padding: 0px 20px;
  top: 5px;
  width: 50%;
}

.close-circle {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 10;
}

.popup .close {
  transition: all 200ms;
  font-size: 20px;
  text-decoration: none;
  color: black;
}

.popup-background .main-slider-button.beta-button form.sign_up_form {
  width: 90%;
}

.popup-background .row {
  margin-right: 0px;
  margin-left: 0px;
}

/* .automate_popup .sign_up_form input {
  display: none;
} */

.automate_popup .beta-button .sign_up_form button {
  /* border-radius: 32px; */
  width: 100%;
}

.automate_popup .popup-assistants {
  padding: 40px 0px;
  justify-items: center;
}

.automate_popup .content-left-popup {
  padding-left: 40px !important;
  padding-right: 0px !important;
}

.automate_popup .content-right-popup {
  padding: 0px;
  padding-right: 10px;
}

.automate_popup p {
  padding-right: 60px;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-top-40 {
  margin-top: 40px;
}

.feature-display-none .display-none-all-features,
.feature-display-none-chat-channel .display-none-all-features,
.feature-display-none-chat-channel-slack .display-none-all-features,
.feature-display-none-chatbot-widget .display-none-all-features {
  margin-top: 0px !important;
  color: #000 !important;
}

.feature-display-none .center_feature_wrapper {
  padding: 0px 120px;
}

.feature-display-none .container-width-padding {
  padding: 0px 16% 15px 16%;
}

.padding-center-new {
  padding: 0px 10% !important;
}

.feature-display-none .container-width-changes-features {
  padding: 0px 12% !important;
}

.feature-display-none-chat-channel .container-width-changes-features {
  padding: 0px 9% !important;
}

.feature-display-none-chat-channel-slack .container-width-changes-features {
  padding: 0px 5% !important;
}

.chat_channel_nov_updated .support_it_hr_main_container {
  margin-bottom: 24px;
}

.feature-display-none-chat-channel .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
  width: 100%;
  float: left;
  padding: 0px 18% !important;
}

.feature-display-none-chat-channel-slack .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
  width: 100%;
  float: left;
  padding: 0px 22% !important;
}

.feature-display-none .container-width-padding {
  padding: 0px 16% 15px 16% !important;
}

.desktop_tab_width_change {
  width: 75% !important;
}

.desktop_tab_width_change_digital {
  width: 80% !important;
}

.display-none-mob-feature {
  display: none;
}

.new-font-webinar {
  font-size: 25px !important;
}

.webinar_left_div .webinar_name,
.webinar_left_div .webinar_title,
.webinar_right_div .webinar_name,
.webinar_right_div .webinar_title {
  text-align: center;
}

.webinar_left_div {
  display: inline-block;
}

.background-color-slider {
  background-color: #f8f8f8 !important;
}

.background-color-update-chatbot {
  background-color: #f8f8f8 !important;
  padding: 46px 0px;
}

.webinar_right_div {
  display: inline-block;
  margin-left: 30px;
}

.sliderimg-blog {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.seperator-pop-demo {
  width: 45%;
  display: inline-block;
  margin-bottom: 1px;
}

.hl-left {
  margin-right: 10px;
}

.hl-right {
  margin-left: 10px;
}

.popup-demo {
  cursor: pointer;
  padding-top: 11px;
  padding-bottom: 8px;
  border-radius: 50px;
  border: 2px solid #2468f6;
  background-color: white;
  color: #2a68f6;
  font-size: 13px !important;
}

.demo-container-pop {
  text-align: center;
  margin-top: 10px;
}

.last_demo_footer {
  border: 1px solid #2468f6;
  padding: 14px 15px 10px 15px;
  border-radius: 40px;
  margin-top: 10px;
  background: #fff;
  color: #2468f6 !important;
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
}

.line_or_pop {
  margin-top: 18px;
  margin-bottom: 10px;
}

.pt-4.ai-padding-top {
  padding-top: 0px !important;
}

.coming_soon_integrations {
  background: #e6ffe0;
  color: #1aa75b;
  font-size: 18px;
  width: -webkit-max-content;
  width: max-content;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  top: 0;
}

.logo_and_soon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.logo_container_integration {
  width: 11%;
  margin-right: 20px;
}

.logo_container_integration .bamboo_logo_image {
  width: 100% !important;
  height: 100%;
  margin-bottom: 0px !important;
}

.header_button_style {
  border: 1px solid #2468f6;
  padding: 10px 30px;
  border-radius: 20px;
  background: #fff;
  color: #2468f6 !important;
  text-transform: none;
  text-decoration: none;
  height: 38px;
  width: 270px;
  text-align: center;
}

.ai-self-service-chatbot .main-slider-left {
  padding: 0px;
}

.marketplace_assist_cards .cards {
  justify-content: space-between;
}

.marketplace_assist_cards .cards__item {
  width: 32%;
}

.marketplace_assist_cards .card {
  box-shadow: 10px 10px 40px #0000001a;
}

.marketplace_assist_cards .card__content {
  padding: 30px !important;
  border: none !important;
}

.marketplace_assist_cards .card__text {
  line-height: 2 !important;
  color: #000000 !important;
}

.card-market-text-head {
  padding: 1rem;
}

.marketplace_assist_reach_button button {
  border-radius: 50px !important;
  padding: 12px;
  width: 28% !important;
  height: auto !important;
}

.show_more_marketplace_assist button {
  border-radius: 50px !important;
  border: 1px solid #2468f6;
  color: #ffff;
}

.background_marketplace_assist {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin-top: 0px;
}

.marketplace-two-card-header {
  margin-top: 56px !important;
}

.header-integrate-logo-marketplace {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  margin-top: 50px;
  align-items: center;
  gap: 16px;
}

.header-integrate-logo-marketplace img {
  width: 110px;
  height: 110px;
}

.marketplace_chatbot_reach_button button {
  width: 16% !important;
}

.app_workflow_marketplace_top {
  background: #edf1ff 0% 0% no-repeat padding-box !important;
}

.app_workflow_section_marketplace {
  padding: 40px 0px;
}

.chatbot-section-marketplace_heading {
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: #edf1ff !important;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.chatbot-names-marketplace-text {
  margin-left: 10px;
}

.chatbot-section-marketplace_heading img {
  width: 36px;
  height: 36px;
}

.chatbot-names-marketplace-text span {
  color: #e57700;
}

.chatbot_main_marketplace {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 26px;
  padding: 24px 0px;
  /* display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    padding: 15px 0px; */
}

.chatbot-padding-top-reduce {
  padding-top: 0px;
}

.chatbot_main_app_marketplace_box {
  padding-top: 30px;
}

.chatbot-card-div-marketplace {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 10px 0px;
  justify-content: space-between;
  transition: box-shadow 0.3s;
  border: 1px solid #c4c2c2;
  border-radius: 6px;
}

.bottom-space-workflow-search {
  margin-bottom: 30px;
}

.chatbot-top-div-marketplace {
  display: flex;
  padding-bottom: 60px;
}

.chatbot-bottom-div-marketplace {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  border-top: 1px solid #0000001a;
}

.app-chatbot-content-marketplace {
  color: #333333 !important;
  margin-top: 8px;
}

.dialog-overflow-text-marketplace {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}

.app-chatbot-icon-marketplace {
  width: 24px;
  height: 24px;
  margin: 10px 18px 10px 14px;
}

.chatbot-count-marketplace {
  color: #416183;
}

.header_container_back_app_market_second {
  display: flex;
  align-items: center;
  gap: 10px;
  float: left;
}

.header_container_back {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: fit-content;
  padding: 6px 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2468f6;
  border-radius: 24px;
  color: #2468f6;
}

.chatbot-card-div-marketplace:hover {
  box-shadow: 0px 10px 20px #0000001a;
  cursor: pointer;
}

.header_container_back img {
  width: 38px;
  height: 38px;
  margin-bottom: 8px;
}

.header_container_back_chatbot {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 26px;
}

.marketplace_wrapper_chatbot_pages {
  padding-top: 40px;
  padding-bottom: 60px;
}

.download_section_marketplace {
  width: 100%;
  background-color: white;
  padding: 20px 30px 20px 30px;
  border: 1px solid #c4c2c2;
}

.download_section_chat_bot_market {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unlock_account_sub_header {
  display: inline;
}

.download_button_marketplace a {
  text-decoration: none;
}

.second_section_marketplace_appworkflow {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  width: 100%;
  height: 600px;
  border: 1px solid #c4c2c2;
  padding: 0 20px;
}

.arrow_back_marketplace {
  width: 18px !important;
  height: 18px !important;
  margin-bottom: 3px !important;
  color: #2468f6;
}

.span-back-arrow {
  position: relative;
  top: 1px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.header-both-right-left-marketplace {
  width: 100%;
  border-bottom: 1px solid #dad9d9;
}

.main-container-dialog-automation {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-left: 30px;
  justify-content: center;
}

.app-market-main-container {
  padding-left: 40px !important;
  padding-right: 0px !important;
  margin-top: 50px;
}

.margin-top-reduce-app-market .main_integrations {
  margin-top: 0px !important;
}

.bottom-space-update-marketplace-container {
  margin-bottom: 26px !important;
}

.header-both-right-left-marketplace img {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.header-both-right-left-marketplace h5 {
  display: inline-block;
  margin-left: 12px;
}

.dialog-marketplace-icon-div {
  padding: 0px 20px 20px;
}

.dialog-marketplace-icon-div h5 {
  color: #2468f6;
}

.icon-div-img-white-background {
  border-radius: 50px;
  border: 1px solid #dcdbdb;
  display: inline-block;
  padding: 8px;
  background: #ffffff;
}

.icon-div-img-blue-background {
  border-radius: 50px;
  display: inline-block;
  padding: 8px;
  background: #2468f6 0% 0% no-repeat padding-box;
}

.chatbot-marketplace-first-container {
  padding: 0px !important;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  margin: 0px !important;
}

.marketplace-chatbot-second-container {
  padding: 0px !important;
}

.chatbot-header-count {
  color: #e57700;
}

.chat-app-icon-marketplace {
  width: 20px;
  height: 20px;
  margin: 0px 10px;
}

.bottom-div-for-marketplace {
  border-top: 1px solid #c4c2c2;
  padding: 20px 20px 10px 20px;
  display: flex;
}

.app-icon-marketplace-first {
  width: 30px;
  height: 30px;
  margin-right: 16px;
  margin-left: 6px;
}

.button_for_download_bots {
  background: #2468f6;
  border-radius: 30px;
  display: inline;
  float: right;
  padding: 8px 20px;
}

.download_button_marketplace {
  background: #2468f6;
  border-radius: 30px;
  display: inline;
  float: right;
  padding: 8px 20px;
  margin-top: 8px;
}

.button_for_download_bots a {
  color: #ffffff;
}

.button_for_download_bots a:hover {
  color: #ffffff;
}

.download_button_marketplace a {
  color: #ffffff;
}

.app-market-main-container-left-side {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dad9d9;
  border-radius: 6px;
  opacity: 1;
  padding: 0px !important;
}

.heading-main-app-left-side {
  padding: 15px;
  border-bottom: 1px solid #dad9d9;
}

.heading-main-app-left-side h5 {
  margin-left: 14px;
}

.app-category-marketplace-arrow {
  margin-right: 10px;
}

.app-arrow-bot-market {
  width: 18px;
  height: 18px;
}

.all-main-list-app-categories {
  padding: 0px;
}

.app-count-category-marketplace {
  color: rgb(233, 134, 31);
  margin-left: 10px;
}

.app-market-main-container-left-side {
  margin-top: 30px;
  height: fit-content;
}

.drop-down-list-app-categories {
  padding: 0px !important;
}

.drop-down-list-app-categories span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sub-drop-down-app-list-menu-marketplace {
  padding: 10px 8px;
  margin: 10px 16px;
}

.app-list-menu-marketplace {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sub-drop-showmore-link {
  padding: 10px 8px;
  margin: 10px 16px;
}

.background-div-app-list-onclick {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c4c2c2;
  padding: 10px;
  border-radius: 6px;
}

.background-div-app-list-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #c4c2c2;
  padding: 10px;
  border-radius: 6px;
  flex-direction: row-reverse;
  gap: 10px;
}

.app-market-left-panel-new {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dad9d9;
  border-radius: 6px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.ai-self-service-chatbot .main-slider-left {
  padding: 0px;
}

.testimonial_background_main_container {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  width: 100%;
}

.new_goto_testimonial_box {
  grid-template-columns: 1.1fr 3fr !important;
}

.testimonial_feedback_box {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  padding: 30px;
  grid-template-columns: auto auto;
}

.testimonial_new_slider .testimonial_feedback_box {
  box-shadow: 0px 3px 6px #00000029;
}

.testimonial_new_slider .carousel-item,
.testimonial_new_slider .carousel-control-prev,
.testimonial_new_slider .carousel-control-next {
  cursor: default !important;
}

.testimonial_feedback_left_image_box {
  display: flex;
  align-items: center;
}

.main-container-section-testimonial-new-goto {
  margin-bottom: 50px;
}

.heading_goto_testimonials_new {
  padding: 0 14%;
}

.testimonial_feedback_right_content_box {
  padding: 0px 20px;
}

.heading_goto_testimonials_new {
  width: 100%;
}

.app-market-main-container-left-side {
  margin-top: 50px;
  height: fit-content;
}

.designation_and_hammer_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 10px;
}

.designation_and_hammer_content_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonial_hammer_logo {
  width: 280px !important;
}

.testimonial_new_slider img {
  box-shadow: none !important;
}

.goto_case_study_button_redirection {
  background: #cbffec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border: 0.5px solid #46b18a;
  border-radius: 28px;
  padding: 7px 20px 5px;
  cursor: pointer;
  color: #000000;
}

.testimonial_new_slider .swipe-chat-channel {
  position: relative;
  bottom: 20px;
  margin-top: 10px !important;
}

.testimonial_new_slider .testimonial_background_main_container {
  margin-bottom: 0px;
}

.testimonial_goto_logo {
  width: 120px !important;
  position: relative;
  bottom: 36px;
}



.rating_box_main_container {
  display: grid;
  grid-gap: 30px;
  grid-auto-flow: column;
  justify-content: center;
  text-align: center;
}

.rating_star_box {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 10px;
}

.rating_left_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
  padding: 40px;
}

.rating_right_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
  padding: 40px;
}

.rating_star_image {
  width: 20px;
  height: 20px;
}

.rating_paragraph_content {
  text-align: center !important;
  margin-top: 16px;
  padding-top: 10px;
}

.capterra_logo_left {
  width: 150px;
}

.g2_logo_right {
  width: 50px;
}

.testimonial_background_main_container_mobile {
  width: 100%;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 38px 18px;
  margin-bottom: 40px;
}

.testimonial_feedback_box_mobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 30px 20px;
}

.testimonial_feedback_top_content_box_mobile p {
  text-align: center;
}

.testimonial_review_bottom_image_box_mobile {
  display: flex;
  gap: 20px;
}

.quotes_image_testimonial {
  width: 16px !important;
  margin-right: 8px;
  margin-bottom: 8px;
}

.top_image_container_testimonials_usecase {
  display: flex;
  align-items: center;
  gap: 6px;
}

.middle_content_container_testimonials_usecase {
  margin: 30px 0px;
}

.main_container_testimonials_usecase {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;
}

.testimonial_feedback_box_mobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 30px 20px;
}

.testimonial_feedback_top_content_box_mobile p {
  text-align: center;
}

.testimonial_review_bottom_image_box_mobile {
  display: flex;
  gap: 20px;
}

.quotes_image_testimonial {
  width: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.top_image_container_testimonials_usecase {
  display: flex;
  align-items: center;
  gap: 6px;
}

.middle_content_container_testimonials_usecase {
  margin: 30px 0px;
}

.main_container_testimonials_usecase {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;
}

.bottom_reviewer_image_container_testimonials_usecase {
  display: flex;
  align-items: center;
  gap: 40px;
}

.end_name_usecase {
  display: flex;
  flex-direction: column;
}

.capterra_usecase {
  margin-right: 16px;
}

.main_container_testimonials_landing_page {
  display: grid;
  grid-gap: 40px;
  grid-auto-flow: column;
}

.left_container_testimonials_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px 20px 20px 40px;
}

.landing-page-left-bottom-testimonials {
  display: flex;
  gap: 30px;
  align-items: center;
}

.testimonial_hammer_logo_landinpage {
  width: 150px;
}

.testimonial_reviewer_image_landing_page {
  width: 150px;
}

.rating_box_main_container_landing {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.rating_top_box_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
}

.rating_bottom_box_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
}

.first-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.second-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.third-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.four-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.five-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.six-background-slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 16px;
}

.paid_main_con .features_change_img-center {
  padding: 0px 0px 40px;
}

.arrow_right_link_marketplace {
  width: 10px;
  height: 10px;
  margin-left: 8px;
}

/* classes for marketplace re work*/

.mp-tab-selected {
  border-bottom: 4px solid #2468f6;
}

.mp-tab-selected h5 {
  color: #2468f6;
}

.mp-tab-selected .icon-div {
  border-radius: 50px;
  display: inline-block;
  padding: 8px;
  background: #2468f6 0% 0% no-repeat padding-box;
}

.icon-div {
  border-radius: 50px;
  border: 1px solid #dcdbdb;
  display: inline-block;
  padding: 8px;
  background: #ffffff;
}

.downshift-ul {
  position: absolute;
  background: white;
  padding: 0;
  border: 1px solid #c4c2c2;
  width: 89.7%;
  border-radius: 0px 0px 6px 6px;
  border-top: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 12px, rgb(0 0 0 / 10%) 0px 3px 6px;
}

.border-radius-bottom-hidden {
  border-radius: 6px 6px 0px 0px;
}

.downshift-li {
  list-style: none;
  height: 48px;
  padding: 10px;
  border-bottom: 1px solid #c4c2c2;
}

.color-link-blue {
  color: #007bff !important;
}

.background-transparent {
  background: transparent !important;
}

.min-height-300 {
  min-height: 300px;
}

.grid-column-3 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-scroll::-webkit-scrollbar-thumb {
  background-color: #b7b6b6;
  border-radius: 3px;
}

.overflow-y-scroll::-webkit-scrollbar {
  width: 6px;
}

.cursor-default {
  cursor: auto !important;
}

.max-height-160 {
  max-height: 286px;
}

.color-orange {
  color: #e57700;
}

.dropdown-wrapper {
  display: flex;
  flex-direction: column;
}

.application_dropdown_content {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.downshift-li:hover {
  background-color: #ffe5d3;
}

.white-space-pre {
  white-space: pre;
}

.color-light-grey {
  color: #666666 !important;
}

.padding-0-10 {
  padding: 0 10px !important;
}

.margin-left-44 {
  margin-left: 44px;
}

.min-height-30 {
  min-height: 30px;
}

.blue-pill {
  background: #d5ffee 0% 0% no-repeat padding-box;
  padding: 2px 8px;
  border-radius: 24px;
  color: #333333;
}

.high-z-index {
  z-index: 9;
}

.higher-z-index {
  z-index: 10;
}

.testimonial_feedback_box_mobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 30px 20px;
}

.testimonial_feedback_top_content_box_mobile p {
  text-align: center;
}

.testimonial_review_bottom_image_box_mobile {
  display: flex;
  gap: 20px;
}

.quotes_image_testimonial {
  width: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.top_image_container_testimonials_usecase {
  display: flex;
  align-items: center;
  gap: 6px;
}

.top_image_container_testimonials_it_support {
  gap: 10px;
  justify-content: center;
}

.middle_content_container_testimonials_usecase {
  margin: 30px 0px 16px;
}

.display-none {
  display: none !important;
}

.it_support_hammer_logo {
  width: 113px;
}

.main_container_testimonials_usecase {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;
}

.bottom_reviewer_image_container_testimonials_usecase {
  display: flex;
  align-items: center;
  gap: 20px;
}

.end_name_usecase {
  display: flex;
  flex-direction: column;
}

.capterra_usecase {
  margin-right: 16px;
}

.main_container_testimonials_landing_page {
  display: grid;
  grid-gap: 40px;
  grid-auto-flow: column;
}

.left_container_testimonials_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px 20px 20px 40px;
}

.landing-page-left-bottom-testimonials {
  display: flex;
  gap: 30px;
  align-items: center;
}

.testimonial_hammer_logo_landinpage {
  width: 150px;
}

.testimonial_reviewer_image_landing_page {
  width: 100px;
}

.rating_box_main_container_landing {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.rating_top_box_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
}

.rating_bottom_box_landing {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
}

.paid_main_con .features_change_img-center {
  padding: 0px 0px 40px;
}

.center-head-left-img-container {
  background-color: #f0fdff;
  padding: 46px 0px;
}

.center-left-img-card-main-div {
  display: flex;
  gap: 20px;
  width: 100%;
}

.ci_single_card_slider_container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.center-left-img-card-sub-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border: 1px solid #c4c2c2;
  border-radius: 6px;
  opacity: 1;
  padding: 20px 0px 15px;
  width: 100%;
}

.center-left-img-card-top-div {
  padding: 0px 20px 40px;
  display: flex;
  gap: 10px;
}

.center-left-img-card-top-div img {
  width: 24px;
  height: 24px;
}

.center-left-img-card-bottom-div {
  border-top: 1px solid #c4c2c2;
  padding: 15px 20px 0px;
}

.center-left-img-card-bottom-div a {
  text-decoration: none !important;
  font-family: "Gordita-Medium";
}

.header-center-chat-bot-channels {
  text-align: center;
  margin-bottom: 40px;
}

.chat-channel-slider-header-bot {
  text-align: center !important;
  margin-bottom: 16px !important;
}

.card-slider-bamboo-chatbot {
  padding: 20px 0px 14px !important;
}

.card-slider-top-bamboo-chatbot {
  padding-left: 14px;
  padding-right: 14px;
}

.card-slider-bottom-bamboo-chatbot {
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
}

.card-slider-bottom-bamboo-chatbot a {
  text-decoration: none;
}

.chatbot-channel-testimonial-rating-container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 40px 0 60px;
  width: 100%;
}

.chatbot-channel-testimonial-rating-container .testimonial_background_main_container {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.blog-main-container-chat-bot {
  width: 100%;
  margin-top: 35px;
}

.center-left-img-card-top-div img {
  width: 24px;
  height: 24px;
}

.center-left-img-card-bottom-div {
  border-top: 1px solid #c4c2c2;
  padding: 15px 20px 0px;
}

.center-left-img-card-bottom-div a {
  text-decoration: none !important;
  font-family: "Gordita-Medium";
}

.header-center-chat-bot-channels {
  text-align: center;
  margin-bottom: 40px;
}

.image-full-width-chatbot-channels {
  width: 100% !important;
}

.chat-channel-slider-header-bot {
  text-align: center !important;
  margin-bottom: 16px !important;
}

.text-overflow-ellipsis-bot {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}

.card-slider-bamboo-chatbot {
  padding: 20px 0px 14px !important;
}

.card-slider-top-bamboo-chatbot {
  padding-left: 14px;
  padding-right: 14px;
}

.card-slider-bottom-bamboo-chatbot {
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
}

.card-slider-bottom-bamboo-chatbot a {
  text-decoration: none;
}

.chatbot-channel-testimonial-rating-container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 40px 0 60px;
  width: 100%;
}

.chatbot-channel-testimonial-rating-container .testimonial_background_main_container {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.blog-main-container-chat-bot {
  width: 100%;
  margin-top: 35px;
}

.dialog-box-chat-bot-marketplace {
  border-radius: 10px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  justify-content: space-between;
  transition: box-shadow 0.3s;
  border: 1px solid #c4c2c2;
}

.dialog-box-chat-bot-marketplace-img {
  width: 30px;
  height: 30px;
  margin: 10px 10px 10px 14px;
}

.dialog-box-description-div {
  margin-top: 17px;
}

.dialog-box-description-div p {
  margin: 0;
  padding: 0;
}

.dialog-top-div-marketplace-chatbot {
  display: flex;
  padding: 10px 10px 10px 0px;
}

.dialog-bottom-div-marketplace-chatbot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0px 20px;
  border-top: 1px solid #0000001a;
}

.dialog-box-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 0px;
}

.page-not-found-image {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.ibm-watson-assistants-main-container .landing_bg.bg_header_trial.trial_mb_80 {
  padding-top: 30px;
  margin-bottom: 0px !important;
}

.ibm-watson-assistants-main-container .ibm-left-section-bottom {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.ibm-watson-assistants-main-container .display-none-all-features {
  display: none;
}

.ibm-watson-top-second-section {
  width: 100%;
  margin-top: 46px;
  margin-bottom: 46px;
}

.ibm-watson-top-second-section .swipe-chat-channel {
  margin-top: 20px;
}

.ibm-watson-tab-section {
  width: 100%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin-bottom: 46px;
  padding-top: 30px;
}

.ibm-watson-tab-section .int_landing_page .rwt__tablist.horizontal_slider {
  justify-content: space-between;
  border-bottom: 2px solid #dddddd !important;
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.ibm-watson-tab-section .int_landing_page .horizontal_slider .rwt__tab {
  border-bottom: none;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
}

.ibm-watson-tab-section .int_landing_page .horizontal_slider.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
}

.ibm-watson-tab-section .int_landing_page .trial_page_img.bg_trial_page {
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.features_usecases_ibm_container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 26px;
}

.list-ibm-usecases {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 0;
}

.features_usecases_ibm_container li {
  display: flex;
  gap: 10px;
  align-items: center;
}

.features_usecases_ibm_container img {
  width: 20px;
  height: 20px;
}

.features_usecases_ibm_sub_container {
  display: flex;
  justify-content: space-around;
}

.ibm_watson_faq_container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 30px 0 30px;
  margin-bottom: 36px;
}

.ibm_watson_faq_container.mob_accordian.mob_accordian_faq .accordion__button:before {
  top: 22px;
}

.card-ibm-watson-assistant {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
}

.card-one-ibm {
  padding: 30px 20px 30px;
  background: #fffaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001f;
  border-radius: 10px;
}

.card-two-ibm {
  padding: 30px 20px 30px;
  background: #fff2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001f;
  border-radius: 10px;
}

.card-three-ibm {
  padding: 30px 20px 30px;
  background: #f0fdff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001f;
  border-radius: 10px;
}

.card-four-ibm {
  padding: 30px 20px 30px;
  background: #f6efff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001f;
  border-radius: 10px;
}

.chatbot-guide-blog-main-container .container_trial .market_wrapper_page p {
  margin-bottom: 10px;
}

.chatbot-guide-blog-main-freshservice .video_prompt2 {
  height: 450px;
}

.chatbot-guide-blog-main-container .video_prompt2 {
  height: 450px;
}

.width-90 {
  width: 90% !important;
}

.features-top-left-section img {
  width: 64px;
  margin-bottom: 20px;
}

.features-top-flex-section {
  display: flex;
  align-items: center;
}

.features-bottom-flex-section {
  display: flex;
  margin-top: 30px;
  vertical-align: baseline;
}

.features-top-flex-section button {
  background: #2468f6;
  color: #ffffff;
  border-radius: 32px;
  padding: 10px;
  text-align: center;
  width: 43%;
  border: none;
  cursor: pointer;
}

.features-top-left-section p {
  padding-right: 20px;
}

.features-top-right-section {
  padding-right: 23px;
}

.feature-page-section-first-automate {
  width: 100%;
}

.feature-page-section-second-automate {
  width: 100%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 40px 0px;
  margin: 26px 0px 46px;
}

.feature-page-section-third-automate {
  width: 100%;
  margin-bottom: 26px;
}

.feature-page-section-fourth-automate {
  width: 100%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 40px 0px;
}

.feature-page-section-five-automate {
  width: 100%;
  margin: 46px 0px 26px;
}

.corousel-image-center {
  display: flex;
  justify-content: center;
}

.feature-automate-new-workflow .onboarding_background {
  padding-top: 0px !important;
}

.demo-page-before-tick::before {
  content: url("../../assets/images/onboarding/onboarding_tick.svg");
  position: relative;
  top: 3px;
  margin-right: 15px;
  width: 20px;
  display: inline-block;
}

.demo-page-before-tick {
  display: flex;
}

.demo_automate_new .three_steps_wrapper {
  margin-bottom: 30px;
}

.demo_automate_new .skit_contactus.roi_page_contact {
  background: #f2fffa 0% 0% no-repeat padding-box;
}

.demo_automate_new ._button-wrapper._full_width button {
  border-radius: 34px;
}

.demo_automate_new ._button-wrapper._full_width button:hover {
  border-radius: 34px;
}

.demo_automate_new ._submit.btn-deactivated {
  opacity: 1;
}

.iso_image_why_auto {
  width: 64%;
  align-self: center;
  margin-top: 20px;
}

.why-automate-right-main-flex {
  display: flex;
  flex-direction: column;
  padding-right: 40px;
}

.why-automate-customer-banner img {
  width: 90%;
}

.roi-left-side-content {
  margin-top: 26px;
}

.roi-top-header {
  margin-bottom: 10px !important;
  margin-top: 30px;
}

.demo_automate_new .icon_arrow {
  position: absolute;
  top: -2%;
  left: 2%;
  right: 0;
  width: 50px;
  height: 50px;
}

.demo-left-automate-content {
  width: 100%;
  float: left;
  margin-top: 28px;
}

.why-automate-customer-banner {
  margin-top: 40px;
}

.demo_automate_new .why_autoamte_wrapper {
  margin-bottom: 40px !important;
}

.banner-header-why-automate {
  margin-bottom: 24px;
}

.demo_automate_new .skit_contactus {
  padding-bottom: 50px !important;
}

.roi-form-text-last {
  margin-top: 30px;
}

.automate_home_page_onboarding {
  margin-bottom: 20px;
}

.nav-bar-left img {
  position: relative;
  bottom: 1px;
}

.new-automate-pricing-enterprise-wrapper-left {
  flex-direction: row !important;
  padding: 0px 20px !important;
}

.pricing-border-enterprise {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #009d5f;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  padding: 12px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-automate-enterprise-wrapper-center {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 20px 0px 10px;
  align-items: center;
  justify-content: center;
}

.new-automate-enterprise-wrapper-center p {
  padding: 0px 30px;
}

.new-automate-enterprise_wrapper_right button {
  border: 1px solid #15c381;
  background: #defff2 0% 0% no-repeat padding-box;
  border-radius: 24px;
  color: #333333;
  transition: .3s;
}
.new-automate-enterprise_wrapper_right button:hover{
  width: 94%;
  height: 50px;
  transition: .3s;
  border: 1px solid #000000;
  border-radius: 26px;
}

.automate-new-professional-section-pricing {
  padding: 50px 0px !important;
}

.automate-new-professional-section-pricing .main-slider-left h1 {
  margin-bottom: 16px !important;
}

.plan-new-pricing {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.plan-new-pricing h6 {
  background: #fff2de 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  padding: 14px 20px;
}

.plan-new-pricing-assistants {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.plan-new-pricing-assistants h6 {
  background: #e5ffe5 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  padding: 14px 20px;
}

.card-automate-box p {
  padding-right: 46px;
}

.card-box-home-main-container {
  justify-content: space-between;
  gap: 40px;
}

.card-box-home-main-container button {
  border: 1px solid #2468f6;
  border-radius: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #2468f6;
  padding: 10px;
  cursor: pointer !important;
}

.card-box-home-main-container button span {
  position: relative;
  left: 10px;
}

.homepage-free-bot-button {
  border-radius: 32px;
  background: #2468f6 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 8px 0px;
  border: 1px solid #2468f6;
}

.homepage-free-bot-button:focus {
  border: 1px solid #2468f6;
}

.homepage-book-demo-button {
  border: 1px solid #2468f6;
  border-radius: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #2468f6;
  padding: 8px 0px;
}

.plans-home-page-new {
  justify-content: space-between;
  width: 62%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.plans-home-page-new span {
  color: #333333 !important;
}

.card-box-home-main-container h5 {
  color: #416183 !important;
}

.card-assistant-box {
  padding-left: 0px !important;
}

.card-automate-box {
  padding-right: 0px !important;
}

.assistant-main-home-page-container {
  background: #f2efff 0% 0% no-repeat padding-box;
  margin-bottom: 26px;
  padding-top: 60px;
}

.testimonial_background_main_container-home-page {
  margin-bottom: 56px;
}

.card-box-section-container {
  margin-bottom: 56px;
}

.automate-main-home-page-container {
  background: #f2efff 0% 0% no-repeat padding-box !important;
}

.swipe-button-home {
  width: 100%;
  float: left;
  position: absolute;
  bottom: 110px;
}

.homepage-new-corousel .carousel {
  width: 100%;
  float: left;
}

.homepage-new-corousel .carousel-item {
  cursor: auto !important;
}

.new-pricing-page-automate:before {
  content: "";
  position: absolute;
  background: #f2fffa 0% 0% no-repeat padding-box;
  width: 100%;
  height: calc(87% - 148px);
  top: 0;
  margin-top: -80px;
}

.new-pricing-page-automate .pricing-columns.pricing-section {
  margin-top: 60px;
}

.new-pricing-page-automate .discount_price {
  height: 24px !important;
}


.new-single-app-container {
  box-shadow: 10px 10px 40px #0000001a;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 20px 30px 40px;
  border-radius: 4px;
  flex: 1 1 0;
  width: 0;
}

.popular-learn-more-link-two {
  position: relative;
  top: 32px;
}

.new-single-app-main-container {
  gap: 50px;
  position: relative;
}

.main-popular-integration {
  margin-bottom: 46px;
}

.new-single-app-container a {
  color: #2468f6 !important;
  position: absolute;
  bottom: 20px;
}

.customer-home-container {
  flex-direction: column;
}

.customer-home-container img {
  width: 80%;
  align-self: center;
}

.customer-home-container-new {
  flex-direction: column;
}

.customer-home-container-new img {
  width: 92%;
  align-self: center;
}

.autoamte_apps_header_right .search_marketpalce .search_click::placeholder {
  font-family: "Gordita-Regular";
  color: #7a94af !important;
}

.autoamte_apps_header_right .search_marketpalce .new-single-app-workflow::placeholder {
  color: #bababa !important;
}
.search_click.double_app_automate_integration_input::placeholder{
  color:#BABABA !important;
}
.double_app_automate_integration_search_icon svg{
  color:#474747 !important;
  fill:#474747 !important;
}
.new_automate_background .main-slider-button.beta-button input::placeholder {
  color: #555555 !important;
}

.demo_automate_new .main-slider-button.beta-button input::placeholder {
  color: #555555 !important;
}

.customer-banner-auto-mobile-pricing h5 {
  color: #193a5d;
}

.new-automate-enterprise-wrapper-center .color-new-enterprise,
.new-automate-enterprise-wrapper-center .enterprise_message {
  line-height: 1.5;
}

.card-automate-box-top-header img,
.card-assistant-box-top-header img {
  width: 64px;
  height: 64px;
}

.card-automate-box-top-header,
.card-assistant-box-top-header {
  margin-bottom: 10px;
  gap: 16px;
  flex-direction: column;
}

.iso-image-section-home {
  margin-bottom: 56px;
}

.customer-banner-home-main-container {
  margin-top: 3rem;
}

.join-banner-cutomer-home {
  margin-bottom: 2rem;
}

.automate-center-image-content-second .automate-center-content {
  margin-bottom: 10%;
}

.automate_onboarding_home_new {
  color: #416183 !important;
}

.roi_input_automate::placeholder {
  font-family: Gordita-Regular !important;
  font-size: 11px !important;
}

.automate_home_page_onboarding #Progress_Status4 {
  width: 0%;
}

.automate_home_page_onboarding .bar_container {
  margin: 0 auto;
  margin-top: 30px;
  width: 84%;
}

.button_navbar_automate {
  text-transform: none !important;
}

.home_page_new_workativ .basic_plans {
  display: none;
}

.home_page_new_workativ .home_page_button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_page_new_workativ .form_below_content.signup_cta {
  justify-content: center;
}

.home_page_new_workativ .main-slider-button.beta-button form.sign_up_form {
  width: 48%;
}

.new_automate_background .onboarding_slider_step_text {
  color: #333333 !important;
}

.coming_soon_automate_new {
  background: #fff2de 0% 0% no-repeat padding-box;
  border-radius: 26px;
  padding: 10px 20px 8px;
  width: 26%;
  text-align: center;
}

.new_automate_background .coming_soon_automate_new {
  width: 20%;
  text-align: center;
}

.card_automate_box_paragraph,
.card_assistants_box_paragraph {
  margin-bottom: 0px !important;
  color: #416183 !important;
}

.card_automate_box_paragraph_two,
.card_assistants_box_paragraph_two {
  margin-bottom: 10px !important;
  color: #000000 !important;
}

.no_code_wrapper_small h4 {
  margin-right: 20px !important;
}

.demo-testimonials-parent {
  margin-bottom: 40px;
}

.testimonial_new_slider .swipe-one {
  cursor: pointer !important;
}

.testimonial_new_slider .testimonial_reviewer_image {
  width: 90%;
}

.testimonial_new_slider .testimonial_feedback_box {
  grid-gap: 0;
}

.line-height-18 {
  line-height: 1.8 !important;
}
.line-height-15{
  line-height: 1.5 !important;
}

.pricing_footer_color {
  text-decoration: none !important;
}

.pricing_footer_color h4:hover {
  text-decoration: underline;
}

.rating_goto_new .testimonial_feedback_box {
  grid-gap: 20 !important;
}

.goto_case_section_desk {
  background-image: url("../../assets/images/testimonials/banner_bg.png");
  padding-bottom: 50px;
  margin-bottom: 40px;
  background-position: bottom;
  background-size: cover;
}
.goto_case_section_desk.cognosante_case_section_desk{
  background-image: url("../../assets/images/testimonials/cognosante_banner_bg.png");
  background-size: contain;
  background-position: top;
}

.goto_case_left_top {
  margin-top: 40px;
}

.goto_case_right_top {
  margin-top: 50px;
}

.goto_case_left_top h5,
.goto_case_left_top h2 {
  margin-bottom: 35px;
}

.goto_case_grid_container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
}

.cards_goto_case {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.cards_goto_case div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001a;
  border-radius: 16px;
  padding: 30px 20px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.card_one_case h2 {
  color: #f59729;
}

.card_two_case h2 {
  color: #ba42ef;
}

.card_three_case h2 {
  color: #1bdbc5;
}

.goto_case_right_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001a;
  border-radius: 16px;
  padding: 40px 0px 24px 24px;
}

.right_span_goto_case {
  text-decoration: underline;
  text-decoration-color: #ffe900;
  color: #000000;
  text-decoration-thickness: 2px;
}

.heart_image_goto_case {
  width: 32px;
  height: 32px;
}
.heart_image_header {
  width: 28px;
  height: 28px;
}
.grid_container_goto_second {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.grid-left-container-goto {
  width: 80%;
}

.redirection-flex-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.redirection-flex-container a{
  color: #000 !important;
}
.redirection-flex-container a:hover{
  color: #007bff !important;
}
.redirection-flex-container-blog{
  display: flex;
  flex-direction: column;
  gap: 18px;
}





.grid-left-container-goto h2 {
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: #b2b2b2 !important;
}

.sticky_container_goto_case {
  position: sticky;
  overflow: visible;
  z-index: 9;
  top: 120px;
  bottom: 200px;
}

.heading_goto_second_case {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-color: #ff149b !important;
}

.case_study_second_card {
  background: #edf1ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 34px 28px;
  margin-top: 40px;
}

.goto_case_book_demo_button {
  background: #2468f6;
  color: #fff;
  border-radius: 32px;
  padding: 10px;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
}

.content_goto_logo {
  width: 7%;
}

.solution_case_list_container {
  background: #e4e2ff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding: 30px 60px;
  margin: auto;
  width: 86%;
}

.solution_list_left_flex,
.solution_list_right_flex {
  flex-direction: column;
  width: 100%;
  display: flex;
  gap: 14px;
}

.solution_case_list_container h3 {
  color: #193a5d;
}

.underline_solution_heading {
  border: 1px solid #a4a0e6;
}

.solution_list_flex_container img {
  width: 16px;
}

.solution_list_flex_container {
  display: flex;
  padding: 0px 35px;
}

.solution_img_case_goto {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 42%;
}

.why_goto_case_section {
  display: flex;
  gap: 20px;
  border: 2px solid #000000;
  border-radius: 20px;
  margin-bottom: 40px;
}

.why_goto_case_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 20px;
  min-width: 35%;
}

.why_goto_case_right {
  background: #fff586 0% 0% no-repeat padding-box;
  border-left: 2px solid #000000;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 30px 20px;
}

.goto_quote_icon_case {
  width: 26%;
  position: relative;
  top: 30px;
}

.why_goto_bottom_destination {
  display: flex;
  justify-content: space-between;
}

.why_goto_bottom_destination img {
  width: 16%;
}

.why_goto_order_list li {
  list-style-type: none;
}

.why_goto_order_list {
  padding-left: 0px;
  line-height: 2.5;
}

.why_goto_order_list li span {
  color: #2b53ff;
  margin-right: 16px;
}

.last_text_case_goto {
  margin: auto;
  width: 84%;
  color: #193a5d;
}

.solutions_points_goto_case {
  display: inline-flex;
}

.mobile_accordian_title_goto_case {
  border-bottom: 1px solid #b9b9b9;
}

.arrow_goto_case_study_mobile {
  width: 7%;
  transition: transform 0.7s ease-in-out;
}

.mobile_accordian_title_goto_case {
  border-bottom: 1px solid #b9b9b9;
  display: flex;
  justify-content: space-between;
}

.goto_popup_case {
  background: #e8fcff 0% 0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 20px 0px;
  width: 394px;
  padding: 20px 40px 40px;
  position: fixed;
  bottom: 0;
  left: -500px;
  z-index: 12;
  animation: slide 1s forwards;
  animation-delay: 5s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.goto_popu_button {
  background: #2468f6 0% 0% no-repeat padding-box;
  border-radius: 50px;
  padding: 7px 10px;
  width: 55%;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.goto_logo_goto_popup {
  width: 26%;
}

.goto_top_popup_container {
  display: flex;
  justify-content: space-between;
}

.goto_top_popup_container h3 {
  margin: auto;
  text-decoration: underline;
}

.goto_bottom_popup_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close_popup_goto {
  position: absolute;
  right: 11px;
  top: 14px;
  width: 24px;
}

.cookies_popup_container {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 20px 0px;
  width: 15%;
  padding: 24px 15px;
  position: fixed;
  right: -500px;
  top: 0px;
  z-index: 10000;
  animation: bottomslider 1s forwards;
  animation-delay: 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

@keyframes bottomslider {
  100% {
    right: 0;
  }
}

.cookies_popup_button {
  background: #2468f6 0 0 no-repeat padding-box;
  padding: 3px 10px 5px;
  width: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 30px;
}

.cookies_new_table,
.cookies_new_table th,
.cookies_new_table td {
  border: 1px solid black;
  border-collapse: collapse;
}
.w-15{
width: 15% !important
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}
.w-60 {
  width: 60% !important;
}
.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.new_home_page_main_container.bg_header_trial.trial_mb_80 {
  padding-bottom: 0 !important;
}

.new_home_page_main_container .ibm-left-section-bottom {
  padding-bottom: 40px;
}

.ad_home_page_main_container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 55px 0px 60px;
  margin-bottom: 40px;
}

.ad_home_page_main_container h4 {
  padding: 0 20%;
}

.ad_flex_container_assist {
  display: flex;
  gap: 30px;
  justify-content: center;
}

.ad_container_one_assist,
.ad_container_two_assist,
.ad_container_three_assist {
  background: #f4faff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 20px;
  padding-top: 30px !important;
  max-width: 30%;
  border-bottom: 20px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ad_flex_container_assist img {
  padding-left: 15px !important;
}

.ad_container_one_assist h5 {
  padding-left: 10%;
  padding-right: 10%;
  color: #555555;
}

.ad_container_two_assist h5 {
  padding-left: 10%;
  padding-right: 10%;
  color: #555555;
}

.ad_container_three_assist h5 {
  padding-left: 20%;
  padding-right: 20%;
  color: #555555;
}

.iso_new_main_container img {
  width: 100px;
  height: 100px;
  display: flex;
  margin-bottom: 10px !important;
}

.support_it_hr_main_container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin-bottom: 50px;
  padding: 50px 0;
}

.support_it_hr_main_container h4 {
  padding: 0px 20%;
  margin-bottom: 26px !important;
}

.support_heading_container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.support_switch_heading_click {
  background: #cbffec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 15px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
  cursor: pointer;
}

.support_switch_heading {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 15px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
  cursor: pointer;
}

.support_content_container {
  display: grid;
  grid-auto-flow: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 20px;
  padding: 50px 40px 0px 34px;
  grid-template-columns: 1fr 1fr;
}

.heading_services_support_home {
  margin-bottom: 34px;
  border-bottom: 2px solid #70d5b0;
  width: fit-content;
}

.support_content_right_container {
  align-self: end;
}

.support_content_left_container {
  padding-bottom: 50px;
}

.support_content_left_container.feature_container {
  width: 95%;
}

.support_content_right_container.feature_img {
  position: relative;
  bottom: 30px;
}

.support_content_left_container.fearure_last_img {
  padding-bottom: 30px;
}

.support_workativ_heading {
  color: #666666;
}

.support_content_container .icons_container_onboarding {
  margin-top: 10%;
}

.feature_main_container {
  background: #EAF5FF 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
  padding: 55px 0 50px;
}

.feature_heading_container {
  display: flex;
  justify-content: center;
  gap: 14px;
}

.feature_content_container {
  display: grid;
  grid-auto-flow: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 20px;
  padding: 40px;
  grid-template-columns: 1fr 1fr;
}
.feature_content_container.content-box-assitant-home-features{
  display: grid;
  grid-template-columns: 1fr 1.9fr;
  gap:30px;
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.feature_switch_heading_click {
  background: #cbffec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 20px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
}

.feature_switch_heading {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 20px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
}

.feature_content_left_container {
  align-self: center;
  padding-right: 10%;
}

.feature_content_right_container,
.onboard_content_right_container {
  align-self: center;
}
.top-30{
  top: 30px !important;
}

.feature_content_left_container .icons_container_onboarding {
  position: relative;
  top: 66px;
}

.support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
  position: relative;
}

.parent_container_support_feature_onboard {
  display: flex;
  gap: 30px;
  justify-content: center;
}

.left_arrow_round_home {
  box-shadow: inset 0px -2px 0px #46b18a;
  border: 0.5px solid #46b18a;
  border-radius: 50%;
  background: #fff;
  height: fit-content;
  align-self: center;
  align-items: center;
  display: flex;
  padding: 16px;
  justify-content: center;
  cursor: pointer;
}

.right_arrow_round_home {
  box-shadow: inset 0px -2px 0px #46b18a;
  border: 0.5px solid #46b18a;
  border-radius: 50%;
  background: #fff;
  height: fit-content;
  align-self: center;
  align-items: center;
  display: flex;
  padding: 16px;
  justify-content: center;
  cursor: pointer;
}

.left_arrow_round_home img {
  width: 20px;
  height: 20px;
}

.right_arrow_round_home img {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}

.onboarding_main_container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
  padding: 50px 0 55px;
}

.main-container-section-testimonial-new-goto .footer_form {
  margin-bottom: 50px !important;
}

.onboard_heading_container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.onboard_switch_heading_click {
  background: #cbffec 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 20px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
}

.onboard_switch_heading {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -2px 0px #46b18a;
  border-radius: 28px;
  padding: 7px 20px 7px;
  border: 0.5px solid #46b18a;
  text-align: center;
}

.onboard_content_container {
  display: grid;
  grid-auto-flow: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 20px;
  padding: 40px;
  grid-template-columns: 0.6fr 1fr;
  grid-gap: 20px;
}

.onboard_content_left_container {
  align-self: center;
  padding-right: 10%;
}

.onboarding_main_container .rating_left_box,
.onboarding_main_container .rating_right_box {
  padding: 30px 40px;
}

.onboarding_main_container .rating_box_main_container {
  margin-bottom: 50px;
}

.color-grey {
  color: #666666 !important;
}

.color-blue {
  color: #1857dc !important;
}
.fill-blue{
  fill: #1857dc !important;
}

.color-anchor-blue {
  color: #2468f6 !important;
}

.color-light-black {
  color: #333333 !important;
}

.color-light-gray {
  color: #444444 !important;
}

.color-apps-blue {
  color: #193a5d !important;
}

.text-underline-offset {
  text-underline-offset: 8px;
}

.min-height-55 {
  min-height: 45px !important;
}

.width-85 {
  width: 85px !important;
}

.it_support_content_container {
  display: grid;
  grid-auto-flow: column;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 20px;
  padding: 50px 0px 0px 34px;
  grid-template-columns: 1fr 1fr;
}

.it_support_content_left_container {
  padding-right: 23%;
  padding-bottom: 30px;
  align-self: center;
}

.it_bot_main_container {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
  padding: 40px 0;
  padding-top: 60px;
}

.bottom_button_content_container_testimonials {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-fit-content {
  height: fit-content !important;
}

/* ADDED BY SELVA FOR NEW CONVERSATION PAGE */

.arrow-disabled {
  cursor: default !important;
  opacity: 0.3;
}

.mobile-dropdown {
  position: absolute;
  width: 90%;
  background: white;
  padding: 10px;
  margin-top: -25px;
  margin-left: 5px;
}

.it-help-page .ad_container_one_assist,
.it-help-page .ad_container_two_assist,
.it-help-page .ad_container_three_assist {
  max-width: 32%;
}

.onboarding_testimoanials_main_container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding-bottom: 50px;
}

.onboarding_testimoanials_main_container .onboarding_main_container {
  margin-bottom: 0px;
  padding: 50px 0 55px;
  background: none;
}

.onboarding_testimoanials_main_container .rating_box_main_container,
.support_chatbot_it_help .onboarding_main_container .rating_box_main_container {
  display: none;
}

.testimonials_container_support {
  width: 86%;
  margin: auto;
}

.onboarding_testimoanials_main_container .middle_content_container_testimonials_usecase {
  margin: 14px 0px 16px;
}

.it_support_left_content_container {
  align-self: center;
  padding-right: 10%;
  padding-bottom: 0;
}

.it_support_right_content_container {
  align-self: center;
}

.align-self-end {
  align-self: end !important;
}

.support_chatbot_it_help .support_it_hr_main_container .support_content_container {
  padding: 40px;
}

.button_usecase_it_support {
  width: 26% !important;
}
.color-header-black{
  color: #333333 !important;
}
.right_container_tab_slider_it_suuport {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1.6fr;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}

.left_cards_tab_appworkflow {
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  z-index: 1;
}
.right_cards_tab_appworkflow{
  position: relative;
}
.right_cards_tab_appworkflow img {
  max-width: 100%;
}
.workflow_arrow_svg {
  width: 70%;
  position: relative;
  left: 42%;
}

.cards_all_main_container_app_workflow {
  display: flex;
  flex-direction: column;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #00000033;
  border-radius: 10px;
  margin-top: 10px;

}

.selected_tab_workflow {
  background: #EDFFF2 0% 0% no-repeat padding-box;
}

.cards_single_main_container_app_workflow {
  padding: 14px 18px 12px;
  border-bottom: 1px solid #D2D3D2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cards_single_main_container_app_workflow img {
  position: relative;
  bottom: 1px;
  width: 10px;
  height: 10px;
}

.border-bottom-none {
  border-bottom: none;
}

.support_tab_right_arrow_mobile {
  width: 35px;
  transform: rotate(180deg);
}

.support_tab_left_arrow_mobile {
  width: 35px;
}

.padding_for_ai_first_container {
  padding: 30px 40px !important;
}

.it_app_workflow_mobile_tab_slider {
  display: flex;
  background: #EDFFF2 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #00000033;
  border-radius: 10px;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.external_slider_links {
  cursor: pointer;
  color: #2e6dfa !important;
  font-family: Gordita-Medium !important;
}

.chat_channels_page_updated .feature_inner_page .signup_cta_p {
  display: none !important;
}

.tab_slider_features_second_container .support_it_hr_main_container h4 {
  display: none;
}

.tab_slider_features_second_container .support_heading_container {
  margin-bottom: 16px !important;
}

.support_chatbot_it_help.features_updated_nov .onboarding_main_container .rating_box_main_container {
  display: grid;
}

.features_updated_nov .onboarding_testimoanials_main_container {
  padding-top: 50px;
  margin-bottom: 50px;
}

.features_updated_nov .footer_form {
  margin-bottom: 40px;
}

.features_all_case_parent_div .support_it_hr_main_container {
  margin-bottom: 0;
  padding: 0;
  background: none;
}

.features_all_case_parent_div {
  background-color: #F8F8F8;
  padding: 50px 0;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.c_integration_slider_box {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 20px #0000001A !important;
  border-radius: 6px;
  justify-content: flex-start;
  background: #fff;
  padding: 20px 0px 0px !important;
  margin: 12px 0 0 !important;
}

.c_integration_slider_top {
  display: flex;
  flex-direction: column;
  min-height: 80px;
  margin-bottom: 0;
  padding: 0 30px 0 18px;
  gap: 6px;
}

.c_integration_slider_bottom {
  padding: 14px 14px 14px 18px;
  display: flex;
  border-top: 1px solid #dad9d9;
  justify-content: space-between;
  align-items: center;
}

.ci_integration_slider_icon {
  width: 26px !important;
  height: 26px !important;
}

.ci_single_card_slider_main {
  width: 23.5%;
}

.ci_slider_workflow_navigation {
  margin-top: 34px !important;
}

.color-light-blue {
  background-color: #F4FAFF !important;
}
.support_chatbot_it_help.chatbot_integration_parent_container .onboarding_main_container .rating_box_main_container {
  display: grid;
}
.chatbot_integration_parent_container .landing_bg.bg_header_trial.trial_mb_80 {
  padding-bottom: 30px;
}
.ci_workflow_hidden .integrations_slider_bg .featured_content .ci_single_card_slider_container {
  margin-bottom: 0 !important;
}
.ci_slides_header_main{
  margin-bottom: 32px;
}
.alert_signup_mail{
  color: rgb(252, 42, 42) !important;
  position:absolute;
  padding-top: 10px;
}
.home_page_new_workativ .alert_signup_mail{
  align-self: flex-start;
  position: absolute;
  left: 26%;
  top: -20px;
  padding-top: 0;
}
.home_page_new_workativ .request_form_top_slider .form_sign_update{
  margin-top: 0px;
}
.mh-25 {
  min-height : 25px !important;
}
.color-red-req{
 color: #FC2A2A !important; 
}
.request_form_top_slider{
  position: relative;
}
.request_form_top_slider .form_sign_update{
  margin-top: 30px;
}
.popup_automate_update_23 .request_form_top_slider .form_sign_update{
  margin-top: 0px;
}
.request_form_assist_left .alert_signup_mail{
  padding-top: 0;
  top: 2px;
}
.new_home_page_main_container .alert_signup_mail{
  padding-top: 0px;
  top: 4px;
}
.new_home_page_main_container .request_form_top_slider .form_sign_update{
  margin-top: 20px;
}
.signup_mail_alert_automate{
  position: static;
  padding-top:0;
}
.disable_button{
  pointer-events: none;
  opacity: 0.3;
}
.support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow {
  padding: 12px 18px 10px;
} 
.border-1-black{
 border: 1px solid black !important;
}
.features_indvidual_dec .request_form_top_slider .form_sign_update{
  margin-top: 0;
}
.faq_btn_feature_parent{
  width: 100%;
  float:left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.faq_btn_feature{
  display: flex;
  gap:10px;
  align-items: center;
}
.faq_btn_feature img{
  width: 26px;
  height: 26px;
}
.chat_channel_features_parent{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 40px 0;
  margin-bottom: 40px;
}
.feature_ind_chnl_con{
  display: flex;
  width:90%;
  margin:auto;
  gap: 20px;
  align-items: center;
  padding: 10px 10px 10px 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 20px;
}
.color-important-chnl{
  color: #FF1C3D !important;
}
.omni_channel_int_box{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding:20px 20px 30px 30px;
  display: flex;
  flex-direction:column;
}

.chat_omnichannel_icon{
  width: 34px !important;
  height: 34px !important;
  align-self: flex-end;
  margin-bottom: 10px;
}
.marketplace_hr_it_container_upd{
  margin-top:50px;
}
.ibm-watson-assistants-main-container .onboard_customer_join_alternative_main .onboarding_main_container{
  margin-bottom: 0;
}
.automate_update_onboarding_main{
  margin: 40px 0 40px 0;
}
.automate_header_banner_client_logo{
  padding-top: 20px !important;
}
.automate_header_banner_client_logo_feature{
  padding-top: 6px !important;
}
.all_apps_automate_marketplace_integration{
  padding: 70px 0 !important;
}
.automate_feature_workflow_23 .bg_header_trial.trial_mb_80{
  padding-top: 10px;
}
.footer_automate_logo_23 img{
  width: 180px;
}
.margin-bottom-30{
  margin-bottom: 30px !important;
}
.border_box_author_bio{
  border-style: solid;
  border-width: medium;
  padding: 20px;
  margin-bottom: 30px;
}
.color-1931a5d{
  color:#193A5D !important;
}
.blog_image_top_bt{
  margin-top: 10px !important;
  margin-bottom: 26px !important;
}
.blogs_ul_upd li{
  margin-bottom: 16px;
  color:#193A5D !important;
}
.blogs_ul_upd li:last-child{
  margin-bottom: 4px;
}
.blogs_ul_upd li::marker{
  font-size: 1.3em;
}
.divs_blogs_upt{
  margin-bottom: 2rem;
}
.blogs_ol_link_upd li{
 list-style-type: none;
 margin-bottom: 12px;
}
.ol_hr_blogs_upt.blogs_ol_link_upd li{
  margin-bottom: 26px;
  color: #193A5D;
}
.blogs_ol_link_upd li:last-child{
  margin-bottom: 4px;
}
.color-heading-blogs{
  color:#2468F6 !important;
  text-decoration: underline;
}
.margin-ul-btm{
  margin-bottom: 2.2rem !important;
}
.nocode_wrapper_upd{
  width: 100%;
  display: flex;
  padding: 20px 0px 20px 34px;
  gap: 8rem;
  background: #FFF1E6 0% 0% no-repeat padding-box;
  align-items: center;
  justify-content: flex-start;
}
.nocode_wrapper_upd.nocode_wrapper_green{
  background: #E6FFE9 0% 0% no-repeat padding-box;
}
.nocode_wrapper_upd button{
 background: #2468F6 0% 0% no-repeat padding-box;
 border-radius: 32px;
 border:none;
 border: none;
 padding: 16px 0px 10px;
 width: 24%;
}
.nocode_wrapper_upd button a{
  color: #FFFFFF !important;
}
.ml-40{
  margin-left: 40px !important;
}

.ml-30{
  margin-left: 30px !important;
}
.author_bio_parent_cont{
  background: #FFF3E6 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 30px 20px;
}
.author_bio_flex_container{
  display: flex;
  align-items: center;
  gap: 30px;
}
.grid_container_goto_second.grid_container_blogs_content{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 22% 73%;
  grid-gap: 50px;
}
.faq_blog_upd{
  margin-top: 50px;
}
.blog_top_header_upt{
  background-image: url("../../assets/img/blogs/help_header.png");
  height: 780px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.blog_top_header_upt.hr_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_blog_header.png");
}
.blog_top_header_upt.ai_blog_header_upt{
  background-image: url("../../assets/img/blogs/ai_blog_header.png");
}
.blog_top_header_upt.sda_blog_header_upt{
  background-image: url("../../assets/img/blogs/sda_header.png");
}
.blog_top_header_upt.it_support_blog_header_upt{
  background-image: url("../../assets/img/blogs/it_support_blog_header.png");
}
.blog_top_header_upt.hr_help_desk_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_help_desk_blog_header.png");
}
.blog_top_header_upt.itsm_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsm_blog_header.png");
}
.blog_top_header_upt.hr_va_guide_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_va_guide_header.png");
}
.blog_top_header_upt.roi_ch_blog_header_upt{
  background-image: url("../../assets/img/blogs/roi_blog_header.png");
}
.blog_top_header_upt.sdc_blog_header_upt{
  background-image: url("../../assets/img/blogs/sdc_blog_header.png");
}
.blog_top_header_upt.esd_blog_header_upt{
  background-image: url("../../assets/img/blogs/esd_blog_header.png");
}
.blog_top_header_upt.hdsd_blog_header_upt{
  background-image: url("../../assets/img/blogs/hdsd_blog_header.png");
}
.blog_top_header_upt.hsmg_blog_header_upt{
  background-image: url("../../assets/img/blogs/hsmg_blog_header.png");
}
.blog_top_header_upt.tit_blog_header_upt{
  background-image: url("../../assets/img/blogs/tit_blog_header.png");
}
.blog_top_header_upt.gae_blog_header_upt{
  background-image: url("../../assets/img/blogs/gae_blog_header.png");
}
.blog_top_header_upt.aiitsm_blog_header_upt{
  background-image: url("../../assets/img/blogs/aiitsm_blog_header.png");
}
.blog_top_header_upt.why_gpt_blog_header_upt{
  background-image: url("../../assets/img/blogs/why_gpt_blog_header.png");
}
.blog_top_header_upt.itsmdt_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsmdt_blog_header.png");
}
.blog_top_header_upt.product_blog_header_upt{
  background-image: url("../../assets/img/blogs/product_blog_header.png");
}
.blog_top_header_upt.wmt_blog_header_upt{
  background-image: url("../../assets/img/blogs/wmt_blog_header.png");
}
.blog_top_header_upt.tnfie_blog_header_upt{
  background-image: url("../../assets/img/blogs/tnfie_blog_header.png");
}
.blog_top_header_upt.mtbsd_blog_header_upt{
  background-image: url("../../assets/img/blogs/mtbsd_blog_header.png");
}
.blog_top_header_upt.hps_blog_header_upt{
  background-image: url("../../assets/img/blogs/hsp_blog_header.png");
}
.blog_top_header_upt.llm_blog_header_upt{
  background-image: url("../../assets/img/blogs/llm_blog_header.png");
}
.blog_top_header_upt.gai_blog_header_upt{
  background-image: url("../../assets/img/blogs/gai_blog_header.png");
}
.blog_top_header_upt.hris_blog_header_upt{
  background-image: url("../../assets/img/blogs/hris_blog_header.png");
}
.blog_top_header_upt.gaesd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaesd_blog_header.png");
}
.blog_top_header_upt.tvsg_blog_header_upt{
  background-image: url("../../assets/img/blogs/tvsg_blog_header.png");
}
.blog_top_header_upt.lssd_blog_header_upt{
  background-image: url("../../assets/img/blogs/lssd_blog_header.png");
}
.blog_top_header_upt.gaepc_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaepc_blog_header.png");
}
.blog_top_header_upt.gakma_blog_header_upt{
  background-image: url("../../assets/img/blogs/gakma_blog_header.png");
}
.blog_top_header_upt.gaisd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaisd_blog_header.png");
}
.blog_top_header_upt.igikb_blog_header_upt{
  background-image: url("../../assets/img/blogs/igikb_blog_header.png");
}
.blog_top_header_upt.gad_blog_header_upt{
  background-image: url("../../assets/img/blogs/gad_blog_header.png");
}
.blog_top_header_upt.badoo_blog_header_upt{
  background-image: url("../../assets/img/blogs/badoo_blog_header.png");
}
.blog_top_header_upt.gaso_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaso_blog_header.png");
}
.blog_top_header_upt.istaga_blog_header_upt{
  background-image: url("../../assets/img/blogs/istaga_blog_header.png");
}
.blog_top_header_upt.garrt_blog_header_upt{
  background-image: url("../../assets/img/blogs/garrt_blog_header.png");
}
.blog_top_header_upt.dtga_blog_header_upt{
  background-image: url("../../assets/img/blogs/dtga_blog_header.png");
}
.blog_top_header_upt.lgasdc_blog_header_upt{
  background-image: url("../../assets/img/blogs/lgasdc_blog_header.png");
}
.blog_top_header_upt.cefc_blog_header_upt{
  background-image: url("../../assets/img/blogs/cefc_blog_header.png");
}
.blog_top_header_upt.ces_blog_header_upt{
  background-image: url("../../assets/img/blogs/ces_blog_header.png");
}
.blog_top_header_upt.kaes_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaes_blog_header.png");
}
.blog_top_header_upt.weio_blog_header_upt{
  background-image: url("../../assets/img/blogs/ebook_blog_header.png");
}
.blog_top_header_upt.kaeq_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaeq_blog_header.png");
}
.blog_top_header_upt.gaavesd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaavesd_blog_header.png");
}
.blog_top_header_upt.gahs_blog_header_upt{
  background-image: url("../../assets/img/blogs/gahs_blog_header.png");
}
.blog_top_header_upt.kaess_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaess_blog_header.png");
}
.blog_top_header_upt.ceo_blog_header_upt{
  background-image: url("../../assets/img/blogs/ceo_blog_header.png");
}
.blog_top_header_upt.kabp_blog_header_upt{
  background-image: url("../../assets/img/blogs/kabp_blog_header.png");
}
.blog_top_header_upt.gash_blog_header_upt{
  background-image: url("../../assets/img/blogs/gash_blog_header.png");
}
.blog_top_header_upt.itsm_2024_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsm_2024_blog_header.png");
}
.blog_top_header_upt.top_itsm_2024_blog_header_upt{
  background-image: url("../../assets/img/blogs/top_itsm_2024_blog_header.png");
}
.blog_top_header_upt.eoka_blog_header_upt{
  background-image: url("../../assets/img/blogs/eoka_blog_header.png");
}
.blog_top_header_upt.eisdm_blog_header_upt{
  background-image: url("../../assets/img/blogs/eisdm_blog_header.png");
}
.list-style-type-none{
  list-style-type: none !important;
} 
.color-white{
  color: #ffffff !important;
}
.blog_content_parent_container{
  margin-top: 50px;
}
.width-40{
  width: 40% !important; 
}
.slider_automate_new .carousel-control-prev, .slider_automate_new .carousel-control-next{
  cursor: default;
}
.slider_automate_new .swipe-one{
  cursor: pointer;
}
.right_arrow_round_home.onboarding_slide_arrow_automate_home, .left_arrow_round_home.onboarding_slide_arrow_automate_home{
  padding: 14px;
}
.right_arrow_round_home.onboarding_slide_arrow_automate_home img, .left_arrow_round_home.onboarding_slide_arrow_automate_home img{
  width: 20px;
  height: 20px;
}
.right_arrow_round_home.hover_color_change_automate_onboard:hover, .left_arrow_round_home.hover_color_change_automate_onboard:hover{
  background: #CBFFEC 0% 0% no-repeat padding-box;
}
.color-head-blog-blue{
  color:#24285B !important;
}
.nocode_wrapper_upd.nocode_it_support_wrap{
  padding: 20px 20px 20px 34px;
}
.header_product_name{
  font-size: 16px !important;
}
.form_text_area_automate{
 resize: none; 
}
.list-style-type-circle{
  list-style-type: circle !important;
}
.color-blog-main-ul{
  color:#193A5D !important;
}
.gap-2-rem{
  gap: 2rem !important;
}
.policy_div_prnt{
  margin-bottom: 40px;
}
.policy_div_prnt ul{
  color:#193A5D !important;
}
.policy_div_prnt ul li{
  line-height: 1.8;
  margin-bottom: 20px;
}
.policy_div_prnt ul li:last-child{
  margin-bottom: 0px;
}
.list-style-type-square{
  list-style-type:square !important;
}
.pl-6{
  padding-left:4.5rem !important;
}
.pl-5-7{
  padding-left:5.5rem !important;
}
.pl-6-5{
  padding-left:6rem !important;
}
.pl-7{
  padding-left:6.5rem !important;
}
.pl-8-5{
  padding-left:8.5rem !important;
}
.pl-9{
  padding-left:8.5rem !important;
}
.en_blog_p_bg{
  padding: 30px !important;
  background: #cbffec 0% 0% no-repeat padding-box;
}
.gen_ai_enter_para{
  padding: 20px !important;
  border: 1px solid #DBBD58;
  background:#FFFFE6;
  width: 100%;
  float: left;
  margin-bottom: 2rem;
}
.gen_ai_blog_para{
  padding: 20px !important;
  border: 1px solid #DBBD58;
  background:#FFFFE6;
}
.video_page_upt{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.cursor_hover_arrow_bg:hover{
  background: #CBFFEC 0% 0% no-repeat padding-box;
  border: 1px solid #46B18A;
}

.webinar-utube-container .landing_bg.bg_header_trial.trial_mb_80{
  padding:20px 0 0!important;
  margin-bottom: 0 !important;
}
.webinar-utube-container .landing_bg.bg_header_trial.trial_mb_80 .col-lg-6.cont_right {
  justify-content: flex-end !important;
}
.webinar-utube-container .container_trial .landing_bg.bg_header_trial.trial_mb_80  .main-slider-left .basic_plans.signup_cta_p {
  margin-top: 6.25rem !important;
}
.webinar-videos-main-container{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 60px 0 80px;
  margin-bottom: 40px;
}
.webinar-vd-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 50px;
}
.webinar-video-box{
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 20px #0000001A;
  position:relative;
  align-self: flex-end;
  cursor:pointer;
  transition: transform 0.8s ease;
}
.webinar-video-box:hover{
  transform: scale(1.03);
}
.webinar-upcoming {
  background: #F15E4E 0% 0% no-repeat padding-box;
  border-radius: 12px 12px 0px 0px;
  color: #ffff;
  width: fit-content;
  float: right;
  position: absolute;
  top: -24px;
  right:10px;
  padding: 8px 14px 4px;
}
.webinar-top-bottom-box{
  padding: 10px 20px;
  background: #FFFFFF;
}
.date-register-webinar{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.webinar-register-now-button{
  width: fit-content;
  background: #314AF2 0% 0% no-repeat padding-box;
  border-radius: 28px;
  height: 34px;
  padding: 1px 16px 0px;
  border: none;
  color: #fff;
  cursor:pointer;
}
.show-more-webinar{
  background: #2468F6 0% 0% no-repeat padding-box;
  border-radius: 24px;
  color: #ffff;
  border: none;
  width: fit-content;
  padding: 7px 20px;
  align-items: center;
  display: flex;
  margin: auto;
  margin-top: 40px;
  cursor:pointer;
}
.webinar-single-container{
  background: #EDF1FF 0% 0% no-repeat padding-box;
  padding: 20px 0 60px;
  margin-bottom: 60px;
}
.web-single-flex-container{
  display: flex;
  gap:60px;
}
.webinar-single-left-flex{
  display: flex;
  flex-direction: column;
  flex: 0.99;
}
.webinar-single-right-flex{
  display: flex;
  flex-direction: column;
  flex: 0.666;
}
.webinar-speaker-container{
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}
.speaker {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.speaker img {
  width: auto;
  height: auto;
}
.webinar-back-button{
  background: #CBFFEC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 6px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
  margin-bottom: 20px;
}
.webinar-agenda{
  background: #CBFFEC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 6px 16px;
  width: max-content;
  margin-bottom: 20px;
}
.webinar-ul.blogs_ul_upd li::marker {
  font-size: 1.6em;
  color: black;
}
.webinar-back-button svg{
  transform: rotate(180deg);
  width: 20px;
  margin-bottom: 3px;
}
.webinar-back-button svg path{
  fill:#000;
}
.webinar-resources-main-container{
  margin-bottom: 60px;
}
.webinar-resources-flex{
  display:flex;
  gap:30px;
}
.webinar-resource-box{
  display:flex;
  flex-direction: column;
  flex-basis: 33.33%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #0000001A;
  border-radius: 4px;
}
.resource-box-up,
.resource-box-down {
  width: 100%;
}
.resource-box-down {
  padding: 20px;
}
.resource-content-webinar{
  color:#000 !important;
  height: 6rem;
}
.opacity-06{
  opacity: 0.6;
}
.ind_image_web_container{
  position: relative;
}
.play_webinar_div{
  position: absolute;
  top: 8rem;
  justify-content: center;
  display: flex;
  width: 100%;
}
.play_webinar_div img{
  width: 120px;
}
.margin-top-17{
  margin-top: 17px !important;
}
.prcing-top-head-plans{
  padding: 0px 20px !important;
}

.assit-pricing-discnt-upt{
  width: 100%;
  float: left;
  text-align: left;
  padding: 0 20px;
}
.assit-pricing-content-left, .assit-pricing-content-right {
  flex-direction: column !important;
  gap:10px;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.assit-pricing-content-right{
  gap:15.2px !important;
}
.assit-pricing-content-box{
  padding-left: 10px !important;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 10px;
}
.grouping-header-pricing{
  align-self: flex-start !important;
  justify-content: space-between !important;
  width: 100%;
}
.grouping-header-right-pricing svg{
  width: 16px !important;
  height: 16px !important;
}
.message-right-last-child svg{
  width: 16px !important;
  height: 16px !important;
}
.assit-pricing-content-right svg{
  width: 16px !important;
  height: 16px !important;
}
.grouping-header-right-pricing{
  align-self:center !important;
}
.last-before-element-grouping{
  border-top: 1px solid #D0D0D0;
  margin-bottom: 0 !important;
  padding-top: 10px;
}
.plan-wrap-assit{
  padding: 20px 20px 15px !important;
}
.pricing_button_wrap-assist{
  padding:0px !important;
}
.mbt-2{
  margin-bottom: 2px !important;
}
.mtp-1{
  margin-top: 1px !important;
}
.cmng-soon-wrapper-pricing-assist{
  margin-top: 20px !important;
}
.pricing-assit-top-sec{
  font-family: "Gordita-Bold" !important;
}
.automate-testimonials-container{
  background: transparent linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  padding: 40px 0 60px;
}
.automate-testimonials-container.bg-testimonials-automate{
  background:#F8F8F8 0% 0% no-repeat padding-box;
  margin-bottom: 50px;
  padding: 60px 0 !important;
}
.automate-testimonials-container.single-double-automate-testimonials{
  padding: 60px 0 !important;
}
.automate_testimonial_director{
 width: 58%;
}
.automate_testimonial_logo{
  width: 38%;
}
.color-director{
  color:#122E4E !important
}
.automate-testimonials-left-grid{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pl-5-5{
  padding-left: 6rem !important;
}
.cta-blog-container{
  padding-left: 40px;
  display: flex;
  height: 170px;
  border-radius: 6px !important;
  margin-bottom: 50px;
  background-size: cover !important;
  background-position: center !important;
  flex-direction: column;
  justify-content: center;
}

.bg-blue-cta-btn{
  background: #FFF474 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -3px 0px #000000;
  border-radius: 34px;
  width: 25%;
  padding: 10px 20px;
  cursor:pointer;
  border:none;
}
.bg-green-cta-btn{
  background: #8FF3AD 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -3px 0px #000000;
  border-radius: 34px;
  width:25%;
  padding: 10px 20px;
  cursor:pointer;
  border:none;
}
.pr-10{
  padding-right: 10rem !important;
}
.w-65{
  width: 65% !important;
}
.outline-none{
  outline:none !important;
}
.cta-button-blog:active {
  outline: none !important;
}
.top-8-rem{
  top:7rem !important;
}
.box-shadow-knowledge{
  box-shadow: 0px 10px 20px #00000029 !important;
}
.pl-0-7{
  padding-left: 0.7rem;
}
.pr-0-7{
  padding-right: 0.7rem;
}
.goto_case_study_button_redirection {
  margin-top: 20px!important;
}
.automate-blog-nav-bar .wrapper_page .bg_header_automation .navbar_right .autoamte_header_top{
  top: 8px !important;
}
.automate-blog-nav-bar .footer_form_container {
  background: #e7fdf4; 
}
.demo-grid-box{
  display: grid;
  grid-template-columns: 60% auto;
  grid-auto-flow: column;
  gap:20px;
}
.demo-grid-box-right{
 align-self:end;
}
.layout-assitant-demo{
  padding-bottom: 80px !important;
}

.gap-10-px{
  gap: 10px;
}
.w-18px{
  width: 18px;
}
.goto_case_grid_container.cognosante-grid-container{
  grid-template-columns: 2fr 1.2fr;
  grid-gap: 80px;
}
.goto_case_right_card.cognosante_right_box{
  padding: 30px 24px 24px;
}
.mb-0-6{
  margin-bottom: 0.6rem !important;
}
.cognosante_case_left_top{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 80px;
}
.solution_case_list_container.solution_list_cognosante{
  background: #E2FBFF;
}
.why_goto_case_right.why_cognosante_right{
  background: #E6FFE7;
  padding: 30px 20px 20px !important;
}
.underline_solution_heading_cog{
  border: 1px solid #7CC8D5;
  width: 80%;
}
.grid_container_goto_second.cog_grid_container_second{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.7fr 2fr;
  grid-gap: 10px;
}
.color-6{
  color: #E452FF !important;
}
.w-20{
  width: 20% !important;
}
.gap-20{
  gap: 20px !important;
}
.cog_team_icon{
  width: 48px;
  height: 48px;
}
.gap-15{
  gap: 15px !important;
}
.related-articles-flex-wrapper {
  display: flex;
  justify-content: center;
  gap: 26px;
}
.related-articles-flex-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 10px 10px 20px #0000001A;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.related-articles-flex-wrapper img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
}
.related-articles-card-bottom {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 4px 4px;
  padding: 24px
}
.new-release-features{
  position: relative;
  top: 1rem;
  color: #ED7823;
}
.new-release-features-beta{
  position: relative;
  top: 1rem;
  color: #ED7823;
}
.content-num-img{
  width: 18% !important;
  height: auto !important;
  align-self: flex-start;
  margin-bottom: 10px;
}
.height-7-rem{
  height: 7rem !important;
}
.background-white{
  background: #FFFFFF !important;
}
.background-grey{
  background: #F8F8F8 !important;
}
.p-40{
  padding: 40px 0 !important;
}
.all-features-new-launch{
  color: #F21D1D;
  position: relative;
  left: 0.5rem;
  bottom: 1rem;
}
.related-article-section{
  margin-top: 50px;
  margin-bottom: 20px
}
.mb-60{
  margin-bottom: 60px !important;
}
.header-new-launch{
  color: #F21D1D;
  position: relative;
  right: 5.8rem;
  bottom: 1rem;
}
.header-new-launch-soon{
  color: #F21D1D;
  position: relative;
}
.footer-new-launch{
  color: #F21D1D;
  position: relative;
  right: -0.3rem;
  top: -0.7rem;
}
.footer-new-launch-coming-soon{
  color: #F21D1D;
  position: relative;
  right: -0.3rem;
  top: -0.7rem;
}
.case-study-home-heart{
  width: 40px;
}
.ad-case-study-bottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.case-study-book-demo{
  background: #2468F6 0% 0% no-repeat padding-box;
  border-radius: 32px;
  padding: 10px 0;
  width: 16%;
  border: none;
  color: white;
}
.ad_home_page_main_container.case_study_home_page_ad{
  background: #E5FFE7 0% 0% no-repeat padding-box;
  margin-bottom: 0 !important;
}
.case-study-section-container{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding-top:30px;
}
.case-study-flex-box{
  display: flex;
  gap:50px;
  position: relative;
  top: 50px;

}
.bottom-box-customer-case{
  display: flex;
  background: #DEEDFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 30px;
  gap:20px;
  margin-top: 30px;
  justify-content: space-between;
}
.case-study-customer-box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #0000001A;
  border-radius: 30px;
  padding:30px;
  flex:1;
}
.dotted-line {
  border-bottom: 1px dashed #707070;
  margin: 22px 0 0;
}
.background-none{
  background: none !important;
}
.box-shadow-none{
  box-shadow: none !important;
}
.goto-testi-case-study-sec{
  background-color: #FFF2EC;
  padding-top: 7rem;
  padding-bottom: 60px;
}
.button-no-border{
  border: none;
  outline: none;
}
.testimonial_background_main_container.cognosante-rating-case-study{
  padding: 50px 0 !important;
  margin-bottom: 0 !important;
}
.pros-cons-hammer-testi{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 50px 0;
  margin-bottom: 50px;
}
.designation_cognosante{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.testimonial_feedback_box.cognosante-box-content{
  padding: 30px 40px;
}
.grid-gap-40{
 grid-gap: 40px !important;
}
.mb-50{
 margin-bottom: 50px !important;
}
.mb-30{
 margin-bottom: 30px !important;
}
.case-study-cta-flex-box{
 display: flex;
 gap: 40px;
 box-shadow: 0px 10px 20px #0000001a;
 border-radius: 20px;
 padding: 60px 40px 40px 40px;
 margin: 0 auto;
}
.case-study-cta-img-box{
  width: 20%;
  height:20%
}
.case-study-cta-content-box-bottom{
  display: flex;
  justify-content: space-between;
}
.case-study-cta-img-blog{
  width: 26%;
  height: 26%;
}
.case-study-cta-flex-box.case-study-cta-flex-box-blog{
  gap: 30px;
  padding: 50px 40px 40px 40px;
}
.list-style-type-counter{
  display: flex;
  gap: 10px;
}
.shared-inbox-content-image{
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.shared-inbox-content-box{
  width: 34%;
}
.shared-inbox-image-box{
  width: 60%;
}
.color-yelow{
  color: #FCD359 !important;
}
.border-solid-grey{
  border: 1px solid #C7C7C7 !important;
}
.mb-10{
 margin-bottom: 10px !important;
}
.easy-platform-section-container{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding:40px 0;
}
.grid-container-easy-platform{
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.grade-security-section-container{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-bottom: 50px;
}
.grid-container-grade-security {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  width: 70%;
  margin: auto;
  row-gap: 20px;
  margin-top: 40px;
}
.enterprise-success-section-container{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 40px 0;
  margin-bottom: 10px;
}
.flex-container-enterprise-success{
  gap:30px
}
.width-fit-content{
  width: fit-content !important;
}
.justify-self-center{
  justify-self: center !important;
}
.mb-32px{
  margin-bottom:32px;
}
.apps_landing_page.apps-integration-knowledge-ai{
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
}
.apps-integration-knowledge-ai .apps_landing_header_wrapper h5{
  background: #E3F2FF 0% 0% no-repeat padding-box !important;
}
.content-item-easy{
  top: 3rem;
  left: 3.5rem;
  padding-right: 60px;
}
.knowledge-ai-landing-section{
  margin-bottom: 40px;
}
.knowledge-ai-landing-section .back_features{
  display:none;
}
.mb-40{
  margin-bottom: 40px !important;
}
.goto-knowledge-ai-section{
  background: #FFF4ED 0% 0% no-repeat padding-box;
  padding: 60px 0;
}
.flex-container-goto-knowledge-ai{
  display:flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.w-10{
  width:10% !important;
}
.flex-1{
  flex: 1 !important;
}
.flex-2{
  flex: 2 !important;
}
.flex-item-goto-knowledge-ai.flex-2 p{
  padding-right: 30%;
}
.w-12 {
  width: 12%!important;
}
.p-0-14-rem{
  padding: 0 14rem;
}
.shared-inbox-knowledge-coming-soon{
  position: relative;
  right: 2.5rem;
}
.line-height-16{
  line-height: 1.6 !important;
}
.mt-40{
  margin-top: 40px !important;
}
.ebook-button-blog{
  background: #FF8879 0% 0% no-repeat padding-box;
  border-radius: 56px;
  width:fit-content;
  padding: 10px 20px;
  border:none;
}
.blog-text-color-padding{
  padding: 20px 14px 10px;
}
.padding-14px{
  padding: 14px;
}
.springed-arrow-assitant{
  position:relative;
  width: 18%;
  align-self: flex-end;
}
.strike-through-con{
  text-decoration: none;
  position: relative;
}
.strike-through-con::before{
  top: 50%; 
  background: #FF8A27; 
  opacity: 0.7;
  content: "";
  width: 110%;
  position: absolute;
  height: 0.2em;
  left: -5%;
  white-space: nowrap;
  display: block;
  transform: rotate(-15deg);
}
.four-in-one{
  width: 7%;
  position: relative;
  right: 60px;
}
.mobile-image-with-box-shadow{
  box-shadow: 0px 5px 10px #00000040;
  margin-top: 20px;
}
.feature-heading-colored{
  background: #CBFFEC;
  padding: 10px;
}
.features_all_case_parent_div.features-all-con-ai {
  background: #EDF7FF;
}
.features_all_case_parent_div.features-all-know-ai {
  background: #FFF7F2;
}
.features_all_case_parent_div.features-all-app-workflow {
  background: #F0FFFA;
}
.features_all_case_parent_div.features-all-shared-inbox {
  background: #F7F2FF;
  padding-bottom: 70px;
}
.bottom-0{
  bottom: 0;
}
.pb-40{
  padding-bottom: 40px !important;
}
.section-coming-end{
  top:26px;
  left:80px;
  position: relative;
}
.gap-10{
  gap:10px;
}
 .support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
  padding: 20px 20px 0px 40px;
  grid-template-columns: 1fr 1.5fr;
 }
 .features_all_case_parent_div .support_it_hr_main_container .support_content_container .it_support_left_content_container{
  padding-right:0;
 }
.bottom-30px{
  bottom: 30px;
}
.w-95{
  width:95%;
}
.omini-channel-content-start{
  justify-content: center !important;
}
.display-block{
  display: block !important;
}

.section-header-menu-assistants{
  width:100%;
  float:left;
}
.header-menu-flex-container{
  display: flex;
  width: 1240px;
  margin: auto;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000040;
  border-radius: 20px;
  padding: 30px 80px;
}
.integration-menu-white-box{
  width: 1206px;
  margin: auto;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000040;
  border-radius: 20px;
  padding: 30px 50px;
  justify-content: space-between;
}
.integration-menu-flex-container{
  margin-bottom: 30px;
}
.header-menu-assistants-box{
  margin-right: 30px;
}
.sub-menu-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sub-menu-header-description{
  width: 210px;
}
.sub-menu-header-description-last-before{
  width: 200px;
}
.sub-menu-icon img{
  width: 40px;
}
.sub-menu-items{
  transition: transform 0.3s ease-in-out; 
  padding: 10px;
  position: relative;
  right: 10px;
  display:flex;
  align-items: flex-start;
  gap: 8px;
}
.sub-menu-items:hover{
  background: #C6FFDD 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
}
.header-menu-assistants-lastbox{
  border-left:2px solid #A2A2A2;
  padding-left: 40px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.color-inherit{
 color:inherit !important;
}
.integration-menu-assistants-box{
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-right: 20px;
}
.integrations-menu-icon-app-name-flex-box{
  display: flex;
  gap: 5px;
  align-items: center;
}
.integrations-menu-icon-app-name-flex-box h3{
  margin-top: 2px;
}
.integrations-menu-icon{
  width: 20px;
  height:20px;
}
.integrations-menu-icon-app-name-flex-box:hover{
  cursor: pointer;
}
.integrations-menu-icon-app-name-flex-box h3:hover{
  color: #2468F6;
  text-decoration: underline;
}
.marketplace-header-menu-container{
  float: left;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000040;
  border-radius: 20px;
  padding: 10px 20px;
  display:flex;
  flex-direction: column;
  position:relative;
  right:7%;
}
.marketplace-header-menu-flex-box{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 18px;
  padding:20px;
}
.marketplace-header-menu-flex-box:hover{
  background: #C6FFDD 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-radius: 8px;
}
.marketplace-header-menu-icon-box img{
  width:48px;
  height:48px;
}

.learn-header-menu-container{
  float: left;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000040;
  border-radius: 20px;
  padding: 20px 60px 20px 30px;
  display:flex;
  flex-direction: column;
  gap:16px;
  position:relative;
  right:14%;
}
.learn-header-menu-flex-box{
  display: flex;
  align-items: center;
  gap: 18px;
}
.learn-header-menu-flex-box{
  cursor: pointer;
}
.learn-header-menu-description-box h3:hover{
  color:#2468F6;
}
.arrow-header-menu-active{
  border-bottom: 15px solid #fff;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  position:absolute;
  bottom:4px;
}
.drop-down-box-mega-menu {
  top: 89% !important;
}
.drop-down-box-small-menu{
  left: -90% !important;
}

.drop-down-box-small-menu::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-bottom: 15px solid #fff;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
}
.drop-down-box-small-menu-learn::before{
  position: absolute;
  bottom: 100%;
  left: 30% !important;
}
.drop-down-box-small-menu a{
  color: #000000 !important;
}
.arrow-header-menu-deactive{
 visibility: hidden;
}
.features-menu-icon-mobile img{
  width: 50px;
}
.background-color-grey{
 background: #F8F8F8 0% 0% no-repeat padding-box !important;
}
.mob_header_wrapper .dropdown_li .section-mobile-header-new a {
 background: transparent;
 border:none;
 display:inline;
 line-height: 0%;
 margin: 0 !important;
 padding: 0 !important;
}
.div-wrapper-box-header-menu-mobile{
  margin: 0 5%;
  padding: 30px 0 40px;
}
.div-wrapper-box-header-menu-mobile-usecase{
  margin: 0 6.3%;
  padding: 30px 0 6px;
}
.div-wrapper-box-header-menu-mobile-marketplace {
  margin: 0 2%;
  padding: 30px 0 10px;
}
.mob_header_wrapper .navbar_right ul li.sticky_pad_0 .submenu_ul .svg_roate_header.sub-name-header-menu{
  background:#FFFFFF;
}
.blog-home-section{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 30px 0 40px;
  margin-bottom: 40px;
}
.flex-container-blog-home {
  display: flex;
  flex-wrap: wrap;
  column-gap: 26px;
  row-gap: 20px;
}
.flex-wrapper-blog-home {
  width: calc(25% - 20px); 
  margin-bottom: 20px;
  box-sizing: border-box;
}
.search-bar-div-blog{
  display: flex;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  align-items: stretch;
  overflow: hidden;
  width:100%;
  padding: 4px 0;
  margin-bottom: 40px;
}
.search-blog-names{
  border:none;
  font-size: 15px;
  width:100%;
}
.search-blog-names:focus{
  outline: none;
}
.search-blog-names::placeholder{
  font-family: "Gordita-Regular";
  font-size:20px;
  color:#999999;
} 
.search-icon-blog-home{
  margin: 10px 10px 10px 20px;
  color: #555555;
  height: 24px;
}
.blog-home-header-flex-container{
  display: flex;
  gap: 40px;
  align-items: center;
}
.blog-home-header-flex-box-left {
  flex: 60%;
}
.blog-home-header-flex-box-right {
  flex: 40%;
}
.read-blog-button{
  background:#2468F6;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 24px;
  border:none;
  color:#ffffff;
}
.read-blog-button:focus {
  border: none;
  outline: none;
}
.read-blog-button:active {
  border: none;
  outline: none;
}
.blog-name-hover{
  transition: color 0.3s, text-decoration 0.3s; 
}
.blog-name-hover:hover{
  color:#2468F6 !important;
  text-decoration: none;
}
.blog-image-hover img{
  transition: box-shadow 0.3s ease;
}
.blog-image-hover:hover img{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
}
.blog-home-button{
  background: none;
  border-radius: 32px;
  border: 1px solid #2468F6;
  width: 38px;
  height:38px;
  color:#000;
  text-align: center;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
  padding-top: 4.5px;
}
.blog-home-button:hover , .blog-home-active-button {
  background:#2468F6;
  border:none;
  border-radius:100%;
  color:#ffffff;
}
.blog-name-hover:hover{
  color:#2468F6;
}
.blog-home-button:focus{
outline: none;
}
.left-arrow-blog-home{
  width: 14px;
  height: 14px;
}
.right-arrow-blog-home{
  width: 14px;
  height: 14px;
  rotate: 180deg;
}
.blog-home-button:hover .right-arrow-blog-home,.blog-home-button:hover .left-arrow-blog-home {
  filter: brightness(0) invert(1);
}
.pt-3-5{
 padding-top: 3.5px !important;
}
.mb-18{
 margin-bottom: 18px !important;
}
.mt-12{
  margin-top: 12px !important;
}
.pb-pt-40{
  padding: 40px 0 40px;
}
.two-line-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.three-line-text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.mb-16{
  margin-bottom:16px !important;
}
.mb-30{
  margin-bottom: 30px !important;
}
.bottom-40{
  bottom: 40px !important;
}
.grid-layout-onboarding-large{
  grid-template-columns: 1fr 1.78fr !important;
  padding: 20px 6px 0px 40px !important;
}
.grid-layout-onboarding-bigger{
  grid-template-columns: 1fr 2fr !important;
  padding: 10px 6px 0px 40px !important;
}
.grid-layout-large-support{
  grid-template-columns: 1fr 1.7fr !important;
  padding: 16px 10px 0px 30px !important;
}
.grid-layout-little-large-support{
  grid-template-columns: 1fr 1.5fr !important;
  padding: 0px 6px 0px 40px !important;
}
.grid-layout-large-small-support{
  grid-template-columns: 1fr 1.5fr !important;
  padding: 16px 10px 0px 30px !important;
}
.grid-layout-chat-channel{
  grid-template-columns: 1fr 1.3fr !important;
  padding: 0px 10px 0px 30px !important;
}
.onboarding-grid-layout-height .onboard_content_left_container{
  padding-right:0 !important;
}
.grid-layout-little-large-support .support_content_right_container {
  align-self: center;
}
.grid-layout-onboarding-bigger .onboard_content_left_container{
  padding-right:0 !important;
}
.header-count-wih-image-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.header-count-image{
  width: 5rem;
  position: absolute;
  left: 5rem;
}
.support_it_hr_main_container .header-count-wih-image-container h4{
  margin-bottom: 0 !important;
}
.col-lg-6.image-box-home{
  max-width: 53%;
  flex: 0 0 53%;
  padding-left: 0;
  padding-right: 0;
}
.col-lg-6.content-box-home{
  max-width: 47%;
  flex: 0 0 47%;
}
.bottom-60px{
  bottom:60px;
}
.goto__case__video, .goto__case__video__mobile{
  display:flex;
  justify-content: center;
}
.goto__case__video img{
  width: 95%;
  margin-bottom: 20px;
}
.goto__case__video__mobile{
  display:flex;
  justify-content: center;
  margin-bottom: 10px;
}

.grid-layout-large-small-support .support_content_left_container .position-absolute.bottom-0 {
  bottom: 28px;
}
.padding-160{
  padding: 0 160px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.pricing-assist-main-container-upt{
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
}
.table_full_compare .table tr th:nth-child(6) {
  all: initial;
}
.table_full_comapare.table-new-assistants .table tr th:nth-child(1) {
 width: 16%;
}
.first-category-two-line{
  margin-bottom: 16px;
}
.pr-20{
  padding-right: 20px;
}
.most_popular.most-popular-assistants{
  right: 18px !important;
}
.pl-40{
  padding-left: 40px !important;
}
.head-section-landing{
  background: #CCFFEC 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 40px 0px
}
.flex-container-landing {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.left-flex-item-landing {
  flex: 0 0 34%;
}
.button-landing{
  background: #2468F6 0% 0% no-repeat padding-box;
  border-radius: 30px;
  width:fit-content;
  padding: 6px 24px 5px;
  cursor: pointer;
  border:none;
  color:#FFFFFF;
}
.button-landing:focus{
  outline:none;
}
.right-flex-item-landing {
  flex: 0 0 56%;
}
.apps-section-landing {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin: 40px 0 20px;
}
.tabs__section__container__landing{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 40px 0;
}
.tabs__wrapper__landing{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}
.tab__items__button__icon{
  display: flex;
  width: fit-content;
  padding: 0 0px 10px;
  cursor: pointer;
}
.active__tab__items__button__icon{
  color: #246DF4;
  border-bottom: 3px solid #246DF4;
}
.active__tab___button{
  color: #246DF4;
}
.tab__icon__landing{
  width: 50px;
  height: 50px;
}
.tab__button__landing{
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
}
.tab__button__landing:focus{
  outline: none;
}
.tabs__content__container__landing{
  display: grid;
  gap: 20px;
  align-items: center;
  width: 100%;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2fr;
  margin-top: 2rem;
}
.tabs__text__content__landing{
  display: flex;
  flex-direction: column;
  align-items: start;
}


.flex-box-testi-lan{
  display: grid;
  margin-top: 4rem;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  gap: 10rem;
}
.right-box-testi-lan{
  display: flex;
  gap: 40px;
  justify-content: flex-end;
}
.testi-box-lan{
  position: relative;
  padding: 40px 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 20px;
  width: 44%;
  justify-content: space-between;
}
.quotes__testi__lan{
  position: absolute;
  width: 20%;
  height: auto;
  top: -40px;
}
.testi-box-top {
  display: flex;
  align-items: stretch;
  height: 240px;
}
.testi-box-bottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.client__image__testi__lan{
  width: 100px;
  height: 100px;
}
.client__logo__image__testi__lan {
  width: 55%;
  height: auto;
}
.book-demo-sign-up-section-landing{
  background: #EDF1FF 0% 0% no-repeat padding-box;
  padding: 60px 0;
  margin-bottom: 50px;
}
.flex-container-demo-signup-landing{
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.flex-container-demo-signup-landing.flex-container-demo-signup-landing-with-form{
  display: flex;
  justify-content: space-around;
  gap: 8rem;
}
.left-item-demo-signup-lan{
  align-self: flex-end;
}
.left-box-testi-lan, .right-item-demo-signup-lan-form{
  display: flex;
  flex-direction: column;
}
.demo-signup-list-box{
  padding: 20px 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.testimonials-section-landing{
  margin-bottom: 60px;
}
.font-section-small-text-medium-apps.custom__lineheight{
  line-height: 1.8 !important;
}
.right-item-demo-signup-lan{
  border: 1px solid #B9B9B9;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.1);
  padding: 30px 20px;
  z-index: 10;
  width: 500px;
}
.right-item-demo-signup-lan-form{
  width: 500px;
  align-self:center;
}
.springed__arrow__landing{
  width: 18%;
  float:right;
}
.justify-space-between{
  justify-content: space-between;
}
.footer__landing{
  padding: 20px 0px 16px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
}
.header__landing {
  width: 100%;
  float: left;
  z-index: 1000;
  height: 60px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}
.header__logo__landing {
  width: 10%;
}
.sticky__header__landing {
  background-color: #ffffff; 
}


.box__paid__ad__table{
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  opacity: 1;
  padding: 60px 40px 60px 30px;
  margin: auto;
}
.paid__ad__tbody__tr{
  position: relative;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  content: '';
  position: absolute;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 0.05;
  width: 100%;
  height: 104px;
  left: 0;
  top: 5px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  content: '';
  position: absolute;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 0.05;
  width: 100%;
  height: 70px;
  left: 0;
  top: 5px;
}
.table__heading__paid__ad{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 25px 20px 25px 20px;
} 

.table__description__paid__ad {
  padding: 25px 20px 25px 20px;
  width: 29rem;
}
.last__box__table{
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 25px 20px 55px 20px;
}
.last__box__table__only__padding{
  padding: 25px 20px 55px 20px;
}

.empty__element__heading, .empty__element__description{
  padding: 0px 20px;
}

.table__section__paid__ad{
  margin-bottom: 60px;
}

.wide-head-section-landing{
  background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 78% no-repeat;
  padding: 60px 0 0;
  margin-bottom: 40px;
}
.demo-wide-head-section-landing{
  background: #CCFFEC;
  padding: 30px 0 40px;
  margin-bottom: 40px;
}
.content-box-wide-landing{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom:20px;
}
.demo-content-box-wide-landing{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap:10px;
  margin-bottom: -10px;
}
.header__cointainer__form__paid__ad{
  width: 55%;
}
.header__form__paid__ad{
  margin-top: 0 !important;
}
.container__form__paid__ad{
  width: 100%;
}
.form__paid__ad{
  display: flex !important;
  flex-direction: column !important;
  gap: 20px;
  margin-top: 0 !important;
}
.form__paid__ad input{
  border-radius: 32px !important;
  border-right: 1px solid #afafaf !important;
  margin-right: 0 !important;
}
.form__paid__ad button{
  border-radius: 32px !important;
}
.paid__ad__book__demo__integrate__icons{
  gap: 10px;
  align-items: center;
  margin-top: -3px;
}
.paid__ad__book__demo__integrate__icons img{
  width: 13%;
  height: 13%;
}
.limited__spot__text{
  width: fit-content;
  position: relative;
  left: 11rem;
  background: #FFE8E8 0% 0% no-repeat padding-box;
  border-radius: 14px;
  color: #D80000;
  padding: 5px 10px 4px;
  margin-top: 10px;
}
.limited__spot__text__testimonials, .limited__spot__text__tabs, .limited__spot__text__bookdemo{
  width: fit-content;
  background: #FFE8E8 0% 0% no-repeat padding-box;
  border-radius: 14px;
  color: #D80000;
  padding: 5px 10px 4px;
  margin-top: 10px;
  align-self: center;
}
.demo-wide-landing-number-box{
  display: flex;
  gap: 10px;
}
.demo-wide-landing-number{
  display: flex;
  gap: 5px;
  align-items: center;
}
.color-sky{
  color: #628ADB !important;
}
.demo-wide-landing-number img{ 
  width: 40px;
  height: 40px;
}
.w_assistants_logo_demo_lan{
  width: 216px;
}
.book-demo-paid-landing-parent .testimonial_background_main_container {
  margin-bottom: 0px;
}
.book-demo-paid-landing-parent .footer__landing{
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.calendly-inline-widget iframe ._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv{
  margin-top: 0px;
}
.book-demo-calendly-paid-lan{
  background: #CCFFEC 0% 0% no-repeat padding-box;
  padding: 50px 0 30px;
  margin-bottom: 50px;
}
.book-demo-box-calendly{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -50px;
}
.demo__testimonial{
  display: flex;
  gap: 4rem;
  align-items: center;
  margin-top: 50px;
}
.demo__testimonial__left{
 flex: 21%;
}
.demo__testimonial__right{
 flex: 70%;
 display: flex;
 background: #CCFFEC;
 box-shadow: 0px 10px 20px #0000001A;
 border: 1px solid #D0D0D0;
 border-radius: 20px;
 gap: 20px;
 padding: 40px 20px 30px 25px;
}
.demo__testimonial_right__subleft{
  flex: 60%;
}
.demo__testimonial_right__subright{
  align-self: flex-start;
  flex: 30%;
}
.demo__testimonial-image__goto{
  width: 5rem;
}
.demo__testimonial-image__manager{
  width: 80%;
  margin-bottom: 20px;
}
.quotes__demo__testi{
  width: 26px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.pt-40{
  padding-top:40px !important ;
}
.p-50{
  padding: 50px 0 !important;
}
.list__items__paid__tick{
  position: relative;
  top: 1px;
  width: 18px;
}

.cookie-container {
  position: fixed;
  bottom: 40px; 
  left: 40px;   
  background-color: white;
  padding: 22px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 40%;
  border-radius: 10px;
}

.cookie-box-left{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cookie-box-left-button{
  display: flex;
  gap: 14px;
}

.close-image-cookie{
  width: 30px;
  height: 30px;
}
.cookie-button-preference-accept{
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  border: 3px solid #2468f6;
  background-color: #ffffff;
  color: #2468f6;
  cursor: pointer;
  border-radius: 50px;
}
.cookie-button-preference-accept:hover{
  background-color: #2468f6;
  color: #ffffff;
}
.blur-background-preference {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1500; 
}

.preference-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 2000;  
  border-radius: 8px; 
  padding-bottom: 20px;
  width: 40%;
}
.preference-popup{
  display: grid;
  overflow: scroll;
}
.preference-heading-box{
  display: flex;
  padding: 20px 20px 0;
  justify-content: space-between;
}
.list-box-preference{
  width: 100%; 
  padding: 20px 20px 0;
}
.border-bottom-one{
  border-bottom: 1px solid #d8d8d8;
}
.button-preference:focus, .confirm-my-choice:focus, .cookie-button-preference-accept:focus{
  outline: none !important;
}
.confirm-my-choice{
  justify-self: end;
  background-color: #3973e6;
  border-color: #3973e6;
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 20px;
  padding: 10px;
  border: none;
  border-radius: 50px;
}

.list-style-disc{
  list-style: disc !important;
}

.display-list-item{
  display: list-item !important;
}

.color-alternative-blue{
  color: #3897FF !important;
}
.mv-al-icon{
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
}
.moveworks-left-side-content{
  width: 70%;
}
.moveworks-right-side-image{
  width: 30%;
}